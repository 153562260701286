import React from 'react';
import WikiDisplay from "./WikiDisplay";
import {inject, observer} from 'mobx-react';
import {PanoIconLightShadowed, PinIconLightShadowed, WikipediaIconLightShadowed} from "../../svg/Icons";
import Api, {MapsApi} from "../../components/Api";

let makeLinks = (poiData, mini) => {
    let links = [];
    let lat = Math.round(poiData.properties.lat*100000)/100000;
    let lon = Math.round(poiData.properties.lon*100000)/100000;
    let zoom = poiData.properties.suggestedZoom;
    links.push({
        icon: PinIconLightShadowed,
        url: `https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=${zoom}`,
        title: mini ? "G.Maps" : "Google Maps"
    });
    links.push({
        icon: PanoIconLightShadowed,
        url: `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lon}`,
        title: mini ? "G.Pano" : "Google Pano"
    });
    if (poiData.properties.data["wikipedia"]) {
        links.push({
            icon: WikipediaIconLightShadowed,
            url: `https://en.wikipedia.org/wiki/${poiData.properties.data["wikipedia"]}`,
            title: "Wikipedia"
        });
    }
    /*if (poiData.properties.data["wikidata"]) {
        links.push({
            icon: WikidataIconLightShadowed,
            url: `https://www.wikidata.org/wiki/${poiData.properties.data["wikidata"]}`,
            title: mini ? "Wikid." : "Wikidata"
        });
    }
    if (poiData.properties.osmId && poiData.properties.osmType) {
        let types = {
            "N" : "node",
            "R": "relation",
            "W": "way"
        };
        let type = types[poiData.properties.osmType];
        links.push({
            icon: OSMIconLightShadowed,
            url: `https://www.openstreetmap.org/${type}/${poiData.properties.osmId}`,
            title: "OSM"
        });
    } else {
        links.push({
            icon: OSMIconLightShadowed,
            url: `https://www.openstreetmap.org/#map=${zoom}/${lat}/${lon}`,
            title: "OSM"
        });
    }*/
    return links;
};

let makeAiDataGetter = (poiData) => {
    let wikipedia = poiData.properties.data["wikipedia"];
    if (wikipedia) {
        return async () => {
            return await MapsApi.get(`/poi/insights/plusminus/hash/${poiData.properties.hash}`);
        }
    } else {
        return null;
    }
};

export default inject("appState")(observer(({poiData, zoomFunction, appState}) => <WikiDisplay
    key={poiData.properties.id}
    displayData={poiData}
    getId={(poiData) => poiData.properties.id}
    getName={(poiData) => poiData.properties.data["name"]}
    getImage={(poiData) => poiData.properties.image}
    getWikipedia={(poiData) => poiData.properties.data["wikipedia"]}
    getCustomDescription={(poiData) => poiData.properties.data["custom:generated_description"]}
    links={makeLinks(poiData, appState.media.mobile)}
    getIcon={(poiData) => poiData.properties.icon}
    zoomTo={() => zoomFunction(poiData.properties.lat, poiData.properties.lon, poiData.properties.suggestedZoom)}
    getAiData={makeAiDataGetter(poiData)}
/>));

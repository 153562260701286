
class WindowHelper {

    get NAV_HEIGHT(){ return 42; }
    get BORDER_AMOUNT(){ return .975; }

    constructor(){
        this.recalc();

        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
        }
        window.addEventListener('resize', appHeight);
        appHeight();
    }

    isMobile() {
        return this.isSkinny();
    }

    isShort(){
        return window.innerHeight <= 540;
    }

    isSomewhatShort(){
        return window.innerHeight <= 640;
    }

    isSkinny(){
        return window.innerWidth <= 780;
    }

    isNearSkinny(){
        return window.innerWidth <= 1000;
    }

    adjustedInnerHeightMin(){
        return this.innerHeightAdjMin;
    }

    adjustedInnerHeightMax(){
        return this.innerHeightAdjMax;
    }

    realInnerHeight(){
        return Math.max(window.innerHeight, this.NAV_HEIGHT + 10);
    }

    onResize(forceMobile){
        let mobile = this.isMobile() || forceMobile;
        if(!mobile || this.realInnerHeight() < this.innerHeightAdjMin || this.realInnerHeight() > this.innerHeightAdjMin + 120){
            this.innerHeightAdjMin = this.realInnerHeight();
        }
        if(!mobile || this.realInnerHeight() > this.innerHeightAdjMax || this.realInnerHeight() < this.innerHeightAdjMax - 120){
            this.innerHeightAdjMax = this.realInnerHeight();
        }
    }

    recalc(){
        this.innerHeightAdjMin = this.realInnerHeight();
        this.innerHeightAdjMax = this.realInnerHeight();
    }

    stick(a, b, amt){
        if(Math.abs(a-b) < amt){
            return b;
        } else {
            return a;
        }
    }
}

export default new WindowHelper();
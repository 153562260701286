import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap'

export default (props) => {
    let onClick = (e) => {
        if (e && !e.ctrlKey && !e.metaKey) {
            if (props.onNav) {
                props.onNav();
            }
            if (!props.noScroll) {
                window.scrollTo({left: 0, top: 0, behavior: "auto"});
            }
        }
        if (props.onClick){
            props.onClick(e);
        }
    };
    return (
        <LinkContainer onClick={onClick} to={props.to} isActive={() => false} id={props.id} data-attr={props['data-attr']}>
            {props.children}
        </LinkContainer>
    );
}
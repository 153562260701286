import React, { useRef, useEffect } from 'react';

export const compareExcludeChildren = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => {
          return obj2.hasOwnProperty(key) && obj1[key] === obj2[key] || key === "children";
      }
  );

export const memoIgnoreChildren = (c) => React.memo(c, compareExcludeChildren);

export const useIsUnmounted = () => {
    const isUnmounted = useRef(false);
    useEffect(() => {
        isUnmounted.current = false;
        return () => isUnmounted.current = true;
    }, []);
    return isUnmounted;
};

export const useComponentWillMount = (func) => {
    const willMount = useRef(true);
    if (willMount.current) { func() }
    willMount.current = false;
}
import React from 'react';
import TabbableInfiniteScrollList, {Subtext} from "./TabbableInfiniteScrollList";
import Breadcrumbs from "./Breadcrumbs";
import {Helmet} from "react-helmet";

export default ({label, subtext, ...others}) => {
    return <TabbableInfiniteScrollList
        header={
            <>
                <Helmet>
                    <title>{label}</title>
                </Helmet>
                <Breadcrumbs noBottomPadding children={label}/>
                {subtext ? <Subtext>{subtext}</Subtext> : null}
            </>
        }
        {...others}
    />
};
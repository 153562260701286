import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const tagCss = css`
  text-transform: lowercase;
  white-space: nowrap;
  &::before {
    content: "#";
    opacity: .3;
  }
`;

export const TagSpan = styled.span`
  ${tagCss};
`;

import Config from "../Config";

let path = Config.APP_IMAGES_BASE_URL + "/";
export default async function getSignedUrl(filename){
    /*let key = "f:" + filename;
    let stored = localStorage.getItem(key);
    if(stored){
        return Promise.resolve(LZString.decompress(stored));
    } else {
        let result = await fetch(Config.APP_API_BASE_URL + "/photo/url/" + encodeURIComponent(filename));
        let signedFilename = await result.text();
        localStorage.setItem(key, LZString.compress(signedFilename));
        return signedFilename;
    }*/

    return Promise.resolve(path + filename);
}

let cacheBust = () => {
    return "?o=" + encodeURIComponent(location.origin);
}

export function getSignedUrlSync(filename){
    return path + filename;// + cacheBust();
}

import React from 'react';
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";
import styled from "@emotion/styled";
import {ExternalLinkIconLightShadowed, HelpIconLightShadowed} from "../svg/Icons";

const Label = styled.div`
  text-align: center;
  text-shadow: 0px 2px 1px rgba(0,0,0,.8);
  line-height: 1.25;
  color: ${Theme.colors.kindaDim};
`;

const LabelThick = styled(Label)`
  ${typeface(14, 500)};
  margin-left: 8px;
`;

const LabelThin = styled(Label)`
  ${typeface(14, 500)};
  margin-left: 6px;
  margin-right: 6px;
`;

const HelpA = styled.a`
  color: ${Theme.colors.notQuiteDim};
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  
  box-shadow: 0 0 0px 1px rgba(255,255,255,.125) inset, 0 2px 4px 1px rgba(0,0,0,.25);
  &:hover, &.focus-visible {
    color: ${Theme.colors.almostDim};
    box-shadow: 0 0 0px 1px rgba(255,255,255,.5) inset, 0 2px 4px 1px rgba(0,0,0,.5);
    
    ${Label} {
      color: ${Theme.colors.almostWhite};
    }
  }
  background: ${Theme.bgs.metal30};
`;

const HelpAThick = styled(HelpA)`
  border-radius: 10px;
  padding: 8px 12px;
`;

const HelpAThin = styled(HelpA)`
  padding: 6px 10px;
  border-radius: 10px;
`;

export const CenterHelp = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`

export const HelpLinkThick = ({path}) => {
    return <HelpAThick href={path} target={"_blank"}>
        <HelpIconLightShadowed scale={28}/>
        <LabelThick>View<br/>Help</LabelThick>
    </HelpAThick>;
};

export const HelpLinkThin = ({path}) => {
    return <HelpAThin href={path} target={"_blank"}>
        <HelpIconLightShadowed scale={14}/>
        <LabelThin>View Help</LabelThin>
        <ExternalLinkIconLightShadowed scale={10}/>
    </HelpAThin>;
};
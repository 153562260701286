import React from 'react';
import styled from '@emotion/styled'
import UserLink from "./UserLink";
import {typeface} from "../styles/Typeface";
import Breadcrumbs from "./Breadcrumbs";
import {inject, observer} from "mobx-react";

const UserLinkStyled = styled(UserLink)`
  ${p => p.mobile ? typeface(20, 700) : typeface(24, 700)};
`;

export default inject("appState")(observer((props) => {
    let sections = props.sections ? props.sections : (props.section ? [props.section] : []);
    return props.user ?
        <Breadcrumbs noBottomPadding={props.noBottomPadding}>
            {[<UserLinkStyled key="userlink" user={props.user} mobile={props.appState.media.mobile}/>].concat(sections) }
        </Breadcrumbs>
        : null;
}));
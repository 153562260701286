import React, {useLayoutEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {runInAction} from 'mobx';
import styled from '@emotion/styled'

const Overlay = styled.div`
    background: ${p => p.bg};
`;

export default inject("appState")(observer(({appState, bg, children, overlay}) => {
    useLayoutEffect(() => {
        if (appState.globalBackground === null){
            runInAction(() => appState.globalBackground = bg);
            return () => runInAction(() => appState.globalBackground = null);
        }
    }, []);

    return overlay ? <Overlay bg={bg}>{children}</Overlay> : <>{children}</>;
}));
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import {
  tagSource,
  tagTarget,
  dragSource,
  dropCollect,
} from 'react-tag-input/dist-modules/components/DragAndDropHelper';
import { canDrag } from 'react-tag-input/dist-modules/components/utils';
import styled from '@emotion/styled';
import {DeleteIcon, XIcon} from "../../svg/Icons";

const ItemTypes = { TAG: 'tag' };

function flow(...funcs) {
  const length = funcs.length
  let index = length
  while (index--) {
    if (typeof funcs[index] !== 'function') {
      throw new TypeError('Expected a function')
    }
  }
  return function(...args) {
    let index = 0
    let result = length ? funcs[index].apply(this, args) : args[0]
    while (++index < length) {
      result = funcs[index].call(this, result)
    }
    return result
  }
}

const RemoveHolder = styled.div`
  height: 0;
  width: 28px;
  display: inline-block;
`;

const RemoveComponent = (props) => {
  const { removeComponent, onRemove, className, tag, index } = props;

  if (removeComponent) {
    const Component = removeComponent;
    return <Component {...props} />;
  }

  return (
    <button
      onClick={onRemove}
      className={className}
      aria-label={`Tag at index ${index} with value ${tag.id} focussed. Press backspace to remove`}>
      <XIcon scale={12}/>
    </button>
  );
};

class Tag extends Component {
  render() {
    const { props } = this;
    const label = props.tag[props.labelField];
    const {
      connectDragSource,
      isDragging,
      connectDropTarget,
      readOnly,
      tag,
      classNames,
    } = props;
    const { className = '' } = tag;
    let ElementType = props.clickable ? 'button' : 'span';
    const tagComponent = (
        <div style={{display: "inline-block", position: "relative", margin: "3px"}}>
          <ElementType
              className={`tag-wrapper ${classNames.tag ? classNames.tag : ''} ${className ? className : ''}`}
              style={{
                opacity: isDragging ? 0 : 1,
                cursor: canDrag(props) ? 'move' : undefined
              }}
              onClick={props.onTagClicked}
          >
            {label}
            {readOnly ? null : <RemoveHolder/>}
          </ElementType>
          { !readOnly ?
            <div style={{opacity: isDragging ? 0 : 1, position: "absolute", top: "3px", right: "3px", display: "inline-block", width: "24px", height: "24px"}}>
              <RemoveComponent
                  tag={props.tag}
                  className={classNames.remove}
                  removeComponent={props.removeComponent}
                  onRemove={props.onDelete}
                  index={props.index}
              />
            </div>
            : null
          }
        </div>
    );
    return connectDragSource(connectDropTarget(tagComponent));
  }
}

export default flow(
  DragSource(ItemTypes.TAG, tagSource, dragSource),
  DropTarget(ItemTypes.TAG, tagTarget, dropCollect)
)(Tag);
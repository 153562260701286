import styled from '@emotion/styled';
import Theme from "./Theme";
import React, {memo} from 'react'
import {typeface} from "./Typeface";

export const ModalButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 80px;
  width: 100%;
  //position: absolute;
  left: 0;
  bottom: 0;
`;

export const ModalButtonsDivRelative = styled(ModalButtonsDiv)`
  height: auto;
  position: relative;
`;

export const ModalContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 8px;
  z-index: 10;
  contain: content;
`;

export const ModalContentDivRelative = styled(ModalContentDiv)`
  height: auto;
  position: relative;
`;

export const ModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ModalLabelDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ModalCenterTextDiv = memo(styled.div`
  text-align: center;
`);

export const ModalTextDivFlex = memo(styled(ModalLabelDiv)`
  justify-content: center;
`);

export const ModalUnboundedDiv = memo(styled.div`
  text-align: center;
`);

export const Explanation = styled.div`
  color: ${Theme.colors.almostDim};
  text-align: center;
  margin-bottom: 10px;
  
  ul {
    text-align: left;
    padding-left: 18px;
    margin-bottom: 0px;
  }
`;

export const ExplanationSmall = styled(Explanation)`
  ${typeface(14, 400)};
`;

export const ExplanationSectionWithHelp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ExplanationHelpHolder = styled.div`
  margin-left: 24px;
  margin-bottom: 10px;
`;

export const IconLabel = styled.div`
  ${typeface(12, 500)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  pointer-events: none;
  transition: opacity 300ms;
  position: relative;
  top: 8px;
  white-space: nowrap;
`;

export const IconHolder = styled.div`
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${p => p.scale || 30}px;
  width: ${p => p.scale || 30}px;
  min-height: ${p => p.scale || 30}px;
  min-width: ${p => p.scale || 30}px;
`;

export const VerticalIconButton = styled.button`
  width: ${p => p.scale || 30}px;
  height: ${p => p.scale || 30}px;
  margin: 2px;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 0px 1px rgba(255,255,255,.1) inset, 0 0 3px rgba(0,0,0,.3);
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  user-select: none;
  color: ${Theme.colors.notQuiteDim};
  pointer-events: none;
  ${IconLabel} {
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    color: ${Theme.colors.almostWhite};
    box-shadow: 0 0 0px 1px rgba(255,255,255,.3) inset, 0 0 3px rgba(0,0,0,.3);
    ${IconLabel} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const VerticalIconButtonNaked = styled(VerticalIconButton)`
  box-shadow: none;
  margin: 0;
  display: inline-flex;
`;

export const CenteredIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormGroupDiv = styled.div`
  display: block;
  margin: 10px;
  text-align: left;
`;

export const FormLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
`;

export const FormLabelDiv = styled.div`
  display: inline-block;
  width: 180px;
  margin-left: 12px;
  color: ${Theme.colors.almostWhite};
  ${typeface(14)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5)
`;

export const FormLabelAlmostDivDim = styled.div`
  display: inline-block;
  width: 180px;
  margin-left: 12px;
  color: ${Theme.colors.almostDim};
  ${typeface(14)};
`;

export const ModalItemsHolder = memo(styled.div`
  width: 100%;
  height: 174px;
  overflow: scroll;
  padding: 10px;
  text-align: left;
`);

export const ModalListScroller = memo(styled.div`
  margin-top: 10px;
  border: 1px solid rgba(255,255,255,.065);
  border-radius: 10px;
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,.65) inset;
  background-color: ${Theme.colors.backgroundLight};
  width: 100%;
`);
import React, {useEffect} from 'react'
import Api from "./Api";
import {ErrorBoundary} from 'react-error-boundary'
import Bowser from 'bowser'
import styled from '@emotion/styled';
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";

const sendError = (app, type, exception, info) => {
    let detail = {
        app: app,
        location: window.location.href,
        browser: Bowser.parse(window.navigator.userAgent),
        type: type
    }
    let error = {
        name: exception.name,
        message: exception.message,
        stack: exception.stack
    };
    Api.post("/error", {detail, error, info});
}

let postedError = false;
const handleRenderError = (app) => (exception, info) => {
    if (!postedError) {
        sendError(app, "render", exception || {}, info);
    }
    postedError = true;
}
const handleWindowError = (app) => (exception) => {
    if (!postedError) {
        sendError(app, "window", exception.error || {}, {});
    }
    postedError = true;
}
const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--app-height);
  width: 100vw;
  background: ${Theme.colors.backgroundDarker};
`;
const Box = styled.div`
  padding: 40px;
  box-shadow: 0 0 0px 1px rgba(255,255,255,.15) inset, 0 1px 10px 0 rgba(0,0,0,.5);
  border-radius: 20px;
  background: ${Theme.colors.backgroundDark};
`;
const BigText = styled.div`
  color: ${Theme.colors.kindaDim};
  ${typeface(22, 500)};
  text-align: center;
`;
const SmallText = styled.div`
  color: ${Theme.colors.dim};
  ${typeface(16, 500)};
  text-align: center;
`;
const Fallback = () => <Page>
    <Box>
        <BigText>An error occurred. Please reload the page.</BigText>
        <SmallText>(Sorry about that. We will try to fix it.)</SmallText>
    </Box>
</Page>;

export default ({app, children}) => {
    useEffect(() => {
        window.addEventListener('error', handleWindowError(app));
    })
    return <ErrorBoundary FallbackComponent={Fallback} onError={handleRenderError(app)}>
        {children}
    </ErrorBoundary>
}
import { css } from "@emotion/react";
import Theme from "./Theme";

export const defaultCss = css`
  
  a {
    color: ${Theme.colors.almostDim};
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      color: ${Theme.colors.almostWhite};
      text-decoration: none;
    }
  }
  
  code, pre code {
    color: ${Theme.colors.almostDim};
  }
  
  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }
  
  sub {
    top: 0.4em;
  }
  
  *:focus {
    outline: none !important;
  }
  
  hr {
    border-top: 1px solid rgba(255,255,255,.25);
  }
  
  .focus-visible {
    outline: 4px auto rgba(255,255,255,.25) !important;
  }
`;

export const ScrollCss = css`
 /*
  scrollbar-color: rgba(255,255,255,.15) rgba(25,25,25,1);
  
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(25,25,25,1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.1);
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(255,255,255,.2);
  }
  */
  
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
`
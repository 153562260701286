import React from 'react'
import {RadioGroup, Radio} from 'react-radio-group'
import styled from '@emotion/styled';
import Theme from "../styles/Theme";

const LabelElement = styled.label`
  cursor: ${p => p.disabled ? "normal" : "pointer"};
  display: inline-block;
  margin: 0;
  text-align: center;

  & > input + div {
    background-color: ${Theme.colors.backgroundLighter};
    padding: 6px;
    box-shadow: inset 0 0 2px 0 rgba(255,255,255,.15);
  }
  
  & > input:checked + div {
    background-color: ${Theme.colors.dimmer};
  }
  
  & > input.focus-visible + div {
    box-shadow: inset 0 0 0 2px rgba(255,255,255,.5);
  }
`;

const AnyLabelElement = styled(LabelElement)`
  font-weight: 700;
  
  & > input + div {
    width: 34px;
  }
`;

const ImportantLabelElement = styled(LabelElement)`
  & > input + div {
    width: 50px;
  }
  
  & > input:checked + div {
    background-color: ${Theme.colors.kindaDim};
    color: ${Theme.colors.background}
  }
`;

const NoLabelElement = styled(ImportantLabelElement)`
  & > input + div {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-left: 10px;
  }
`;

const YesLabelElement = styled(ImportantLabelElement)`
  & > input + div {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-right: 10px;
  }
`;

const RadioHolder = styled.div`
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.35);
  border-radius: 16px;
  opacity: ${p => p.disabled ? .25 : "unset"};
`;

const RadioElement = styled(Radio)`
  opacity: 0;
  width: 0;
  height: 0;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
`;

const LabelDiv = styled.div`
  display: inline-block;
`;

export default class ThreeStateCheckbox extends React.Component {

    constructor(props){
        super(props);

        this.changed = this.changed.bind(this);
    }

    changed(newValue){
        if(this.props.onChange){
            this.props.onChange(newValue);
        }
    }

    render() {
        let identifier = ((this.props.value === undefined) ? "empty" : (this.props.value ? "true" : "false"));
        return (
                <RadioGroup Component={"span"} selectedValue={identifier} onChange={this.changed}>
                    <RadioHolder disabled={this.props.disabled}>
                        <NoLabelElement disabled={this.props.disabled}>
                            <RadioElement disabled={this.props.disabled} value={"false"}/>
                            <LabelDiv>No</LabelDiv>
                        </NoLabelElement>
                        <AnyLabelElement disabled={this.props.disabled}>
                            <RadioElement disabled={this.props.disabled} value={"empty"}/>
                            <LabelDiv>&nbsp;–&nbsp;</LabelDiv>
                        </AnyLabelElement>
                        <YesLabelElement disabled={this.props.disabled}>
                            <RadioElement disabled={this.props.disabled} value={"true"}/>
                            <LabelDiv>Yes</LabelDiv>
                        </YesLabelElement>
                    </RadioHolder>
                </RadioGroup>
        )
    }

}
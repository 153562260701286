import React, {useEffect, useState, useRef} from 'react';
import ContextMenu from "./ContextMenu";
import ContextContext from "./ContextContext";
import styled from "@emotion/styled";
import { observer } from "mobx-react"

const PortalDiv = styled.div`
  z-index: 1000;
  pointer-events: none;
`;

export default observer((props) => {
    let [menu, setMenu] = useState(null);
    let defaultPortalRef = useRef(null);
    let trackedTouch = useRef(null);

    let portalRef = props.portalRef ?? defaultPortalRef;

    let setMenuElement = (element) => {
        setMenu(element);
        if (props.showCallback){
            props.showCallback(!!element);
        }
    }

    let showMenuAt = (x, y, or, content) => {
        let rect = new DOMRect(x, y, 0, 0);
        setMenuElement(
            <ContextMenu
                itemRect={rect}
                content={content ?? props.content}
                close={() => setMenu(null)}
                openRight={or}
            />
        );
    };

    let mousedown = (e) => {
        if (e.button === 2){
            e.stopPropagation();
            showMenuAt(e.clientX, e.clientY);
        }
    };

    let windowMouseup = (e) => {
        if (e.button === 0) {
            if (portalRef.current && !portalRef.current.contains(e.target) && menu !== null) {
                requestAnimationFrame(() => {
                    setMenuElement(null);
                });
            }
        }
    };

    let windowTouchend = (e) => {
        if(portalRef.current && !portalRef.current.contains(e.target)){
            if (!trackedTouch.current || trackedTouch.current.identifier !== e.changedTouches[0].identifier) {
                setMenuElement(null);
            }
        }
    };

    let contextmenu = (e) => {
        e.preventDefault();
    };

    let touchstart = (e) => {
        let touch = e.changedTouches[0];
        trackedTouch.current = {identifier: touch.identifier, clientX: touch.clientX, clientY: touch.clientY, active: true};
        setTimeout(() => {
            if (trackedTouch.current && trackedTouch.current.identifier === touch.identifier && trackedTouch.current.active){
                let dx = touch.clientX - trackedTouch.current.clientX;
                let dy = touch.clientY - trackedTouch.current.clientY;
                let dsqr = dx*dx+dy*dy;
                if (dsqr < 20*20){
                    showMenuAt(touch.clientX, touch.clientY);
                }
            }
        }, 500);
    };

    let touchmove = (e) => {
        let touch = e.changedTouches[0];
        if (trackedTouch.current && trackedTouch.current.identifier === touch.identifier){
            trackedTouch.current.clientX = touch.clientX;
            trackedTouch.current.clientY = touch.clientY;
        }
    };

    let touchend = (e) => {
        if (trackedTouch.current){
            trackedTouch.current.active = false;
        }
    };

    let touchcancel = (e) => {
        if (trackedTouch.current){
            trackedTouch.current.active = false;
        }
    };

    useEffect(() => {
        window.addEventListener("mouseup", windowMouseup);
        return () => window.removeEventListener("mouseup", windowMouseup);
    });

    useEffect(() => {
        window.addEventListener("touchend", windowTouchend);
        return () => window.removeEventListener("touchend", windowTouchend);
    });

    let close = () => setMenuElement(null);

    if (props.showMenuAt) {
        props.showMenuAt(showMenuAt);
    }

    if (props.close) {
        props.close(close);
    }

    return <>
        <props.holder
            onMouseDown={props.triggerOnClick ? mousedown : null}
            onTouchStart={touchstart}
            onTouchMove={touchmove}
            onTouchEnd={touchend}
            onTouchCancel={touchcancel}
            onContextMenu={props.triggerOnClick ? contextmenu : null}
            {...props.holderProps}
        >
            {props.children}
        </props.holder>
        { menu ?
            <ContextContext.Provider value={{depth: 1, close, portal: props.portalRef ?? portalRef, openRight: true, touch: props.isTouch}}>
                {menu}
            </ContextContext.Provider>
            :
            null
        }
        {props.portalRef ? null : <PortalDiv ref={portalRef}/>}
    </>;
});
import React  from 'react';
import Api from "./Api";
import KeywordList from "./KeywordList";
import {tagCss} from "../styles/Tag";
import {css} from '@emotion/react'
import Theme from "../styles/Theme";

const getTagData = async (center) => {
    return await Api.get("/tag/info/" + center);
};

const tagSuggestions = async (text) => {
    if(text && text !== "") {
        let results = await Api.get("/tag/suggestions/" + encodeURIComponent(text));
        return results.map(r => ({id: r.name, text: r.name, data: r}));
    } else {
        return [];
    }
};

const isTag = async (text) => {
    return (await getTagData(text)).entity !== null;
};

const validateTag = (onError) => async (text) => {
    if (text.length === 0){
        if (onError) {
            onError("Empty tag.");
        }
        return false;
    }
    if (text.length > 48){
        if (onError) {
            onError("Tags must be at most 48 characters.");
        }
        return false;
    }
    if (!text.match(/^[a-zA-Z0-9-]+$/g)){
        if (onError) {
            onError("Tag must consist of letters, numbers, and dashes.");
        }
        return false;
    }

    return true;
}

const tagModifier = (text) => text.replace("#", "");


const tagStyleModified = css`
  ${tagCss};
  background: ${Theme.colors.backgroundDarker};
  border: none;
`;

const nonFilterTagStyle = css`
  color: ${Theme.colors.nearingDim};
`;

const filterTagStyle = css`
  background: ${Theme.colors.kindaDim};
  color: ${Theme.colors.background};
`;

const TagList = (props) => {
    return <KeywordList
        allowTag={props.allowAny ? validateTag(props.onTagNameError) : isTag}
        editable={props.editable}
        suggestions={tagSuggestions}
        placeholder={props.placeholder}
        tags={props.tags}
        onTagClick={props.onTagClick}
        tagsOnChange={props.tagsOnChange}
        styleAsFilter={props.styleAsFilter}
        tagStyle={tagStyleModified}
        nonFilterTagStyle={nonFilterTagStyle}
        filterTagStyle={filterTagStyle}
        suggestionStyle={tagCss}
        textModifier={tagModifier}
        inputWidth={"120px"}
        autoCapitalize={"off"}
        autoCorrect={"off"}
    />
};

export default TagList;
import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {runInAction} from "mobx"
import {inject, observer} from "mobx-react";
import Api from "./Api";
import {SectionHeader} from "../routes/storylist/user/UserStoryList";
import {ModalButtonsDiv, ModalContentDiv, ModalUnboundedDiv} from "../styles/Modal";
import {Button120, Button120Gray} from "../styles/Button";
import StyledModal from "./StyledModal";
import {AlmostDimSpan} from "../styles/Span";
import Theme from "../styles/Theme";
import {HelpIconLightShadowed, XIconLightShadowed} from "../svg/Icons";

const Floating = styled.div`
  position: fixed;
  width: 330px;
  bottom: 20px;
  left: calc(50vw - 165px);
  z-index: 200;
  background: rgba(35,35,35,.9);
  border-radius: 20px;
  box-shadow: 0 0 0px 1px rgba(255,255,255,.15) inset, 0 0 10px 0 rgba(0,0,0,.35);
  padding: 10px 10px 15px 10px;
  opacity: ${p => p.show ? 1 : 0};
  visibility: ${p => p.show ? 'visible' : 'hidden'};
  transition: 250ms opacity, 250ms visibility;
  will-change: opacity, visibility;
`;

const SectionHeaderAdjusted = styled(SectionHeader)`
  padding: 8px;
  user-select: none;
`;

const ButtonHolder = styled.div`
  position: absolute;
  top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HelpButtonHolder = styled(ButtonHolder)`
  left: 12px;
`;

const CloseButtonHolder = styled(ButtonHolder)`
  right: 12px;
`;

const ControlsButton = styled.div`
  user-select: none;
  cursor: pointer;
  text-align: center;
  
  height: 24px;
  width: 24px;
  
  color: ${Theme.colors.dimmer};
  opacity: .65;
  
  &:hover {
    color: ${Theme.colors.kindaDim};
  }
`;

export default inject("appState")(observer(({appState}) => {
    let [showOffModal, setShowOffModal] = useState(false);
    let loadConfig = async () => {
        if (appState.user) {
            let results = await Api.get("/user/me/settings/overlay");
            runInAction(() => appState.showOverlayControls = results.enabled);
        }
    };

    let visible = () => (appState.user && appState.showOverlayControls);

    let hideContent = async () => {
        if (visible()) {
            await Api.post("/overlay/hide");
        }
    };

    let showPhoto = async () => {
        if (visible() && showButton()){
            await Api.post("/overlay/photo", {photoIdent: appState.photo.info.ident});
        }
    };

    let showButton = () => {
        return appState.inPhoto && appState.photo.info && appState.photo.info.public;
    };

    let setEnabledOff = async () => {
        if (visible()) {
            runInAction(() => appState.showOverlayControls = false);
            setShowOffModal(false)
            let results = await Api.post("/user/me/settings/overlay/enabled", {enabled: false});
            runInAction(() => appState.showOverlayControls = results.enabled);
        }
    };

    let openHelp = () => {
        window.open("/help/overlay", '_blank');
    };

    useEffect(() => {
        (async () => { await loadConfig() })()
    }, [appState.user]);

    return <>
        <Floating show={(appState.user && appState.showOverlayControls)}>
            <SectionHeaderAdjusted>Stream Overlay Controls</SectionHeaderAdjusted>
            <ModalButtonsDiv>
                <Button120 onClick={hideContent}>Hide All</Button120><Button120Gray onClick={showPhoto} disabled={!showButton()}>Show This</Button120Gray>
            </ModalButtonsDiv>
            <HelpButtonHolder><ControlsButton onClick={openHelp}><HelpIconLightShadowed scale={16}/></ControlsButton></HelpButtonHolder>
            <CloseButtonHolder><ControlsButton onClick={() => setShowOffModal(true)}><XIconLightShadowed scale={16}/></ControlsButton></CloseButtonHolder>
        </Floating>
        <StyledModal
            isOpen={showOffModal}
            onRequestClose={() => setShowOffModal(false)}
            contentLabel="Close Controls"
            width={"min(360px, 100vw)"}
        >
            <ModalContentDiv>
                <ModalUnboundedDiv>
                    <AlmostDimSpan>Stream overlay controls will be hidden until re-enabled in Settings page.</AlmostDimSpan>
                </ModalUnboundedDiv>
            </ModalContentDiv>
            <ModalButtonsDiv>
                <Button120 onClick={() => setShowOffModal(false)}>Cancel</Button120>
                <Button120Gray onClick={setEnabledOff}>OK</Button120Gray>
            </ModalButtonsDiv>
        </StyledModal>
    </>;
}));
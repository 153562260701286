
export default (() => {
    let config =  {
        APP_API_BASE_URL : window.GLOBAL_ENV.APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
        APP_POSTHOG_BASE_URL : window.GLOBAL_ENV.APP_POSTHOG_BASE_URL || process.env.REACT_APP_POSTHOG_BASE_URL,
        APP_ADMINAPI_BASE_URL : window.GLOBAL_ENV.APP_ADMINAPI_BASE_URL || process.env.REACT_APP_ADMINAPI_BASE_URL,
        APP_STATIC_BASE_URL : window.GLOBAL_ENV.APP_STATIC_BASE_URL || process.env.REACT_APP_STATIC_BASE_URL,
        APP_IMAGES_BASE_URL : window.GLOBAL_ENV.APP_IMAGES_BASE_URL || process.env.REACT_APP_IMAGES_BASE_URL,
        APP_CDNSTATIC_BASE_URL : window.GLOBAL_ENV.APP_CDNSTATIC_BASE_URL || process.env.REACT_APP_CDNSTATIC_BASE_URL,
        APP_WS_BASE_URL : window.GLOBAL_ENV.APP_WS_BASE_URL || process.env.REACT_APP_WS_BASE_URL,
        APP_POSTHOG_KEY : window.GLOBAL_ENV.APP_POSTHOG_KEY || process.env.REACT_APP_POSTHOG_KEY,
        APP_STRIPE_PUBLISHABLE_KEY : window.GLOBAL_ENV.APP_STRIPE_PUBLISHABLE_KEY || process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        APP_IS_DEV : !!process.env.REACT_APP_IS_DEV
    };

    if(config.APP_API_BASE_URL === "%APP_API_BASE_URL%"){
        config.APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    }
    if(config.APP_POSTHOG_BASE_URL === "%APP_POSTHOG_BASE_URL%"){
        config.APP_POSTHOG_BASE_URL = process.env.REACT_APP_POSTHOG_BASE_URL;
    }
    if(config.APP_ADMINAPI_BASE_URL === "%APP_ADMINAPI_BASE_URL%"){
        config.APP_ADMINAPI_BASE_URL = process.env.REACT_APP_ADMINAPI_BASE_URL;
    }
    if(config.APP_STATIC_BASE_URL === "%APP_STATIC_BASE_URL%"){
        config.APP_STATIC_BASE_URL = process.env.REACT_APP_STATIC_BASE_URL;
    }
    if(config.APP_IMAGES_BASE_URL === "%APP_IMAGES_BASE_URL%"){
        config.APP_IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;
    }
    if(config.APP_CDNSTATIC_BASE_URL === "%APP_CDNSTATIC_BASE_URL%"){
        config.APP_CDNSTATIC_BASE_URL = process.env.REACT_APP_CDNSTATIC_BASE_URL;
    }
    if(config.APP_WS_BASE_URL === "%APP_WS_BASE_URL%"){
        config.APP_WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
    }
    if(config.APP_POSTHOG_KEY === "%APP_POSTHOG_KEY%"){
        config.APP_POSTHOG_KEY = process.env.REACT_APP_POSTHOG_KEY;
    }
    if(config.APP_STRIPE_PUBLISHABLE_KEY === "%APP_STRIPE_PUBLISHABLE_KEY%"){
        config.APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    }

    return config;
})()

import React, {useEffect, useRef} from 'react';
import Modal from 'react-modal';
import { ClassNames } from '@emotion/react'
import styled from '@emotion/styled';
import {backdropFilterSupportCondition} from "../styles/BackdropFilter"
import {runInAction} from "mobx"
import {inject, observer} from "mobx-react"
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";

const ModalWithStyles = styled(Modal)`
  position: fixed;
  border-radius: 20px;
  width: ${p => p.width};
  height: ${p => p.height};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto;
  display: ${p => p.hidden ? "none" : (p.relative ? "block" : "flex")};
  ${p => p.relative ? `will-change: height;` : `
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `};
  box-shadow: 0 0 10px 0px rgba(0,0,0,.75), 0 0 2px 1px rgba(0,0,0,.5), 0 0 0px 1px rgba(160,160,160,1);
  outline: none;
  padding: 15px;
  overflow: ${p => p.overflow};
  cursor: auto;
  
  @supports ${backdropFilterSupportCondition()} {
    -webkit-backdrop-filter: blur(10px); 
    backdrop-filter: blur(10px); 
    background-color: rgba(45,45,45,.25);              
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    background: rgba(40,40,40,1);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  color: ${Theme.colors.dim};
  ${typeface(14, 500, .1)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
`;

export default inject("appState")(observer((props) => {
    let oldProps = useRef(null);

    useEffect(() => {
        let currentShown = !!props.isOpen && !props.hidden;
        let oldShown = oldProps.current ? !!oldProps.current.isOpen && !oldProps.current.hidden : false;
        if (currentShown && !oldShown){
            runInAction(() => {
                props.appState.modalCount += 1;
            });
        } else if (!currentShown && oldShown){
            runInAction(() => {
                props.appState.modalCount -= 1;
            });
        }
        oldProps.current = props;
    }, [props.isOpen, props.hidden]);
    useEffect(() => () => {
        let currentShown = !!props.isOpen && !props.hidden;
        let oldShown = oldProps.current ? !!oldProps.current.isOpen && !oldProps.current.hidden : false;
        if (!currentShown && oldShown) {
            runInAction(() => {
                props.appState.modalCount -= 1;
            });
        }
    }, []);
    return <ClassNames>
        {({ css }) => (
            <ModalWithStyles
                className={props.className}
                height={props.height || "auto"}
                width={props.width || "min(320px, 100vw)"}
                maxHeight={props.maxHeight || "640px"}
                maxWidth={props.maxWidth || "480px"}
                isOpen={props.isOpen}
                hidden={props.hidden}
                onRequestClose={props.onRequestClose}
                contentLabel={props.contentLabel}
                overflow={props.overflow || "hidden"}
                relative={props.relative}
                overlayClassName={css`
                    z-index:1000;
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,.65);
                    ${props.hidden ? "display: none;" : ""};
                    cursor: pointer;
                `}
                shouldFocusAfterRender={props.shouldFocusAfterRender}
            >
                {props.contentLabel ? <Header>{props.contentLabel}</Header> : null}
                {props.children}
            </ModalWithStyles>
        )}
    </ClassNames>;
}));


import React, {useState, useContext, useRef, useEffect} from 'react';
import ContextMenu from "./ContextMenu";
import ContextContext from "./ContextContext";

let globalID = {id: 0};

export default function ContextItem(props) {

    let [menu, setMenu] = useState(null);
    let itemRef = useRef(null);
    let context = useContext(ContextContext);
    let id = useRef(null);

    useEffect(() => {
        globalID.id++;
        id.current = globalID.id;
    }, []);

    let openSubmenu = (e, closeIfOpen, dragging) => {
        context.setActiveItem(id.current);
        if (props.content && !menu) {
            let itemRect = itemRef.current.getBoundingClientRect();
            setMenu(itemRect);
        } else if (closeIfOpen && props.content && !!menu) {
            setMenu(null);
        }
    }

    let mouseover = (e) => {
        if(!context.touch) {
            openSubmenu();
        }
    };

    let mousedown = (e) => {
        if (context.touch && props.allClickable) {
            openSubmenu();
        }
    };

    useEffect(() => {
        if (!!menu && context.activeItem !== id.current){
            setMenu(null);
        }
    });

    let {onMouseDown, onMouseUp, onClick, className} = props;
    return <>
        <div {...{onMouseDown, onMouseUp, onClick, className}}
             ref={itemRef}
             onMouseDown={mousedown}
             onMouseOver={mouseover}
        >
            {props.children({openSubmenu})}
        </div>
        {menu ? <ContextMenu itemRect={menu} content={props.content}/> : null}
    </>;
}
import React from 'react';
import styled from '@emotion/styled';
import {typeface} from "../styles/Typeface";
import WindowHelper from "../helpers/WindowHelper";
import DumbLinkContainer from "./DumbLinkContainer";
import {inject, observer} from 'mobx-react';

const Contents = styled.div`
  height: ${WindowHelper.NAV_HEIGHT}px;
  display: flex;
  max-width: 400px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  ${p => typeface(p.skinny ? 14 : 16, 700)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.3);
`;

const Section = styled.div`
  text-align: center;
`;

const Link = styled.a`
  
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  
  opacity: .25;
  transition: opacity 250ms;
  &:hover { 
    opacity: .5;
  }
`;

const Line = styled.div`
  height: 0px;
  width: 400px;
  max-width: 90%;
  border-top: 1px solid rgba(0,0,0,.9);
  border-bottom: 1px solid rgba(255,255,255,.3);
`;

export default inject('appState')(observer(({appState, className}) => {
    return <Footer className={className}>
        <Line/>
        <Contents skinny={appState.media.mobile}>
            <Section><DumbLinkContainer to={"/blog"}><Link>Blog</Link></DumbLinkContainer></Section>
            <Section><DumbLinkContainer to={"/help/terms-of-use"}><Link>Terms of Use</Link></DumbLinkContainer></Section>
            <Section><DumbLinkContainer to={"/help/privacy"}><Link>Privacy</Link></DumbLinkContainer></Section>
            <Section><DumbLinkContainer to={"/help/support"}><Link>Support</Link></DumbLinkContainer></Section>
        </Contents>
    </Footer>
}));
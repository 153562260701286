import React, {useEffect, useRef} from 'react'
import {observable, runInAction} from 'mobx'
import {inject, observer} from 'mobx-react'
import Api from "../../../components/Api";
import Thumbnail from "../../../components/Thumbnail";
import {DimSpan} from "../../../styles/Span";
import Theme from "../../../styles/Theme";
import styled from '@emotion/styled'
import {typeface} from "../../../styles/Typeface";
import {Button120} from "../../../styles/Button";
import {CheckmarkIconLightShadowed} from "../../../svg/Icons";
import {SpinnerFadeIn} from "../../../components/Spinner";

const Question = styled.div`
  text-align: center;
  ${p => p.skinny ? typeface(16) : typeface(20)};
  margin-bottom: 6px;
`;

const LoginDiv = styled(Question)`
`;

const VoteSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  ${p => p.skinny ? `` : `min-width: 768px`};
`;

const VoteBlock = styled.div`
  text-align: center;
  min-width: 0;
`;

const ThumbHolder = styled.div`
  height: ${p => p.skinny ? 128 * p.scale : 256 * p.scale}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
  ${p => p.scale < 1 ? `transform: scale(${p.scale})` : ``};
`;

const VoteButton = styled(Button120)`
  background: none;
  min-width: unset;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: ${Theme.colors.nearingDim};
  
  &:hover {
    color: ${Theme.colors.almostWhite};
  }
`;

const HolderDiv = styled.div`
  width: ${p => p.width}px;
  max-width: 100%;
  height: ${p => p.height}px;
  transition: height 500ms, width 500ms;
  transition-timing-function: ease-in-out;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionAndAnswers = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SelectInstructions = styled.div`
  text-align: center;
  color: ${Theme.colors.nearingDim};
  ${typeface(16, 400, .02)};
`

const SenseName = styled.div`
  text-align: center;
  color: ${Theme.colors.almostWhite};
  ${p => typeface(p.skinny ? 24 : 32, 500, .025)};
  white-space: nowrap;
`;

const SpinnerDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

export default inject('appState')(observer((props) => {
    const data = useRef(observable({
        photos: [],
        sense: null,
        notLoggedIn: false,
        tryLater: false,
        loading: true,
        photoScale: 1
    }));

    let state = data.current;

    let resize = () => {
        runInAction(() => {
            data.current.photoScale = props.appState.media.mobile ? Math.min(window.innerWidth / 480, 1) : Math.min(window.innerWidth / 840, 1);
        });
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();
        return () =>  window.removeEventListener("resize", resize);
    });

    let applyMatchup = async (response) => {
        if (response.matchup) {
            let promises = response.matchup.photoIdents.map(ident => Api.get(`/gallery/photo/${ident}`));
            let results = await Promise.all(promises);
            results = shuffle(results);
            runInAction(() => {
                state.sense = response.matchup.sense;
                state.photos = results.filter(e => !!e).map(e => e[0]);
                state.tryLater = false;
                state.loading = false;
            });
        } else {
            runInAction(() => {
                state.sense = null;
                state.photos = [];
                state.tryLater = true;
                state.loading = false;
            });
        }
    };

    useEffect(() => {
        (async () => {
            if (props.appState.user) {
                runInAction(() => {
                    state.notLoggedIn = false;
                });
                let response = await Api.post("/photo/showcase/matchup");
                if (response) {
                    await applyMatchup(response);
                }
            } else {
                runInAction(() => {
                    state.sense = null;
                    state.photos = [];
                    state.notLoggedIn = true;
                    state.tryLater = false;
                    state.loading = false;
                });
            }
        })();
    }, [props.appState.user]);

    let vote = async (ident) => {
        let response = await Api.post("/photo/showcase/vote", {
            winnerPhotoIdent: ident,
            matchup: {
                sense: state.sense,
                photoIdents: state.photos.map(data => data.ident)
            }
        });
        if (response) {
            await applyMatchup(response);
        }
    };

    let message = null;
    if(state.notLoggedIn){
        message = "Please log in to rank photos.";
    } else if (state.tryLater) {
        message = "Try later for additional matchups.";
    }

    let big = state.loading || state.photos.length > 0;
    let width = big ? Math.min(props.appState.viewport.bodyClientWidth, 1024) : 440;
    let height = big ? (props.appState.media.skinny ? 270 : 410) : 50;

    return <HolderDiv skinny={props.appState.media.skinny} width={width} height={height}>
        {state.loading ?
            <SpinnerDiv>
                <SpinnerFadeIn/>
            </SpinnerDiv>
            :
            ((message !== null) ?
                <LoginDiv skinny={props.appState.media.skinny}>
                    <DimSpan>{message}</DimSpan>
                </LoginDiv>
                :
                (state.sense ?
                        <QuestionAndAnswers>
                            <Question skinny={props.appState.media.skinny}>
                                <SelectInstructions skinny={props.appState.media.skinny}>Select the photo that best evokes</SelectInstructions>
                                <SenseName skinny={props.appState.media.skinny}>{state.sense}</SenseName>
                            </Question>
                            <VoteSection skinny={props.appState.media.skinny}>
                                {state.photos.map(photo =>
                                    <VoteBlock key={photo.ident}>
                                        <ThumbHolder skinny={props.appState.media.skinny} scale={state.photoScale}>
                                            <Thumbnail flexible={true} lightbox={true} thumb={photo} noMargin={true}/>
                                        </ThumbHolder>
                                        <div>
                                            <VoteButton onClick={() => vote(photo.ident)}><CheckmarkIconLightShadowed scale={18}/></VoteButton>
                                        </div>
                                    </VoteBlock>
                                )}
                            </VoteSection>
                        </QuestionAndAnswers>
                        : null
                )
            )
        }
    </HolderDiv>
}));
import React, { Component } from 'react'
import Api from "./Api";
import ThreeStateCheckbox from "./ThreeStateCheckbox";
import {Handle, RailElement, Tick, Track} from "./sliderhelpers/SliderHelpers";
import DateTimeRangePicker from "./DateTimeRangePicker";
import Theme from "../styles/Theme";
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import Select from 'react-select';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import TagList from "./TagList";
import {ButtonLikeButton, ButtonLikeMiniDimButton, TextLikeButtonSmall} from "../styles/Button";
import {
    Explanation,
    ExplanationHelpHolder, ExplanationSectionWithHelp,
    ExplanationSmall,
    ModalButtonsDiv,
    ModalFlex,
    ModalLabelDiv
} from "../styles/Modal";
import StyledModal from "./StyledModal";
import CodeBlock from "./CodeBlock";
import {Emphasis} from "../styles/Span";
import escapeHtml from 'escape-html'
import queryString from 'query-string'
import {UndoIconLightShadowed} from "../svg/Icons";
import {typeface} from "../styles/Typeface";
import {SpinnerFadeIn} from "./Spinner";
import {HelpLinkThick} from "./HelpLink";
import moment from 'moment';
import {SectionHeader} from "../routes/storylist/user/UserStoryList";

export const DEFAULT_SORT_FIELD = "taken";
export const DEFAULT_SORT_DIRECTION = "desc";

const Sidebar = styled.div`
  color: ${Theme.colors.kindaDim};
  height: 100%;
  width: 254px;
  padding-left: 30px;
  padding-right: 2px;
  ${typeface(13, 400)};
  position: relative;
`;

const FilterHolder = styled.div`
  padding-top: 6px;
  padding-bottom: 6px;
`;

const FilterLabelHolder = styled.div`
  position: relative;
  display: flex;
`;

const FilterLabelStyle = css`
  display: inline-block;
  padding: 0 8px 0 0;
  align-self: center;
  user-select: none;
  margin: 0;
`;

const FilterLabel = styled.div`
  ${FilterLabelStyle};
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
`;

const FilterLabelButton = styled.button`
  ${FilterLabelStyle};
  cursor: pointer;
  color: ${p => p.active ? Theme.colors.almostWhite : "inherit"};
  background: none;
  outline: none;
  border: none;
  text-shadow: 0px 2px 1px rgba(0,0,0,${p => p.active ? ".5" : ".25"});
  
  &::before {
    content: "${p => p.directionLabel}";
    position: absolute;
    left: -18px;
  }
`;

const SliderHolder = styled.div`
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 3px;
  height: 42px;
  user-select: none;
`;

const InlineFilterHolder = styled.div`
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
`;

const InputHolder = styled.div`
  text-align: right;
  align-self: center;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const KeywordHolder = styled.div`
  text-align: center;
`;

const SELECT_STYLES = {
    control: (provided, state) => ({
        ...provided,
        color: Theme.colors.dim,
        backgroundColor: Theme.colors.backgroundLighter,
        border: "none",
        boxShadow: "0 1px 4px 0 rgba(0,0,0,.35), inset 0 0 2px 0 rgba(255,255,255,.15)",
        cursor: "pointer",
        borderRadius: "3px",
        marginTop: "2px"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? Theme.colors.backgroundLighter : Theme.colors.backgroundDark,
        cursor: "pointer"
    }),
    container: (provided, state) => ({
        ...provided,
        color: Theme.colors.kindaDim,
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: Theme.colors.backgroundDark,
        boxShadow: "0 0 2px 1px rgba(0,0,0,.35), 0 0 20px 5px " + Theme.colors.background,
        borderRadius: "3px"
    }),
    menu: (provided, state) => ({
        ...provided,
        background: "none"
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: Theme.colors.kindaDim
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: Theme.colors.background
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        backgroundColor: Theme.colors.kindaDim,
        color: Theme.colors.dim,
        ':hover': {
            backgroundColor: Theme.colors.almostWhite,
            color: Theme.colors.dimmer,
        }
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: Theme.colors.kindaDim,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: Theme.colors.dim,
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        color: Theme.colors.kindaDim,
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        'div:hover': {
            color: Theme.colors.kindaDim,
            background: "rgba(255,255,255,.1)"
        },
        'div': {
            borderRadius: "4px",
            padding: "5px",
            margin: "5px"
        }
    }),
};

const TagOptions = styled.div`
  display: inline-block;
`;

const TagOptionsLabel = styled(FilterLabel)`
  cursor: auto;
`;

const TagOption = styled.div`
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
`;

const TagOptionLabel = styled.label`
  margin: 0px;
  color: ${Theme.colors.almostDim};
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

const TagOptionsHolder = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const TagOptionInput = styled.input`
  margin-right: 6px;
`;

const CenteredBottom = styled.div`
  justify-content: center;
  display: flex;
  padding-bottom: 20px;
`;

const TitleHolder = styled.div`
  margin-bottom: 8px;
  text-align: center;
  width: 222px;
`;

const Title = styled(SectionHeader)`
  align-items: unset;
  width: unset;
  padding: unset;
  text-align: unset;
  vertical-align: sub;
  will-change: left;
  display: inline;
`;

const FloatRightTransition = styled.div`
  float: right;
  margin: 1px;
  width: ${ p => p.show ? "60px" : "0px" };
  opacity: ${ p => p.show ? "1" : "0" };
  visibility: ${ p => p.show ? "visible" : "hidden" };
  transition: 300ms;
  will-change: opacity, width;
`;

const ButtonLikeMiniDimDivStyled = styled(ButtonLikeMiniDimButton)`
  padding-left: 8px;
  padding-right: 8px;
  line-height: 19px;
`;

const SpinnerCenter = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 30px);
`;

const ContentArea = styled.div`
  position: absolute;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const HelpHolder = styled.div`
  margin-bottom: 8px;
`;

const TextLikeButtonSmallTwoLine = styled(TextLikeButtonSmall)`
  display: inline-block;
  height: 50px;
  width: 110px;
  min-width: unset;
  margin: 0;
`;

export const getFilteredPhotoListingUrl = (num, userIdent, userAlias, afterSortValue, afterIdent, filterBarInfo, paramsCallback) => {
    let asc = filterBarInfo ? (filterBarInfo.sortDirection === "asc") : false;
    let url;
    if (userAlias) {
        url = "/browser/alias/" + userAlias;
    } else {
        url = "/browser/ident/" + userIdent;
    }

    let params = filterBarInfo ? Object.assign({}, filterBarInfo.filters) : {};

    if (filterBarInfo && filterBarInfo.tags && filterBarInfo.tags.length > 0) {
        params["tags"] = filterBarInfo.tags;
        params["tags-inclusive"] = !filterBarInfo.tagExact;
        params["tags-all"] = !filterBarInfo.tagAny;
        params["tags-not"] = filterBarInfo.tagNot;
    }
    params["sort-field"] = filterBarInfo ? filterBarInfo.sortField : "taken";
    params["after-sort-value"] = afterSortValue;
    params["after-ident"] = afterIdent;
    params["ascend"] = asc;
    params["inclusive"] = false;
    params["num"] = num;
    params["null-is-big"] = asc;

    if (paramsCallback){
        paramsCallback(url, params);
    }

    return url + "?" + queryString.stringify(params);
};


class PhotoListingFilterBar extends Component {

    constructor(props) {
        super(props);

        this.data = {
            filterDefinitions: [],
            filters: {},
            sortField: props.defaultSortField,
            sortDirection: props.defaultSortDirection,
            tags: [],
            tagExact: false,
            tagAny: false,
            tagNot: false,
            oldForcedFilters: null
        };

        this.state = Object.assign({codeModalOpen: false, filterChangeCount: 0, filtersLoading: false}, this.data);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.filterDefinitions !== this.state.filterDefinitions
            || nextState.filters !== this.state.filters
            || nextState.sortField !== this.state.sortField
            || nextState.tags !== this.state.tags
            || nextState.tagExact !== this.state.tagExact
            || nextState.tagAny !== this.state.tagAny
            || nextState.tagNot !== this.state.tagNot
            || nextState.sortDirection !== this.state.sortDirection
            || nextProps.userIdent!== this.props.userIdent
            || nextProps.userAlias !== this.props.userAlias
            || nextState.codeModalOpen !== this.state.codeModalOpen
            || nextState.filterChangeCount !== this.state.filterChangeCount
            || nextState.filtersLoading !== this.state.filtersLoading
            || nextProps.forcedFilters !== this.props.forcedFilters;
    };

    componentDidMount() {
        this.data.sortField = this.props.defaultSortField;
        this.data.sortDirection = this.props.defaultSortDirection;
        this.data.oldForcedFilters = this.props.forcedFilters;
        this.data.oldSession = this.props.session;
        this.setState({sortField: this.data.sortField, sortDirection: this.data.sortDirection});
        this.reloadFilterDefinitions(false);
    };

    changed = () => {
        if (this.props.changed) {
            this.props.changed({
                filters: this.data.filters,
                sortField : this.data.sortField,
                sortDirection: this.data.sortDirection,
                tags: this.data.tags,
                tagExact: this.data.tagExact,
                tagAny: this.data.tagAny,
                tagNot: this.data.tagNot
            }, this.filtersNotDefault());
        }
        this.setState({filterChangeCount: this.state.filterChangeCount + 1});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.userIdent !== this.props.userIdent
            || prevProps.userAlias !== this.props.userAlias
            || !this.data.filterDefinitions
            || (!this.props.paused &&
                (this.props.forcedFilters !== this.data.oldForcedFilters
                    || this.props.session !== this.data.oldSession)
            )
        ) {
            this.data.oldForcedFilters = this.props.forcedFilters;
            this.data.oldSession = this.props.session;
            this.reloadFilterDefinitions(true);
        }
    }

    reloadFilterDefinitions = async (notifyChanged) => {
        if (this.props.userIdent || this.props.userAlias){
            let url;
            if (this.props.userAlias) {
                url = "/browser/alias/" + this.props.userAlias + "/filters";
            } else {
                url = "/browser/ident/" + this.props.userIdent + "/filters";
            }
            this.setState({filtersLoading: true});
            try {
                let json = await Api.get(url);
                if (json) {
                    this.data.filterDefinitions = json.filters;
                    this.reset(notifyChanged);
                    this.setState({
                        filterDefinitions: this.data.filterDefinitions,
                        filters: this.data.filters,
                        filtersLoading: false
                    });
                } else {
                    this.setState({filterDefinitions: [], filters: {}, filtersLoading: false});
                }
            } catch (e) {
                this.setState({filterDefinitions: [], filters: {}, filtersLoading: false});
            }
        }
    };

    booleanFilterChanged = (propName) => {
        return (value) => {
            if(value === "true"){
                this.data.filters[propName] = true;
            } else if (value === "false"){
                this.data.filters[propName] = false;
            } else {
                delete this.data.filters[propName];
            }
            this.setState(this.data);
            this.changed();
        };
    };

    multiselectFilterChanged = (propName) => {
        return (value) => {
            if(value !== null) {
                this.data.filters[propName] = value.map(option => option.value);
            } else {
                delete this.data.filters[propName];
            }
            this.setState(this.data);
            this.changed();
        };
    };

    rangeFilterChanged = (propName, transform) => {
        return (value) => {
            if(value !== null && value[0] > 0) {
                this.data.filters[propName+"-min"] = transform(value[0]);
            } else {
                delete this.data.filters[propName+"-min"];
            }
            if(value !== null && value[1] < 1000) {
                this.data.filters[propName+"-max"] = transform(value[1]);
            } else {
                delete this.data.filters[propName+"-max"];
            }
            this.setState(this.data);
            this.changed();
        };
    };

    dateRangeFilterChanged = (propName) => {
        let serialize = (date) => date.format("YYYY-MM-DD HH:mm:ss.SSSSSS");
        return (value) => {
            if(value && value[0]) {
                this.data.filters[propName+"-min"] = serialize(value[0]);
            } else {
                delete this.data.filters[propName+"-min"];
            }
            if(value && value[1]) {
                this.data.filters[propName+"-max"] = serialize(value[1].add(1, 'days'));
            } else {
                delete this.data.filters[propName+"-max"];
            }
            this.setState(this.data);
            this.changed();
        };
    };

    labelClicked = (identifier) => {
        if(this.data.sortField === identifier){
            if(this.data.sortDirection === "asc"){
                this.data.sortDirection = "desc";
            } else {
                this.data.sortDirection = "asc";
            }
            this.setState({ sortDirection: this.data.sortDirection });
        } else {
            this.data.sortField = identifier;
            this.data.sortDirection = "asc";
            this.setState({ sortField: this.data.sortField, sortDirection: this.data.sortDirection });
        }
        this.changed();
    };

    filterToComponent = (filter) => {
        let comp = null;

        let active = filter.identifier === this.state.sortField;
        let directionLabel = "";
        if (active){
            directionLabel = (this.state.sortDirection === "asc") ? "▲" : "▼";
        }
        let label =(
            <FilterLabelHolder>
                <FilterLabelButton
                    active={active}
                    onClick={() => this.labelClicked(filter.identifier)}
                    id={filter.identifier}
                    directionLabel={directionLabel}
                >
                    {filter.label}
                </FilterLabelButton>
            </FilterLabelHolder>
        );

        let forcedValue = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier] : undefined;
        let forcedValueMin = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-min"] : undefined;
        let forcedValueMax = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-max"] : undefined;
        let locked = forcedValue !== undefined || forcedValueMin !== undefined || forcedValueMax !== undefined;

        switch(filter.type){
            case "boolean": {
                let value = this.state.filters[filter.identifier];
                comp = <InlineFilterHolder>
                    {label}
                    <InputHolder>
                        <ThreeStateCheckbox disabled={locked} value={value} onChange={this.booleanFilterChanged(filter.identifier)}/>
                    </InputHolder>
                </InlineFilterHolder>;
                break;
            }
            case "multiselect": {
                let value = this.state.filters[filter.identifier];
                let values = value ? value.map(v => ({value: v, label: v})) : [];
                comp = <React.Fragment>
                    {label}
                    <Select
                        options={filter.values ? filter.values.map(v => {
                            return {value: v, label: v}
                        }) : []}
                        onChange={this.multiselectFilterChanged(filter.identifier)}
                        isMulti={true}
                        isSeachable={true}
                        placeholder={"–"}
                        styles={SELECT_STYLES}
                        value={values}
                    />
                </React.Fragment>;
                break;
            }
            case "range": {
                let valueMin = this.state.filters[filter.identifier+"-min"];
                let valueMax = this.state.filters[filter.identifier+"-max"];

                const sliderStyle = {
                    position: "relative",
                    width: "225px"
                };

                let domain = [0, 1000];
                let logScale = filter.logScale;
                let integer = filter.integer;
                let transform;
                let revTransform;
                let display = (value) => {
                    if (Math.abs(value) < 100) {
                        return parseFloat(value.toPrecision(2));
                    } else {
                        return parseFloat(value.toFixed(0));
                    }
                };
                if (logScale && filter.min !== 0) {
                    transform = (value) => {
                        let adjValue = value / 1000;
                        return display(filter.min * Math.pow(filter.max / filter.min, adjValue));
                    };
                    revTransform = (value) => {
                        return Math.log(value/filter.min) / Math.log(filter.max / filter.min) * 1000;
                    }
                } else {
                    transform = (value) => {
                        let adjValue = value / 1000;
                        return display((1 - adjValue) * filter.min + adjValue * filter.max);
                    };
                    revTransform = (value) => {
                        return (value - filter.min) * 1000 / (filter.max - filter.min);
                    };
                }
                comp =
                    <React.Fragment>
                        {label}
                        <SliderHolder>
                            <Slider rootStyle={sliderStyle}
                                    domain={domain}
                                    values={[valueMin ? revTransform(valueMin) : domain[0], valueMax ? revTransform(valueMax) : domain[1]]}
                                    onChange={this.rangeFilterChanged(filter.identifier, transform)}>
                                <Rail>
                                    {({getRailProps}) => (
                                        <RailElement width={214} {...getRailProps()} />
                                    )}
                                </Rail>
                                <Tracks left={false} right={false}>
                                    {({tracks, getTrackProps}) => (
                                        <div className="slider-tracks">
                                            {tracks.map(({id, source, target}) => (
                                                <Track
                                                    key={id}
                                                    source={source}
                                                    target={target}
                                                    getTrackProps={getTrackProps}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Tracks>
                                <Handles>
                                    {({handles, activeHandleID, getHandleProps}) => (
                                        <div className="slider-handles">
                                            {handles.map(handle => (
                                                <Handle
                                                    key={handle.id}
                                                    handle={handle}
                                                    domain={domain}
                                                    getHandleProps={getHandleProps}
                                                    isActive={handle.id === activeHandleID}
                                                    transform={transform}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </Handles>
                                <Ticks count={5}>
                                    {({ticks}) => (
                                        <div className="slider-ticks">
                                            {ticks.map((tick, index) => {
                                                let show;
                                                if (index === 0 || index === ticks.length - 1 || !integer){
                                                    show = true;
                                                } else {
                                                    show = Math.floor(transform(ticks[index - 1].value)) !== Math.floor(transform(tick.value));
                                                }
                                                return show ? <Tick
                                                    key={tick.id}
                                                    tick={tick}
                                                    count={ticks.length}
                                                    domain={domain}
                                                    transform={transform}
                                                /> : <React.Fragment key={tick.id}/>
                                            })}
                                        </div>
                                    )}
                                </Ticks>
                            </Slider>
                        </SliderHolder>
                    </React.Fragment>;
                break;
            }
            case "daterange": {
                let valueMin = this.state.filters[filter.identifier+"-min"];
                let valueMax = this.state.filters[filter.identifier+"-max"];
                comp = <InlineFilterHolder>
                    {label}
                    <InputHolder>
                        <DateTimeRangePicker
                            value={[valueMin ? moment(valueMin) : null, valueMax ? moment(valueMax).subtract(1, 'days') : null]}
                            onChange={this.dateRangeFilterChanged(filter.identifier)}
                        />
                    </InputHolder>
                </InlineFilterHolder>;
                break;
            }
        }
        return <div>{comp}</div>;
    };

    tagsChanged = (tags) => {
        this.data.tags = tags;
        this.setState(this.data);
        this.changed();
    };

    tagExactChanged = (e) => {
        this.data.tagExact = e.target.checked;
        this.setState({tagExact: e.target.checked});
        if(this.data.tags && this.data.tags.length > 0) {
            this.changed();
        }
    };

    tagAnyChanged = (e) => {
        this.data.tagAny = e.target.checked;
        this.setState({tagAny: e.target.checked});
        if(this.data.tags && this.data.tags.length > 1) {
            this.changed();
        }
    };

    tagNotChanged = (e) => {
        this.data.tagNot = e.target.checked;
        this.setState({tagNot: e.target.checked});
        if(this.data.tags && this.data.tags.length > 0) {
            this.changed();
        }
    };

    getEmbedText = () => {
        let data = "data-thumblist";
        for (let filter in this.state.filters){
            if(filter === "published") {
                continue;
            }
            let value = this.state.filters[filter];
            if (Array.isArray(value)){
                value = value.join(",");
            }
            data += " data-" + filter + "=\"" + escapeHtml(value) + "\"";
        }
        if (this.state.tags.length > 0){
            let value = this.state.tags.join(",");
            data += " data-tags=\"" + escapeHtml(value) + "\"";
            if (this.state.tagAny){
                data += " data-tags-all=\"false\"";
            }
            if (this.state.tagExact){
                data += " data-tags-inclusive=\"false\"";
            }
            if (this.state.tagNot){
                data += " data-tags-not=\"true\"";
            }
        }
        if (this.state.sortField !== "taken"){
            data += ` data-sort-field="${this.state.sortField}"`;
        }
        if (this.state.sortDirection !== "desc"){
            data += ` data-sort-direction="${this.state.sortDirection}"`;
        }
        return `<div ${data}></div>`;
    };

    openCodeModal = () => {
        this.setState({codeModalOpen: true});
    };

    closeCodeModal = () => {
        this.setState({codeModalOpen: false});
    };

    reset = (notifyChange) => {
        this.data.filters = {};
        this.data.filterDefinitions.forEach(filter => {
            let forcedValue = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier] : undefined;
            let forcedValueMin = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-min"] : undefined;
            let forcedValueMax = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-max"] : undefined;

            if (forcedValue !== undefined){
                this.data.filters[filter.identifier] = forcedValue;
            }
            if (forcedValueMin !== undefined){
                this.data.filters[filter.identifier+"-min"] = forcedValueMin;
            }
            if (forcedValueMax !== undefined){
                this.data.filters[filter.identifier+"-max"] = forcedValueMax;
            }
        });
        this.data.sortField = this.props.defaultSortField;
        this.data.sortDirection = this.props.defaultSortDirection;
        this.data.tags = [];
        this.data.tagExact = false;
        this.data.tagAny = false;
        this.data.tagNot = false;
        this.setState(this.data);
        if (notifyChange) {
            this.changed();
        }
    };

    filtersNotDefault = () => {
        for (let i = 0; i < this.data.filterDefinitions.length; i++) {
            let filter = this.data.filterDefinitions[i];

            let forcedValue = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier] : undefined;
            let forcedValueMin = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-min"] : undefined;
            let forcedValueMax = this.props.forcedFilters ? this.props.forcedFilters[filter.identifier+"-max"] : undefined;

            if (forcedValue !== undefined){
                if (this.data.filters[filter.identifier] !== forcedValue){
                    return true;
                }
            } else {
                if (this.data.filters[filter.identifier] !== undefined){
                    return true;
                }
            }
            if (forcedValueMin !== undefined){
                if (this.data.filters[filter.identifier+"-min"] !== forcedValueMin){
                    return true;
                }
            } else {
                if (this.data.filters[filter.identifier+"-min"] !== undefined){
                    return true;
                }
            }
            if (forcedValueMax !== undefined){
                if (this.data.filters[filter.identifier+"-max"] !== forcedValueMax){
                    return true;
                }
                if (this.data.filters[filter.identifier+"-max"] !== undefined){
                    return true;
                }
            }
        }
        return (this.data.sortField !== this.props.defaultSortField ||
                this.data.sortDirection !== this.props.defaultSortDirection ||
                this.data.tags.length !== 0 ||
                this.data.tagExact !== false ||
                this.data.tagAny !== false ||
                this.data.tagNot !== false);
    }

    render() {
        return <Sidebar>
            <ContentArea>
                <TitleHolder>
                    <Title>Filter and Sort</Title>
                    <FloatRightTransition show={this.filtersNotDefault()}>
                        <ButtonLikeMiniDimDivStyled onClick={() => { if (this.filtersNotDefault()) { this.reset(true); }}}><UndoIconLightShadowed marginRight={"2px"} scale={12}/>Reset</ButtonLikeMiniDimDivStyled>
                    </FloatRightTransition>
                </TitleHolder>
                {this.state.filterDefinitions.length > 0 ?
                    <FilterHolder key={"tags"}>
                        <TagOptionsHolder>
                            <TagOptionsLabel>Tags</TagOptionsLabel>
                            <TagOptions>
                                <TagOption><TagOptionLabel><TagOptionInput checked={this.state.tagAny} onChange={this.tagAnyChanged} type={"checkbox"} /> Any</TagOptionLabel></TagOption>
                                <TagOption><TagOptionLabel><TagOptionInput checked={this.state.tagExact} onChange={this.tagExactChanged} type={"checkbox"} /> Exact</TagOptionLabel></TagOption>
                                <TagOption><TagOptionLabel><TagOptionInput checked={this.state.tagNot} onChange={this.tagNotChanged} type={"checkbox"} /> Not</TagOptionLabel></TagOption>
                            </TagOptions>
                        </TagOptionsHolder>
                        <KeywordHolder>
                            <TagList
                                editable={true}
                                placeholder="Enter tags"
                                tags={this.state.tags}
                                tagsOnChange={this.tagsChanged}
                                styleAsFilter={true}
                            />
                        </KeywordHolder>
                    </FilterHolder>
                    : null
                }
                {this.state.filterDefinitions.map(def =>
                    <FilterHolder key={def.identifier}>{this.filterToComponent(def)}</FilterHolder>
                )}
                {this.props.showEmbedCode ?
                    <>
                        {this.state.filterDefinitions.length > 0 ?
                            <CenteredBottom>
                                <TextLikeButtonSmallTwoLine onClick={this.openCodeModal}>HTML Embed Code</TextLikeButtonSmallTwoLine>
                                <HelpHolder><HelpLinkThick path={"/help/photos/browse"}/></HelpHolder>
                            </CenteredBottom>
                            : null
                        }
                        <StyledModal
                            width={"min(480px, 100vw)"}
                            isOpen={this.state.codeModalOpen}
                            onRequestClose={this.closeCodeModal}
                            contentLabel="HTML Embed Code"
                        >
                            <ModalFlex>
                                <ModalLabelDiv>
                                    <Explanation>
                                        Embed a live <Emphasis>thumbnail list</Emphasis> subject to current filters:
                                    </Explanation>
                                    <CodeBlock text={this.getEmbedText()}/>
                                    <ExplanationSectionWithHelp>
                                        <ExplanationSmall>
                                            <ul>
                                                <li>Only <Emphasis>public</Emphasis> photos are included</li>
                                                <li>Page must include <Emphasis>plugin loader code</Emphasis></li>
                                                <li>Apply custom <Emphasis>CSS styling</Emphasis> as needed</li>
                                            </ul>
                                        </ExplanationSmall>
                                        <ExplanationHelpHolder>
                                            <HelpLinkThick path={"/help/plugins/types/thumbnail-list"}/>
                                        </ExplanationHelpHolder>
                                    </ExplanationSectionWithHelp>
                                </ModalLabelDiv>
                                <ModalButtonsDiv>
                                    <ButtonLikeButton onClick={this.closeCodeModal}>Close</ButtonLikeButton>
                                </ModalButtonsDiv>
                            </ModalFlex>
                        </StyledModal>
                    </>
                    : null
                }
            </ContentArea>
            {this.state.filtersLoading ? <SpinnerCenter><SpinnerFadeIn/></SpinnerCenter> : null}
        </Sidebar>
    };
}

export default PhotoListingFilterBar;
import React, {memo} from 'react'
import {TransparentInput} from "../styles/Input";

export default memo(React.forwardRef((props, ref) => {
    let {className, inputComponent, inputRef, inputClassName, useTagAdded, inputData, ...inputProps} = props;
    let inputPropsFinal;
    if (useTagAdded){
        inputPropsFinal = inputProps;
    } else {
        let {tagAdded, ...otherProps} = inputProps;
        inputPropsFinal = otherProps;
    }
    if(inputClassName) {
        inputProps['className'] = inputClassName;
    }
    let InputComponent = inputComponent || TransparentInput;
    return <div className={className} ref={ref}>
        <InputComponent ref={inputRef} {...inputPropsFinal} {...inputData}/>
    </div>;
}));
import React from 'react';
import styled from '@emotion/styled';
import Theme from "../styles/Theme";
import Background from "./Background";
import WithFooter from "./WithFooter";
import {typeface} from "../styles/Typeface";
import {inject, observer} from 'mobx-react'

const PageDiv = styled.div`
  padding-top: 80px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginMessage = styled.div`
  ${p => typeface(p.mobile ? 16 : 20, 400)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  color: ${Theme.colors.almostDim};
  padding: 30px;
  text-align: center;
`;

const Holder = styled.div`
  width: 50%;
  min-width: 240px;
  max-width: 480px;
  height: ${p => p.mobile ? 160 : 160 }px;
  border-radius: ${p => p.mobile ? 30 : 40 }px;
  border: dashed 3px rgba(255,255,255,.08);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.dimmer};
`;

export default inject("appState")(observer(({appState, message}) => {
    return <Background bg={Theme.colors.background}>
        <WithFooter>
            <PageDiv>
                { appState.loggedOut ?
                    <Holder mobile={appState.media.mobile}>
                        <LoginMessage mobile={appState.media.mobile}>{message ? message : <>Please log in to use this feature.</>}</LoginMessage>
                    </Holder>
                    : null
                }
            </PageDiv>
        </WithFooter>
    </Background>
}));
import React, {useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import {observable, runInAction} from 'mobx'
import {observer, inject} from 'mobx-react';
import {typeface} from "../../styles/Typeface";
import Theme from "../../styles/Theme";
import MapThumbnail from "./MapThumbnail";
import {MapIconHolder} from "./QueryDisplay";
import {
    DownvoteLightShadowed,
    MagnifyIconLightShadowed,
    MinusIcon,
    MinusIconLightShadowed,
    PlusIcon,
    PlusIconLightShadowed,
    RobotIconLightShadowed, UpvoteLightShadowed,
} from "../../svg/Icons";
import {SpinnerCenter, SpinnerFadeIn} from "../../components/Spinner";
import TabDisplay, {DefaultTabTitleCurrent, DefaultTabTitleLink} from "./TabDisplay";

const Title = styled.div`
  text-align: center;
  ${p => typeface(p.mobile ? 14 : 16, 600)};
  ${p => p.mobile ? `
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 0px;
    margin-bottom: 0px;
  ` : `
    margin-bottom: 2px;
  `}
`;

const Summary = styled.div`
  ${p => typeface(p.mobile ? 13 : 14, 400)};
  text-overflow: ellipsis;
  p {
    margin-bottom: 12px;
  }
  ${p => p.mobile ? `
  line-height: 1.3;
  p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: none;
  }
  p:first-of-type {
      display: -webkit-box;
      margin-bottom: 0;
  }
  `: 
  `margin: 0 20px;`}
`;

const SummaryButton = styled.a`
  color: ${Theme.colors.almostWhite};
  &:hover {
    color: white;
  }
`;

const FadeInCentered = styled.div`
  opacity: ${p => p.show ? 1.0 : 0.0};
  transition: opacity 150ms;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkContainer = styled.a`
  width: ${ p => p.mobile ? 56 : 60}px;
  height: ${ p => p.mobile ? 50 : 60}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${p => p.mobile ? Theme.colors.dim : Theme.colors.notQuiteDim} !important;
  &:hover {
    color: ${Theme.colors.almostWhite} !important;
  }
`;

const LinkText = styled.span`
  margin-top: 4px;
  text-align: center;
  ${typeface(10, 600)};
  min-height: ${p => p.mini ? 20 : 32}px;
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  user-select: none;
`;

const Container = styled.div`
  width: 100%;
  padding: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const ContainerShort = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${p => p.condensed ? "70px" : "100%"};
  margin-top: ${p => p.condensed ? "0" : "3px"};
  padding-top: ${p => p.condensed ? "0" : "8px"};
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
  height: 100%;
`;

const ImageSection = styled.div`
  height: ${p => p.mobile ? 80 : 140}px;
  ${p => p.mobile ? `
    width: 80px;
    ` : ``
  };
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ImageSectionSmall = styled(ImageSection)`
  height: ${p => p.mobile ? 70 : 92}px;
  ${p => p.mobile ? `
    width: 80px;
    ` : ``
  };
`;

const ContentSection = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  ${p => p.mobile ? `` : `
  flex-shrink: 1;
  padding: 0px 10px;
  -webkit-mask-image: -webkit-gradient(rgba(0,0,0,1) 66.667%, rgba(0,0,0,.6), rgba(0,0,0,.25), rgba(0,0,0,0));
  mask-image: linear-gradient(rgba(0,0,0,1) 66.667%, rgba(0,0,0,.6), rgba(0,0,0,.25), rgba(0,0,0,0));
  `};
`;

const FullSection = styled.div`
  height: 100%;
  width: 100%;
  margin: 4px;
`;

const LinksSection = styled.div`
  margin-top: ${p => p.mobile ? 4 : 10}px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
`;

const AIList = styled.ul`
  ${p => typeface(p.mobile ? 14 : 15, 400, .0085)};
  list-style: none;
  list-style-position: outside;
  padding-right: 10px;
`;

const ProsList = styled(AIList)`
  color: ${Theme.colors.aiReadable};
  //text-shadow: 0px 2px 1px rgba(0,0,0,.75);
`;

const ConsList = styled(AIList)`
  color: ${Theme.colors.minusReadable};
  //text-shadow: 0px 2px 1px rgba(0,0,0,.4);
`;

const BulletDiv = styled.div`
  position: absolute;
  left: -20px;
  top: 1px;
  user-select: none;
`;

const PlusDiv = styled(BulletDiv)`
  color: ${Theme.colors.ai};
`;

const MinusDiv = styled(BulletDiv)`
  color: ${Theme.colors.minus};
`;

const AiListItem = styled.li`
  margin-bottom: 4px;
  position: relative;
`;

const InfoDisplay = inject("appState")(observer(({appState, displayData, getImage, getName, getWikipedia, getId, getCustomDescription, links, getIcon, zoomTo, condensed}) => {
    let wikipedia = getWikipedia(displayData);
    const data = useRef(observable({
        data: null,
        show: !wikipedia,
        title: null,
    }));
    useEffect(() => {
        (async () => {
            let title = wikipedia;
            if (data.current.title !== title) {
                runInAction(() => {
                    data.current.data = null;
                });
                if (title) {
                    let titleClean = title.substring(3);
                    let url = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=extracts%7Cinfo&redirects=1&exlimit=20&exintro=1&explaintext=1&exsectionformat=wiki&inprop=url&origin=*&titles=${titleClean}`;
                    let result = await fetch(url);
                    let json = await result.json();
                    let pages = json.query.pages;
                    let keys = Array.from(Object.keys(pages));
                    if (keys.length > 0) {
                        runInAction(() => {
                            data.current.data = pages[keys[0]];
                            data.current.show = true;
                        });
                    }
                } else {
                    runInAction(() => {
                        data.current.show = true;
                    });
                }
                runInAction(() => {
                    data.current.title = title;
                });
            }
        })();
    }, [displayData]);
    let img = getImage(displayData);
    let imgFinal = img === "null" ? null : img;
    let icon = getIcon(displayData);

    let normalize = (text) => text.replace(" ()", "");

    let imageSection = imgFinal ?
        <ImageSection mobile={appState.media.somewhatMini}>
            <MapThumbnail shadowSize={appState.media.somewhatMini ? 5 : 8} src={imgFinal} maxHeight={appState.media.somewhatMini ? 60 : 112} maxWidth={appState.media.somewhatMini ? 80 : 200}/>
        </ImageSection>
        :
        <ImageSectionSmall mobile={appState.media.somewhatMini}>
            <MapIconHolder icon={icon}/>
        </ImageSectionSmall>;

    let titleOnlySection = <FadeInCentered show={data.current.show}>
            <Title mobile={appState.media.somewhatMini}>{getName(displayData)}</Title>
        </FadeInCentered>;

    let contentOnlySection = <ContentSection mobile={appState.media.somewhatMini}>
        <FadeInCentered show={data.current.show}>
            {wikipedia && data.current.data && data.current.data.extract ?
                <SummaryButton target="_blank" href={data.current.data.canonicalurl}>
                    <Summary mobile={appState.media.somewhatMini}>{normalize(data.current.data.extract).split("\n").map((p, index) => <p key={"p-"+index}>{p}</p>)}</Summary>
                </SummaryButton>
                : null
            }
            { (!wikipedia) ?
                <Summary mobile={appState.media.somewhatMini}>{getCustomDescription(displayData)}</Summary>
                : null
            }
        </FadeInCentered>
    </ContentSection>;

    let contentSection = <ContentSection mobile={appState.media.somewhatMini}>
        <FadeInCentered show={data.current.show}>
            <Title mobile={appState.media.somewhatMini}>{getName(displayData)}</Title>
            {wikipedia && data.current.data && data.current.data.extract ?
                <SummaryButton target="_blank" href={data.current.data.canonicalurl}>
                    <Summary mobile={appState.media.somewhatMini}>{normalize(data.current.data.extract).split("\n").map((p, index) => <p key={"p-"+index}>{p}</p>)}</Summary>
                </SummaryButton>
                : null
            }
            { (!wikipedia) ?
                <Summary>{getCustomDescription(displayData)}</Summary>
                : null
            }
        </FadeInCentered>
    </ContentSection>;

    let linksSection = (links && links.length > 0) ?
        <LinksSection mobile={appState.media.somewhatMini}>
            {zoomTo ?
                <LinkContainer key={"zoom"} onClick={zoomTo} mobile={appState.media.mobile}>
                    <MagnifyIconLightShadowed scale={18}/>
                    <LinkText mini={appState.media.somewhatMini}>{appState.media.mobile ? "Zoom" : "Zoom To"}</LinkText>
                </LinkContainer>
                : null
            }
            {links.map(link =>
                <LinkContainer key={link.url} target="_blank" href={link.url} mobile={appState.media.mobile}>
                    {link.icon ? <link.icon scale={18}/> : null}
                    <LinkText mini={appState.media.somewhatMini}>{link.title}</LinkText>
                </LinkContainer>
            )}
        </LinksSection>
        : null;

        if (appState.media.somewhatMini) {
            return <ContainerShort condensed={condensed}>
                {titleOnlySection}
                <Columns>
                    {imageSection}
                    {contentOnlySection}
                </Columns>
                {linksSection}
            </ContainerShort>;
        } else {
            return <Container>
                {imageSection}
                {contentSection}
                {linksSection}
            </Container>;
        }
}));

const AiTab = inject("appState")(observer(({appState, getAiData}) => {
    const data = useRef(observable({
        aiData: null
    }));

    useEffect(() => {
        (async () => {
            let aiData = await getAiData();
            runInAction(() => {
                data.current.aiData = aiData;
            })
        })();
    }, []);

    return data.current.aiData ?
        <>
            <ProsList mobile={appState.media.mobile}>{data.current.aiData.pros.map((item, index) =>
                <AiListItem key={`pro-${index}`}><PlusDiv><UpvoteLightShadowed scale={14}/></PlusDiv>{item}</AiListItem>
            )}</ProsList>
            <ConsList mobile={appState.media.mobile}>{data.current.aiData.cons.map((item, index) =>
                <AiListItem key={`con-${index}`}><MinusDiv><DownvoteLightShadowed scale={14}/></MinusDiv>{item}</AiListItem>
            )}</ConsList>
        </>
        :
        <SpinnerCenter>
            <SpinnerFadeIn/>
        </SpinnerCenter>
}));

const AiTabTitleCurrent = styled(DefaultTabTitleCurrent)`
  color: ${Theme.colors.ai};
`;

const AiShimmerText = styled.span`
  @keyframes shimmer {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 30rem 0;
    }
  }
  
  &:before {
    content: "AI Insights";
    position: absolute; 
    background: rgba(255,255,255,0) linear-gradient(90deg, rgba(255,255,255,0) 30%, ${Theme.colors.ai} 50%, rgba(255,255,255,0) 70%);
    background-repeat: repeat;
    background-size:30rem 1px;
    animation: infinite 4s linear;
    animation-name: shimmer;
    color: rgba(0,0,0,0) !important;
    background-clip: text;
    -webkit-background-clip: text;
    text-shadow: none;
  }
`;

const AiTabTitleLink = styled(DefaultTabTitleLink)`
  &:hover, &.focus-visible {
    color: ${Theme.colors.ai} !important;
  }
`;

export const AiTabTitle = ({selected, label, onClick, mobile}) => {
    return <>
        {selected ?
            <AiTabTitleCurrent mobile={mobile}>
                {/*<RobotIconLightShadowed scale={16} holderVerticalAlign={"-3px"} marginLeft={"3px"} marginRight={"6px"}/>*/}
                {label}
            </AiTabTitleCurrent>
            :
            <AiTabTitleLink mobile={mobile} onClick={onClick}>
                {/*<RobotIconLightShadowed scale={16} holderVerticalAlign={"-3px"} marginLeft={"3px"} marginRight={"6px"}/>*/}
                <AiShimmerText>{label}</AiShimmerText>
            </AiTabTitleLink>}
    </>;
}

const InfoHolder = styled.div`
  margin-top: 20px;
  height: 100%;
`;

let makeTabs = ({displayData, getImage, getName, getWikipedia, getId, getCustomDescription, links, getIcon, zoomTo, getAiData}) => {
    let tabs = [
        {
            label: "Summary",
            content: <InfoDisplay
                displayData={displayData}
                getImage={getImage}
                getName={getName}
                getWikipedia={getWikipedia}
                getId={getId}
                getCustomDescription={getCustomDescription}
                links={links} getIcon={getIcon}
                zoomTo={zoomTo}
                condensed={!!getAiData}
            />
        }
    ];
    if (getAiData) {
        tabs.push({
            label: "AI Insights",
            scrollY: true,
            scrollYMini: true,
            content: <AiTab getAiData={getAiData}/>,
            titleComponent: AiTabTitle
        });
    }
    return tabs;
};

export default ({displayData, getImage, getName, getWikipedia, getId, getCustomDescription, links, getIcon, zoomTo, getAiData}) => {
    if (getAiData) {
        return <TabDisplay
            queryNum={0}
            makeTabs={() => makeTabs({displayData, getImage, getName, getWikipedia, getId, getCustomDescription, links, getIcon, zoomTo, getAiData})}
        />;
    } else {
        return <InfoDisplay
            displayData={displayData}
            getImage={getImage}
            getName={getName}
            getWikipedia={getWikipedia}
            getId={getId}
            getCustomDescription={getCustomDescription}
            links={links} getIcon={getIcon}
            zoomTo={zoomTo}
        />;
    }
};
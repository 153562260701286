
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Theme from "../../styles/Theme";

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipHolder = styled.div`
  position: absolute;
  margin-top: -30px;
  pointer-events: none;
`;

const TooltipText = styled.span`
  width: 100px;
  background-color: rgba(0,0,0,.75);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -50px;
`;

const Arrow = styled.div`
  content: "";
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0,0,0,.75) transparent transparent transparent;
  z-index: 1;
  margin-top: -16px;
`

const TrackElement = styled.div`
  position: absolute;
  height: 8px;
  z-index: 0;
  background-color: ${p => p.inactive ? Theme.colors.backgroundLighter : Theme.colors.kindaDim};
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.35), inset 0 0 2px 0 rgba(255,255,255,.15);
  cursor: pointer;
  left: ${p => p.source.percent}%;
  width: ${p => p.target.percent - p.source.percent}%;
`;

const TickElement = styled.div`
  background-color: ${Theme.colors.dim};
`;

const TickText = styled.div`
  color: ${Theme.colors.dim};
`;

export const RailElement = styled.div`
  position: absolute;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${Theme.colors.backgroundDark};
  box-shadow: inset 0 1px 4px 0 rgba(0,0,0,.35);
  z-index: 0;
`;

const HandleElement = styled.div`
  position: absolute;
  margin-left: -6px;
  margin-top: -3px;
  z-index: 0;
  width: 13px;
  height: 13px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.35), inset 0 0 2px 0 rgba(255,255,255,.15);
  background-color: ${p => p.inactive ? Theme.colors.dim : Theme.colors.kindaDim};
`;

export class Handle extends Component {
    state = {
        mouseOver: false
    };

    render() {
        const {
            domain: [min, max],
            handle: { id, value, percent },
            transform,
            getHandleProps,
            isActive
        } = this.props;
        const showTooltip = (isActive || this.state.mouseOver) && !this.props.noTooltip;

        return (
            <React.Fragment>
                {showTooltip ? (
                    <>
                        <TooltipHolder
                            ref={(ref) => this.ref = ref}
                            style={{
                                left: `calc(min(max(${percent}%, 40px), calc(100% - 40px))`
                            }}
                        >
                            <Tooltip>
                                <TooltipText>{transform(value)}</TooltipText>
                            </Tooltip>
                        </TooltipHolder>
                        <Arrow style={{
                            left: `${percent}%`
                        }}/>
                    </>
                ) : null}
                <HandleElement
                    inactive={false}
                    role="slider"
                    aria-valuemin={min}
                    aria-valuemax={max}
                    aria-valuenow={value}
                    style={{
                        left: `${percent}%`
                    }}
                    {...getHandleProps(id, {
                        onMouseLeave: () => {
                            this.setState({
                                mouseOver: false
                            });
                        },
                        onMouseOver: () => {
                            this.setState({
                                mouseOver: true
                            });
                        }
                    })}
                />
            </React.Fragment>
        );
    }
}

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    transform: PropTypes.func.isRequired
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps }) {
    return (
        <TrackElement
            inactive={source.percent === 0 && target.percent === 100}
            source={source}
            target={target}
            {...getTrackProps()}
        />
    );
}

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export class Tick extends Component {

    render() {
        const {
            domain: [min, max],
            tick,
            count,
            transform
        } = this.props;
        return (
            <div>
                <TickElement
                    style={{
                        position: "absolute",
                        marginTop: 14,
                        width: 1,
                        height: 5,
                        left: `${tick.percent}%`
                    }}
                />
                <TickText
                    style={{
                        position: "absolute",
                        marginTop: 22,
                        fontSize: 10,
                        fontFamily: "Arial",
                        textAlign: "center",
                        marginLeft: `${-(100 / count) / 2}%`,
                        width: `${100 / count}%`,
                        left: `${tick.percent}%`
                    }}
                >
                    {transform(tick.value)}
                </TickText>
            </div>
        );
    }
}

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired
    }).isRequired,
    count: PropTypes.number.isRequired,
    domain: PropTypes.array.isRequired,
    transform: PropTypes.func.isRequired
};

Tick.defaultProps = {
    format: d => d
};

import Config from "./Config";
import posthog from 'posthog-js';

function webglSupport() {
    try {
        let canvas = document.createElement('canvas');
        return !!window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
        return false;
    }
}

export default () => {
    let url = new URL(window.location.href);
    let attachPosthog = url.searchParams.get("attachPosthog");
    posthog.init(Config.APP_POSTHOG_KEY, {api_host: (attachPosthog && attachPosthog !== "") ? Config.APP_POSTHOG_BASE_URL : (Config.APP_API_BASE_URL + '/api/a')});
    posthog.register({
        "DevicePixelRatio": window.devicePixelRatio,
        "WebGL": webglSupport() ? "yes" : "no",
        "Touch": "no"
    });
    return posthog;
}
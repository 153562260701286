import * as PIXI from 'pixi.js'

const VERT = `

precision highp float;

attribute vec2 aVertexPosition;
attribute vec2 aTextureCoord;

uniform mat3 projectionMatrix;
uniform mat3 invWorldTransform;

varying vec2 vTextureCoord;

void main(void)
{
    gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
    vTextureCoord = aTextureCoord;
}
`;

const FRAG = `

precision highp float;

varying vec2 vTextureCoord;

uniform sampler2D uSampler;

uniform float exposure;
uniform float offset;
uniform float gamma;
uniform float saturation;

const mat3 toYcbcr = mat3(0.299, 0.587, 0.114, -0.168736, -0.331264, 0.500, 0.500, -0.418688 , -0.081312);
const mat3 toRgb = mat3(1.0, 0.0, 1.402, 1.0, -0.344136, -0.714136, 1.0, 1.772, 0.0);

vec3 HUEtoRGB(float H)
  {
    float R = abs(H * 6.0 - 3.0) - 1.0;
    float G = 2.0 - abs(H * 6.0 - 2.0);
    float B = 2.0 - abs(H * 6.0 - 4.0);
    return clamp(vec3(R,G,B), 0.0, 1.0);
  }
  
vec3 HSLtoRGB(vec3 HSL)
{
    vec3 RGB = HUEtoRGB(HSL.x);
    float C = (1.0 - abs(2.0 * HSL.z - 1.0)) * HSL.y;
    return (RGB - 0.5) * C + HSL.z;
}

vec3 RGBtoHCV(vec3 RGB)
  {
    vec4 P = (RGB.g < RGB.b) ? vec4(RGB.bg, -1.0, 2.0/3.0) : vec4(RGB.gb, 0.0, -1.0/3.0);
    vec4 Q = (RGB.r < P.x) ? vec4(P.xyw, RGB.r) : vec4(RGB.r, P.yzx);
    float C = Q.x - min(Q.w, Q.y);
    float H = abs((Q.w - Q.y) / (6.0 * C + 1e-10) + Q.z);
    return vec3(H, C, Q.x);
  }

vec3 RGBtoHSL(vec3 RGB)
  {
    vec3 HCV = RGBtoHCV(RGB);
    float L = HCV.z - HCV.y * 0.5;
    float S = HCV.y / (1.0 - abs(L * 2.0 - 1.0) + 1e-10);
    return vec3(HCV.x, S, L);
  }

float doGamma(float v){
    if(v <= .0031308){
        return max(min(12.92 * v, 1.0), 0.0);
    } else {
        return max(min(1.055 * pow(v, .41666666666) - .055, 1.0), 0.0);
    }
}
 
vec4 doGamma(vec4 c){
    return vec4(doGamma(c.r),doGamma(c.g),doGamma(c.b),c.a);
}

float revGamma(float v){
    if(v <= .04045){
        return v / 12.92;
    } else {
        return pow((v+.055)/1.055, 2.4);
    }
}

vec4 revGamma(vec4 c){
     return vec4(revGamma(c.r),revGamma(c.g),revGamma(c.b),c.a);
}

float adjust(float value){
    float adjusted = pow(min(max(exposure * value + offset, 0.0), 1.0), gamma);
    return max(0.0, min(1.0, adjusted));
}
 
vec4 adjust(vec4 inp){
    vec3 hsv = RGBtoHSL(inp.rgb);
        hsv.g = (saturation <= 0.0) ? clamp((1.0 + saturation) * hsv.g, 0.0, 1.0) : clamp(hsv.g * (1.0 / ( 1.0 - saturation)), 0.0, 1.0);
    vec4 value = vec4(HSLtoRGB(hsv), inp.a);
        
    vec4 conv = doGamma(value);
    conv = vec4(adjust(conv.r)*conv.a, adjust(conv.g)*conv.a, adjust(conv.b)*conv.a, conv.a);
    return revGamma(conv);
}

void main(void)
{
    gl_FragColor = adjust(texture2D(uSampler, vTextureCoord));
}
`;

/**
 * @class
 * @extends PIXI.Shader
 * @memberof PIXI.extras
 * @param gl {PIXI.Shader} The WebGL shader manager this shader works for.
 */
export default class ImageShaderLQWithAdjustments extends PIXI.Shader
{
    constructor()
    {
        let uniforms = {};
        uniforms.exposure = 1.0;
        uniforms.offset = 0.0;
        uniforms.gamma = 1.0;
        uniforms.saturation = 0.0;

        super(new PIXI.Program(VERT, FRAG, "lq-with-adj"), uniforms);

        //this.bind();
    }
}

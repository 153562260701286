import React from "react"
import InfiniteScrollList from "../../components/InfiniteScrollList";
import styled from '@emotion/styled'
import {inject, observer} from 'mobx-react'
import UserLink from "../../components/UserLink";
import Breadcrumbs from "../../components/Breadcrumbs";
import {UserImageComponent} from "../gallery/profile/ProfileHeader";
import DumbLinkContainer from "../../components/DumbLinkContainer";
import LoggedOutError from "../../components/LoggedOutError";

const Header = styled.div`
  margin-bottom: 20px;
`;

const UserA = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UserImageHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const ElementFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ElementFlexForward = styled(ElementFlex)`
  flex-direction: column;
`;

const ElementFlexReverse = styled(ElementFlex)`
  flex-direction: column-reverse;
`;

const ElementHolder = styled.div`
  margin: 20px 0;
`;

const UserItem = ({user}) => {
    return <div><UserLink nonInteractive user={user}/></div>;
}

export default inject("appState")(observer(({appState, title, emptyMessage, loginRequired, getRetrievalParams}) => {

    let renderElement = (data, navCallback) => {
        let link;
        if (data.alias){
            link = "/" + data.alias;
        } else {
            link = "/user/_" + data.ident;
        }
        return <ElementHolder key={data.ident}>
            <DumbLinkContainer to={link}>
                <UserA onClick={navCallback}>
                    <UserImageHolder>
                        <UserImageComponent filename={data.imageFilename}/>
                    </UserImageHolder>
                    <UserItem user={data}/>
                </UserA>
            </DumbLinkContainer>
        </ElementHolder>;
    };

    let renderContainerReverse = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexReverse ref={setRef}>
                {children}
            </ElementFlexReverse>
            :
            <></>;
    };

    let renderContainer = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexForward ref={setRef}>
                {children}
            </ElementFlexForward>
            :
            <></>;
    };

    let header = <>
        <Header>
            <Breadcrumbs noBottomPadding>{title}</Breadcrumbs>
        </Header>
    </>;

    return ((appState.user || !loginRequired) ?
        <InfiniteScrollList
            params={getRetrievalParams()}
            waitFor={null}
            header={header}
            renderElement={renderElement}
            renderContainerReverse={renderContainerReverse}
            renderContainer={renderContainer}
            emptyMessage={emptyMessage ? emptyMessage : "No users."}
        />
        :
        <LoggedOutError message={"Please log in."}/>
    );
}));
import React, {useEffect, useState, useContext, useRef} from 'react';
import ReactDOM from 'react-dom';
import ContextContext from "./ContextContext";
import {backdropFilterSupportCondition} from "../../../styles/BackdropFilter";
import Scroll from "../../../components/Scroll";
import Theme from "../../../styles/Theme";
import ContextItem from "./ContextItem";
import {TextLike} from "../../../styles/Button";
import { css } from "@emotion/react";
import styled from '@emotion/styled';

export const ContextMenuCss = css`
  min-width: 200px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.75), 0 0 15px 0 rgba(0,0,0,.5), inset 0 0 0px 1px rgba(255,255,255,.15);
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
  contain: layout paint;
  
  * {
    outline: none;
  }
  
  @supports ${backdropFilterSupportCondition()} {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(25,25,25,.75);
  }
  @supports not ${backdropFilterSupportCondition()} {
    background-color: rgba(25,25,25,.95);
  }
`;

export const ContextMenuStyled = styled.div`
  ${ContextMenuCss};
  z-index: 1000;
  max-height: calc(var(--app-height) / 2);
  ${p => p.dragOver ? "background-color: rgba(50,50,50,.95) !important" : ""};
  pointer-events: auto;
`;

export const ScrollInheritMaxHeight = styled(Scroll)`
  max-height: inherit;
`;

export const MenuDividerDiv = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgba(255,255,255,.1);
`;

export const MenuPartCss = css`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  user-select: none;
  color: ${Theme.colors.almostWhite};
  display: flex;
`;

export const MenuPart = styled.div`
  ${MenuPartCss};
`;

export const MenuLabelStyled = styled.div`
  ${MenuPartCss};
  color: ${Theme.colors.almostDim};
  font-style: italic;
`;

export const ContextItemStyled = styled(ContextItem)`
  ${MenuPartCss};
  cursor: pointer;
  margin-bottom: 4px;
  user-select: none;
  
  &:hover {
    background-color: rgba(255,255,255,.1);
  }
`;

export const ContextItemStyledEmbed = styled(ContextItemStyled)`
  ${TextLike};
  color: ${Theme.colors.almostDim};
`;

export const VisualCheckboxHolder = styled.div`
  display: inline-block;
  padding-right: 8px;
`;

export const VisualCheckbox = styled.input`
  user-select: none;
  pointer-events: none;
`;

export default (props) => {

    let itemRect = props.itemRect;
    let [content, setContent] = useState(null);
    let [activeItem, setActiveItem] = useState(null);
    let isMounted = useRef(false);
    let context = useContext(ContextContext);
    let overlap = 10;
    let [openRight, setOpenRight] = useState(true);

    let refLoaded = (ref) => {
        if (ref){
            let menuRect = ref.getBoundingClientRect();

            let useForce = props.openRight !== null && props.openRight !== undefined;
            let right;
            if (useForce) {
                right = props.openRight;
            } else {
                if (context.openRight) {
                    right = itemRect.right + menuRect.width <= window.innerWidth;
                } else {
                    right = itemRect.left - menuRect.width < 0;
                }
            }
            if (right) {
                ref.style.left = Math.min(itemRect.right - overlap, window.innerWidth - menuRect.width) + "px";
            } else {
                ref.style.left = Math.max(itemRect.left - menuRect.width + overlap, 0) + "px";
            }
            if (menuRect.height + itemRect.top <= window.innerHeight) {
                ref.style.top = (itemRect.top) + "px";
            } else {
                ref.style.top = (itemRect.bottom - menuRect.height) + "px";
            }
            ref.style.position = "fixed";
            setOpenRight(right);
        }
    };

    let reload = async () => {
        let val = await props.content({reload, close: context.close});
        if (isMounted.current) {
            setContent(val);
        }
    };

    useEffect(() => {
        isMounted.current = true;
        (async () => {
            if (props.content){
                await reload();
            }
        })();
        return () => isMounted.current = false;
    }, []);

    return ReactDOM.createPortal(content ?
        <div onContextMenu={e => e.preventDefault()} ref={refLoaded}>
            <ContextContext.Provider
                value={{
                    depth: context.depth+1,
                    close: context.close,
                    activeItem,
                    setActiveItem,
                    portal: context.portal,
                    openRight: openRight,
                    touch: context.touch
                }}
            >
                {content}
            </ContextContext.Provider>
        </div>
        :
        <></>, context.portal.current);
}

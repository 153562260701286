import Config from "../Config";
import { decode } from 'jsonwebtoken'
import { EventEmitter } from 'eventemitter3'

class Api {

    get JSON() { return "json" };
    get TEXT() { return "text" };
    get PASS() { return "pass" };

    constructor (prefix, baseurl, loginprefix) {
        this.loggedIn = false;
        this.refreshEmitter = new EventEmitter();
        this.prefix = prefix;
        this.baseurl = baseurl;
        this.loginprefix = loginprefix;
    };

    setAnonymous = (value) => {
        this.anonymous = value;
    };

    get = async (path, type = this.JSON, canRetry = true) => {
        let response = await this.getSimple(path);
        if (response.ok){
            return await this.handleValid(response, type);
        } else {
            return await this.handleInvalid(response, type, canRetry ? () => this.get(path, type, false) : null);
        }
    };

    post = async (path, object, type = this.JSON, cors = true, jsonBody=true, canRetry=true) => {
        let result = await this.postSimple(path, object, cors, jsonBody);
        if (result.ok) {
            return await this.handleValid(result, type);
        } else {
            return await this.handleInvalid(result, type, canRetry ? () => this.post(path, object, type, cors, jsonBody, false) : null);
        }
    };

    addRefreshFunction = (f) => {
        this.refreshEmitter.on("refresh", f);
    };

    removeRefreshFunction = (f) => {
        this.refreshEmitter.removeListener("refresh", f);
    };

    addSuspendedFunction = (f) => {
        this.refreshEmitter.on("suspended", f);
    };

    removeSuspendedFunction = (f) => {
        this.refreshEmitter.removeListener("suspended", f);
    };

    refresh = () => {
        let newLoggedIn = this.isCredentialed();
        if (this.loggedIn !== newLoggedIn){
            this.loggedIn = newLoggedIn;
            this.refreshEmitter.emit("refresh");
        }
        return newLoggedIn;
    };

    getSimple = async (path) => {
        let headers = await this.getHeaders();
        return await fetch(this.baseurl + "/" + this.prefix + path, {
            credentials: 'include',
            headers: headers
        });
    };

    postSimple = async (path, object, cors=true, jsonBody=true) => {
        let headers = await this.getHeaders();
        headers.append('content-type', 'application/json');
        return await fetch(this.baseurl + "/" + this.prefix + path, {
            method: "POST",
            body: jsonBody ? JSON.stringify(object) : object,
            credentials: "include",
            headers: headers,
            mode: cors ? 'cors' : 'no-cors'
        });
    };

    reloadAccess = async (potentialChanges) => {
        if (this.anonymous) {
            return false;
        } else {
            let refresh = localStorage.getItem("refresh");
            let decoded = decode(refresh);
            if (Date.now() / 1000 >= decoded.exp) {
                refresh = null;
            } else {
                let refreshHeaders = new Headers();
                refreshHeaders.append("Authorization", "Bearer " + refresh);
                let response = await fetch(this.baseurl + this.loginprefix + `/login/refresh${potentialChanges ? "?changes=true" : ""}`, {
                    method: "POST",
                    credentials: "include",
                    headers: refreshHeaders,
                    mode: 'cors'
                });

                if (response.ok) {
                    let data = await response.json();

                    if (data.access) {
                        localStorage.setItem("access", data.access);
                    }

                    if (data.refresh) {
                        localStorage.setItem("refresh", data.refresh);
                    }

                    return true;
                } else {
                    if (response.status === 401) {
                        let access = localStorage.getItem("access");
                        let decodedAccess;
                        if (access){
                            decodedAccess = decode(access);
                        }
                        if (!access || Date.now() / 1000 >= decodedAccess.exp) {
                            localStorage.removeItem("access");
                            localStorage.removeItem("refresh");
                        }
                    }
                    return false;
                }
            }
        }
    }

    notifyReload = () => {
        this.refreshEmitter.emit("refresh");
    }

    reloadAndNotify = async () => {
        await this.reloadAccess(true);
        this.notifyReload();
    }

    toHeaders = (tokens) => {
        let headers = new Headers();
        if (!this.anonymous && tokens.access) {
            headers.append("Authorization", "Bearer " + tokens.access);
        }
        return headers;
    };

    getHeaders = async () => {
        if (this.anonymous){
            return new Headers();
        } else {
            if (this.authLock) {
                return this.toHeaders(await this.authLock);
            }

            let authLock = new Promise(async (resolve) => {
                let tokens = {};
                try {
                    let access = localStorage.getItem("access");
                    let refresh = localStorage.getItem("refresh");

                    if (access) {
                        let decoded = decode(access);
                        if (Date.now() / 1000 >= decoded.exp - 2) {
                            access = null;
                        }
                    }

                    if (!access && refresh) {
                        await this.reloadAccess();
                        access = localStorage.getItem("access");
                    }

                    if (access) {
                        tokens.access = access;
                    }

                    this.refresh();
                } finally {
                    resolve(tokens);
                }
            });
            this.authLock = authLock;
            authLock.then(() => this.authLock = null);
            return this.toHeaders(await authLock);
        }
    };

    handleInvalid = async (response, type, retry) => {
        if (!this.anonymous) {
            if (response.status === 401) {
                let text = await response.clone().text();
                if (text === "invalid-creds") {
                    localStorage.removeItem("access");
                    if (retry) {
                        return retry();
                    } else {
                        return null;
                    }
                }
            } else if (response.status === 403) {
                let obj = await response.clone().json();
                if (obj.code === "suspended") {
                    this.refreshEmitter.emit("suspended");
                }
            }
        }
        if (type === this.PASS) {
            return response;
        } else {
            return null;
        }
    };

    handleValid = async (response, type) => {
        if (type === this.TEXT) {
            return await response.text();
        } else if (type === this.JSON) {
            let txt = await response.text();
            if (txt.length > 0){
                return JSON.parse(txt);
            } else {
                return null;
            }
        } else if (type === this.PASS) {
            return response;
        } else {
            return null;
        }
    };

    saveTokens = (accessToken, refreshToken) => {
        if (!this.anonymous) {
            localStorage.setItem("access", accessToken);
            localStorage.setItem("refresh", refreshToken);
        }
        this.refresh();
    };

    clearTokens = () => {
        if (!this.anonymous) {
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
        }
        this.refresh();
    };

    isCredentialed = () => {
        if (this.anonymous){
            return false;
        } else {
            let access = localStorage.getItem("access");
            let refresh = localStorage.getItem("refresh");

            if (access) {
                let decoded = decode(access);
                if (Date.now() / 1000 < decoded.exp - 5) {
                    return true;
                }
            }

            if (refresh) {
                let decoded = decode(refresh);
                if (Date.now() / 1000 < decoded.exp - 5) {
                    return true;
                }
            }

            return false;
        }
    };

    getToken = () => {
        if (this.anonymous){
            return null;
        } else {
            let access = localStorage.getItem("access");
            if (access) {
                return decode(access);
            } else {
                return null;
            }
        }
    }
}
export const MapsApi = new Api("maps", Config.APP_API_BASE_URL, "");
export const AdminApi = new Api("admin", Config.APP_ADMINAPI_BASE_URL, "/admin");
export default new Api("api", Config.APP_API_BASE_URL, "");
import React, { Component } from 'react';
import * as PIXI from 'pixi.js'
import { skipHello, clearTextureCache } from '@pixi/utils';
import ImageHQRenderer from './ImageHQRenderer'
import ThumbPreviewRenderer from './ThumbPreviewRenderer'
import { runInAction, autorun, toJS, reaction, comparer, trace } from "mobx";
import { observer, inject } from "mobx-react";
import {getSignedUrlSync} from "../../helpers/SignedUrlLoader";
import WindowHelper from "../../helpers/WindowHelper";
import {sleep} from "../../helpers/AsyncHelper";
import LRUCache from "lru-cache";
var innerHeight = require('ios-inner-height');

const SHADOW_THICKNESS = 25;
const SHADOW_THICKNESS_REGION = 18; // lower since it will not be inset
const SHADOW_THICKNESS_OVERLAY = 12;

const USE_CUSTOM_SHADERS = true;

PIXI.Renderer.registerPlugin('imagehq', ImageHQRenderer);
PIXI.Renderer.registerPlugin('thumbpreview', ThumbPreviewRenderer);
skipHello();

function intPow(base, exp){
    let result = 1;
    for(let i = 0; i < exp; ++i){
        result = result * base;
    }
    return result;
}

class Canvas extends Component {

    constructor(props) {
        super(props);

        this.x = 256;
        this.y = 256;
        this.z = .5;
        this.halted = false;
        this.oHalted = false;
        this.needUpdate = true;
        this.needLazyUpdate = false;
        this.selectedRegionPct = 0;
        this.t = 0;
        this.timeSinceLastLazyUpdate = 0;
        this.timeInThumbnail = 0;
        this.staticBaseTextures = this.makeTextureCache(0);
        this.thumbnailTextures = this.makeTextureCache(8);
        this.baseTextures = this.makeTextureCache(256);
        this.oInnerHeight = 0;
        this.timeInPhoto = 0;

        this.animate = this.animate.bind(this);
        this.setShadowPlacement = this.setShadowPlacement.bind(this);
        this.placeShadow = this.placeShadow.bind(this);
        this.reset = this.reset.bind(this);
        this._fromInternalCoords = this._fromInternalCoords.bind(this);
    }

    newFrameHeight(){
        return Math.max(innerHeight(), this.props.appState.viewport.navHeight + 10);
    }

    newFrameWidth(){
        return Math.max(this.props.appState.viewport.bodyClientWidth, 10);
    }

    useCanvas(){
        return false;
    }

    bufferChunkSize(){
        return 128;
    }

    getBufferScale(gl) {
        let slider = Math.max(0, Math.min(1, 2 - window.devicePixelRatio));
        let intended = 2.1 + slider * slider * .15;
        let maxDim = Math.max(this.renderWidth, this.renderHeight);
        if(this.useCanvas()){
            return Math.min(intended, (4096 - this.bufferChunkSize()) / maxDim);
        } else {
            return Math.min(intended, (this.getMaxTextureSize(gl) - this.bufferChunkSize()) / maxDim);
        }
    }

    getMaxTextureSize(gl){
        if(this.maxTextureSize === undefined){
            this.maxTextureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);
        }
        return this.maxTextureSize;
    }

    intersect(r1, r2){
        if(r1.right < r2.left || r1.left > r2.right){
            return false;
        }
        if(r1.bottom < r2.top || r1.top > r2.bottom){
            return false;
        }

        return true;
    }

    _toInternalCoords(xx,yy,zz, adW, adH, fillZ, minDim){
        let nnx = .5 * (1 + xx / zz) * adW * fillZ / minDim;
        let nny = .5 * (1 + yy / zz) * adH * fillZ / minDim;
        let nnz = zz * fillZ;
        return [nnx, nny, nnz]
    };

    _fromInternalCoords(nnx,nny,nnz, adW, adH, fillZ, minDim){
        let zz = nnz / fillZ;
        let xx = (2 * nnx / (adW * fillZ / minDim) - 1) * zz;
        let yy = (2 * nny / (adH * fillZ / minDim) - 1) * zz;
        return [xx, yy, zz]
    };

    toInternalCoords(xx,yy,zz){
        return this._toInternalCoords(xx, yy, zz, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    };

    fromInternalCoords(nxx,nyy,nzz){
        return this._fromInternalCoords(nxx, nyy, nzz, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    };

    spriteFromImage(url, bucket, callback){
        return this.spritesFromImage(url, bucket, callback, 1, null)[0];
    }

    spritesFromImage(url, bucket, callback, num, bitmap){
        let sprites = [];
        for (let i = 0; i < num; i++) {
            sprites[i] = new PIXI.Sprite();
        }
        this.loadTexture(url, url, bucket, bitmap).then((bt) => {
            for (let i = 0; i < num; i++) {
                if (sprites[i].texture){
                    sprites[i].texture.destroy();
                }
                sprites[i].texture = new PIXI.Texture(bt, sprites[i].frame);
            }
            if(callback){
                callback(sprites);
            }
        }, (error) => { console.log(error); });
        return sprites;
    }

    componentDidMount() {
        this.props.onRef(this);

        this.allSprite = new PIXI.Sprite();
        this.allSprite.name = "allSprite";

        this.stageSprite = new PIXI.Sprite();
        this.stageSprite.name = "stageSprite";

        if(!this.shadowEdge){
            this.shadowEdge = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowEdge[i] = this.spriteFromImage('/static/images/shadow-edge.png', this.staticBaseTextures);
                this.shadowEdge[i].anchor.set(1);
                this.shadowEdge[i].visible = false;
                this.stageSprite.addChild(this.shadowEdge[i]);
            }
        }
        if(!this.shadowCorner) {
            this.shadowCorner = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowCorner[i] = this.spriteFromImage('/static/images/shadow-corner.png', this.staticBaseTextures);
                this.shadowCorner[i].anchor.set(1);
                this.shadowCorner[i].visible = false;
                this.stageSprite.addChild(this.shadowCorner[i]);
            }
        }

        this.photoLayer = new PIXI.Sprite();
        this.stageSprite.addChild(this.photoLayer);

        this.graphics = new PIXI.Graphics();
        this.graphics.nativeLines = false;
        this.stageSprite.addChild(this.graphics);

        this.graphicsAdditive = new PIXI.Graphics();
        this.graphicsAdditive.nativeLines = false;
        this.graphicsAdditive.blendMode = PIXI.BLEND_MODES.ADD;
        this.stageSprite.addChild(this.graphicsAdditive);

        if(!this.shadowRegionEdge){
            this.shadowRegionEdge = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowRegionEdge[i] = this.spriteFromImage('/static/images/shadow-edge.png', this.staticBaseTextures);
                this.shadowRegionEdge[i].anchor.set(1);
                this.shadowRegionEdge[i].visible = false;
                this.stageSprite.addChild(this.shadowRegionEdge[i]);
            }
        }
        if(!this.shadowRegionCorner) {
            this.shadowRegionCorner = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowRegionCorner[i] = this.spriteFromImage('/static/images/shadow-corner.png', this.staticBaseTextures);
                this.shadowRegionCorner[i].anchor.set(1);
                this.shadowRegionCorner[i].visible = false;
                this.stageSprite.addChild(this.shadowRegionCorner[i]);
            }
        }

        this.extraShadows = [];
        this.extraShadowsSprite = new PIXI.Sprite();
        this.stageSprite.addChild(this.extraShadowsSprite);

        this.allSprite.addChild(this.stageSprite);

        this.updateThumbnailSpeculation();

        reaction(() => this.props.appState.thumbnailSpeculativePotential, this.promoteSpeculationPotential);

        runInAction(() => {
            this.props.appState.speculate = (thumbnail, callback) => {
                this.thumbnailSpeculativePotential = thumbnail;
                this.thumbnailSpeculativePotentialCallback = callback;
                this.promoteSpeculationPotential();
            }
            this.props.appState.runCanvasAnimationFrame = () => {
                this.animate(0, []);
            }
        });

        this.updateDimensions();

        this.props.emitter.on("animate", this.animate);

        this.componentDidUpdate(this.props);
    }

    promoteSpeculationPotential = () => {
        let ts = !!this.thumbnailSpeculative ? this.thumbnailSpeculative.ident : null;
        let tsp = !!this.thumbnailSpeculativePotential ? this.thumbnailSpeculativePotential.ident : null;
        if ((ts === tsp) && !!tsp) {
            this.thumbnailSpeculativePotential = null;
            if (this.thumbnailSpeculativePotentialCallback) {
                this.thumbnailSpeculativePotentialCallback();
                this.thumbnailSpeculativePotentialCallback = null;
            }
        } else if (ts !== tsp && (!this.inPhoto || (this.inPhoto && (!this.thumbnailSprite || !this.thumbnailSprite.renderable) && (!this.bufferThumbnailSprite || !this.bufferThumbnailSprite.renderable))) && !!tsp) {
            this.thumbnailSpeculative = this.thumbnailSpeculativePotential;
            this.thumbnailSpeculativeCallback = this.thumbnailSpeculativePotentialCallback;
            this.thumbnailSpeculativePotential = null;
            this.thumbnailSpeculativePotentialCallback = null;
            this.updateThumbnailSpeculation();
        }
    };

    updateThumbnailSpeculation = async () => {
        if(this.thumbnailSprite) {
            this.thumbnailSprite.texture.destroy();
            this.thumbnailSprite.destroy();
            this.thumbnailSprite = null;
        }
        if(this.bufferThumbnailSprite) {
            this.bufferThumbnailSprite.texture.destroy();
            this.bufferThumbnailSprite.destroy();
            this.bufferThumbnailSprite = null;
        }
        if(this.previewSprite) {
            if(this.shadowPreviewEdge) {
                for(let i = 0; i < 4; ++i) {
                    this.shadowPreviewEdge[i].texture.destroy();
                    this.shadowPreviewEdge[i].destroy();
                }
            }
            if(this.shadowPreviewCorner) {
                for(let i = 0; i < 4; ++i) {
                    this.shadowPreviewCorner[i].texture.destroy();
                    this.shadowPreviewCorner[i].destroy();
                }
            }
            this.shadowPreviewEdge = null;
            this.shadowPreviewCorner = null;
            this.previewSprite.texture.destroy();
            this.previewSprite.destroy();
            this.previewSprite = null;
        }
        this.previewSprite = new PIXI.Sprite();
        this.previewSprite.name = "previewSprite";
        if(!this.shadowPreviewEdge){
            this.shadowPreviewEdge = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowPreviewEdge[i] = this.spriteFromImage("/static/images/shadow-edge.png", this.staticBaseTextures);
                this.shadowPreviewEdge[i].anchor.set(1);
                this.previewSprite.addChild(this.shadowPreviewEdge[i]);
            }
        }
        if(!this.shadowPreviewCorner) {
            this.shadowPreviewCorner = [];
            for(let i = 0; i < 4; ++i) {
                this.shadowPreviewCorner[i] = this.spriteFromImage('/static/images/shadow-corner.png', this.staticBaseTextures);
                this.shadowPreviewCorner[i].anchor.set(1);
                this.previewSprite.addChild(this.shadowPreviewCorner[i]);
            }
        }
        this.thumbnailSpriteLoaded = false;
        if (this.thumbnailSpeculative) {
            let filename = this.thumbnailSpeculative.fn;
            let ident = this.thumbnailSpeculative.ident;
            let fullFilename = this.thumbnailSpeculative.imgSrc ? this.thumbnailSpeculative.imgSrc + "/" + filename : getSignedUrlSync(filename);
            let bitmap = this.thumbnailSpeculative.bitmap;
            let sprites = this.spritesFromImage(fullFilename, this.thumbnailTextures, (sprites) => {
                if (this.thumbnailSpeculative && ident === this.thumbnailSpeculative.ident) {
                    sprites.forEach((sprite) => {
                        sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                    });
                    this.thumbnailSpriteLoaded = true;
                    if (this.thumbnailSpeculativeCallback){
                        this.thumbnailSpeculativeCallback();
                        this.thumbnailSpeculativeCallback = null;
                    }
                }
            }, 2, bitmap);
            this.thumbnailSprite = sprites[0];
            this.thumbnailSprite.name = "thumbnailSprite";
            this.bufferThumbnailSprite = sprites[1];
            this.bufferThumbnailSprite.name = "bufferThumbnailSprite";
            if (USE_CUSTOM_SHADERS) {
                this.thumbnailSprite.pluginName = "thumbpreview";
                this.bufferThumbnailSprite.pluginName = "thumbpreview";
            }
            this.bufferContentsSprite.addChild(this.bufferThumbnailSprite);
            this.previewSprite.addChild(this.thumbnailSprite);
        }
        this.timeInThumbnail = 0;
        this.allSprite.addChild(this.previewSprite);
    };

    placeShadow(shadowCorners, shadowEdges, xIn, yIn, wIn, hIn, inset, thickness, alpha, visible, infoW, infoH, adW, adH, z, fillZ, minDim){

        let w = wIn / infoW * adW * z;
        let h = hIn / infoH * adH * z;

        let [x,y] = this._photoPxToScreenCoords(xIn+wIn/2, yIn+hIn/2,  infoW, infoH, adW, adH, fillZ, minDim);
        x *= window.devicePixelRatio;
        y *= window.devicePixelRatio;

        this.placeShadowDirect(x, y, w, h, shadowCorners, shadowEdges, inset, thickness, alpha, visible, z, fillZ)
    }

    placeShadowDirect(x, y, w, h, shadowCorners, shadowEdges, inset, thickness, alpha, visible){

        let amt = thickness * window.devicePixelRatio;

        w = w - inset*amt;
        h = h - inset*amt;

        for(let i = 0; i < 4; ++i) {
            shadowCorners[i].alpha = alpha;
            shadowEdges[i].alpha = alpha;
            shadowCorners[i].visible = visible;
            shadowEdges[i].visible = visible;
        }

        shadowCorners[0].x = x - w / 2;
        shadowCorners[0].y = y - h / 2;
        shadowCorners[0].width = amt;
        shadowCorners[0].height = amt;
        shadowCorners[0].rotation = 0;

        shadowCorners[1].x = x + w / 2;
        shadowCorners[1].y = y - h / 2;
        shadowCorners[1].width = amt;
        shadowCorners[1].height = amt;
        shadowCorners[1].rotation = Math.PI / 2;

        shadowCorners[2].x = x + w / 2;
        shadowCorners[2].y = y + h / 2;
        shadowCorners[2].width = amt;
        shadowCorners[2].height = amt;
        shadowCorners[2].rotation = Math.PI;

        shadowCorners[3].x = x - w / 2;
        shadowCorners[3].y = y + h / 2;
        shadowCorners[3].width = amt;
        shadowCorners[3].height = amt;
        shadowCorners[3].rotation = 3 * Math.PI / 2;

        shadowEdges[0].x = x + w / 2;
        shadowEdges[0].y = y - h / 2;
        shadowEdges[0].width = w;
        shadowEdges[0].height = amt;
        shadowEdges[0].rotation = 0;

        shadowEdges[1].x = x + w / 2;
        shadowEdges[1].y = y + h / 2;
        shadowEdges[1].width = h;
        shadowEdges[1].height = amt;
        shadowEdges[1].rotation = Math.PI/2;

        shadowEdges[2].x = x - w / 2;
        shadowEdges[2].y = y + h / 2;
        shadowEdges[2].width = w;
        shadowEdges[2].height = amt;
        shadowEdges[2].rotation = Math.PI;

        shadowEdges[3].x = x - w / 2;
        shadowEdges[3].y = y - h / 2;
        shadowEdges[3].width = h;
        shadowEdges[3].height = amt;
        shadowEdges[3].rotation = 3*Math.PI/2;
    }

    setShadowPlacement(visible=true){
        this.placeShadow(this.shadowCorner, this.shadowEdge, 0, 0, this.info.w, this.info.h, .666, SHADOW_THICKNESS * this.z / this.fillZ, .3, visible,
            this.info.w, this.info.h, this.activeDisplay.w, this.activeDisplay.h, this.z, this.fillZ, this.minDim);

        let region = this.selectedRegion;
        let regionVis = region !== null;
        let regionX = region ? region.left : 0;
        let regionY = region ? region.top : 0;
        let regionW = region ? region.right - region.left : 0;
        let regionH = region ? region.bottom - region.top : 0;
        this.placeShadow(this.shadowRegionCorner, this.shadowRegionEdge, regionX, regionY, regionW, regionH, 0, SHADOW_THICKNESS_REGION, this.selectedRegionPct * this.selectedRegionPct * .2, regionVis && visible,
            this.info.w, this.info.h, this.activeDisplay.w, this.activeDisplay.h, this.z, this.fillZ, this.minDim);
    }

    componentDidUpdate(prevProps) {
        autorun(() => {
            this.modalOpen = this.props.appState.modalCount > 0;
            this.halted = false;
        });

        autorun(() => {
            this.nz = this.props.appState.photo.view.z;
            this.nx = this.props.appState.photo.view.x;
            this.ny = this.props.appState.photo.view.y - window.devicePixelRatio/2*this.props.appState.viewport.navHeight/this.nz;
            this.halted = false;
        });

        autorun(() => {
            this.dragRegion = toJS(this.props.appState.photo.dragRegion);
            this.dragValid = this.props.appState.photo.dragValid;
            this.halted = false;
        });

        autorun(() => {
            this.newRegions = toJS(this.props.appState.photoEdits.regions.create);
            this.halted = false;
        });

        autorun(() => {
            this.deletedRegions = toJS(this.props.appState.photoEdits.regions.delete);
            this.halted = false;
        });

        autorun(() => {
            this.showRegions = toJS(this.props.appState.photo.showRegions);
            this.halted = false;
        });

        autorun(() => {
            this.hiddenRegions = toJS(this.props.appState.photo.hiddenRegions);
            this.halted = false;
        });

        autorun(() => {
            this.editing = toJS(this.props.appState.photo.editing);
            this.halted = false;
        });

        autorun(() => {
            if(this.props.appState.photo.info) {
                this.currentRegions = toJS(this.props.appState.photo.info.regions);
                this.halted = false;
            }
        });

        autorun(() => {
            if(this.props.appState.photo.info) {
                this.hoverRegion = toJS(this.props.appState.photo.hoverRegion);
                this.halted = false;
            }
        });

        autorun(() => {
            this.selectedRegion = toJS(this.props.appState.photo.selectedRegion);
            this.halted = false;
        }, {name: "cache for canvas"});

        autorun(() => {
            this.selectedRegionPct = toJS(this.props.appState.photo.selectedRegionPct);
            this.halted = false;
        });

        autorun(() => {
            this.selectedRegionZoomed = toJS(this.props.appState.photo.selectedRegionZoomed);
            this.halted = false;
        }, {name: "cache for canvas"});

        autorun(() => {
            this.bufferSprite.exposure = toJS(this.props.appState.photo.adjustments.exposure);
            this.bufferSprite.offset = toJS(this.props.appState.photo.adjustments.offset);
            this.bufferSprite.gamma = toJS(this.props.appState.photo.adjustments.gamma);
            this.bufferSprite.saturation = toJS(this.props.appState.photo.adjustments.saturation);
            this.halted = false;
        });

        /*autorun(() => {
            this.hoverOnPhoto = toJS(this.props.appState.photo.hoverOnPhoto);
            this.halted = false;
        });*/

        autorun(() => {
            if (
                (this.info && !this.props.appState.photo.info)
                || (!this.info && this.props.appState.photo.info)
                || (this.info && this.props.appState.photo.info && this.props.appState.photo.info.ident !== this.info.ident)
            ) {
                this.reset();
            }
        });

        autorun(() => {
            this.movingRegion = toJS(this.props.appState.photoEdits.moveCreatedRegion);
            this.halted = false;
        });

        autorun(() => {
            this.showRegionsBoldAmt = this.props.appState.photo.showRegionsBoldAmt;
            this.halted = false;
        });

        autorun(() => {
            if(this.inPhoto !== this.props.appState.inPhoto){
                this.inPhoto = this.props.appState.inPhoto;
                this.reset();
            }
        });
    }

    makeTextureCache(maxNum) {
        return new LRUCache({max: maxNum, dispose: (key, element) => {
            if (element.baseTexture) {
                element.baseTexture.destroy();
                element.baseTexture = null;
            }
        }});
    }

    clearTextureBucket(bucket){
        bucket.reset();
    }

    reset(){
        this.needUpdate = true;
        this.halted = false;

        this.graphics.clear();

        this.regionOpacity = 0;

        if (this.bufferContentsSprite) {
            this.bufferContentsSprite.removeChildren();
            this.bufferContentsSprite.destroy(true);
            this.bufferContentsSprite = null;
        }

        if (this.slots) {
            for (let yIndex = 0; yIndex < this.slots.length; ++yIndex) {
                for (let xIndex = 0; xIndex < this.slots[yIndex].length; ++xIndex) {
                    let slot = this.slots[yIndex][xIndex];
                    slot.sprite.texture.destroy();
                    slot.sprite.destroy();
                    slot.sprite = null;
                    for (let i = 1; i < slot.zoomLevels.length; ++i) {
                        if (slot.zoomLevels[i].texture) {
                            slot.zoomLevels[i].texture.destroy(true);
                            slot.zoomLevels[i].texture = null;
                        }
                    }
                }
            }
        }
        this.slots = [];

        this.clearTextureBucket(this.baseTextures);
        clearTextureCache();

        this.bufferContentsSprite = new PIXI.Sprite();
        this.bufferContentsSprite.name = "bufferContentsSprite";

        if (this.props.appState.photo.info) {
            this.timeInPhoto = 0;
            this.info = this.props.appState.photo.info;
            this.activeDisplay = this.info.displays[0];

            this.minDim = Math.min(
                this.renderWidth / this.activeDisplay.w,
                this.renderHeight / this.activeDisplay.h);

            this.drawMinDim = Math.min(
                this.drawRenderWidth / this.activeDisplay.w,
                this.drawRenderWidth / this.activeDisplay.h);

            this.fillZ = this.minDim;
            this.drawFillZ = this.drawMinDim;
            this.nativeZ = Math.max(this.minDim, 1);

            let ow = this.activeDisplay.w;
            let oh = this.activeDisplay.h;
            let dim = this.activeDisplay.cellDimension;

            for (let yIndex = 0; yIndex * dim < oh; ++yIndex) {
                this.slots[yIndex] = [];
                for (let xIndex = 0; xIndex * dim < ow; ++xIndex) {
                    let slot = {};
                    this.slots[yIndex][xIndex] = slot;

                    let sprite = new PIXI.Sprite();
                    sprite.x = xIndex * dim;
                    sprite.y = yIndex * dim;
                    sprite.width = Math.min(dim, ow - xIndex * dim);
                    sprite.height = Math.min(dim, oh - yIndex * dim);
                    this.bufferContentsSprite.addChildAt(sprite, 0);

                    slot.sprite = sprite;
                    slot.zoomLevels = [];

                    for (let i = 0; i < this.activeDisplay.grids.length; ++i) {
                        let grid = this.activeDisplay.grids[i];
                        let scale = intPow(2, grid.zoomLevel - 1);

                        let cellXIndex = Math.floor(xIndex / scale);
                        let cellYIndex = Math.floor(yIndex / scale);

                        let internalXIndex = xIndex % scale;
                        let internalYIndex = yIndex % scale;

                        let zoomLevelInfo = {};
                        zoomLevelInfo.timeInFrame = 0;
                        zoomLevelInfo.filename = grid.cells[cellYIndex][cellXIndex].filename;
                        zoomLevelInfo.frame = new PIXI.Rectangle(
                            internalXIndex * dim / scale,
                            internalYIndex * dim / scale,
                            Math.min(dim / scale, Math.floor(sprite.width / scale)),
                            Math.min(dim / scale, Math.floor(sprite.height / scale)));
                        zoomLevelInfo.texture = null;
                        zoomLevelInfo.zoom = scale;
                        slot.zoomLevels[grid.zoomLevel] = zoomLevelInfo;
                    }
                }
            }
        } else {
            this.info = null;
        }

        runInAction(() => this.props.appState.photo.frameIsFilled = false);

        this.updateThumbnailSpeculation();
        this.updateDimensions();
    }

    loadTexture(filename, url, bucket, bitmap){
        if(!bucket.has(filename)) {
            let obj = {};
            obj.downloaded = false;
            obj.inGpu = false;
            obj.baseTexture = null;
            obj.promise = new Promise((resolve, reject) => {
                if(!bitmap) {
                    let loader = new PIXI.Loader();
                    loader.add(filename, url);
                    loader.load(async (loader, resources) => {
                        let error = resources[filename].error;
                        if (!error) {
                            let baseTexture = resources[filename].texture.baseTexture;
                            baseTexture.mipmap = false;
                            baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                            obj.baseTexture = baseTexture;
                            obj.downloaded = true;
                            let self = this;
                            await self.renderer.plugins.prepare.upload(baseTexture, async () => {
                                await sleep(Math.random() * 30);
                                obj.inGpu = true;
                                self.needLazyUpdate = true;
                                resolve(baseTexture);
                            });
                        } else {
                            reject(error);
                        }
                        loader.destroy();
                    });
                } else {
                    let baseTexture = PIXI.BaseTexture.from(bitmap);
                    baseTexture.mipmap = false;
                    baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                    obj.baseTexture = baseTexture;
                    obj.downloaded = true;
                    this.renderer.plugins.prepare.upload(baseTexture, async () => {
                        obj.inGpu = true;
                        this.needLazyUpdate = true;
                        resolve(baseTexture);
                    });
                }
            });
            bucket.set(filename, obj);
            return obj.promise;
        } else {
            let obj = bucket.get(filename);
            if(obj.baseTexture){
                return Promise.resolve(obj.baseTexture);
            } else {
                return obj.promise;
            }
        }
    }

    updateDimensions() {

        this.frameWidth = this.newFrameWidth();
        this.frameHeight = this.newFrameHeight();

        this.refs.photoCanvas.style.width = this.frameWidth + 'px';
        this.refs.photoCanvas.style.height = this.frameHeight + 'px';

        this.renderWidth = this.frameWidth * window.devicePixelRatio;
        this.renderHeight = this.frameHeight * window.devicePixelRatio;

        this.drawRenderWidth = Math.max(this.props.appState.viewport.bodyClientWidth, 10) * window.devicePixelRatio;
        this.drawRenderHeight = (WindowHelper.adjustedInnerHeightMin() - this.props.appState.viewport.navHeight) * window.devicePixelRatio;

        if(!this.renderer) {
            this.renderer = new PIXI.Renderer(
                {
                    width: this.renderWidth,
                    height: this.renderHeight,
                    powerPreference: "high-performance",
                    view: this.refs.photoCanvas,
                    clearBeforeRender: false,
                    preserveDrawingBuffer: false,
                    premultipliedAlpha: false,
                    backgroundAlpha: 0,
                    antialias: false,
                    legacy: false
                });
        } else {
            this.renderer.resize(this.renderWidth, this.renderHeight);
        }

        this.renderer.renderTexture.clear();

        if (this.bufferSprite) {
            this.photoLayer.removeChild(this.bufferSprite);
            this.bufferSprite.destroy(true);
            this.bufferSprite = null;
        }

        let rez = 1;
        let scale = this.getBufferScale(this.renderer.gl);
        let brt = new PIXI.BaseRenderTexture(this.renderWidth * scale + this.bufferChunkSize(), this.renderHeight * scale + this.bufferChunkSize(), PIXI.SCALE_MODES.NEARERST, rez);
        brt.mipmap = false;
        let rt = new PIXI.RenderTexture(brt);
        this.bufferSprite = new PIXI.Sprite(rt);
        this.bufferSprite.name = "bufferSprite";
        if (USE_CUSTOM_SHADERS) {
            this.bufferSprite.pluginName = "imagehq";
        }
        this.bufferSprite.x = this.renderer.width / 2;
        this.bufferSprite.y = this.renderer.height / 2;
        this.bufferSprite.exposure = toJS(this.props.appState.photo.adjustments.exposure);
        this.bufferSprite.offset = toJS(this.props.appState.photo.adjustments.offset);
        this.bufferSprite.gamma = toJS(this.props.appState.photo.adjustments.gamma);
        this.bufferSprite.saturation = toJS(this.props.appState.photo.adjustments.saturation);
        this.photoLayer.addChild(this.bufferSprite);
        this.bufferRt = rt;
        this.bufferBrt = brt;
        this.halted = false;
        this.needUpdate = true;

        if (this.info) {
            this.minDim = Math.min(
                this.renderWidth / this.info.w,
                this.renderHeight / this.info.h);

            this.drawMinDim = Math.min(
                this.drawRenderWidth / this.info.w,
                this.drawRenderHeight / this.info.h);

            let minZ = this.minDim;
            let maxZ = Math.max(this.minDim, 1);
            this.fillZ = minZ;
            this.drawFillZ = this.drawMinDim;
            this.nativeZ = maxZ;
        }

        runInAction(() => {
            this.props.appState.viewport.width = this.frameWidth;
            this.props.appState.viewport.height = this.frameHeight;
            this.props.appState.viewport.renderWidth = this.renderWidth;
            this.props.appState.viewport.renderHeight = this.renderHeight;
        });

        if(this.info) {
            let updates = [];
            this.animate(0, updates);
            updates.forEach((f) => f());
        }
    }

    componentWillUnmount() {
        this.props.emitter.removeListener("animate", this.animate);
    }

    animate(delta, updates) {

        if (!this.props.appState.inPhoto){
            return;
        }

        if(this.thumbnailSprite) {
            this.thumbnailSprite.renderable = false;
        }
        if(this.bufferThumbnailSprite){
            this.bufferThumbnailSprite.renderable = false;
        }

        this.timeInPhoto += delta;

        if(this.frameWidth !== this.newFrameWidth() || this.frameHeight !== this.newFrameHeight()){
            this.updateDimensions();
        }

        let reducedV = (this.renderer.height-WindowHelper.adjustedInnerHeightMin()*window.devicePixelRatio);
        let shift = this.props.appState.viewport.navHeight*window.devicePixelRatio - reducedV;

        if(this.oShift !== shift || this.oReducedV !== reducedV){
            this.halted = false;
        }
        this.oShift = shift;
        this.oReducedV = reducedV;

        if (this.info && this.props.appState.photo.infoReady) {
            this.t++;
            this.timeSinceLastLazyUpdate++;
            this.oldThumbFillZ = null;

            let oldRegionOpacity = this.regionOpacity;
            let regionOpacityChange = 0;
            if(this.showRegions && !this.hiddenRegions){
                regionOpacityChange = 2.5;
            } else {
                if(this.selectedRegionZoomed){
                    regionOpacityChange = -1.5;
                } else {
                    regionOpacityChange = -1;
                }
            }
            this.regionOpacity = Math.min(Math.max(0.0, this.regionOpacity + delta * regionOpacityChange), 1.0);
            if(oldRegionOpacity !== this.regionOpacity){
                this.halted = false;
            }

            if(this.oldTimeInPhoto <= 1.5 && this.timeInPhoto > 1.5){
                this.halted = false;
            }
            this.oldTimeInPhoto = this.timeInPhoto;

            let doHq = this.halted && !this.oHalted && this.timeInPhoto > 1.5;

            let ox = this.x;
            let oy = this.y;
            let oz = this.z;

            let dx = this.nx - ox;
            let dy = this.ny - oy;
            let dz = this.nz - oz;

            let dist = Math.sqrt(dx*dx + dy*dy + dz*dz*20);

            this.z = Math.min(Math.max(this.nz, Number.EPSILON), Number.MAX_VALUE);
            this.x = this.nx;
            this.y = this.ny;

            let effectiveW;
            let effectiveH;
            let factor = (this.renderer.width / this.activeDisplay.w) / (this.renderer.height / this.activeDisplay.h);
            if (factor > 1) {
                effectiveW = this.activeDisplay.w * factor;
                effectiveH = this.activeDisplay.h;
            } else {
                effectiveW = this.activeDisplay.w;
                effectiveH = this.activeDisplay.h / factor;
            }

            let bestZoomLevel = null;
            let bestRatio = null;
            for(let i = 0; i < this.activeDisplay.grids.length; ++i) {
                let grid = this.activeDisplay.grids[i];
                let zoom = intPow(2, grid.zoomLevel-1);
                let ratio = zoom * this.z;
                let threshold = 1.0 / (this.getBufferScale(this.renderer.gl));

                if(bestZoomLevel === null){
                    bestZoomLevel = grid.zoomLevel;
                    bestRatio = ratio;
                } else {
                    if (bestRatio > threshold) {
                        if (ratio < bestRatio && ratio >= threshold) {
                            bestZoomLevel = grid.zoomLevel;
                            bestRatio = ratio;
                        }
                    } else {
                        if (ratio > bestRatio) {
                            bestZoomLevel = grid.zoomLevel;
                            bestRatio = ratio;
                        }
                    }
                }
            }

            let bestScale = intPow(2, bestZoomLevel-1);
            let dimAdj = this.bufferChunkSize();
            let xOff = (this.fillZ / this.z * effectiveW / 2);
            let yOff = (this.fillZ / this.z * effectiveH / 2);
            let contentsX = (-this.x + xOff) / bestScale;
            let contentsY = (-this.y + yOff) / bestScale;

            let bufferX = this.bufferContentsSprite.x;
            let bufferY = this.bufferContentsSprite.y;
            let bufferSX = this.bufferContentsSprite.scale.x;
            let bufferSY = this.bufferContentsSprite.scale.y;

            this.bufferContentsSprite.x = Math.floor(contentsX/dimAdj+1)*dimAdj;
            this.bufferContentsSprite.y = Math.floor(contentsY/dimAdj+1)*dimAdj;
            this.bufferContentsSprite.scale.x = 1/bestScale;
            this.bufferContentsSprite.scale.y = 1/bestScale;

            if(bufferX !== this.bufferContentsSprite.x || bufferY !== this.bufferContentsSprite.y
                || bufferSX !== this.bufferContentsSprite.scale.x || bufferSY !== this.bufferContentsSprite.scale.y){
                this.needUpdate = true;
            }

            let viewport = new PIXI.Rectangle(
                this.x - this.fillZ / this.z * effectiveW / 2,
                this.y - this.fillZ / this.z * effectiveH / 2,
                this.renderer.width/this.z,
                (this.renderer.height + Math.max(0, reducedV*window.devicePixelRatio))/this.z);

            let viewportMidX = viewport.x + viewport.width/2;
            let viewportMidY = viewport.y + viewport.height/2;

            let isGood = (t) => { return this.baseTextures.get(t.filename) && this.baseTextures.get(t.filename).inGpu };

            let unmoved = Math.abs(this.z - this.drawFillZ * this.props.appState.viewport.borderAmount) < .01;

            let bestLoadedCompletely = true;
            for(let y = 0; y < this.slots.length; ++y) {
                let row = this.slots[y];
                for (let x = 0; x < row.length; ++x) {
                    let slot = row[x];
                    if(!isGood(slot.zoomLevels[bestZoomLevel])){
                        bestLoadedCompletely = false;
                        break;
                    }
                }
            }

            let useLQ = unmoved && !bestLoadedCompletely;
            let dontDisplayLQ = unmoved && this.bufferThumbnailSprite && (this.timeInPhoto < 1);

            let notPerfect = false;
            let frameIsFilled = true;
            for(let y = 0; y < this.slots.length; ++y){
                let row = this.slots[y];
                for(let x = 0; x < row.length; ++x){
                    let slot = row[x];
                    let topLeft = new PIXI.Point(slot.sprite.x, slot.sprite.y);
                    let bottomRight = new PIXI.Point(slot.sprite.x + slot.sprite.width, slot.sprite.y + slot.sprite.height);
                    let bounds = new PIXI.Rectangle(topLeft.x, topLeft.y, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);

                    let inFrame = this.intersect(viewport, bounds);

                    let somethingLoaded = false;
                    for(let i = 1; i < slot.zoomLevels.length; ++i){
                        if(i !== bestZoomLevel){
                            slot.zoomLevels[i].timeInFrame = 0;
                        }
                        if(this.baseTextures.has(slot.zoomLevels[i].filename)){
                            somethingLoaded = true;
                        }
                    }

                    if(inFrame && !somethingLoaded){
                        let lowRez = slot.zoomLevels[slot.zoomLevels.length - 1];
                        let url = getSignedUrlSync(lowRez.filename);
                        this.loadTexture(lowRez.filename, url, this.baseTextures);
                    }
                    if(inFrame && dist < 7){
                        let best = slot.zoomLevels[bestZoomLevel];
                        best.timeInFrame += delta;

                        let boundsMidX = bounds.x + bounds.width/2;
                        let boundsMidY = bounds.y + bounds.height/2;

                        let frameDistX = Math.max(Math.abs(viewportMidX - boundsMidX) - bounds.width / 2, 0)/window.devicePixelRatio;
                        let frameDistY = Math.max(Math.abs(viewportMidY - boundsMidY) - bounds.height / 2, 0)/window.devicePixelRatio;
                        let frameDistSqr = frameDistX*frameDistX + frameDistY*frameDistY;

                        if (!this.baseTextures.has(best.filename) && (!somethingLoaded
                                || best.timeInFrame > Math.min(frameDistSqr*.000015*this.z, 8.0)
                                //|| Math.min(frameDist*frameDist*.000006*this.z, 10.0) < 2
                                || this.z < this.fillZ
                            )) {
                            let url = getSignedUrlSync(best.filename);
                            this.loadTexture(best.filename, url, this.baseTextures);
                        }
                    } else {
                        slot.timeInFrame = 0;
                    }
                    let somethingFullyLoaded = false;
                    for(let i = 1; i < slot.zoomLevels.length; ++i){
                        if(isGood(slot.zoomLevels[i])){
                            somethingFullyLoaded = true;
                        }
                    }
                    if(!somethingFullyLoaded){
                        frameIsFilled = false;
                    }
                    if(inFrame){
                        let best = useLQ ? slot.zoomLevels[slot.zoomLevels.length-1] : slot.zoomLevels[bestZoomLevel];
                        if(!isGood(best)){
                            notPerfect = true;
                            for(let i = bestZoomLevel-1; i > 0; --i){
                                if(isGood(slot.zoomLevels[i])){
                                    best = slot.zoomLevels[i];
                                    break;
                                }
                            }
                        }
                        if(!isGood(best)){
                            for(let i = bestZoomLevel+1; i < slot.zoomLevels.length; ++i){
                                if(isGood(slot.zoomLevels[i])){
                                    best = slot.zoomLevels[i];
                                    break;
                                }
                            }
                        }
                        if(isGood(best)) {
                            let baseTextureInfo = this.baseTextures.get(best.filename);

                            if (best.texture === null) {
                                best.texture = new PIXI.Texture(baseTextureInfo.baseTexture, best.frame);
                                this.needUpdate = true;
                            } else {
                                if (slot.sprite.texture !== best.texture) {
                                    this.needUpdate = true;
                                }
                                slot.sprite.texture = best.texture;
                                slot.activeZoomLevel = best;
                            }
                            if (slot.activeZoomLevel) {
                                let old = slot.sprite.texture.baseTexture.scaleMode;
                                if (Math.abs(1.0 / this.z - slot.activeZoomLevel.zoom) < .0001) {
                                    if (old !== PIXI.SCALE_MODES.NEAREST) {
                                        slot.sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
                                        this.needUpdate = true;
                                    }
                                } else {
                                    if (old !== PIXI.SCALE_MODES.LINEAR) {
                                        slot.sprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                                        this.needUpdate = true;
                                    }
                                }
                            }
                            let shouldRender = !!slot.activeZoomLevel;
                            if (shouldRender !== slot.sprite.renderable) {
                                this.needUpdate = true;
                            }
                            slot.sprite.renderable = shouldRender;
                        }
                    } else {
                        if(false !== slot.sprite.renderable){
                            this.needUpdate = true;
                        }
                        slot.sprite.renderable = false;
                    }
                }
            }
            if (frameIsFilled) {
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        runInAction(() => this.props.appState.photo.frameIsFilled = true);
                    });
                });
            }
            if(this.bufferThumbnailSprite && this.inPhoto && this.thumbnailSpeculative && this.thumbnailSpeculative.ident === this.props.appState.photo.ident) {
                let oldOpacity = this.thumbnailSpriteOpacity;
                if(!frameIsFilled || (dontDisplayLQ && !bestLoadedCompletely)){
                    this.thumbWaitedAFrame = false;
                    this.timeInThumbnail += delta;
                    this.thumbnailSpriteOpacity = 1.0;
                } else {
                    if (this.thumbWaitedAFrame) {
                        this.thumbnailSpriteOpacity = Math.max(0, this.thumbnailSpriteOpacity - .5 * Math.min(delta, .1) * Math.min(10, Math.max(2, 20 / this.timeInThumbnail)));
                    }
                    this.thumbWaitedAFrame = true;
                }
                this.bufferThumbnailSprite.alpha  = this.thumbnailSpriteOpacity;
                this.bufferThumbnailSprite.renderable = this.thumbnailSpriteOpacity > 0;
                this.bufferThumbnailSprite.width = this.thumbnailSpeculative.w;
                this.bufferThumbnailSprite.height = this.thumbnailSpeculative.h;
                if (this.thumbnailSpriteOpacity > 0 && this.thumbnailSpriteLoaded) {
                    requestAnimationFrame(() => {
                        requestAnimationFrame(() => {
                            runInAction(() => this.props.appState.photo.frameIsFilled = true);
                        });
                    });
                }
                if (oldOpacity !== this.thumbnailSpriteOpacity){
                    this.needUpdate = true;
                }
            } else {
                this.timeInThumbnail = 0;
            }


            this.bufferSprite.renderable = true;
            this.bufferSprite.rotation = 0;

            let bufferScaling = bestScale * this.z;
            this.bufferSprite.scale.x = bufferScaling;
            this.bufferSprite.scale.y = bufferScaling;
            this.bufferSprite.x = (((((contentsX) % dimAdj)+dimAdj)%dimAdj) - dimAdj)*bufferScaling;
            this.bufferSprite.y = (((((contentsY) % dimAdj)+dimAdj)%dimAdj) - dimAdj)*bufferScaling;

            if (Math.abs(this.bufferSprite.scale.x - 1) < .00001) {
                this.bufferSprite.scale.x = 1;
                this.bufferSprite.scale.y = 1;
                this.bufferSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
                this.bufferSprite.hq = false;
                this.bufferSprite.native = true;
            } else {
                this.bufferSprite.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
                this.bufferSprite.hq = doHq && !notPerfect;
                this.bufferSprite.native = false;
            }

            if(this.needLazyUpdate && this.timeSinceLastLazyUpdate > 10){
                this.needUpdate = true;
                this.needLazyUpdate = false;
                this.timeSinceLastLazyUpdate = 0;
            }
            this.halted = this.halted && !this.needUpdate;
            if ((!this.halted || doHq) && this.info) {

                this.graphics.clear();
                this.graphicsAdditive.clear();

                if(this.needUpdate) {
                    this.needUpdate = false;
                    this.renderer.clear();
                    this.renderer.render(this.bufferContentsSprite, {renderTexture: this.bufferRt, clear: true});
                }
                this.setShadowPlacement(this.props.appState.photo.frameIsFilled);

                let regionShadowInfo = [];

                if (this.dragRegion && this.editing && !this.props.appState.photo.clickable && !this.modalOpen) {
                    let d = this.dragRegion;
                    let start = this.photoPxToScreenCoords(d.left, d.top);
                    let end = this.photoPxToScreenCoords(d.right, d.bottom);
                    this.graphicsAdditive
                        .lineStyle(Math.ceil(window.devicePixelRatio), 0xffffff, this.dragValid ? 1.0 : .1)
                        .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                        .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                        .lineTo(end[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                        .lineTo(start[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                        .lineTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio);

                    regionShadowInfo.push({region:this.dragRegion, opacity: this.dragValid ? 1 : .2});

                    if(this.dragValid) {
                        this.graphicsAdditive.lineStyle(Math.ceil(window.devicePixelRatio), 0xffffff, .2);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio * 2 / 3 + end[0] * window.devicePixelRatio / 3, start[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio * 2 / 3 + end[0] * window.devicePixelRatio / 3, end[1] * window.devicePixelRatio);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio / 3 + end[0] * window.devicePixelRatio * 2 / 3, start[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio / 3 + end[0] * window.devicePixelRatio * 2 / 3, end[1] * window.devicePixelRatio);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio * 2 / 3 + end[1] * window.devicePixelRatio / 3)
                            .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio * 2 / 3 + end[1] * window.devicePixelRatio / 3);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio / 3 + end[1] * window.devicePixelRatio * 2 / 3)
                            .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio / 3 + end[1] * window.devicePixelRatio * 2 / 3);
                    }
                }
                if (this.newRegions && this.editing && !this.props.appState.photo.clickable && !this.selectedRegionZoomed && !this.modalOpen) {
                    for (let region of this.newRegions) {
                        let start = this.photoPxToScreenCoords(region.left, region.top);
                        let end = this.photoPxToScreenCoords(region.right, region.bottom);
                        this.graphics
                            .lineStyle(Math.ceil(window.devicePixelRatio), 0x00ff00, .7)
                            .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                            .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                            .lineTo(end[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio);

                        regionShadowInfo.push({region:region, opacity: this.selectedRegionZoomed ? 0 : 1});
                    }
                    if(this.movingRegion){
                        let start = this.photoPxToScreenCoords(this.movingRegion.region.left, this.movingRegion.region.top);
                        let end = this.photoPxToScreenCoords(this.movingRegion.region.right, this.movingRegion.region.bottom);
                        this.graphicsAdditive.lineStyle(Math.ceil(window.devicePixelRatio), 0xFFFFFF, .2);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio*2/3 + end[0] * window.devicePixelRatio/3, start[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio*2/3 + end[0] * window.devicePixelRatio/3, end[1] * window.devicePixelRatio);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio/3 + end[0] * window.devicePixelRatio*2/3, start[1] * window.devicePixelRatio)
                            .lineTo(start[0] * window.devicePixelRatio/3 + end[0] * window.devicePixelRatio*2/3, end[1] * window.devicePixelRatio);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio*2/3 + end[1] * window.devicePixelRatio/3)
                            .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio*2/3 + end[1] * window.devicePixelRatio/3);
                        this.graphicsAdditive
                            .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio/3 + end[1] * window.devicePixelRatio*2/3)
                            .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio/3 + end[1] * window.devicePixelRatio*2/3);
                    }
                }
                /*if (this.hoverOnPhoto){
                    let start = this.photoPxToScreenCoords(0, 0);
                    let end = this.photoPxToScreenCoords(this.info.w, this.info.h);
                    let color = 0xFFFFFF;
                    let outlineOpacity = .5;
                    this.graphicsAdditive
                        .lineStyle(Math.ceil(window.devicePixelRatio), color, outlineOpacity)
                        .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                        .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                        .lineTo(end[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                        .lineTo(start[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                        .lineTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio);
                }*/
                if (this.currentRegions && !this.modalOpen) {
                    if (this.editing && !this.selectedRegionZoomed && !this.props.appState.photo.clickable) {
                        for (let region of this.currentRegions) {
                            let thisIsHoverRegion = this.hoverRegion && (this.hoverRegion.ident === region.ident);

                            let start = this.photoPxToScreenCoords(region.left, region.top);
                            let end = this.photoPxToScreenCoords(region.right, region.bottom);
                            let color = 0xFFFFFF;
                            let isDeleted = !!(this.deletedRegions.get(region.ident));
                            let outlineOpacity = thisIsHoverRegion ? .8 : .5;

                            if (isDeleted) {
                                color = 0xFF0000;
                                outlineOpacity = .7;
                            }

                            (isDeleted ? this.graphics : this.graphicsAdditive)
                                .lineStyle(Math.ceil(window.devicePixelRatio), color, outlineOpacity)
                                .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                                .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                                .lineTo(end[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                                .lineTo(start[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                                .lineTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio);

                            regionShadowInfo.push({region: region, opacity: thisIsHoverRegion ? 1.5 : 1});
                        }
                    } else {
                        for (let i = 0; i < this.currentRegions.length; ++i) {
                            let region = this.currentRegions[i];

                            let xPct = 2 * ((region.left + region.right) * .5 / this.info.w - .5);
                            let yPct = 2 * ((region.top + region.bottom) * .5 / this.info.h - .5);
                            let distToCenter = Math.sqrt(xPct * xPct + yPct * yPct);
                            let off = Math.min(1.0 - distToCenter * .5, 1.0);

                            let w = region.right - region.left;
                            let h = region.bottom - region.top;
                            let wadd = w * this.showRegionsBoldAmt * .035;
                            let hadd = h * this.showRegionsBoldAmt * .035;
                            let regionAdj = {};

                            regionAdj.left = region.left - wadd;
                            regionAdj.right = region.right + wadd;
                            regionAdj.top = region.top - hadd;
                            regionAdj.bottom = region.bottom + hadd;

                            let regionOpacity = Math.max(Math.min(this.regionOpacity * 2 - off, 1.0), 0.0);
                            if(regionOpacity > 0) {

                                let start = this.photoPxToScreenCoords(regionAdj.left, regionAdj.top);
                                let end = this.photoPxToScreenCoords(regionAdj.right, regionAdj.bottom);
                                let color = 0xFFFFFF;

                                let thisIsHoverRegion = this.hoverRegion && (this.hoverRegion.ident === region.ident);
                                let outlineOpacity = thisIsHoverRegion ? .65 : .15;
                                this.graphicsAdditive
                                    .lineStyle(Math.ceil(window.devicePixelRatio), color, outlineOpacity * regionOpacity)
                                    .moveTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                                    .lineTo(end[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio)
                                    .lineTo(end[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                                    .lineTo(start[0] * window.devicePixelRatio, end[1] * window.devicePixelRatio)
                                    .lineTo(start[0] * window.devicePixelRatio, start[1] * window.devicePixelRatio);

                                regionShadowInfo.push({
                                    region: regionAdj,
                                    opacity: regionOpacity * (thisIsHoverRegion ? 1.5 : 1)
                                });
                            }
                        }
                    }
                }

                let size = regionShadowInfo.length;
                if(this.extraShadows.length !== size){
                    this.extraShadows = [];
                    this.extraShadowsSprite.children.forEach(child => {
                        child.texture.destroy();
                        child.destroy();
                    });
                    this.extraShadowsSprite.removeChildren();
                    for(let j = 0; j < size; ++j){
                        let edges = [];
                        for(let i = 0; i < 4; ++i) {
                            edges[i] = this.spriteFromImage('/static/images/shadow-edge.png', this.staticBaseTextures);
                            edges[i].anchor.set(1);
                            this.extraShadowsSprite.addChild(edges[i]);
                        }
                        let corners = [];
                        for(let i = 0; i < 4; ++i) {
                            corners[i] = this.spriteFromImage('/static/images/shadow-corner.png', this.staticBaseTextures);
                            corners[i].anchor.set(1);
                            this.extraShadowsSprite.addChild(corners[i]);
                        }
                        this.extraShadows.push([corners, edges]);
                    }
                }

                for (let i = 0; i < size; ++i) {
                    let region = regionShadowInfo[i].region;
                    let regionOpacity = regionShadowInfo[i].opacity;

                    let corners = this.extraShadows[i][0];
                    let edges = this.extraShadows[i][1];

                    this.placeShadow(corners, edges, region.left, region.top, region.right - region.left, region.bottom - region.top,
                        0, SHADOW_THICKNESS_OVERLAY, .225 * regionOpacity, this.props.appState.photo.frameIsFilled,
                        this.info.w, this.info.h, this.activeDisplay.w, this.activeDisplay.h, this.z, this.fillZ, this.minDim);
                }


                if(this.selectedRegion && this.selectedRegionPct > 0){
                    let region = this.selectedRegion;
                    let start = this.photoPxToScreenCoords(region.left, region.top);
                    let end = this.photoPxToScreenCoords(region.right, region.bottom);
                    let screenStart = [0,0];
                    let screenEnd = [this.renderWidth, this.renderHeight + Math.max(0, reducedV*window.devicePixelRatio)];
                    this.graphics
                        .beginFill(0x232323, Math.max(0, this.selectedRegionPct))
                        .lineStyle(0,0,0)
                        .drawPolygon([
                            screenStart[0], screenStart[0],
                            screenEnd[0], screenStart[0],
                            screenEnd[0], start[1]*window.devicePixelRatio,
                            screenStart[0], start[1]*window.devicePixelRatio,
                        ])
                        .drawPolygon([
                            screenStart[0], screenEnd[1],
                            screenEnd[0], screenEnd[1],
                            screenEnd[0], end[1]*window.devicePixelRatio,
                            screenStart[0], end[1]*window.devicePixelRatio,
                        ])
                        .drawPolygon([
                            screenStart[0], start[1]*window.devicePixelRatio,
                            start[0]*window.devicePixelRatio, start[1]*window.devicePixelRatio,
                            start[0]*window.devicePixelRatio, end[1]*window.devicePixelRatio,
                            screenStart[0], end[1]*window.devicePixelRatio,
                        ])
                        .drawPolygon([
                            screenEnd[0], start[1]*window.devicePixelRatio,
                            end[0]*window.devicePixelRatio, start[1]*window.devicePixelRatio,
                            end[0]*window.devicePixelRatio, end[1]*window.devicePixelRatio,
                            screenEnd[0], end[1]*window.devicePixelRatio,
                        ])
                        .endFill();
                }
                updates.push(()=> {
                    this.stageSprite.visible = true;
                    this.previewSprite.visible = false;
                    this.stageSprite.y = -reducedV/window.devicePixelRatio;
                    this.renderer.clear();
                    this.renderer.render(this.allSprite, {clear: true});
                });
            }
        } else {
            this.regionOpacity = 0;
            if (this.previewSprite && this.inPhoto){

                if(this.thumbnailSpeculative && this.props.appState.photo.ident && this.thumbnailSpeculative.ident === this.props.appState.photo.ident && this.thumbnailSprite) {
                    this.thumbnailSprite.renderable = true;

                    let adjHeightPt = (WindowHelper.adjustedInnerHeightMin() - this.props.appState.viewport.navHeight);
                    let renderHeight = adjHeightPt * window.devicePixelRatio;
                    let usableRenderHeight = (adjHeightPt - this.props.appState.viewport.minVerticalPadding - this.props.appState.viewport.additionalVGutter) * window.devicePixelRatio;
                    let renderWidth = this.renderer.width;

                    let minDim = Math.min(
                        renderWidth / this.thumbnailSprite.width,
                        renderHeight / this.thumbnailSprite.height);
                    let usableMinDim = Math.min(
                        renderWidth / this.thumbnailSprite.width,
                        usableRenderHeight / this.thumbnailSprite.height);

                    let effectiveBorderAmount = Math.min(Math.min(1, this.props.appState.viewport.borderAmount), usableMinDim/minDim);

                    let factor = (this.renderer.width / this.thumbnailSprite.width) / (renderHeight / this.thumbnailSprite.height);

                    let fillZ = Math.min(
                        this.renderer.width / this.thumbnailSprite.width,
                        renderHeight / this.thumbnailSprite.height);

                    let scale = fillZ * effectiveBorderAmount;
                    this.previewSprite.width = scale;
                    this.previewSprite.height = scale;
                    if (factor > 1) {
                        this.previewSprite.x = .5 * (this.renderer.width - this.thumbnailSprite.width * scale);
                        this.previewSprite.y = .5 * (renderHeight * (1 - effectiveBorderAmount));
                    } else {
                        this.previewSprite.x = .5 * this.renderer.width * (1 - effectiveBorderAmount);
                        this.previewSprite.y = .5 * (renderHeight - this.thumbnailSprite.height * scale);
                    }

                    // TODO: fix glitching when entering photo from gallery when back button not visible on iPhone
                    this.previewSprite.y += shift + reducedV;
                    this.thumbnailSprite.alpha = 1;
                    this.thumbnailSprite.renderable = true;
                    if (this.oldThumbFillZ === null || this.oldThumbFillZ !== fillZ || !this.halted) {
                        if (this.shadowPreviewCorner) {
                            this.placeShadowDirect(
                                this.thumbnailSprite.x + this.thumbnailSprite.width / 2,
                                this.thumbnailSprite.y + this.thumbnailSprite.height / 2,
                                this.thumbnailSprite.width,
                                this.thumbnailSprite.height,
                                this.shadowPreviewCorner,
                                this.shadowPreviewEdge,
                                .666, SHADOW_THICKNESS / fillZ, .3,
                                this.thumbnailSpriteLoaded);
                        }
                        runInAction(() => this.props.appState.photo.frameIsFilled = true);
                        updates.push(()=> {
                            this.stageSprite.visible = false;
                            this.previewSprite.visible = true;
                            this.renderer.clear(0, 0);
                            this.renderer.render(this.allSprite, {clear: true});
                        });
                    }
                    this.oldThumbFillZ = fillZ;
                }
            } else {
                this.oldThumbFillZ = null;
            }
        }
        this.oHalted = this.halted;
        this.halted = true;

        this.promoteSpeculationPotential();
    }

    getCanvasRef() {
        return this.refs.photoCanvas;
    }

    render() {
        return (
            <canvas style={{willChange: "transform"}} ref="photoCanvas"/>
        );
    }

    _screenCoordsToPhotoPct(x, y, adW, adH, fillZ, minDim){
        let [photoX, photoY, photoScale] = this._fromInternalCoords(this.x, this.y, this.z, adW, adH, fillZ, minDim);

        let xRatio = this.renderWidth / adW;
        let yRatio = this.renderHeight / adH;

        let yAdj = 1;
        let xAdj = 1;
        if(xRatio > yRatio){
            xAdj = xRatio / yRatio;
        } else {
            yAdj = yRatio / xRatio;
        }

        let xNorm = ((x / this.frameWidth) * 2 - 1)*xAdj;
        let yNorm = ((y / this.frameHeight) * 2 - 1)*yAdj;

        let photoXPct = (xNorm + photoX) / photoScale * .5 + .5;
        let photoYPct = (yNorm + photoY) / photoScale * .5 + .5;

        return [photoXPct, photoYPct];
    }

    _screenCoordsToPhotoPx(x, y, infoW, infoH, adW, adH, fillZ, minDim){
        let pct = this._screenCoordsToPhotoPct(x, y, adW, adH, fillZ, minDim);

        let pxX = pct[0] * infoW;
        let pxY = pct[1] * infoH;

        return [pxX, pxY];
    }

    _photoPctToScreenCoords(photoXPct, photoYPct, adW, adH, fillZ, minDim){
        let [photoX, photoY, photoScale] = this._fromInternalCoords(this.x, this.y, this.z, adW, adH, fillZ, minDim);

        let xRatio = this.renderWidth / adW;
        let yRatio = this.renderHeight / adH;

        let yAdj = 1;
        let xAdj = 1;
        if(xRatio > yRatio){
            xAdj = xRatio / yRatio;
        } else {
            yAdj = yRatio / xRatio;
        }

        let xNorm = (photoXPct - .5) * 2 * photoScale - photoX;
        let yNorm = (photoYPct - .5) * 2 * photoScale - photoY;

        let x = (xNorm / xAdj + 1) / 2 * this.frameWidth;
        let y = (yNorm / yAdj + 1) / 2 * this.frameHeight;

        return [x, y]
    }

    _photoPxToScreenCoords(pxX, pxY, infoW, infoH, adW, adH, fillZ, minDim){
        let pct0 = pxX / infoW;
        let pct1 = pxY / infoH;

        return this._photoPctToScreenCoords(pct0, pct1, adW, adH, fillZ, minDim);
    }

    screenCoordsToPhotoPct(x, y){
        return this._screenCoordsToPhotoPct(x, y, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    }

    screenCoordsPhotoPx(x, y){
        return this._screenCoordsToPhotoPx(x, y, this.info.w, this.info.h, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    }

    photoPctToScreenCoords(photoXPct, photoYPct){
        return this._photoPctToScreenCoords(photoXPct, photoYPct, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    }

    photoPxToScreenCoords(pxX, pxY){
        return this._photoPxToScreenCoords(pxX, pxY, this.info.w, this.info.h, this.activeDisplay.w, this.activeDisplay.h, this.fillZ, this.minDim);
    }
}

export default inject("appState")(observer(Canvas))
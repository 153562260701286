import React, {useRef, useEffect} from 'react';
import styled from '@emotion/styled';
import {observable, runInAction} from 'mobx'
import {observer, inject} from 'mobx-react';
import {typeface} from "../../styles/Typeface";
import Theme from "../../styles/Theme";
import Thumbnail from "../../components/Thumbnail";
import RestrictedMarkdown from "../../components/RestrictedMarkdown";

const Title = styled.div`
  text-align: center;
  margin-bottom: ${p => p.mobile ? 2 : 10}px;
  ${p => typeface(p.mobile ? 14 : 16, 600)};
  ${p => p.mobile ? `
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 4px;
    margin-bottom: 8px;
  ` : ``}
`;

const Summary = styled(RestrictedMarkdown)`
  ${p => typeface(p.mobile ? 13 : 14, 400)};
  text-overflow: ellipsis;
  p {
    margin-bottom: 12px;
  }
  ${p => p.mobile ? `
  p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: none;
  }
  p:first-of-type {
      display: -webkit-box;
      margin-bottom: 0;
  }
  `: 
  `margin: 0 20px;`}
  
  &:empty:before {
    content: "No description provided.";
    font-style: italic;
    color: ${Theme.colors.dim};
    position: relative;
  }
`;

const ButtonLink = styled.a`
  color: ${Theme.colors.almostWhite};
  &:hover {
    color: white;
  }
`;

const ButtonLinkWhite = styled.a`
  color: white;
  &:hover {
    color: white;
  }
`;

const FadeInCentered = styled.div`
  opacity: ${p => p.show ? 1.0 : 0.0};
  transition: opacity 150ms;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const ContainerShort = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  ${p => p.bottomMargin ? `margin-bottom: 20px` : ``};
`;

const ImageSection = styled.div`
  ${p => p.mobile ? `` : `margin: 15px;`};
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ContentSection = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  width: 100%;
  ${p => p.mobile ? `` : `
  flex-shrink: 1;
  padding: 0px 10px;
  -webkit-mask-image: -webkit-gradient(rgba(0,0,0,1) 66.667%, rgba(0,0,0,.6), rgba(0,0,0,.25), rgba(0,0,0,0));
  mask-image: linear-gradient(rgba(0,0,0,1) 66.667%, rgba(0,0,0,.6), rgba(0,0,0,.25), rgba(0,0,0,0));
  `};
`;

const Spacer = styled.div`
  height: 15px;
`;

export default inject("appState")(observer(({appState, info, zoomFunction}) => {
    const data = useRef(observable({
        show: false,
    }));

    const functions = useRef({
        click: null,
        speculate: null
    });

    let buttonFuncs = {
        onClick: functions.current.click,
        onMouseOver: functions.current.speculate,
        onMouseDown: functions.current.speculate,
    };

    let imageSection =
        <ImageSection mobile={appState.media.somewhatMini}>
            <Thumbnail
                doClickSetter={(func) => functions.current.click = func}
                doSpeculateSetter={(func) => functions.current.speculate = func}
                forceSkinny={appState.media.somewhatMini}
                thumb={info.thumbnail}
                onLoad={() => runInAction(() => data.current.show = true)}
                noMargin
            />
        </ImageSection>;

    let titleOnlySection = info.title ? <FadeInCentered show={data.current.show}>
             <ButtonLinkWhite {...buttonFuncs}>
                <Title mobile={appState.media.somewhatMini}>{info.title}</Title>
             </ButtonLinkWhite>
        </FadeInCentered> : null;

    let contentOnlySection = <ContentSection mobile={appState.media.somewhatMini}>
        <FadeInCentered show={data.current.show}>
            <ButtonLink {...buttonFuncs}>
                <Summary mobile={appState.media.somewhatMini} text={info.description}/>
            </ButtonLink>
        </FadeInCentered>
    </ContentSection>;

    let contentSection = <ContentSection mobile={appState.media.somewhatMini}>
        <FadeInCentered show={data.current.show}>
            {info.title ?
                <ButtonLinkWhite {...buttonFuncs}>
                    <Title mobile={appState.media.somewhatMini}>{info.title}</Title>
                </ButtonLinkWhite>
                : null
            }
            <ButtonLink {...buttonFuncs}>
                <Summary mobile={appState.media.somewhatMini} text={info.description}/>
            </ButtonLink>
        </FadeInCentered>
        <Spacer/>
    </ContentSection>;

        if (appState.media.somewhatMini) {
            return <ContainerShort>
                {titleOnlySection}
                <Columns bottomMargin={!!info.title}>
                    {imageSection}
                    {contentOnlySection}
                </Columns>
            </ContainerShort>;
        } else {
            return <Container>
                {imageSection}
                {contentSection}
            </Container>;
        }
}));
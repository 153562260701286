import * as PIXI from "pixi.js"

const vertex = `
precision mediump float;

attribute vec2 aVertexPosition;
attribute vec2 aTextureCoord;
attribute vec4 aColor;
attribute float aTextureId;

uniform mat3 projectionMatrix;
uniform mat3 translationMatrix;
uniform vec4 tint;

varying vec2 vTextureCoord;
varying vec4 vColor;
varying float vTextureId;

void main(void){
    gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);

    vTextureCoord = aTextureCoord;
    vTextureId = aTextureId;
    vColor = aColor * tint;
}
`;

const fragment = `
precision mediump float;

varying vec2 vTextureCoord;
varying vec4 vColor;
varying float vTextureId;

uniform sampler2D uSamplers[%count%];

uniform float test;

void main(void){
    vec4 color;
    float amt = .001 * vColor.a;
    %forloop%
    gl_FragColor = vColor * color + vec4(test,test,test,0.0);
}
`;

class CustomBatchShaderGenerator {

    constructor(vertexSrc, fragTemplate) {
        this.vertexSrc = vertexSrc;
        this.fragTemplate = fragTemplate;

        this.programCache = {};
        this.defaultGroupCache = {};
    }

    generateShader(maxTextures) {
        if (!this.programCache[maxTextures]) {
            const sampleValues = new Int32Array(maxTextures);

            for (let i = 0; i < maxTextures; i++)
            {
                sampleValues[i] = i;
            }

            this.defaultGroupCache[maxTextures] = PIXI.UniformGroup.from({ uSamplers: sampleValues }, true);

            let fragmentSrc = this.fragTemplate;

            fragmentSrc = fragmentSrc.replace(/%count%/gi, `${maxTextures}`);
            fragmentSrc = fragmentSrc.replace(/%forloop%/gi, this.generateSampleSrc(maxTextures));

            this.programCache[maxTextures] = new PIXI.Program(this.vertexSrc, fragmentSrc);
        }

        const uniforms = {
            tint: new Float32Array([1, 1, 1, 1]),
            translationMatrix: new PIXI.Matrix(),
            default: this.defaultGroupCache[maxTextures]
        };

        return new PIXI.Shader(this.programCache[maxTextures], uniforms);
    }

    generateSampleSrc(maxTextures) {
        let src = '\n';
        for (let i = 0; i < maxTextures; i++)
        {
            if (i > 0)
            {
                src += '\nelse ';
            }

            if (i < maxTextures - 1)
            {
                src += `if(vTextureId < ${i}.5)`;
            }

            src += '\n{';
            src += this.generateColor(i);
            src += '\n}';
        }

        src += '\n';
        return src;
    }

    generateColor(texId) {
        return `
        color = .3 * texture2D(uSamplers[${texId}], vTextureCoord) 
                 + .175 * texture2D(uSamplers[${texId}], vTextureCoord + vec2(amt,amt)) 
                 + .175 * texture2D(uSamplers[${texId}], vTextureCoord + vec2(amt,-amt)) 
                 + .175 * texture2D(uSamplers[${texId}], vTextureCoord + vec2(-amt,-amt))
                 + .175 * texture2D(uSamplers[${texId}], vTextureCoord + vec2(-amt,amt));
        `
    }
}

export default class ThumbPreviewRenderer extends PIXI.AbstractBatchRenderer {

    constructor(renderer)
    {
        super(renderer);

        this.shaderGenerator = new CustomBatchShaderGenerator(vertex, fragment);
        this.geometryClass = PIXI.BatchGeometry;
        this.vertexSize = 6;
    }
};
import React, {useState} from 'react'
import DumbLinkContainer from "../../../components/DumbLinkContainer";
import {getSignedUrlSync} from "../../../helpers/SignedUrlLoader";
import {DimSpan} from "../../../styles/Span";
import {PhotoIconLightShadowed, TextIconLightShadowed} from "../../../svg/Icons";
import moment from 'moment'
import styled from '@emotion/styled'
import Theme from "../../../styles/Theme";
import {typeface} from "../../../styles/Typeface";
import {urlTitleSegment} from "../../../helpers/MiscHelpers";
import RestrictedMarkdown from "../../../components/RestrictedMarkdown";

const ImgShadow = styled.img`
  box-shadow: 0 0 5px rgba(0,0,0,.5);
  opacity: ${ p => p.visible ? 1 : 0};
  transition: opacity 300ms;
`;

const ThumbHolder = styled.div`
  width: ${p => p.doubleSize ? 128 : 64}px;
  height: ${p => p.doubleSize ? 128 : 64}px;
  margin-right: ${p => p.doubleSize ? 32 : 16}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StoryTitle = styled.div`
  ${p => typeface(p.doubleSize ? 22 : 18, 700)};
  text-align: left;
  color: ${Theme.colors.kindaDim};
  display: inline;
  line-height: 1.25;
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
`;

const StorySubText = styled.div`
  ${p => typeface(p.doubleSize ? 18 : 14, 400)};
  text-align: left;
  color: ${Theme.colors.dim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
`;

const StoryLinkHolder = styled.div`
  width: 100%;
  contain: layout;
`;

const RightFloat = styled.div`
  display: block;
  margin-left: auto;
  ${p => typeface(p.doubleSize ? 18 : 14, 500)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.325);
  color: ${Theme.colors.nearingDim};
  white-space: nowrap;
  padding-left: 8px;
  width: ${p => p.doubleSize ? 64 : 46}px;
  text-align: left;
`;

const TextDiv = styled.div`
  text-align: left;
`;

const StoryGroup = styled.a`
  display: block;
  cursor: pointer;
  border-radius: 12px;
  padding-left: ${p => p.doubleSize ? 28 : 14}px;
  padding-right: ${p => p.doubleSize ? 28 : 14}px;
  padding-top: ${p => p.doubleSize ? 22 : 10}px;
  padding-bottom: ${p => p.doubleSize ? 22 : 10}px;
  
  transition: 150ms box-shadow, 150ms background;
  will-change: box-shadow, background;
  
  &:hover, &.focus-visible {
    background: rgba(255,255,255,.025);
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.15), 0 0 6px 0 rgba(0,0,0,.3);
  }
`;

const HeadingGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BodySnippet = styled.div`
  display: block;
  -webkit-mask-image: -webkit-gradient(rgba(0,0,0,1), rgba(0,0,0,.75), rgba(0,0,0,0));
  mask-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.75), rgba(0,0,0,0));
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const RestrictedMarkdownStyled = styled(RestrictedMarkdown)`
  user-select: none;
  color: ${Theme.colors.almostWhite};
  opacity: .5;
  text-align: initial;
  width: 85%;
  max-height: 200px;
  overflow: hidden;
`;

const TypeHolder = styled.div`
  text-align: center;
`;

const TypeDiv = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${Theme.colors.almostDim};
  color: ${Theme.colors.background};
  ${typeface(14, 700)};
  text-transform: uppercase;
  height: 20px;
  border-radius: 10px;
  padding: 2px 6px;
`;

export default ({story, onNav, showAuthor, doubleSize, className, showSnippet, showType, maxWidth}) => {
    let [loaded, setLoaded] = useState(false);
    let size = doubleSize ? 128 : 64;
    return <StoryLinkHolder doubleSize={doubleSize} key={story.ident} maxWidth={maxWidth}>
        <DumbLinkContainer to={`/story/_${story.ident}${urlTitleSegment(story.title)}`} onNav={onNav}>
            <StoryGroup className={className} doubleSize={doubleSize}>
                {showType && story.type !== "default" ? <TypeHolder><TypeDiv>{story.type}</TypeDiv></TypeHolder> : null}
                <HeadingGroup>
                    <ThumbHolder doubleSize={doubleSize}>
                        {story.coverPhoto ?
                            <ImgShadow
                                crossOrigin={"Anonymous"}
                                width={story.coverPhoto.img[size].w}
                                height={story.coverPhoto.img[size].h}
                                src={getSignedUrlSync(story.coverPhoto.img[size].fn)}
                                srcSet={`${getSignedUrlSync(story.coverPhoto.img[doubleSize ? 256 : 128].fn)} 2x`}
                                onLoad={() => setLoaded(true)}
                                visible={loaded}
                            />
                            : <DimSpan><TextIconLightShadowed holderVerticalAlign={'middle'} scale={doubleSize ? 84 : 42}/></DimSpan>
                        }
                    </ThumbHolder>
                    <TextDiv>
                        <StoryTitle doubleSize={doubleSize}>
                            {story.title && story.title !== "" ? story.title : "(untitled)"}
                        </StoryTitle>
                        {showAuthor ?
                            <StorySubText doubleSize={doubleSize}>
                                Story by {story.user.name}
                            </StorySubText>
                            :
                            (story.public ?
                                <StorySubText doubleSize={doubleSize}>
                                    {moment(story.publicAt).format('MMMM Do, YYYY')}
                                </StorySubText>
                                : null)
                        }
                    </TextDiv>
                    <RightFloat doubleSize={doubleSize}>
                        <PhotoIconLightShadowed shadowAmt={.5} marginRight={"6px"} holderVerticalAlign={`${doubleSize ? -8 : -4}px`} inline={true} scale={doubleSize ? 28 : 18}/>
                        {story.numPhotos}
                    </RightFloat>
                </HeadingGroup>
                {(showSnippet && story.firstTextBlock) ?
                    <BodySnippet>
                        <RestrictedMarkdownStyled text={story.firstTextBlock + "\n…"} noBottomMargin={true}/>
                    </BodySnippet>
                    : null
                }
            </StoryGroup>
        </DumbLinkContainer>
    </StoryLinkHolder>;
};
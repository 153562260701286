import React  from 'react';
import {inject, observer} from "mobx-react";
import Thumbnail from '../../components/Thumbnail'
import Config from "../../Config";
import { runInAction, observable } from "mobx";
import Api from "../../components/Api";
import styled from '@emotion/styled'
import queryString from 'query-string'
import {sleep} from "../../helpers/AsyncHelper";
import Theme from "../../styles/Theme";
import equal from 'fast-deep-equal/es6'
import Background from "../../components/Background";
import WithFooter from "../../components/WithFooter";

const GalleryPage = styled.div`
  padding: ${p => p.mobile ? 7 : 15}px;
  margin-left: auto;
  margin-right: auto;
  ${p => p.pageCss ? p.pageCss : ``};
`;

const VerticalSpacer = styled.div`
  height: ${p => (p.mobile ? 200 : 240) * (p.moreSpace ? 1.1 : 1) }px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${p => p.mobile ? 25 : 60 }px;
`;

export const EndDiv = styled.div`
  width: 50%;
  min-width: 240px;
  max-width: 480px;
  height: ${p => p.mobile ? 160 : 160 }px;
  border-radius: ${p => p.mobile ? 30 : 40 }px;
  border: dashed 2px rgba(255,255,255,.065);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.dimmer};
  opacity: ${p => p.visible ? 1.0 : 0};
  transition: opacity ${p => p.visible ? 500 : 0}ms linear;
`;

const ThumbnailFlexContainer = styled.div`
  margin:auto;
`;

const ThumbnailFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbnailFlexForward = styled(ThumbnailFlex)`
  flex-wrap: wrap;
`;

const ThumbnailFlexReverse = styled(ThumbnailFlex)`
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
`;

export const GalleryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;

const MaybeHidden = styled.div`
  ${ p => p.hide ? "opacity: .25;" : ""}
`;

const PageDiv = styled.div`
`;

class GalleryComponent extends React.Component {

    constructor(props) {
        super(props);

        this.data = observable({
            thumbs: [],
            thumbsReverse: [],
            canLoadMoreForward: true,
            canLoadMoreReverse: true,
            doneLoadingForward: false,
            doneLoadingReverse: false,
            lastThumb: null,
            firstThumb: null,
            time: null
        });
        this.loadedIdents = new Set();
    }

    resetData = () => {
        runInAction(() => {
            this.data.thumbs = [];
            this.data.thumbsReverse = [];
            this.data.canLoadMoreForward = true;
            this.data.canLoadMoreReverse = true;
            this.data.doneLoadingForward = false;
            this.data.doneLoadingReverse = false;
            this.data.lastThumb = null;
            this.data.firstThumb = null;
            this.data.time = null;
        });
        this.loadedIdents.clear();
    }

    componentDidMount() {
        this.reset();
        window.addEventListener('scroll', this.scroll);
        this.props.appState.emitter.on("photoChanged", this.photoChanged);
    }

    gridChanged = (element) => {
        this.grid = element;

        if (this.grid) {
            this.gridOldHeight = this.grid.scrollHeight;
            this.gridListener = new MutationObserver(() => {
                this.gridOldHeight = this.grid.scrollHeight;
            });
            let config = {attributes: false, childList: true, subtree: false};
            this.gridListener.observe(this.grid, config);
        }
    }

    gridReverseChanged = (element) => {
        this.gridReverse = element;

        if (this.gridReverse) {
            this.gridReverseOldHeight = this.gridReverse.scrollHeight;
            this.gridReverseListener = new MutationObserver(() => {
                let amt = this.gridReverse.scrollHeight - this.gridReverseOldHeight;
                if (amt > 0) {
                    window.scrollBy({left: 0, top: amt, behavior: "auto"});
                }
                this.gridReverseOldHeight = this.gridReverse.scrollHeight;
            });
            let config = {attributes: false, childList: true, subtree: false};
            this.gridReverseListener.observe(this.gridReverse, config);
        }
    }

    componentDidUpdate(prevProps){
        if (!equal(prevProps.params, this.props.params)) {
            this.reset();
        }
    }

    componentWillUnmount(){
        if (this.gridListener){
            this.gridListener.disconnect();
            this.gridListener = null;
        }
        if (this.gridReverseListener){
            this.gridReverseListener.disconnect();
            this.gridReverseListener = null;
        }

        this.awaitingLoadUrl = "";
        window.removeEventListener('scroll', this.scroll);
        if(this.socket){
            this.socket.close();
            this.socket = null;
        }
        this.props.appState.emitter.removeListener("photoChanged", this.photoChanged);
    }

    scroll = () => {
        if(this.props.appState.photo.ident){
            return;
        }
        let threshold = 600;
        if((window.scrollY + window.innerHeight >= document.body.scrollHeight - threshold - 800)){
            this.loadForward();
        }
        if((window.scrollY <= threshold)){
            this.loadReverse();
        }
    }

    reset = async () => {
        window.scrollTo({left: 0, top: 0, behavior: "auto"});
        this.resetData();
        this.awaitingLoadUrl = "";
        this.processingThumbs = {};
        if(this.socket){
            this.socket.close();
            this.socket = null;
        }

        do {
            await this.loadForward();
        } while (this.data.canLoadMoreForward && document.documentElement.scrollHeight <= document.documentElement.clientHeight);

        await this.loadReverse(true);
        await sleep(500);
    }

    loadForward = async () => {
        return await this.load(false);
    }
    loadReverse = async () => {
        return await this.load(true);
    }

    loadReverseWithSleep = async () => {
        await sleep(500);
        return await this.loadReverse();
    }

    load = async (reverse) => {
        let canLoadMore = reverse ? this.data.canLoadMoreReverse : this.data.canLoadMoreForward;
        if (canLoadMore) {
            let {url, query, sortAscend, sortField} = this.props.params;

            let finalQuery = {...query};
            if (sortField) {
                finalQuery["sort-field"] = sortField;
            }
            if (sortAscend){
                finalQuery["after-sort-value"] = "min";
                finalQuery["after-ident"] = "min";
            } else {
                finalQuery["after-sort-value"] = "max";
                finalQuery["after-ident"] = "max";
            }
            finalQuery["inclusive"] = false;
            finalQuery["num"] = 16;
            finalQuery["null-is-big"] = sortAscend;

            if (reverse){
                runInAction(() => this.data.canLoadMoreReverse = false);
                finalQuery["ascend"] = !sortAscend;
                if (this.data.firstThumb){
                    finalQuery["after-sort-value"] = this.data.firstThumb.sortValue;
                    finalQuery["after-ident"] = this.data.firstThumb.ident;
                } else if (this.data.lastThumb) {
                    finalQuery["after-sort-value"] = this.data.lastThumb.sortValue;
                    finalQuery["after-ident"] = this.data.lastThumb.ident;
                    finalQuery["inclusive"] = true;
                } else {
                    runInAction(() => {
                        this.data.canLoadMoreForward = false;
                        this.data.doneLoadingForward = true;
                    });
                }
            } else {
                runInAction(() => this.data.canLoadMoreForward = false);
                finalQuery["ascend"] = sortAscend;
                if (this.data.lastThumb){
                    finalQuery["after-sort-value"] = this.data.lastThumb.sortValue;
                    finalQuery["after-ident"] = this.data.lastThumb.ident;
                } else if (this.data.firstThumb) {
                    finalQuery["after-sort-value"] = this.data.firstThumb.sortValue;
                    finalQuery["after-ident"] = this.data.firstThumb.ident;
                    finalQuery["inclusive"] = true;
                } else {
                    runInAction(() => {
                        this.data.canLoadMoreReverse = false;
                        this.data.doneLoadingReverse = true;
                    });
                }
            }

            runInAction(() => {
                let series = {};
                series.url = url;
                series.type = "gallery";

                series.nextQuery = {};
                series.nextQuery["sort-field"] = sortField;
                series.nextQuery["num"] = 1;
                series.nextQuery["inclusive"] = false;
                series.nextQuery["null-is-big"] = sortAscend;
                series.nextQuery["ascend"] = sortAscend;

                series.previousQuery = {};
                series.previousQuery["sort-field"] = sortField;
                series.previousQuery["num"] = 1;
                series.previousQuery["inclusive"] = false;
                series.previousQuery["null-is-big"] = sortAscend;
                series.previousQuery["ascend"] = !sortAscend;

                this.underlaySeries = series;
            });

            this.awaitingLoadUrl = url;
            let thumbs = await Api.get(url + "?" + queryString.stringify(finalQuery));
            if (this.props.waitFor) {
                await this.props.waitFor;
            }
            if (thumbs && thumbs.length !== 0) {
                if(this.awaitingLoadUrl !== url){
                    return;
                }
                let unprocessed = [];
                let nestedThumbs = [];
                thumbs.forEach(thumb => {
                    let data = observable({data: thumb});
                    if (!this.loadedIdents.has(thumb.ident)) {
                        this.loadedIdents.add(thumb.ident);
                        if (!thumb.processed) {
                            unprocessed.push(data);
                        }
                        nestedThumbs.push(data);
                    }
                });
                if(unprocessed.length > 0){
                    let idents = unprocessed.map(u => u.data.ident);
                    if(!this.socket || this.socket.readyState === 2 || this.socket.readyState === 3) {
                        this.awaitingSocketIdents = idents;
                        this.socket = new WebSocket(Config.APP_WS_BASE_URL + "/api/photo/listen/completion");
                        this.socket.addEventListener('open', () => {
                            this.socket.send(this.awaitingSocketIdents);
                            this.awaitingSocketIdents = [];
                        });
                        this.socket.addEventListener('message', async (event) => {
                            let thumbSocketJson = await Api.get("/gallery/photo/" + event.data);
                            if (thumbSocketJson.length > 0){
                                let existingData = this.processingThumbs[event.data];
                                if (existingData) {
                                    runInAction(() => {
                                        existingData.data = thumbSocketJson[0];
                                    });
                                }
                            }
                        });
                    } else if(this.socket.readyState === 0) {
                        this.awaitingSocketIdents = this.awaitingSocketIdents.concat(idents.join(","));
                    } else {
                        this.socket.send(idents.join(","));
                    }
                    unprocessed.forEach(thumb => {
                        this.processingThumbs[thumb.data.ident] = thumb;
                    });
                }
                if (reverse){
                    runInAction(() => this.data.thumbsReverse = this.data.thumbsReverse.concat(nestedThumbs));
                } else {
                    runInAction(() => this.data.thumbs = this.data.thumbs.concat(nestedThumbs));
                }
                if (nestedThumbs.length > 0) {
                    if (reverse) {
                        runInAction(() => this.data.firstThumb = {
                            "sortValue": nestedThumbs[nestedThumbs.length - 1].data.sortValue,
                            "ident": nestedThumbs[nestedThumbs.length - 1].data.ident
                        });
                    } else {
                        runInAction(() => this.data.lastThumb = {
                            "sortValue": nestedThumbs[nestedThumbs.length - 1].data.sortValue,
                            "ident": nestedThumbs[nestedThumbs.length - 1].data.ident
                        });
                    }
                }
                this.waitAndUnlock(reverse);
            } else {
                if (reverse){
                    runInAction(() => this.data.doneLoadingReverse = true);
                } else {
                    runInAction(() => this.data.doneLoadingForward = true);
                }
            }
        }
    }

    waitAndUnlock = (reverse) => {
        if(reverse){
            runInAction(() => this.data.canLoadMoreReverse = true);
        } else {
            runInAction(() => this.data.canLoadMoreForward = true);
        }
    }

    photoChanged = async (ident, deleted) => {
        let results;
        let dataSources = [this.data.thumbs, this.data.thumbsReverse];
        for (let i = 0; i < dataSources.length; i++){
            let dataSource = dataSources[i];
            for (let j = 0; j < dataSource.length; j++){
                let element = dataSource[j];
                if (element.data.ident === ident){
                    if (deleted) {
                        runInAction(() => {
                            element.deleted = true;
                        });
                    } else {
                        if (!results) {
                            results = await Api.get(`/gallery/photo/${ident}`);
                        }
                        runInAction(() => {
                            let sortValue = element.data.sortValue;
                            element.data = results[0];
                            element.data.sortValue = sortValue;
                        });
                    }
                }
            }
        }
    };

    render(){
        let doneLoading = this.data.doneLoadingForward && this.data.doneLoadingReverse;
        let empty = this.data.thumbs.length + this.data.thumbsReverse.length === 0;
        let mobile = this.props.appState.media.mobile;
        return <Background bg={Theme.colors.background}>
            <WithFooter>
                <PageDiv>
                    {this.props.header}
                    <GalleryPage mobile={mobile} pageCss={this.props.pageCss}>
                        { this.data.thumbsReverse.length > 0 ?
                            <ThumbnailFlexContainer>
                                <ThumbnailFlexReverse ref={this.gridReverseChanged}>
                                    {this.data.thumbsReverse.map((t) => (!t.deleted ?
                                            <MaybeHidden key={t.data.ident}
                                                         hide={t.data.isPublic === this.props.unpublished}>
                                                <Thumbnail underlaySeries={this.underlaySeries} thumb={t.data}/>
                                            </MaybeHidden>
                                            : null
                                    ))}
                                </ThumbnailFlexReverse>
                            </ThumbnailFlexContainer>
                            :
                            <></>
                        }
                        {this.data.thumbs.length > 0 ?
                            <ThumbnailFlexContainer>
                                <ThumbnailFlexForward ref={this.gridChanged}>
                                    {this.data.thumbs.map((t) => (!t.deleted ?
                                            <MaybeHidden key={t.data.ident}
                                                         hide={t.data.isPublic === this.props.unpublished}>
                                                <Thumbnail underlaySeries={this.underlaySeries} thumb={t.data}/>
                                            </MaybeHidden>
                                            : null
                                    ))}
                                </ThumbnailFlexForward>
                            </ThumbnailFlexContainer>
                            :
                            <></>
                        }
                    </GalleryPage>
                    <VerticalSpacer moreSpace={true} mobile={mobile}>
                        <EndDiv mobile={mobile} visible={doneLoading}>
                            {empty ? (this.props.emptyMessage ? this.props.emptyMessage : "We couldn't find anything.") : (this.props.doneMessage ? this.props.doneMessage : "That's all we could find!")}
                        </EndDiv>
                    </VerticalSpacer>
                </PageDiv>
            </WithFooter>
        </Background>
    }

}

export default inject("appState")(observer(GalleryComponent));
import React, {useState, useLayoutEffect} from 'react';
import styled from '@emotion/styled'

const FadeInDiv = styled.div`
  opacity: ${p => p.loaded ? 1 : 0};
  transition: opacity ${p => p.timeMs || 250}ms;
`;

export default ({children, timeMs}) => {
    let [loaded, setLoaded] = useState(false);
    useLayoutEffect(() => {
        requestAnimationFrame(() => setLoaded(true));
    }, []);
    return <FadeInDiv loaded={loaded} timeMs={timeMs}>
        {children}
    </FadeInDiv>;
};
import React, {useRef} from 'react';
import styled from '@emotion/styled';
import copy from 'clipboard-copy';
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";
import Scroll from "./Scroll";

const CodeBlockHolder = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,.35) inset;
  background-color: ${Theme.colors.background};
  cursor: ${ p => p.disabled ? "not-allowed" : "pointer"};
  color: ${ p => p.disabled ? Theme.colors.almostWhite : Theme.colors.almostWhite};
  animation-duration: .5s;
  position: relative;
  width: ${p => p.width ? p.width : `100%`};
  user-select: ${ p => p.disabled ? "none" : "auto"};
  
  @keyframes flashCode {
    0% {
      background-color: ${Theme.colors.almostWhite};
    }
    100% { 
      background-color: ${Theme.colors.background};
    }
  }
`;

const CodeSpan = styled.div`
  display: inline;
  font-family: ${ p => p.disabled ? "inherit" : "monospace"};
  text-align: ${ p => p.disabled ? "center" : "left"};
  white-space: pre;
  word-break: break-all;
  font-size: 12px;
`;

const CopiedNotification = styled.div`
  opacity: 0;
  color: ${Theme.colors.backgroundDark};
  background-color:  ${Theme.colors.almostWhite};
  border-radius: 6px;
  padding: 3px;
  user-select: none;
  animation-duration: 3s;
  position: absolute;
  bottom: 4px;
  right: 4px;
  ${typeface(12, 400)};
  
  @keyframes flashCopied {
    0% {
      opacity: 1;
    }
    75% {
      opacity: .75;
    }
    100% {
      opacity: 0;
    }
  }
`;

const CodeBlockScroller = styled.div`
  height: ${ p => p.height };
  ${ p => p.disabled ? `
    display: flex;
    justify-content: center;
    align-items: center;
  ` : ``
  }
`;

export default (props) => {
    let codeRef = useRef(null);
    let copiedRef = useRef(null);

    let doCopy = () => {
        if (!props.disabled) {
            copy(props.text);
            if (document.activeElement) {
                document.activeElement.blur();
            }
            if (codeRef.current) {
                codeRef.current.style.animationName = "";
                requestAnimationFrame(() => codeRef.current.style.animationName = "flashCode");
            }
            if (copiedRef.current) {
                copiedRef.current.style.animationName = "";
                requestAnimationFrame(() => copiedRef.current.style.animationName = "flashCopied");
            }
        }
    };

    return (
        <>
            <CodeBlockHolder width={props.width} disabled={props.disabled} ref={codeRef} tabIndex={0} onClick={doCopy} onKeyDown={(e) => { if(e.key === "Enter"){doCopy()}}}>
                <Scroll scrollX>
                    <CodeBlockScroller disabled={props.disabled} height={props.height || "80px"}>
                        <CodeSpan disabled={props.disabled}>{props.text}</CodeSpan>
                    </CodeBlockScroller>
                </Scroll>
                <CopiedNotification ref={copiedRef}>Copied</CopiedNotification>
            </CodeBlockHolder>
        </>
    )
};
import React, {useEffect, useState, useRef} from 'react';
import {typeface} from "../../styles/Typeface";
import Theme from "../../styles/Theme";
import Scroll from "../../components/Scroll";
import styled from '@emotion/styled';
import {observer, inject} from 'mobx-react';
import {observable, runInAction} from 'mobx'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 5px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${p => p.mini ? 5 : 20}px;
`;

const Spacer = styled.span`
  margin-left: 1px;
  margin-right: 1px;
  color: ${Theme.colors.dimmer};
  ${typeface(16, 700)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
  user-select: none;
`;

export const DefaultTabTitleLink = styled.a`
  padding: 0px 5px;
  ${p => typeface(p.mobile ? 14 : 16, 700)};
  color: ${Theme.colors.dim} !important;
  &:hover, &.focus-visible {
    color: ${Theme.colors.kindaDim} !important;
  }
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
  display: inline-block;
  user-select: none;
`;

export const DefaultTabTitleCurrent = styled.span`
  padding: 0px 5px;
  ${p => typeface(p.mobile ? 14 : 16, 700)};
  color: ${Theme.colors.kindaDim};
  display: inline-block;
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  user-select: none;
`;

const ScrollContainer = styled.div`
  height: calc(100% - 10px);
  width: 100%;
`;

const QueryScroll = styled(Scroll)`
  height: 100%;
  padding: 0px 10px;
`;

const Shadow = styled.div`
  width: 100%;
  height: 5px;
  background: linear-gradient(rgba(0,0,0,.25), rgba(0,0,0,0));
  position: absolute;
  left: 0;
  border-top: 1px solid rgba(55,55,55,1);
  opacity: ${p => p.show ? 1 : 0};
  transition: opacity 250ms;
  will-change: opacity;
  z-index: 20;
`;

const DefaultTabTitle = ({selected, label, onClick, mobile}) => {
    return selected ?
        <DefaultTabTitleCurrent mobile={mobile}>{label}</DefaultTabTitleCurrent>
        :
        <DefaultTabTitleLink mobile={mobile} onClick={onClick}>{label}</DefaultTabTitleLink>;
}

const Tabs = inject("appState")(observer(({tabs, appState}) => {

    const data = useRef(observable({
        tab: tabs[0],
        scrolled: false,
        forceShadow: false,
    }, {
        tab: observable.ref
    }));

    useEffect(() => {
        goto(tabs[0]);
    }, [tabs]);

    let goto = (tab) => {
        runInAction(() => {
            data.current.tab = tab;
            data.current.forceShadow = true;
        });
        requestAnimationFrame(() => {
            runInAction(() => {
                data.current.forceShadow = false;
            });
        });
    };

    let current = data.current.tab;
    let forceShadow = data.current.forceShadow;
    let scrolled = data.current.scrolled;

    let scrollX = appState.media.somewhatMini ? current.scrollXMini : current.scrollX;
    let scrollY = appState.media.somewhatMini ? current.scrollYMini : current.scrollY;

    return <>
        <div>
            <TabContainer mini={appState.media.somewhatMini}>
                {tabs.map((tab, index) => {
                    let TitleComponent = tab.titleComponent || DefaultTabTitle;
                    return <React.Fragment key={tab.label}>
                        <TitleComponent mobile={appState.media.mobile} selected={tab === current} label={tab.label} onClick={() => goto(tab)} />
                        {(tab !== tabs[tabs.length - 1]) ?
                            <Spacer>·</Spacer>
                            : null
                        }
                    </React.Fragment>;
                })}
            </TabContainer>
            <Shadow show={scrolled && scrollY}/>
        </div>
        <ScrollContainer>
            {(!appState.media.somewhatMini || scrollX || scrollY) ?
                <QueryScroll
                    scrollX={scrollX}
                    scrollY={scrollY}
                    showArrow={true}
                    scrolledCallback={(scrolled) => runInAction(() => data.current.scrolled = scrolled)}
                    bgFadeColorArray={[35,35,35]}
                >
                    {current.content}
                </QueryScroll>
                : current.content
            }
        </ScrollContainer>
    </>;
}));

export default ({queryNum, makeTabs}) => {
    let [tabs, setTabs] = useState(makeTabs());
    useEffect(() => {
        setTabs(makeTabs());
    }, [queryNum, makeTabs]);
    return <Container>
        {tabs ? <Tabs tabs={tabs}/> : null}
    </Container>;
};

import React from "react"
import GalleryComponent from "../GalleryComponent";
import StandardTabbableInfiniteScrollList from "../../../components/StandardTabbableInfiniteScrollList";

const getRetrievalParams = (segment) => {
    let url = `/gallery/recent/${segment}`;
    let query = {};
    let sortField = segment === "showcased" ? "showcased_at" : "public_at";
    return {url: url, sortAscend: false, sortField: sortField, query: query};
};

const DATA = [
    {
        id: "showcased",
        label: "Showcased Only",
        path: "/explore/recent/photos/showcased"
    },
    {
        id: "all",
        label: "All Photos",
        path: "/explore/recent/photos/all"
    }
];

const RecentGallery = ({segment}) => <StandardTabbableInfiniteScrollList
    label={"Recent Photos"}
    segment={segment}
    data={DATA}
    segmentToParams={getRetrievalParams}
    subtext={segment === "showcased" ? "By Showcase Date" : "By Publish Date"}
    component={GalleryComponent}
/>;
export const RecentGalleryAll = () => <RecentGallery segment={"all"}/>;
export const RecentGalleryShowcased = () => <RecentGallery segment={"showcased"}/>;

import { css } from '@emotion/react'

const a = -0.0223, b = 0.185, c = -0.1745;

function spacing(size, weight) {
  let weightPct = 1 + (4.0/3.0) * (weight - 400)/400;
  return a + b * Math.pow(Math.E, c * size / weightPct);
}

export const typeface = (size=16, weight=400, additionalSpacing=0) => css`
    font-size: ${size}px;
    font-weight: ${weight};
    letter-spacing: ${spacing(size, weight) + additionalSpacing}em;
    line-height: 1.4;
    font-feature-settings: "cv05", "cv06", "cv07", "cv08", "cpsp", "ss01", "ss03", "ss04";
`;
import React from 'react';
import TextInput from "../TextInput";
import Suggestions from 'react-tag-input/dist-modules/components/Suggestions'
import {DEFAULT_CLASSNAMES,INPUT_FIELD_POSITIONS} from 'react-tag-input/dist-modules/components/constants'
import TagFixed from "./TagFixed";

// monkey patch until https://github.com/prakhar1989/react-tags/issues/547 is resolved
const dnd = require('react-dnd');
dnd.DragDropContext = () => (c) => c;
const ReactTags = require('react-tag-input');

// use custom text input component
export default class ReactTagsFixed extends ReactTags.WithOutContext {

    getTagItemsOverride = () => {
        const {
              classNames,
              tags,
              labelField,
              removeComponent,
              readOnly,
              allowDragDrop,
        } = this.props;

        const moveTag = allowDragDrop ? this.moveTag : null;
        return tags.map((tag, index) => {
            return (
                <TagFixed
                    key={tag.key || tag.id}
                    index={index}
                    tag={tag}
                    labelField={labelField}
                    onDelete={this.handleDelete.bind(this, index)}
                    moveTag={moveTag}
                    removeComponent={removeComponent}
                    onTagClicked={this.handleTagClick.bind(this, index)}
                    readOnly={readOnly}
                    classNames={{ ...DEFAULT_CLASSNAMES, ...classNames }}
                    allowDragDrop={allowDragDrop}
                    clickable={this.props.clickable}
                />
            );
        });
    };

    render() {
        const tagItems = this.getTagItemsOverride();
        const classNames = {...DEFAULT_CLASSNAMES, ...this.props.classNames};

        // get the suggestions for the given query
        const query = this.state.query.trim(),
            selectedIndex = this.state.selectedIndex,
            suggestions = this.state.suggestions;

        const {
            placeholder,
            name: inputName,
            id: inputId,
            maxLength,
            inline,
            inputFieldPosition,
        } = this.props;

        const position = !inline
            ? INPUT_FIELD_POSITIONS.BOTTOM
            : inputFieldPosition;

        let oldReadOnly = this.oldReadOnly;

        const tagInput = !this.props.readOnly ? (
            <div
                className={classNames.tagInput}
                ref={(ref) => {
                        if (ref) {
                            if (oldReadOnly && !this.props.readOnly) {
                                ref.style.transition = "opacity 0ms";
                                ref.style.opacity = "0";
                                setTimeout(() => {
                                    ref.style.transition = "opacity 500ms";
                                    ref.style.opacity = "1";
                                }, 0);
                            } else {
                                ref.style.transition = "opacity 0ms";
                                ref.style.opacity = "1";
                            }
                        }
                    }}
            >
                <TextInput
                    inputRef={(input) => {
                        this.textInput = input;
                    }}
                    inputComponent={this.props.inputComponent || 'input'}
                    inputData={this.props.inputData || {}}
                    useTagAdded={this.props.useTagAdded}
                    tagAdded={(text) => this.props.handleAddition({text: text})}
                    inputClassName={classNames.tagInputField}
                    type="text"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onPaste={this.handlePaste}
                    name={inputName}
                    id={inputId}
                    maxLength={maxLength}
                    value={this.props.inputValue}
                    autoCapitalize={this.props.autoCapitalize}
                    autoCorrect={this.props.autoCorrect}
                    autoComplete={this.props.autoComplete}
                />

                <Suggestions
                    query={query}
                    suggestions={suggestions}
                    labelField={this.props.labelField}
                    selectedIndex={selectedIndex}
                    handleClick={this.handleSuggestionClick}
                    handleHover={this.handleSuggestionHover}
                    minQueryLength={this.props.minQueryLength}
                    shouldRenderSuggestions={this.props.shouldRenderSuggestions}
                    isFocused={this.state.isFocused}
                    classNames={classNames}
                    renderSuggestion={this.props.renderSuggestion}
                />
            </div>
        ) : null;

        this.oldReadOnly = this.props.readOnly;

        return (
            <div className={classNames.tags + ' react-tags-wrapper'} ref={this.reactTagsRef}>
                <p
                  role="alert"
                  className="sr-only"
                  style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    clip: 'rect(0 0 0 0)',
                    margin: '-1px',
                    padding: 0,
                    width: '1px',
                    height: '1px',
                    border: 0,
                  }}>
                  {this.state.ariaLiveStatus}
                </p>
                {position === INPUT_FIELD_POSITIONS.TOP && tagInput}
                <div className={classNames.selected}>
                    {tagItems}
                    {position === INPUT_FIELD_POSITIONS.INLINE && tagInput}
                </div>
                {position === INPUT_FIELD_POSITIONS.BOTTOM && tagInput}
            </div>
        );
    }
}

import React from "react"
const StandardTabbableInfiniteScrollList = React.lazy(() => import( "../../../components/StandardTabbableInfiniteScrollList"));
const GalleryComponent = React.lazy(() => import("../GalleryComponent"));

const getRetrievalParams = (segment) => {
    let url = `/gallery/top/${segment}`;
    let query = {};
    return {url: url, sortAscend: false, query: query};
};

const DATA = [
    {
        id: "day",
        label: "Day",
        path: "/explore/top/day/photos"
    },
    {
        id: "week",
        label: "Week",
        path: "/explore/top/week/photos"
    },
    {
        id: "month",
        label: "Month",
        path: "/explore/top/month/photos"
    },
    {
        id: "alltime",
        label: "All-Time",
        path: "/explore/top/alltime/photos"
    },
];

export const TopGallery = ({segment}) => <StandardTabbableInfiniteScrollList
    label={"Top Photos"}
    segment={segment}
    data={DATA}
    segmentToParams={getRetrievalParams}
    subtext={"By Favorite Count"}
    component={GalleryComponent}
/>;
export const TopGalleryAllTime = () => <TopGallery segment={"alltime"}/>;
export const TopGalleryMonth = () => <TopGallery segment={"month"}/>;
export const TopGalleryWeek = () => <TopGallery segment={"week"}/>;
export const TopGalleryDay = () => <TopGallery segment={"day"}/>;

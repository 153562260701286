import moment from 'moment'
const SMALL_NUMBERS = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19
};

const DIGITS = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9
};

const TENS = {
    twenty: 20,
    thirty: 30,
    forty: 40,
    fifty: 50,
    sixty: 60,
    seventy: 70,
    eighty: 80,
    ninety: 90
}

const lastParser = {};
lastParser.pattern = function () { return /(last|past) ([\w-]+) (\w+)/; };
lastParser.extract = function (context, match) {

    let result = context.createParsingResult(match.index, match[0]);
    result.start = context.createParsingComponents();
    result.end = context.createParsingComponents();

    let amtText = match[2];
    let amt = 0;
    if (SMALL_NUMBERS[amtText]){
        amt = SMALL_NUMBERS[amtText];
    } else if (TENS[amtText]) {
        amt = TENS[amtText];
    } else if (amtText.match(/\d+/)) {
        amt = parseInt(amtText);
    } else {
        let parts = amtText.split("-");
        if (parts.length === 2 && TENS[parts[0]] && DIGITS[parts[1]]) {
            amt = TENS[parts[0]] + DIGITS[parts[1]];
        } else {
            return false;
        }
    }

    let unit = match[3];

    let end = moment();
    let start;

    if (unit === "day" || unit === "days"){
        start = end.clone().subtract(amt, "day");
    }

    if (unit === "week" || unit === "weeks"){
        start = end.clone().subtract(amt, "week");
    }

    if (unit === "month" || unit === "months"){
        start = end.clone().subtract(amt, "month");
    }

    if (unit === "year" || unit === "years"){
        start = end.clone().subtract(amt, "year");
    }

    result.start.imply('year', start.year());
    result.start.imply('month', start.month() + 1);
    result.start.imply('day', start.date());
    result.start.imply('hour', start.hour());
    result.start.imply('minute', start.minute());
    result.start.imply('second', start.second());

    result.end.imply('year', end.year());
    result.end.imply('month', end.month() + 1);
    result.end.imply('day', end.date());
    result.end.imply('hour', end.hour());
    result.end.imply('minute', end.minute());
    result.end.imply('second', end.second());

    return result;
};

export default lastParser;
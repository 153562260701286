import React, {useRef, useEffect} from 'react';
import maplibre from '!maplibre-gl/dist/maplibre-gl-dev';
import {compile, rasterUniforms, symbolIconUniforms} from './MaplibreCompile'
import styled from '@emotion/styled';
import WindowHelper from "../../helpers/WindowHelper";
import {observable, runInAction, autorun, toJS, reaction} from 'mobx'
import {observer, inject} from 'mobx-react';

import gradientFrag from '!!raw-loader!./gradient.fragment.glsl';
import gradientVert from '!!raw-loader!./gradient.vertex.glsl';
import hillshadeFrag from '!!raw-loader!./hillshade.fragment.glsl';
import hillshadeVert from '!!raw-loader!./hillshade.vertex.glsl';
import iconFrag from '!!raw-loader!./icon.fragment.glsl';
import iconVert from '!!raw-loader!./icon.vertex.glsl';

import Theme from "../../styles/Theme";
import {typeface} from "../../styles/Typeface";
import Config from "../../Config";
import Api, {MapsApi} from "../../components/Api";
import queryString from 'query-string'
import {
    AstroIconLightShadowed,
    CameraIconLightShadowed,
    DownIconLightShadowed,
    GalleryIconLightShadowed,
    LandscapeIconLightShadowed,
    LeftIconLightShadowed,
    MoveLeftIconLightShadowed,
    MoveRightIconLightShadowed,
    PlusIconLightShadowed,
    PrevIconLightShadowed,
    TapIconLightShadowed,
    XIconLightShadowed
} from "../../svg/Icons";
import PoiDisplay from "./PoiDisplay";
import QueryDisplay from "./QueryDisplay";
import SpeciesDisplay from "./SpeciesDisplay";
import SearchBar from "./SearchBar";
import ProductInfoDisplay from "./ProductInfoDisplay";
import * as portals from 'react-reverse-portal';
import Spinner from "../../components/Spinner";
import MapTipScreen from "./MapTipScreen";
import PhotoDisplay from "./PhotoDisplay";
import {urlTitleSegment, validCoords} from "../../helpers/MiscHelpers";
import {MapPhotoSelector} from "./MapPhotoSelector";
import {ButtonLikeMiniDimButton} from "../../styles/Button";
import {broadcastToListeners} from "../../helpers/UseListener";
import {UserImage} from "../gallery/profile/ProfileHeader";

export const MAP_TIP_VERSION = 1;

const ExpandDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const MapDiv = styled(ExpandDiv)`
  ${p => p.point ? `cursor: pointer;` : ``}
`;

const InfoBox = styled.div`
  background: ${Theme.colors.background};
  z-index: 5;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, .9);//, inset 0 0 0 1px rgba(55,55,55,1);
  border: 1px solid rgba(55, 55, 55, 1);
  border-radius: 10px;
  will-change: transform;
  padding: 15px;
  overflow: hidden;
  display: flex;
`;

let padding = (mini) => (mini ? 6 : 10);

const TipInfoBox = styled(InfoBox)`
  padding-top: 10px;
  position: absolute;
  width: min(440px, calc(100vw - 20px));
  left: calc(50vw - calc(.5 * min(440px, calc(100vw - 20px))));
  height: 380px;
  top: calc(0.5 * (100vh - 380px));
`;

const RightInfoBox = styled(InfoBox)`
  position: absolute;
  width: min(400px, calc(100vw - ${p => padding(p.mini)*2}px));
  right: ${p => (p.skinny ? 
          `calc(50vw - calc(.5 * min(400px, calc(100vw - ${padding(p.mini)*2}px))));` 
          :
          `${padding(p.mini)}px;`)};
`;

const MapPhotoBox = styled(RightInfoBox)`
  visibility: ${p => p.hidden ? 'none' : 'visible'};
  ${p => p.mini ? `height: 320px;` : `height: calc(100% - 20px);`};
  bottom: 10px;
  padding: 0px 5px 0px 5px;
  width: min(400px, calc(100vw - ${p => padding(p.mini)*2}px));
  right: ${p => (p.skinny ? 
          `calc(50vw - calc(.5 * min(400px, calc(100vw - ${padding(p.mini)*2}px))));` 
          :
          `${padding(p.mini)}px;`)};
`;

const QueryInfo = styled(RightInfoBox)`
  ${p => p.mini ? 
    `height: 180px;` :
    (p.inCorner ? `height: unset; max-height: 420px;` : `height: calc(100% - 20px - 270px - 15px);`)
  };
  top: ${p => padding(p.mini)}px;
  padding: ${p => p.mini ? 5 : 15}px 5px 0px 5px;
`;

const ProductInfo = styled(RightInfoBox)`
  bottom: ${p => padding(p.mini)}px;
  height: ${p => p.mini ? 180 : 270}px;
  padding: ${p => p.mini ? 5 : 15}px 15px 5px 15px;
`;

const ButtonStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Theme.colors.background};
  border-radius: 100%;
  height: 24px;
  width: 24px;
  border: 1px solid rgba(255,255,255,.075);
  box-shadow: 0px 2px 2px 1px rgba(0,0,0,.25);
`;

const CornerButtonComponent = ({children, ...props}) => {
    return <button {...props}>
        <ButtonStack>
            {children}
        </ButtonStack>
    </button>;
}

const PopupButton = styled(CornerButtonComponent)`
  position: absolute;
  top: 3px;
  background: none;
  border: none;
  color: ${Theme.colors.kindaDim};
  z-index: 110;
  padding: 1px;
  
  &:hover {
    color: ${Theme.colors.almostWhite};
  }
  
  &:hover ${ButtonStack} {
    border: 1px solid rgba(255,255,255,.25);
  }
`;

const BackButton = styled(PopupButton)`
  left: 3px;
`;

const CloseButton = styled(PopupButton)`
  right: 3px;
`;

const SearchContainer = styled.div`
  display: inline-block;
  z-index: 20;
  max-height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px - 10px - 10px);
  pointer-events: auto;
`;

const LowerLeftContainer = styled.div`
  position: absolute;
  
  left: 0px;
  bottom: 0px;
  display: flex;
  align-items: flex-end;
  padding: 5px;
`;

const LowerRightContainer = styled.div`
  position: absolute;
  display: flex;
  pointer-events: none;
  right: ${p => p.attached ? 0 : (p.sidebarOpen ? (p.mini ? (p.productsHidden ? padding(true) + 60 : padding(true)) : 400 + 10 + 10) : 10)}px;
  bottom: ${p => p.attached ? 0 : ((p.sidebarOpen && p.mini && !p.productsHidden) ? (p.bottom ? p.bottom : 180) + padding(true)*2 : 10)}px;
`;

const TapContainer = styled.div`
  display: inline-flex;
  pointer-events: none;
  will-change: opacity;
  opacity: ${p => p.visible ? 1 : 0};
  transition: 250ms opacity;
`;

const PhotoSetterInfoHolder = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 6px;
`;

const CancelButton = styled(ButtonLikeMiniDimButton)`
  width: 100%;
  pointer-events: auto;
  background: rgba(45,45,45,1);
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0px 2px 8px 1px rgba(0,0,0,.9);
  &:hover {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.4), 0px 2px 8px 1px rgba(0,0,0,.9);
  }
  height: 26px;
  border-radius: 13px;
  margin: 0;
  ${typeface(13, 500)};
  z-index: 5;
`;

const TopLeftDiv = styled.div`
  position: absolute;
  padding: 10px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  pointer-events: none;
  width: 100vw;
  height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px);
  overflow: hidden;
`;

const TopRightDiv = styled.div`
  position: absolute;
  padding: 5px;
  top: 0;
  right: ${p => p.sidebarOpen ? 410 : 0}px;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  gap: 10px;
  pointer-events: none;
  width: calc(100vw - ${p => p.sidebarOpen ? 410 : 0}px);
  height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px);
  overflow: hidden;
`;

const KeyBg = styled.div`
  background: rgba(0,0,0,.825);
  z-index: 5;
  border-radius: ${p => p.attached ? `8px 0px 0px 0px` : (p.tiny ? `8px` : `10px`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255,255,255,.9);
  text-align: center;
  user-select: none;
  text-shadow: 0px 2px 1px rgba(0,0,0,1);
  ${p => typeface(p.mini ? 12 : 14, 400)};
  will-change: opacity;
  gap: 10px;
  padding: ${p => p.tiny ? 4 : 10}px;
`;

const TapBg = styled(KeyBg)`
  gap: 5px;
  width: 104px;
  height: 54px;
  flex-direction: row;
  ${typeface(12, 400)};
  border: 1px solid rgba(255,255,255,.2);   
  box-shadow: 0px 2px 8px 1px rgba(0,0,0,.9);
`;

const TapSetPhotoBg = styled(TapBg)`
  width: 124px;
  //color: rgba(0,0,0,1);
  //background: rgba(255,255,255,.875);
  //text-shadow: none;
  //box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .325);
  ${typeface(13, 500)};
  //-webkit-font-smoothing: auto;
  //-moz-font-smoothing: auto;
`;

const MapKeyItem = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const AstroGradient = styled.div`
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,.7);
  border-radius: 5px;
  width: ${p => p.mobile ? 40 : 60}px;
  height: 20px;
  background: linear-gradient(to right, 
   ${
        [
            [0, 0, 0],
            [.06, .0, .13],
            [.09, .0, .185],
            [.125, .0, .25],
            [.175, .0, .375],
            [.45, .0, .35],
            [.7, .5, .1],
            [.65, .65, .4],
            [1, 1, .7]
        ].map(v => `rgb(${255 * v[0]}, ${255 * v[1]}, ${255 * v[2]})`).join(",")
    }
  );
`;

const ObservationIcon = styled.div`
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0 0 0px 2px rgba(0,0,0,1);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  background: rgb(255, 60, 55);
`;

const MapTypeContainer = styled.div`
  margin: 5px;
  background: ${Theme.colors.dimmer};
  z-index: 5;
  box-shadow: 0px 2px 8px 1px rgba(0,0,0,.9), 0px 0px 0px 1px inset rgba(255,255,255,.1);
  border-radius: 10px;
  will-change: transform;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: ${p => p.mobile ? 40 : 52}px;
  width: ${p => p.mobile ? 40 : 52}px;
  justify-content: center;
  align-items: center;
  color: ${p => p.active ? Theme.colors.almostWhite : Theme.colors.nearingDim};
  text-align: center;
  user-select: none;
`;

const MapTypeContainerButton = styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 5px;
  background: ${Theme.colors.background};
  z-index: 5;
  box-shadow: 0px 2px 8px 1px rgba(0,0,0,.9), 0px 0px 0px 1px inset rgba(255,255,255,.1);
  border-radius: 10px;
  will-change: transform;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: ${p => p.mobile ? 40 : 52}px;
  width: ${p => p.mobile ? 40 : 52}px;
  justify-content: center;
  align-items: center;
  color: ${p => p.active ? Theme.colors.almostWhite : Theme.colors.nearingDim};
  text-align: center;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
`;

const MapTypeContainerButtonSmall = styled(MapTypeContainerButton)`
  height: ${p => p.mobile ? 20 : 26}px;
  width: ${p => p.mobile ? 20 : 26}px;
  border-radius: 13px;
`;

const MapTypeLabel = styled.div`
  ${typeface(12, 400)};
  display: inline;
`;

const MapTypeIcon = styled.div`
  display: inline-flex;
  height: 28px;
  justify-content: center;
  align-items: center;
`;

const CameraButton = styled(MapTypeContainerButton)`
  position: absolute;
  bottom: ${p => padding(p.mini)}px;
  right: ${p => padding(p.mini)}px;
  height: 32px;
  width: 32px;
  z-index: 5;
  padding: 24px;
  border-radius: 24px;
`;

const CenterOverlay = styled.div`
  height: 100px;
  width: 100px;
  pointer-events: none;
  user-select: none;
  color: ${Theme.colors.almostWhite};
`;

const Stacked = styled.div`
  position:absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
`;

const PopupData = styled.div`
  display: ${p => p.hidden ? "none" : "block"};
  width: 100%;
`;

const UserImageHolder = styled.div`
  height: 54px;
  width: ${p => p.minimized ? 0 : 54}px;
  opacity: ${p => p.minimized ? 0 : 1};
  pointer-events: ${p => p.minimized ? 'none' : 'auto'};
  transition: opacity 250ms, width 250ms;
`;

const UserImageButton = styled.button`
  all: unset;
  display: block;
  position: relative;
  border-radius: 27px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, .9);
  height: 54px;
  width: 54px;
  &:hover {
    img {
      filter: none !important;
    }
  }
`;

const MoveLeftHolder = styled.div`
  position: absolute;
  top: 0;
  left: -2px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
`;

const MAP_BG_LAYERS = [
    "background",
    "landcover_grass",
    "landcover_wood",
    "water",
    "water_intermittent",
    "landcover-ice-shelf",
    "landcover-glacier",
    "landcover_sand",
    "landuse",
    "landuse_overlay_national_park",
    "building",
];

const MAP_LAYERS = [
    "tunnel_railway_transit",
    "road_area_pier",
    "road_pier",
    "road_bridge_area",
    "road_path",
    "road_minor",
    "tunnel_minor",
    "tunnel_major",
    "aeroway-area",
    "aeroway-taxiway",
    "aeroway-runway",
    "road_trunk_primary",
    "road_secondary",
    "road_tertiary",
    "road_major_motorway",
    "railway-transit",
    "railway",
    "waterway-bridge-case",
    "waterway-bridge",
    "bridge_minor case",
    "bridge_major case",
    "bridge_minor",
    "bridge_major",
    "admin_sub",
    "admin_country_z0-4",
    "admin_country_z5-",
    "airport-label",
    "road_major_label",
    "place_label_other",
    "place_label_city",
    //"country_label-other",
    "country_label",
];

const POI_LAYERS = [
    "photo-poi",
];

const MapType = ({current, type, setter, label, icon, mobile}) => {
    const Icon = icon;
    let active = current === type;
    let ContainerType = active ? MapTypeContainer : MapTypeContainerButton;
    return <ContainerType active={active} onClick={active ? null : () => setter(type)} mobile={mobile}>
        <MapTypeIcon><Icon scale={mobile ? 20 : 24}/></MapTypeIcon>
        { !mobile ?
            <MapTypeLabel>{label}</MapTypeLabel>
            : null
        }
    </ContainerType>;
};

const AstroKey = ({mobile}) => {
    return <MapKeyItem>
        <AstroGradient mobile={mobile}/>
        Relative light pollution
    </MapKeyItem>;
};

const uppercase = (s) => s[0].toUpperCase() + s.slice(1);

const ObservationKey = ({type}) => {
    let finalType = type === "unranked" ? "species" : type;
    return <MapKeyItem>
        <ObservationIcon/>
        {uppercase(finalType)} sighting
    </MapKeyItem>;
};

const AttributionKey = styled.div`
  ${p => typeface(p.dim ? 9 : 11)};
  color: ${p => p.dim ? 'rgba(75,75,75,.75)' : 'rgba(160,160,160,.75)'};
  margin-right: 2px;
`;

const AttributionLink = styled.a`
  text-decoration: underline;
  text-shadow: none;
  color: ${p => p.dim ? 'rgba(75,75,75,.75)' : 'rgba(160,160,160,.75)'};
  pointer-events: auto;
  &:hover {
    color: ${p => p.dim ? 'rgba(100,100,100,.75)' : 'rgba(200,200,200,.75)'};
    text-decoration: underline;
    text-shadow: none;
  }
`;

const TopLeftPortalDiv = styled.div`
  position: absolute;
  z-index: 10000;
  pointer-events: none;
`;

const PhotoSelectorGraphic = styled.div`
  top: 4px;
  left: 4px;
  height: calc(100% - 8px);
  width: calc(100% - 8px - ${p => p.sidebarOpen ? 410 : 0}px);
  position: absolute;
  pointer-events: none;
  border-radius: 15px;
  border: 2px dashed rgba(255,255,255,.75);
  //box-shadow: inset 0 0 5px 1px rgb(0 0 0);
`;

maplibre.addProtocol('auth', async (params, callback) => {
    let result = await Api.getSimple(`${params.url.split("://")[1]}`);
    let bytes = await result.arrayBuffer();
    callback(null, bytes, null, null);
});

const BaseMap = (
    {
        appState,
        params,
        activeDataChanged,
        allowTips,
        allowQuerying,
        allowSearch,
        allowInteraction,
        allowFiltering,
        allowLayerSelection,
        allowPhotoClick,
        dimKey,
        coords,
        location,
        locationSetter,
        userAlias,
        userIdent,
        resizeId
    }
) => {

    const sawTip = () => {
        let val = localStorage.getItem("mapTipVersionSeen");
        return (val && parseInt(val) >= MAP_TIP_VERSION);
    }

    const registerSawTip = () => {
        let val = localStorage.getItem("mapTipVersionSeen");
        if (!val || val < MAP_TIP_VERSION) {
            localStorage.setItem("mapTipVersionSeen", MAP_TIP_VERSION.toString());
        }
    };

    const styleQueue = useRef([]);
    const queryLayerActive = useRef(false);
    const queryMarkerLayerActive = useRef(false);
    const singlePoiLayerActive = useRef(false);
    const singlePhotoLayerActive = useRef(false);
    const locationMarkerLayerActive = useRef(false);
    const userLayerActive = useRef(false);
    const activeSpeciesId = useRef(null);
    const loadingMarker = useRef(null);
    const mapRef = useRef(null);
    const mapElementRef = useRef(null);
    const data = useRef(observable({
        hasLoaded: false,
        mapType: "normal",
        popupData: null,
        subPopupData: null,
        queryNum: 0,
        queryActiveNum: 0,
        productInfoHidden: false,
        tapHidden: false,
        searchResultVisibility: false,
        showTip: false,
        mapElement: null,
        hoverState: new Set(),
        showPhotoSelector: false,
        settingLocationForPhoto: null,
        userImageFilename: null
    }, {
        popupData: observable.deep,
        subPopupData: observable.deep,
    }));

    let showTip = () => {
        runInAction(() => {
            data.current.showTip = true;
        });
        closePhotoSelector();
        registerSawTip();
    };

    useEffect(() => {
        (async () => {
            runInAction(() => {
                data.current.user = null;
            });
            let userResults;
            if (userAlias) {
                userResults = await Api.get(`/user/alias/${userAlias}`);
            } else {
                userResults = await Api.get(`/user/ident/${userIdent}`);
            }
            if (userResults) {
                runInAction(() => {
                    data.current.user = userResults;
                });
            }
        })();
    }, [userAlias, userIdent]);

    useEffect(() => {
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if ((!params || !params.hashtype) && !sawTip() && allowTips){
            showTip();
        }
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.resize();
        }
    }, [resizeId]);

    useEffect(() => {
        clearLocationMarkerIcon();
        if (location) {
            applyAfterStyle(() => {
                locationMarkerLayerActive.current = true;
                mapRef.current.addSource("location-marker-source", {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        'features': [{
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [location.lon, location.lat]
                            }
                        }]
                    }
                });
                mapRef.current.addLayer({
                    'id': 'location-marker-layer',
                    'type': 'symbol',
                    'source': 'location-marker-source',
                    "layout": {
                        "icon-size": 1,
                        "visibility": "visible",
                        "symbol-placement": "point",
                        "icon-image": "marker-blue",
                        "icon-allow-overlap": true,
                        "icon-anchor": "bottom"
                    },
                    "paint": {
                        "text-halo-blur": 0,
                        "text-halo-color": "rgba(0,0,0,1)",
                        "text-halo-width": 2,
                        "icon-halo-width": 2
                    }
                });
            });
        }
    }, [location]);

    const reloadUserTiles = (lastIdent) => {
        let userSegment = userIdent ? `/ident/${userIdent}` : `/alias/${userAlias}`;
        let includeSegment = lastIdent ? `&include-ident=${lastIdent}` : ``;
        mapRef.current.style.sourceCaches['user-source'].clearTiles();
        mapRef.current.style.sourceCaches['user-source']._source.tiles[0] = `auth:///user${userSegment}/map/{z}/{x}/{y}?sort-field=showcased_at&after-ident=max&after-sort-value=max&ascend=false&inclusive=true&null-is-big=false&num=300${includeSegment}`;
        mapRef.current.style.sourceCaches['user-source'].update(mapRef.current.transform);
    };

    useEffect(() => {
        if (userLayerActive.current) {
            mapRef.current.removeLayer("user-layer-small");
            mapRef.current.removeLayer("user-layer");
            mapRef.current.removeSource("user-source");
            userLayerActive.current = false;
        }
        if (userIdent || userAlias){
            let userSegment = userIdent ? `/ident/${userIdent}` : `/alias/${userAlias}`;

            applyAfterStyle(() => {
                userLayerActive.current = true;
                mapRef.current.addSource("user-source", {
                    "tilejson": "2.1.0",
                    "type": "vector",
                    "tiles": [
                        `auth:///user${userSegment}/map/{z}/{x}/{y}?sort-field=showcased_at&after-ident=max&after-sort-value=max&ascend=false&inclusive=true&null-is-big=false&num=300`
                    ],
                    "id": "user-id",
                    "format": "pbf",
                    "maxzoom": 22,
                    "minzoom": 0,
                    "vector_layers": [
                        {
                            "id": "default",
                            "maxzoom": 22,
                            "minzoom": 0,
                            "fields": {
                                "filename": "String",
                                "ident": "String"
                            }
                        }
                    ],
                });
                mapRef.current.addLayer({
                    'id': 'user-layer-small',
                    'type': 'symbol',
                    'source': 'user-source',
                    "source-layer": "default",
                    "filter": [">", ["get", "rank"], 10],
                    "layout": {
                        "icon-size": .65,
                        "symbol-placement": "point",
                        "icon-image": ["image", "grad-circle"],
                        "icon-overlap": "cooperative",
                    },
                });
                mapRef.current.addLayer({
                    'id': 'user-layer',
                    'type': 'symbol',
                    'source': 'user-source',
                    "source-layer": "default",
                    "filter": ["<=", ["get", "rank"], 10],
                    "layout": {
                        "icon-size": 1,
                        "symbol-placement": "point",
                        "icon-image": ["image", ["get", "filename"]],
                        "icon-overlap": "never",
                    },
                });
            });
        }
    }, [userIdent, userAlias]);

    const spinnerNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

    let updateMap = () => {
        let showStreetMap = true;
        let showNightLights = data.current.mapType === "astro";
        let showNightLightsGlow = data.current.mapType === "astro";
        if (mapRef.current && data.current.hasLoaded) {
            MAP_BG_LAYERS.forEach((layer) => {
                mapRef.current.setLayoutProperty(layer, "visibility", (showStreetMap && !showNightLights && !showNightLightsGlow) ? "visible" : "none");
            });
            MAP_LAYERS.forEach((layer) => {
                mapRef.current.setLayoutProperty(layer, "visibility", showStreetMap ? "visible" : "none");
            });
            /*POI_LAYERS.forEach((layer) => {
                mapRef.current.setLayoutProperty(layer, "visibility", showStreetMap ? "visible" : "none");
            });*/
            mapRef.current.setLayoutProperty("light", "visibility", showNightLights ? "visible" : "none");
            mapRef.current.setLayoutProperty("disperse", "visibility", showNightLightsGlow ? "visible" : "none");
            mapRef.current.setLayoutProperty("dem", "visibility", (showNightLights || showNightLightsGlow) ? "none" : "visible");
        }
        if (!appState.media.somewhatMini && allowQuerying) {
            if (mapRef.current) {
                mapRef.current.easeTo({padding: {right: 400, top: 0, bottom: 0}});
            }
        } else {
            if (data.current.productInfoHidden && allowQuerying) {
                if (mapRef.current) {
                    mapRef.current.easeTo({padding: {right: 0, top: 180, bottom: 0}});
                }
            } else {
                if (mapRef.current) {
                    mapRef.current.easeTo({padding: {right: 0, top: 180, bottom: 180}});
                }
            }
        }
    };

    let getPhoto = async (ident) => {
        runInAction(() => data.current.queryNum++);
        return await Api.get("/photo/" + ident);
    };

    let getFullPoi = async (id) => {
        let mount = appState.mapPreferences.productMount;
        runInAction(() => data.current.queryNum++);
        return await MapsApi.get(`/poi/id/${id}${mount ? `?mount=${appState.mapPreferences.productMount}` : ``}`);
    };

    let getFullPoiByHash = async (hash) => {
        let mount = appState.mapPreferences.productMount;
        runInAction(() => data.current.queryNum++);
        return await MapsApi.get(`/poi/hash/${hash}${mount ? `?mount=${appState.mapPreferences.productMount}` : ``}`);
    };

    let getFullSpecies = async (id) => {
        let mount = appState.mapPreferences.productMount;
        runInAction(() => data.current.queryNum++);
        return await MapsApi.get(`/species/id/${id}${mount ? `?mount=${appState.mapPreferences.productMount}` : ``}`);
    };

    let getFullQuery = async (lng, lat) => {
        let mount = appState.mapPreferences.productMount;
        let query = {
            "lng": lng,
            "lat": lat,
        };
        if (mount) {
            query.mount = mount;
        }
        runInAction(() => data.current.queryNum++);
        return await MapsApi.get("/query?" + queryString.stringify(query));
    };

    let reload = async (data) => {
        if (data.type === "poi") {
            return { type: "poi", properties: await getFullPoi(data.properties.id) };
        } else if (data.type === "species") {
            return { type: "species", properties: await getFullSpecies(data.properties.taxonId) };
        } else if (data.type === "query") {
            return { type: "query", properties: await getFullQuery(data.properties.lng, data.properties.lat) };
        } else if (data.type === "photo") {
            return { type: "photo", properties: await getPhoto(data.ident) };
        }
    };

    let applyAfterStyle = (func) => {
        if (styleQueue.current) {
            styleQueue.current.push(func);
        } else {
            func();
        }
    }

    let zoomToPhoto = async (ident, zoom, instant) => {
        let info = await getPhoto(ident);
        if (info && info.coords) {
            window.requestAnimationFrame(() => {
                if (zoom) {
                    zoomTo(info.coords.lat, info.coords.lon, 13, instant);
                }
                setSinglePhoto(info.coords.lat, info.coords.lon);
            });
        }
        return info;
    }

    let applyQuery = async (lat, lng) => {
        clearQueryIcons();
        setLoadingMarker(lat, lng);
        let oldNum = data.current.queryNum;
        let result = await getFullQuery(lng, lat);
        if (!result || oldNum + 1 !== data.current.queryNum){
            return;
        }
        runInAction(() => data.current.showTip = false);
        clearLoadingMarker();
        clearQueryIcons();
        runInAction(() => {
            data.current.tapHidden = true;
            data.current.queryActiveNum = data.current.queryNum;
            data.current.subPopupData = null;
            data.current.popupData = {type: "query", properties: result};
        });
        applyAfterStyle(() => {
            queryMarkerLayerActive.current = true;
            mapRef.current.addSource("query-marker-source", {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [lng, lat]
                        }
                    }]
                }
            });
            mapRef.current.addLayer({
                'id': 'query-marker-layer',
                'type': 'symbol',
                'source': 'query-marker-source',
                "layout": {
                    "icon-size": 1,
                    "visibility": "visible",
                    "symbol-placement": "point",
                    "icon-image": "marker-blue",
                    "icon-allow-overlap": true,
                    "icon-anchor": "bottom"
                },
                "paint": {
                    "text-halo-blur": 0,
                    "text-halo-color": "rgba(0,0,0,1)",
                    "text-halo-width": 2,
                    "icon-halo-width": 2
                }
            });
            clearQueryPoiIcons();
            mapRef.current.addSource("query-source", {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    'features': result.insights.top.map(e => {
                        e.poi.data["image"] = e.poi.image;
                        return ({
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [e.poi.lon, e.poi.lat]
                            },
                            'properties': {...e.poi.data, "custom:importance": e.poi.importance}
                        });
                    })
                }
            });
            mapRef.current.addLayer({
                'id': 'query-layer',
                'type': 'symbol',
                'source': 'query-source',
                "layout": {
                    "icon-size": 1,
                    "text-anchor": "top",
                    "text-field": "{name}",
                    "text-font": ["Noto Sans Regular"],
                    "text-max-width": 8,
                    "text-offset": [0, 0.8],
                    "icon-anchor": "center",
                    "text-size": 11,
                    "visibility": "visible",
                    "symbol-placement": "point",
                    "icon-image": "star-yellow",
                    "symbol-sort-key": ["*", -1, ["number", ["get", "custom:importance"], -1]],
                    "icon-allow-overlap": true,
                    "text-optional": true,
                },
                "paint": {
                    "text-color": "rgba(255,255,128,1.0)",
                    "icon-color": "rgba(255,255,128,1.0)",
                    "text-halo-blur": 0,
                    "text-halo-color": "rgba(0,0,0,1)",
                    "text-halo-width": 2,
                    "icon-halo-width": 2
                }
            }, "query-marker-layer");
            queryLayerActive.current = true;
        });
    }

    useEffect(() => {
        (async () => {
            if (params) {
                if (params.type === "poi") {
                    let full = await getFullPoiByHash(params.id);
                    if (!full) {
                        return;
                    }
                    runInAction(() => {
                        data.current.subPopupData = null;
                        data.current.popupData = {type: "poi", properties: full};
                    });
                    window.requestAnimationFrame(() => {
                        zoomTo(full.lat, full.lon, full.suggestedZoom, true);
                        setSinglePoi(full.lat, full.lon, full.data);
                    });
                } else if (params.type === "species") {
                    let result = await getFullSpecies(params.id);
                    if (!result) {
                        return;
                    }
                    runInAction(() => data.current.popupData = {type: "species", properties: result});
                    window.requestAnimationFrame(() => {
                        zoomTo(0, 0, 0, true);
                        showSpeciesId(result.taxonId);
                    })
                } else if (params.type === "query") {
                    let lat = params.lat;
                    let lng = params.lng;
                    window.requestAnimationFrame(() => {
                        applyQuery(lat, lng);
                        zoomTo(lat, lng, 10, true);
                    });
                } else if (params.type === "photo") {
                    let info = await zoomToPhoto(params.id, true, true);
                    runInAction(() => {
                        data.current.subPopupData = null;
                        data.current.popupData = {type: "photo", properties: info};
                    });
                }
            }
        })();
    }, []);

    let activeDataChangedFunc = useRef();
    useEffect(() => {
        activeDataChangedFunc.current = activeDataChanged;
    }, [activeDataChanged]);

    useEffect(() => autorun(() => {
        let activeData = data.current.subPopupData || data.current.popupData;
        if (activeDataChangedFunc.current) {
            activeDataChangedFunc.current(activeData);
        }
    }), []);

    useEffect(() => reaction(() => appState.mapPreferences.productMount, async () => {
        if (data.current.subPopupData) {
            let newData = await reload(data.current.subPopupData);
            if (newData.properties) {
                runInAction(() => data.current.subPopupData = newData);
            }
        }
        if (data.current.popupData) {
            let newData = await reload(data.current.popupData);
            if (newData.properties) {
                runInAction(() => data.current.popupData = newData);
            }
        }
    }));

    useEffect(() => autorun(() => {
        mapRef.current.getCanvas().style.cursor = data.current.hoverState.size > 0 ? 'pointer' : '';
    }));

    const setMapElement = (ref) => {
        if (ref && ref !== mapElementRef.current) {
            mapElementRef.current = ref;
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
            mapRef.current = new maplibre.Map({
                container: ref,
                style: `/static/mapstyle.json`,
                //center: [-119.5995131, 37.7421994], // yosemite
                center: validCoords(coords) ? [coords.lon, coords.lat] : [-73.9817044, 40.7264699], // new york city
                zoom: 13,
                maxPitch: 0,
                dragRotate: false,
                optimizeForTerrain: false,
                localIdeographFontFamily: false,
                attributionControl: false,
                fadeDuration: 150,
                interactive: allowInteraction || allowPhotoClick,
                transformRequest: (url, resourceType) => {
                    if (/^static:\/\//.test(url)) {
                        return {url: new URL(url.substr('static://'.length), Config.APP_STATIC_BASE_URL).href};
                    }
                    if (/^api:\/\//.test(url)) {
                        return {url: new URL(url.substr('api://'.length), Config.APP_CDNSTATIC_BASE_URL).href};
                    }
                }
            });
            if (!appState.media.somewhatMini && allowQuerying) {
                mapRef.current.setPadding({right: 400});
            } else {
                mapRef.current.setPadding({});
            }
            mapRef.current.touchZoomRotate.disableRotation();
            mapRef.current.on('styledata', function (e) {
                if (styleQueue.current) {
                    styleQueue.current.forEach(e => e());
                    styleQueue.current = null;
                }
            });
            mapRef.current.on('styleimagemissing', (e) =>  {
                const filename = e.id;
                mapRef.current.addImage(filename, {width: 0, height: 0, data: new Uint8Array()});
                mapRef.current.loadImage(`${Config.APP_IMAGES_BASE_URL}/${filename}`, (error, image) => {
                    if (!error) {
                        mapRef.current.removeImage(filename);
                        mapRef.current.addImage(filename, image, {pixelRatio: 2});
                    }
                });
            });

            let altUseProgram = (layerName, program, uniformGetter) => function (name, programConfiguration) {
                this.cache = this.cache || {};
                if (!this.cache[layerName]) {
                    let raster = this.cache["background"];
                    let Program = raster.constructor;
                    this.cache[layerName] = new Program(this.context, name, program, programConfiguration, uniformGetter, this._showOverdrawInspector);
                }
                return this.cache[layerName];
            }

            let layerIdRasterPrograms = {
                'disperse': altUseProgram('disperse', compile(gradientFrag, gradientVert), rasterUniforms),
                'dem': altUseProgram('dem', compile(hillshadeFrag, hillshadeVert), rasterUniforms),
                'user-layer':  altUseProgram('user-layer', compile(iconFrag, iconVert), symbolIconUniforms),
            }
            let layerIdUseAdditive = {
                'disperse': true
            };

            let useProgram = mapRef.current.painter.useProgram;
            let renderLayer = mapRef.current.painter.renderLayer;
            let additiveColorModeForRenderPass = () => ({
                blendFunction: [0x0001, 0x0001],
                blendColor: {r: 0, g: 0, b: 0, a: 0},
                mask: [true, true, true, true]
            });
            let colorModeForRenderPass = mapRef.current.painter.colorModeForRenderPass;
            mapRef.current.painter.renderLayer = function (painter, sourceCache, layer, coords) {
                if (layerIdRasterPrograms[layer.id]) {
                    painter.useProgram = layerIdRasterPrograms[layer.id];
                }
                if (layerIdUseAdditive[layer.id]) {
                    painter.colorModeForRenderPass = additiveColorModeForRenderPass;
                }
                renderLayer.call(this, painter, sourceCache, layer, coords);
                painter.useProgram = useProgram;
                painter.colorModeForRenderPass = colorModeForRenderPass;
            };

            mapRef.current.on('load', () => {
                runInAction(() => data.current.hasLoaded = true);
                updateMap();
                let userLayerMouseEnter = (layer, speculate) => (e) => {
                    if (!data.current.settingLocationForPhoto) {
                        runInAction(() => data.current.hoverState.add(layer));
                        if (speculate && e.features && e.features.length > 0) {
                            let props = e.features[0].properties;
                            runInAction(() => {
                                appState.speculate({
                                    ident: props.ident,
                                    w: props.full_w,
                                    h: props.full_h,
                                    fn: props.filename
                                });
                            });
                        }
                    }
                };
                let userLayerMouseLeave = (layer) => () => {
                    if (!data.current.settingLocationForPhoto) {
                        runInAction(() => data.current.hoverState.delete(layer));
                    }
                };
                if (allowQuerying && allowInteraction) {
                    mapRef.current.on('click', 'photo-poi', async (e) => {
                        if (!data.current.settingLocationForPhoto) {
                            clearQueryIcons();
                            if (e.features && e.features.length > 0) {
                                e.stop = true;
                                let props = e.features[0].properties;
                                let full = await getFullPoi(props["custom:id"]);
                                if (!full) {
                                    return;
                                }
                                setSinglePoi(full.lat, full.lon, full.data);
                                runInAction(() => {
                                    data.current.subPopupData = null;
                                    data.current.popupData = {type: "poi", properties: full};
                                });
                            }
                        }
                    });
                    mapRef.current.on('mouseenter', 'query-layer', () => {
                        if (!data.current.settingLocationForPhoto) {
                            runInAction(() => data.current.hoverState.add('query-layer'));
                        }
                    });
                    mapRef.current.on('mouseleave', 'query-layer', () => {
                        if (!data.current.settingLocationForPhoto) {
                            runInAction(() => data.current.hoverState.delete('query-layer'));
                        }
                    });
                    mapRef.current.on('click', 'query-layer', async (e) => {
                        if (!data.current.settingLocationForPhoto) {
                            if (e.features && e.features.length > 0) {
                                e.stop = true;
                                let props = e.features[0].properties;
                                let coords = e.features[0].geometry.coordinates;
                                setSinglePoi(coords[1], coords[0], props);
                                let full = await getFullPoi(props["custom:id"]);
                                if (!full) {
                                    return;
                                }
                                runInAction(() => {
                                    data.current.subPopupData = {type: "poi", properties: full};
                                });
                            }
                        }
                    });
                    let userLayerClick = async (e) => {
                        if (!data.current.settingLocationForPhoto) {
                            if (e.features && e.features.length > 0) {
                                e.stop = true;
                                let props = e.features[0].properties;
                                let info = await zoomToPhoto(props.ident, false);
                                runInAction(() => {
                                    data.current.subPopupData = null;
                                    data.current.popupData = {type: "photo", properties: info};
                                });
                            }
                        }
                    };
                    mapRef.current.on('click', 'user-layer', userLayerClick);
                    mapRef.current.on('click', 'user-layer-small', userLayerClick);
                    mapRef.current.on('mouseenter', 'user-layer', userLayerMouseEnter('user-layer', false));
                    mapRef.current.on('mouseleave', 'user-layer', userLayerMouseLeave('user-layer'));
                    mapRef.current.on('mouseenter', 'user-layer-small', userLayerMouseEnter('user-layer-small', false));
                    mapRef.current.on('mouseleave', 'user-layer-small', userLayerMouseLeave('user-layer-small'));
                    mapRef.current.on('mouseenter', 'photo-poi', () => {
                        if (!data.current.settingLocationForPhoto) {
                            runInAction(() => data.current.hoverState.add('photo-poi'));
                        }
                    });
                    mapRef.current.on('mouseleave', 'photo-poi', () => {
                        if (!data.current.settingLocationForPhoto) {
                            runInAction(() => data.current.hoverState.delete('photo-poi'));
                        }
                    });
                    mapRef.current.on('click', async (e) => {
                        if (!data.current.settingLocationForPhoto) {
                            runInAction(() => data.current.queryResult = null);
                            if (!e.stop && !data.current.poiPopupEvent) {
                                await applyQuery(e.lngLat.lat, e.lngLat.lng);
                            }
                        } else {
                            applyPhotoLocationSetter(e.lngLat.lat, e.lngLat.lng);
                        }
                    });
                } else if (locationSetter) {
                    runInAction(() => data.current.hoverState.add('drag'));
                    mapRef.current.on('click', async (e) => {
                        if (!e.stop) {
                            locationSetter({lat: e.lngLat.lat, lon: e.lngLat.lng});
                        }
                    });
                    mapRef.current.on('dragstart', () => {
                        runInAction(() => data.current.hoverState.delete('drag'));
                    });
                    mapRef.current.on('drag', () => {
                        runInAction(() => data.current.hoverState.delete('drag'));
                    });
                    mapRef.current.on('dragend', () => {
                        runInAction(() => data.current.hoverState.add('drag'));
                    });
                } else if (allowPhotoClick) {
                    let userLayerClick = async (e) => {
                        if (e.features && e.features.length > 0) {
                            e.stop = true;
                            let props = e.features[0].properties;
                            let url = "/photo/_" + props.ident;
                            runInAction(() => {
                                appState.underlayScrollPos = appState.viewport.windowScrollY;
                                appState.photo.initTransition = true;
                                appState.photo.fadeIn = true;
                                appState.photo.lightbox = false;
                                appState.photo.ident = props.ident;
                                appState.history.push(url, {
                                    usePhotoOverlay: true,
                                    photoIdent: props.ident,
                                    underlayLocation: toJS(appState.underlayLocation)
                                });
                            });
                        }
                    };
                    mapRef.current.on('click', 'user-layer', userLayerClick);
                    mapRef.current.on('click', 'user-layer-small', userLayerClick);
                    mapRef.current.on('mouseenter', 'user-layer', userLayerMouseEnter('user-layer', true));
                    mapRef.current.on('mouseleave', 'user-layer', userLayerMouseLeave('user-layer'));
                    mapRef.current.on('mouseenter', 'user-layer-small', userLayerMouseEnter('user-layer-small', true));
                    mapRef.current.on('mouseleave', 'user-layer-small', userLayerMouseLeave('user-layer-small'));
                }
            });
        }
    }

    useEffect(() => autorun(() => updateMap()), []);

    let clear = () => {
        runInAction(() => {
            data.current.popupData = null;
            data.current.subPopupData = null;
        });
        clearQueryIcons();
    };

    let clearQueryIcons = () => {
        clearSpeciesId();
        clearQueryPoiIcons();
        clearQueryMarkerIcon();
        clearSinglePoi();
        clearLoadingMarker();
        clearSinglePhoto();
    };

    let clearSinglePhoto = () => {
        if (singlePhotoLayerActive.current) {
            mapRef.current.removeLayer("single-photo-layer");
            mapRef.current.removeSource("single-photo-source");
            singlePhotoLayerActive.current = false;
        }
    }

    let setSinglePhoto = (lat, lon) => {
        runInAction(() => data.current.showTip = false);
        applyAfterStyle(() => {
            clearQueryIcons();
            mapRef.current.addSource("single-photo-source", {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [lon, lat]
                        },
                    }]
                }
            });
            mapRef.current.addLayer({
                'id': 'single-photo-layer',
                'type': 'symbol',
                'source': 'single-photo-source',
                "layout": {
                    "icon-size": 1,
                    "visibility": "visible",
                    "symbol-placement": "point",
                    "icon-image": "marker-blue",
                    "icon-allow-overlap": true,
                    "icon-anchor": "bottom"
                },
                "paint": {
                    "text-halo-blur": 0,
                    "text-halo-color": "rgba(0,0,0,1)",
                    "text-halo-width": 2,
                    "icon-halo-width": 2
                }
            });
            singlePhotoLayerActive.current = true;
        });
    };

    let clearSinglePoi = () => {
        if (singlePoiLayerActive.current) {
            mapRef.current.removeLayer("single-poi-layer");
            mapRef.current.removeSource("single-poi-source");
            singlePoiLayerActive.current = false;
        }
    }

    let setSinglePoi = (lat, lon, poi) => {
        runInAction(() => data.current.showTip = false);
        applyAfterStyle(() => {
            clearSinglePoi();
            mapRef.current.addSource("single-poi-source", {
                type: 'geojson',
                data: {
                    "type": "FeatureCollection",
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': [lon, lat]
                        },
                        'properties': poi
                    }]
                }
            });
            mapRef.current.addLayer({
                'id': 'single-poi-layer',
                'type': 'symbol',
                'source': 'single-poi-source',
                "layout": {
                    "icon-size": 1,
                    "text-anchor": "top",
                    "text-field": "{name}",
                    "text-font": ["Noto Sans Regular"],
                    "text-max-width": 8,
                    "text-offset": [0, 0.8],
                    "icon-anchor": "center",
                    "text-size": 11,
                    "visibility": "visible",
                    "symbol-placement": "point",
                    "icon-image": "star-yellow",
                    "icon-allow-overlap": true,
                    "text-optional": true,
                },
                "paint": {
                    "text-color": "rgba(255,255,128,1.0)",
                    "icon-color": "rgba(255,255,128,1.0)",
                    "text-halo-blur": 0,
                    "text-halo-color": "rgba(0,0,0,1)",
                    "text-halo-width": 2,
                    "icon-halo-width": 2
                }
            });
            singlePoiLayerActive.current = true;
        });
    };

    let setLoadingMarker = (lat, lon) => {
        clearLoadingMarker();
        loadingMarker.current = new maplibre.Marker({
            draggable: false,
            element: spinnerNode.element,
        }).setLngLat([lon, lat]).addTo(mapRef.current);
    };

    let clearLoadingMarker = () => {
        if (loadingMarker.current) {
            loadingMarker.current.remove();
            loadingMarker.current = null;
        }
    };

    let clearLocationMarkerIcon = () => {
        if (locationMarkerLayerActive.current) {
            mapRef.current.removeLayer("location-marker-layer");
            mapRef.current.removeSource("location-marker-source");
            locationMarkerLayerActive.current = false;
        }
    }

    let clearQueryPoiIcons = () => {
        if (queryLayerActive.current) {
            mapRef.current.removeLayer("query-layer");
            mapRef.current.removeSource("query-source");
            queryLayerActive.current = false;
        }
    }

    let clearQueryMarkerIcon = () => {
        if (queryMarkerLayerActive.current) {
            mapRef.current.removeLayer("query-marker-layer");
            mapRef.current.removeSource("query-marker-source");
            queryMarkerLayerActive.current = false;
        }
    };

    let showSpeciesId = (id) => {
        runInAction(() => data.current.showTip = false);
        applyAfterStyle(() => {
            if (activeSpeciesId.current !== id) {
                mapRef.current.addSource("species-source", {
                    "tilejson": "2.1.0",
                    "type": "vector",
                    "tiles": [
                        `api://maps/species/${id}/{z}/{x}/{y}`
                    ],
                    "id": "species-id",
                    "format": "pbf",
                    "maxzoom": 18,
                    "minzoom": 0,
                    "vector_layers": [
                        {
                            "id": "default",
                            "maxzoom": 18,
                            "minzoom": 0,
                            "fields": []
                        }
                    ],
                });
                mapRef.current.addLayer({
                    'id': 'species-layer',
                    'type': 'symbol',
                    'source': "species-source",
                    "source-layer": "default",
                    "layout": {
                        "icon-size": 1,
                        "visibility": "visible",
                        "symbol-placement": "point",
                        "icon-image": "circle-red",
                        "icon-allow-overlap": true,
                        "icon-anchor": "center"
                    },
                    "paint": {
                        "text-halo-blur": 0,
                        "text-halo-color": "rgba(0,0,0,1)",
                        "text-halo-width": 2,
                        "icon-halo-width": 2
                    }
                });
                activeSpeciesId.current = id;
            }
        });
    }
    let clearSpeciesId = () => {
        if (activeSpeciesId.current !== null) {
            mapRef.current.removeLayer("species-layer");
            mapRef.current.removeSource("species-source");
            activeSpeciesId.current = null;
        }
    };

    let back = () => {
        runInAction(() => data.current.subPopupData = null);
        clearSinglePoi();
        clearSpeciesId();
    };

    let clickSearchResult = async (searchResult) => {
        clear();
        runInAction(() => data.current.showTip = false);
        if (searchResult.type === "poi") {
            let result = await getFullPoi(searchResult.id);
            if (!result) {
                return;
            }
            if (allowQuerying) {
                runInAction(() => data.current.popupData = {type: "poi", properties: result});
            }
            window.requestAnimationFrame(() => {
                zoomTo(result.lat, result.lon, result.suggestedZoom);
                setSinglePoi(result.lat, result.lon, result.data);
            });
        } else if (searchResult.type === "species") {
            let result = await getFullSpecies(searchResult.id);
            if (!result) {
                return;
            }
            if (allowQuerying) {
                showSpeciesId(result.taxonId);
                runInAction(() => data.current.popupData = {type: "species", properties: result});
            }
        }
    };

    useEffect(() => {
        if (validCoords(coords)) {
            zoomTo(coords.lat, coords.lon, 11, true);
        }
    }, [coords]);

    let zoomTo = (lat, lon, zoom, instant) => {
        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [lon, lat],
                zoom: zoom,
                screenSpeed: instant ? Number.MAX_VALUE : 2,
            });
        }
    };

    let mapTypeSetter = (type) => {
        runInAction(() => data.current.mapType = type);
    };

    let onResultVisibilityChange = (visible) => {
        runInAction(() => {
            data.current.searchResultVisibility = visible;
            if (visible) {
                data.current.showTip = false;
            }
        });
    }

    let openProductInfo = () => runInAction(() => data.current.productInfoHidden = false);
    let closeProductInfo = () => runInAction(() => data.current.productInfoHidden = true);

    let openPhotoSelector = () => {
        runInAction(() => {
            data.current.showPhotoSelector = true;
            data.current.showTip = false;
        });
    };
    let closePhotoSelector = () => {
        runInAction(() => {
            data.current.showPhotoSelector = false;
        });
        cancelLocationSetter();
    };

    let openLocationSetterFor = (ident) => {
        runInAction(() => {
            data.current.settingLocationForPhoto = ident;
            data.current.hoverState.add('photo-setter');
        });
    }
    let cancelLocationSetter = () => {
        runInAction(() => {
            data.current.settingLocationForPhoto = null;
            data.current.hoverState.delete('photo-setter');
        });
    }
    let applyPhotoLocationSetter = async (lat, lon) => {
        if (data.current.settingLocationForPhoto) {
            let info = await Api.post("/photo/" + data.current.settingLocationForPhoto + "/edit", {coords: {lat, lon}});
            broadcastToListeners(`photo:${info.ident}`, info);
            reloadUserTiles(info.ident);
            cancelLocationSetter();
        }
    }
    let clearPhotoLocation = async (ident) => {
        let info = await Api.post("/photo/" + ident + "/edit", {coords: {lat: null, lon: null}});
        broadcastToListeners(`photo:${info.ident}`, info);
        reloadUserTiles();
    };

    let clickUserImage = () => {
        runInAction(() => {
            appState.history.push(userAlias ? `/${userAlias}` : `/user/_${userIdent}`)
        });
    };

    let viewingUser = !!userIdent || !!userAlias;

    let popupData = data.current.subPopupData || data.current.popupData;
    let showInfoInCorner = popupData && popupData.type === "photo";
    let showingSpecies = popupData?.type === "species";
    let speciesTaxonRank = showingSpecies ? popupData?.properties?.taxonRank : "";
    let isAstro = data.current.mapType === "astro";
    let showingAstroKey = (isAstro && !(appState.media.somewhatMini && !!data.current.popupData));
    let showingPopup = data.current.popupData || data.current.subPopupData;
    let popupPriority = appState.media.mobile && (showingPopup || showPhotoSelector);
    let tinyKey = !showingAstroKey && !showingSpecies;

    let showPhotoSelector = viewingUser && data.current.showPhotoSelector && !showingPopup && allowFiltering;
    let showPhotoSelectorIcon = viewingUser && !data.current.showPhotoSelector && !showingPopup && allowFiltering;
    let showTipButton = !data.current.showTip && !data.current.searchResultVisibility && !showingPopup && allowTips;
    let showLayerIcons = (!popupPriority && !showPhotoSelector) || !appState.media.mobile;
    let showQueryHint = !showingPopup && !data.current.tapHidden && !data.current.searchResultVisibility && !(viewingUser && appState.media.mobile);

    let attachedKey = !showingAstroKey && !showingSpecies && ((!showingPopup && !showPhotoSelector) || showInfoInCorner);
    let sidebarOpen = (!!data.current.popupData || showPhotoSelector) && !showInfoInCorner;

    let pageRef = useRef();

    return <ExpandDiv>
        {data.current.popupData ? <>
            <QueryInfo skinny={appState.media.skinny} mini={appState.media.somewhatMini} inCorner={showInfoInCorner}>
                {data.current.subPopupData ? <BackButton onClick={back}><LeftIconLightShadowed scale={16}/></BackButton> : null}
                <CloseButton onClick={clear}><XIconLightShadowed scale={14}/></CloseButton>
                { data.current.popupData ?
                    <PopupData hidden={!!data.current.subPopupData}>
                        { data.current.popupData.type === "poi" ?
                            <PoiDisplay poiData={toJS(data.current.popupData)} zoomFunction={zoomTo}/>
                            : null }
                        { data.current.popupData.type === "species" ?
                            <SpeciesDisplay speciesData={toJS(data.current.popupData)}/>
                            : null }
                        { data.current.popupData.type === "query" ?
                            <QueryDisplay
                                queryNum={data.current.queryActiveNum}
                                queryData={toJS(data.current.popupData)}
                                onClickPoi={
                                    async (poi) => {
                                        let properties = await getFullPoi(poi.id);
                                        if (!properties) {
                                            return;
                                        }
                                        setSinglePoi(properties.lat, properties.lon, properties.data);
                                        runInAction(() => data.current.subPopupData = {type: "poi", properties: properties})
                                    }
                                }
                                onClickSpecies={
                                    async (species) => {
                                        let properties = await getFullSpecies(species.taxonId);
                                        if (!properties){
                                            return;
                                        }
                                        showSpeciesId(species.taxonId);
                                        runInAction(() => data.current.subPopupData = {type: "species", properties: properties})
                                    }
                                }
                            />
                            : null }
                        { data.current.popupData.type === "photo" ?
                            <PhotoDisplay info={toJS(data.current.popupData.properties)} zoomFunction={zoomTo}/>
                            : null }
                    </PopupData> : null }
                {data.current.subPopupData
                    ?
                    <PopupData>
                        { data.current.subPopupData.type === "poi" ?
                            <PoiDisplay poiData={toJS(data.current.subPopupData)} zoomFunction={zoomTo}/>
                            : null }
                        { data.current.subPopupData.type === "species" ?
                            <SpeciesDisplay speciesData={toJS(data.current.subPopupData)}/>
                            : null }
                    </PopupData>
                    :
                    null
                }
            </QueryInfo>
            {!showInfoInCorner ?
                ((data.current.productInfoHidden && appState.media.somewhatMini) ?
                    <CameraButton mini={appState.media.somewhatMini} onClick={openProductInfo}>
                        <CameraIconLightShadowed scale={24} />
                    </CameraButton>
                    :
                    <ProductInfo skinny={appState.media.skinny} mini={appState.media.somewhatMini}>
                        {appState.media.somewhatMini ?
                            <CloseButton onClick={closeProductInfo}><DownIconLightShadowed scale={14} /></CloseButton>
                            : null
                        }
                        <ProductInfoDisplay data={toJS(data.current.subPopupData ?? data.current.popupData)} />
                    </ProductInfo>
                ) : null
            }
        </> : null}
        {(userIdent || userAlias ?
            <MapPhotoBox hidden={!showPhotoSelector} skinny={appState.media.skinny} mini={appState.media.somewhatMini}>
                <PopupData hidden={!showPhotoSelector}>
                    <MapPhotoSelector
                        hidden={!showPhotoSelector}
                        userIdent={userIdent}
                        userAlias={userAlias}
                        mobile={appState.media.mobile}
                        pageRef={pageRef}
                        zoomToPhoto={zoomToPhoto}
                        openLocationSetterFor={openLocationSetterFor}
                        clearPhotoLocation={clearPhotoLocation}
                        settingLocationForPhoto={data.current.settingLocationForPhoto}
                        cancelLocationSetter={cancelLocationSetter}
                    />
                </PopupData>
                <CloseButton onClick={closePhotoSelector}><XIconLightShadowed scale={14}/></CloseButton>
            </MapPhotoBox>
            : null
        )}
        <MapDiv ref={setMapElement}/>
        {
            data.current.settingLocationForPhoto ? <PhotoSelectorGraphic sidebarOpen={!appState.media.mobile}/> : null
        }
        {showLayerIcons ?
            <LowerLeftContainer>
                {allowLayerSelection ?
                    <>
                        <MapType current={data.current.mapType} setter={mapTypeSetter} type={"normal"} label={"Normal"}
                                 icon={LandscapeIconLightShadowed} mobile={appState.media.mobile} />
                        <MapType current={data.current.mapType} setter={mapTypeSetter} type={"astro"} label={"Astro"}
                                 icon={AstroIconLightShadowed} mobile={appState.media.mobile} />
                    </>
                    : null
                }
                {
                    showTipButton ?
                        <MapTypeContainerButtonSmall
                            onClick={showTip}
                        >
                            𝓲
                        </MapTypeContainerButtonSmall>
                        : null
                }
            </LowerLeftContainer>
            : null
        }
        <LowerRightContainer attached={attachedKey} tiny={tinyKey} mini={appState.media.somewhatMini} sidebarOpen={sidebarOpen} productsHidden={data.current.productInfoHidden} bottom={showPhotoSelector ? 320 : 180}>
            <KeyBg attached={attachedKey} tiny={tinyKey} mini={appState.media.somewhatMini}>
                {showingAstroKey ? <AstroKey mobile={appState.media.mobile}/> : null}
                {showingSpecies ? <ObservationKey type={speciesTaxonRank}/> : null}
                <AttributionKey dim={dimKey}>© <AttributionLink dim={dimKey} href={"https://www.openstreetmap.org/copyright"} target={"_blank"}>OpenStreetMap</AttributionLink> contributors</AttributionKey>
            </KeyBg>
        </LowerRightContainer>
        {allowQuerying || (!popupPriority && allowSearch) ?
            <TopLeftDiv>
                {viewingUser ?
                    <UserImageHolder minimized={data.current.searchResultVisibility}>
                        <UserImageButton onClick={clickUserImage}>
                            <UserImage
                                filename={data.current.user ? data.current.user.imageFilename : null}
                                scale={54}
                                blackAndWhite={true}
                            />
                        </UserImageButton>
                    </UserImageHolder>
                    : null
                }
                {(!popupPriority && allowSearch) ?
                    <SearchContainer>
                        <SearchBar
                            allowSpecies={allowQuerying}
                            mobile={appState.media.mobile}
                            onSelect={clickSearchResult}
                            onResultVisibilityChange={onResultVisibilityChange}
                            width={data.current.searchResultVisibility ? "400px" : `min(260px, calc(100vw - ${data.current.tapHidden ? 0 : 140}px))`}
                        />
                    </SearchContainer>
                    : null
                }
                {(allowQuerying && !data.current.settingLocationForPhoto && !viewingUser) ?
                    <TapContainer visible={showQueryHint}>
                        <TapBg>
                            <TapIconLightShadowed scale={26} />
                            <div>Tap Map to Query</div>
                        </TapBg>
                    </TapContainer>
                    : null
                }
            </TopLeftDiv>
            : null
        }
        {
            (showPhotoSelectorIcon || data.current.settingLocationForPhoto) ?
                <TopRightDiv sidebarOpen={data.current.showPhotoSelector && !appState.media.mobile}>
                    {data.current.settingLocationForPhoto ?
                        <PhotoSetterInfoHolder>
                            <TapContainer visible={true}>
                                <TapSetPhotoBg>
                                    <TapIconLightShadowed shadowAmt={0} scale={26} />
                                    <div>Set Photo Location</div>
                                </TapSetPhotoBg>
                            </TapContainer>
                            <CancelButton onClick={cancelLocationSetter}>Cancel</CancelButton>
                        </PhotoSetterInfoHolder>
                        :
                        <MapTypeContainerButton onClick={openPhotoSelector} mobile={appState.media.mobile}>
                            <MapTypeIcon><GalleryIconLightShadowed
                                scale={appState.media.mobile ? 16 : 18} /></MapTypeIcon>
                            {!appState.media.mobile ?
                                <MapTypeLabel>Photos</MapTypeLabel>
                                : null
                            }
                        </MapTypeContainerButton>
                    }
                </TopRightDiv>
                : null
        }
        <portals.InPortal node={spinnerNode}>
            <CenterOverlay>
                <Stacked>
                    <Spinner/>
                </Stacked>
                <Stacked>
                    <PlusIconLightShadowed scale={12}/>
                </Stacked>
            </CenterOverlay>
        </portals.InPortal>
        {
            data.current.showTip ?
                <TipInfoBox skinny={appState.media.skinny} mini={appState.media.somewhatMini}>
                    <MapTipScreen onHide={() => runInAction(() => data.current.showTip = false)}/>
                </TipInfoBox>
                : null
        }
        <TopLeftPortalDiv ref={pageRef}/>
    </ExpandDiv>;
}

export default inject("appState")(observer(BaseMap));
import * as chrono from 'chrono-node';
import wholeMonth from './ChronoWholeMonthRefiner'
import wholeYear from './ChronoWholeYearRefiner'
import yearParser from "./ChronoYearParser"
import weekParser from "./ChronoWeekParser"
import pastDate from './ChronoPastDateRefiner'
import lastParser from "./ChronoLastParser"

const parser = chrono.casual.clone();

parser.parsers.unshift(yearParser);
parser.parsers.unshift(weekParser);
parser.parsers.unshift(lastParser);

parser.refiners.pop(); // get rid of UnlikelyFormatRefiner
parser.refiners.push(pastDate);
parser.refiners.push(wholeYear);
parser.refiners.push(wholeMonth);

export default parser;
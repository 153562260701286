import React from 'react';
import styled from '@emotion/styled'
import Theme from "../styles/Theme";
import BreadcrumbSeparator from "./BreadcrumbSeparator";
import {typeface} from "../styles/Typeface";
import {inject, observer} from "mobx-react";

const Holder = styled.div`
  display: block;
  ${p => p.mobile ? typeface(20, 400) : typeface(24, 400)};
  color: ${Theme.colors.almostDim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.35);
  width: 100%;
  margin: 0 auto 0;
  padding: ${p => p.noTopPadding ? 0 : 34}px 0 ${p => p.noBottomPadding ? 0 : 14}px;
  text-align: center;
`;

const BreadcrumbSeparatorStyled = styled(BreadcrumbSeparator)`
  color: ${p => p.color ? p.color : Theme.colors.dim};
`;

export default inject("appState")(observer((props) => {
    let content = [];
    let i = 0;
    let children = Array.isArray(props.children) ? props.children : [props.children];
    children.filter(e => e !== null).forEach(c => {
        content.push(c);
        content.push(<BreadcrumbSeparatorStyled slim={props.sepSlim} color={props.sepColor} mobile={props.appState.media.mobile} key={"sep-"+i}/>);
        i++;
    });
    content.pop();
    return <Holder mobile={props.appState.media.mobile} noTopPadding={props.noTopPadding} noBottomPadding={props.noBottomPadding}>
        {content}
    </Holder>;
}));

import styled from '@emotion/styled';
import { css } from '@emotion/react'
import Theme from "./Theme";

export const Transparent = css`
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  text-shadow: inherit;
  background: none;
  padding: 6px 6px 5px;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,.15);
  border-radius: 0;
  cursor: pointer;
  outline: none;
  color: ${Theme.colors.almostWhite};
  overflow: hidden;
  
  transition-property: border-bottom-color, border-bottom-width, padding;
  transition-duration: 250ms;
  
  &:focus {
    border-bottom: 1px solid rgba(255,255,255,.6);
    cursor: text;
    outline: none !important;
    background: none;
  }
  
  &::placeholder {
    font-style: normal;
    font-weight: 400;
    color: rgba(255,255,255,.15);
    text-shadow: 0px 2px 1px rgba(0,0,0,.15);
    text-align: center;
    transition-property: color;
    transition-duration: 500ms;
  }
  
  &:focus::placeholder {
    color: rgba(255,255,255,.075);
    text-shadow: 0px 2px 1px rgba(0,0,0,.075);
  }
  
  &:focus::placeholder {
    text-decoration: none;
  }
`;

export const TransparentInput = styled.input`
  ${Transparent};
`;
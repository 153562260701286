import moment from 'moment'

export default {refine: refine};

function refine(context, results) {

    if (context.option['forwardDate']) {
        return results;
    }

    results.forEach(function(result) {

        let refMoment = moment(result.refDate);

        if (!result.start.isCertain('year') && result.start.isCertain('month') && refMoment.isBefore(moment(result.start.date()))) {
            result.start.imply('year', result.start.get('year') - 1);
        }

        if (result.end && !result.end.isCertain('year') && result.end.isCertain('month') && refMoment.isBefore(moment(result.end.date()))) {
            result.end.imply('year', result.end.get('year') - 1);
        }

        if (!result.start.isCertain('month') && !result.start.isCertain('year') && result.start.isCertain('weekday') &&
            refMoment.isBefore(moment(result.start.date()))
        ) {
            if (refMoment.day() < result.start.get('weekday')) {
                refMoment.day(result.start.get('weekday') - 7);
            } else {
                refMoment.day(result.start.get('weekday'));
            }

            result.start.imply('day', refMoment.date());
            result.start.imply('month', refMoment.month() + 1);
            result.start.imply('year', refMoment.year());
        }

        if (result.end && !result.end.isCertain('month') && !result.end.isCertain('year') && result.end.isCertain('weekday') &&
            refMoment.isBefore(moment(result.end.date()))
        ) {
            if (refMoment.day() < result.end.get('weekday')) {
                refMoment.day(result.end.get('weekday') - 7);
            } else {
                refMoment.day(result.end.get('weekday'));
            }

            result.end.imply('day', refMoment.date());
            result.end.imply('month', refMoment.month() + 1);
            result.end.imply('year', refMoment.year());
        }

        if(result.end && result.end.date() < result.start.date()){
            let t = result.start;
            result.start = result.end;
            result.end = t;
        }
    });

    return results;
}
import React from 'react';
import { ClassNames, css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from "../styles/Theme";
import {Transparent, TransparentInput} from "../styles/Input";
import {typeface} from "../styles/Typeface";
import ReactTagsFixed from "./reacttagsfixed/ReactTagsFixed";

const Holder = styled.div`
  //display: inline-flex;
  //justify-content: center;
`;

const tagsClass = (props) => css`
  position: relative;
  display: inline;
  //vertical-align: middle;
`;
const tagInputClass = (props, canAdd) => css`
  width: ${ props.styleAsFilter ? "222px" : props.inputWidth };
  border-radius: 3px;
  display: ${ canAdd ? "inline-block" : "none" };
  vertical-align: middle;
  margin: 3px 3px;
`;
const tagInputFieldClass = (props) => css`
  ${Transparent};
  height: 30px;
  ${typeface(props.fontSize || 16, 400)};
  width: ${ props.styleAsFilter ? "222px" : props.inputWidth };
  &::placeholder {
    text-align: center;
  }
  color: ${ Theme.colors.almostWhite };
  ${ props.styleAsFilter ? "transform: translateZ(0);" : "" }
`;
const selectedClass = (props) => css`
  ${typeface(props.fontSize || 16, 400)};
  display: inline;
  border-radius: 5px;
  color: ${ Theme.colors.almostWhite };
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;
const tagClass = (props) => css`
  cursor: ${props.editable ? "move" : "pointer"};
  background: ${ props.styleAsFilter ? Theme.colors.kindaDim : "none"};
  display: inline-block;
  //padding: ${ props.styleAsFilter ? "4px 4px 4px 10px" : "4px 10px"};
  padding-left: 10px;
  padding-right: ${ props.editable ? "0px" : "10px"};
  //margin: 3px 3px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 0 1px rgba(255,255,255,.1) inset, 0 1px 4px 0 rgba(0,0,0,.75);
  color: ${ props.styleAsFilter ?  Theme.colors.backgroundDarkest : Theme.colors.almostWhite};
  
  ${typeface(14, 400)};
  vertical-align: middle;
  height: 30px;
  line-height: 30px;
  
  ${ !props.styleAsFilter ? `
    &:hover, &.focus-visible  {
      color: ${Theme.colors.almostWhite};
      box-shadow: 0 0 0 1px rgba(255,255,255,.25) inset, 0 1px 4px 0 rgba(0,0,0,.75);
    }
  ` : ``};
  
  ${ props.tagStyle ? props.tagStyle : "" }
  ${ props.styleAsFilter ?  (props.filterTagStyle ? props.filterTagStyle : "") : (props.nonFilterTagStyle ? props.nonFilterTagStyle : "") };
`;
const removeClass = (props) => css`
  display: inline-block;
  cursor: pointer;
  color: ${ props.styleAsFilter ?  Theme.colors.dimmer : Theme.colors.kindaDim} !important;
  
  border-radius: 4px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;

  border: none;
  background: none;
  padding: 0;
  
  &:hover {
    color: ${ props.styleAsFilter ?  "black" : "white"} !important;
    background: ${ props.styleAsFilter ?  "rgba(255,255,255,.5)" : "none"} !important;
  }
`;
const suggestionsClass = (props) => css`
  position: absolute;
  top: 100%;
  background: ${Theme.colors.background};
  border-radius: 10px;
  box-shadow: 0px 0px 0px 1px rgba(255,255,255,.1) inset, 0px 0px 8px 0px rgba(0,0,0,.75);
  overflow: hidden;
  color: ${ Theme.colors.kindaDim };
  text-align: left;
  z-index: 10;
  ul {
    list-style-type: none;
    width: 222px;
    padding: 0px;
    margin: 0px;
  }
  li {
    padding: 5px 5px;
    margin: 0;
    ${props.suggestionStyle ? props.suggestionStyle : ""}
  }
  li mark {
    background: none;
    color: ${ Theme.colors.kindaDim };
    padding: 0px;
    text-decoration: none;
  }
`;
const activeSuggestionClass = (props) => css`
  background: rgba(255,255,255,.05);
  cursor: pointer;
`;

class KeywordList extends React.Component {

    constructor(props) {
        super(props);

        this.requestNum = 0;
        this.state = {suggestions: [], tags: this.props.tags.map(v => ({id: v, text: v}))};
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tags.length !== this.props.tags.length || !prevProps.tags.every((value, index) => value === this.props.tags[index])) {
            this.setState({tags: this.props.tags.map(v => ({id: v, text: v}))});
        }
    };

    handleDelete = (i) => {
        let newTags = this.state.tags.filter((tag, index) => index !== i);

        if (this.props.tagsOnChange) {
            this.props.tagsOnChange(newTags.map(v => v.text));
        }
    };

    canAdd = () => !this.props.maxItems || this.state.tags.length < this.props.maxItems;

    handleAddition = async (tag) => {
        if(this.canAdd()) {
            if (this.props.textModifier) {
                tag.text = this.props.textModifier(tag.text);
            }
            if (this.props.allowTag) {
                if (!(await this.props.allowTag(tag.text))) {
                    return
                }
            }
            let newTags = [...this.state.tags, tag];
            if (this.props.tagsOnChange) {
                this.props.tagsOnChange(newTags.map(v => v.text));
            }
        }
    };

    handleTagClick = (index) => {
        if (!this.props.editable && this.props.onTagClick) {
            this.props.onTagClick(this.props.tags[index]);
        }
    };

    handleDrag = (tag, currPos, newPos) => {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        if (this.props.tagsOnChange) {
            this.props.tagsOnChange(newTags.map(v => v.text));
        }
    };

    suggestions = async (text) => {
        if(this.props.textModifier){
            text = this.props.textModifier(text);
        }
        if (this.props.suggestions) {
            return this.props.suggestions(text);
        } else {
            return [];
        }
    };

    handleInputChange = async (input) => {
        if(input !== null && input !== "") {
            this.requestNum++;
            let lastRequestNum = this.requestNum;
            let suggestions = await this.suggestions(input);
            if (this.requestNum === lastRequestNum){
                this.setState({suggestions: suggestions});
            }
        } else {
            this.setState({suggestions: []})
        }
    };

    render = () => {
        return (
                <ClassNames>
                    {({ css, cx }) => (
                        <ReactTagsFixed
                            tags={this.state.tags}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            handleDrag={this.handleDrag}
                            handleTagClick={this.handleTagClick}
                            handleInputChange={this.handleInputChange}
                            readOnly={!this.props.editable}
                            suggestions={this.state.suggestions}
                            handleFilterSuggestions={(i, a) => a}
                            minQueryLength={1}
                            placeholder={this.props.placeholder}
                            autofocus={false}
                            inputComponent={this.props.inputComponent || TransparentInput}
                            inputData={this.props.inputData}
                            useTagAdded={this.props.useTagAdded}
                            autoCorrect={this.props.autoCorrect}
                            autoCapitalize={this.props.autoCapitalize}
                            autoComplete={this.props.autoComplete}
                            clickable={!!this.props.onTagClick}

                            classNames = {{
                                tags: css(tagsClass(this.props)),
                                tagInput: css(tagInputClass(this.props, this.canAdd())),
                                tagInputField: css(tagInputFieldClass(this.props)),
                                selected: css(selectedClass(this.props)),
                                tag: css(tagClass(this.props)),
                                remove: css(removeClass(this.props)),
                                suggestions: css(suggestionsClass(this.props)),
                                activeSuggestion: css(activeSuggestionClass(this.props))
                            }}
                        />
                    )}
                </ClassNames>
        );
    };
}

export default KeywordList;
import React, {useState, useEffect} from 'react'
import MarkdownIt from 'markdown-it'
import MarkdownItContainer from 'markdown-it-container'
import MarkdownItAnchor from 'markdown-it-anchor'
import styled from '@emotion/styled'
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";

let hljsPromise = {};
let hljsObj = {};

const loadHljs = async () => {
    let hljsCore = (await import('highlight.js/lib/core')).default;
    let xml = (await import('highlight.js/lib/languages/xml')).default;
    await import('highlight.js/styles/darcula.css');
    hljsCore.registerLanguage('html', xml);
    return hljsCore;
};

const getHljs = async () => {
    if (!hljsPromise.value){
        hljsPromise.value = loadHljs();
    }
    if (!hljsObj.value) {
        hljsObj.value = await hljsPromise.value;
    }
    return hljsObj.value;
}

const getMd = (opts) => {
    let md = new MarkdownIt({
        linkify: true,
        breaks: true,
        ...opts
    })
        .use(MarkdownItContainer, "center")
        .use(MarkdownItContainer, "right")
        .use(MarkdownItContainer, "small-box", {
            validate: function(params) {
                return params.trim() === "small-box";
            },
            render: function (tokens, idx, options, env, self) {
                if (tokens[idx].nesting === 1) {
                  return '<div class="small-box-outer"><div class="small-box-inner">';
                } else {
                  return '</div></div>';
                }
              }
        })
        .use(MarkdownItContainer, "inset-box", {
            validate: function(params) {
                return params.trim() === "inset-box";
            },
            render: function (tokens, idx, options, env, self) {
                if (tokens[idx].nesting === 1) {
                  return '<div class="inset-box-outer"><div class="inset-box-inner">';
                } else {
                  return '</div></div>';
                }
              }
        });

    let defaultRender = md.renderer.rules.link_open || ((tokens, idx, options, env, self) => {
        return self.renderToken(tokens, idx, options);
    });

    const addHttp = (url) => {
        if (!/^\w+\:\/\//.test(url)) {
            url = "http://" + url;
        }
        return url;
    };

    md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        if (options.forceNewTab) {
            let aIndex = tokens[idx].attrIndex('target');
            if (aIndex < 0) {
                tokens[idx].attrPush(['target', '_blank']);
            } else {
                tokens[idx].attrs[aIndex][1] = '_blank';
            }
            let relIndex = tokens[idx].attrIndex('rel');
            if (relIndex < 0) {
                tokens[idx].attrPush(['rel', 'noopener']);
            } else {
                tokens[idx].attrs[relIndex][1] = 'noopener';
            }
        } else {
            if (env.linkOpener){
                let hIndex = tokens[idx].attrIndex('href');
                let url;
                if (hIndex >= 0) {
                    url = tokens[idx].attrs[hIndex][1];
                    tokens[idx].attrs.splice(hIndex, 1);
                    tokens[idx].attrs.push(["x-link-data", url]);
                }
                tokens[idx].tag = "button";
            }
        }
        if (options.preventRelativeLinks) {
            let hIndex = tokens[idx].attrIndex('href');
            if (hIndex >= 0) {
                tokens[idx].attrs[hIndex][1] = addHttp(tokens[idx].attrs[hIndex][1]);
            }
        }
        return defaultRender(tokens, idx, options, env, self);
    };
    md.renderer.rules.link_close = (tokens, idx, options, env, self) => {
        if (!options.forceNewTab) {
            if (env.linkOpener){
                tokens[idx].tag = "button";
            }
        }
        return defaultRender(tokens, idx, options, env, self);
    };

    return md;
}

const MarkdownStyle = styled.div`
  
  ${ p => p.noBottomMargin ? `
  > *:last-child {
    margin-bottom: 0;
  }
  > *:last-child > *:last-child {
    margin-bottom: 0;
  }
  > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }
  > *:last-child > *:last-child > *:last-child > *:last-child {
    margin-bottom: 0;
  }
  ` : null }
  
  a {
    font-weight: 700;
    display: inline;
    
    &::after {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='10 9 30 29' fill='%23666' stroke='%23666'><path d='M38.288 10.297l1.414 1.415-14.99 14.99-1.414-1.414z'/><path d='M40 20h-2v-8h-8v-2h10z'/><path d='M35 38H15c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h11v2H15c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V24h2v11c0 1.7-1.3 3-3 3z'/></svg>");
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: 2px;
      filter: drop-shadow(0 1px 1px rgba(0,0,0,.8));
    }
    
    &:hover::after {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='10 9 30 29' fill='%23CCC' stroke='%23CCC'><path d='M38.288 10.297l1.414 1.415-14.99 14.99-1.414-1.414z'/><path d='M40 20h-2v-8h-8v-2h10z'/><path d='M35 38H15c-1.7 0-3-1.3-3-3V15c0-1.7 1.3-3 3-3h11v2H15c-.6 0-1 .4-1 1v20c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V24h2v11c0 1.7-1.3 3-3 3z'/></svg>");
    }
  }
  
  h1 {
    ${typeface(24, 600, .0035)};
    text-shadow: 0 2px 1px rgba(0,0,0,1);
  }
  
  h2 {
    ${typeface(20, 600, .002)};
    text-shadow: 0 2px 1px rgba(0,0,0,.8);
  }
  
  h3 {
    ${typeface(18, 600, .00125)};
    text-shadow: 0 2px 1px rgba(0,0,0,.8);
  }
  
  h4 {
    ${typeface(16, 600, .001)};
    text-shadow: 0 2px 1px rgba(0,0,0,.5);
  }
  
  h5 {
    ${typeface(16, 500, .0005)};
    text-shadow: 0 2px 1px rgba(0,0,0,.5);
  }
  
  h6 {
    ${typeface(16, 500, .0005)};
  }
  
  blockquote {
    border-left: 4px solid ${Theme.colors.dim};
    padding-left: 16px;
  }
  
  table {
    margin: 30px auto;
    font-size: 90%;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.5);
    @media (max-width: 540px) {
      table-layout: fixed;
      width: 100%;
    }
  }
  
  td, th {
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid ${Theme.colors.dimmest};
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  th {
    background: ${Theme.colors.dimmest}; 
    padding-top: 6px;
    padding-bottom: 6px;
    color: ${Theme.colors.kindaDim};
    font-weight: 500;
  }
  
  td {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  
  td > * {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  .center {
    text-align: center;
  }
  
  .right {
    text-align: right;
  }
  
  .small-box-outer {
    display: flex; 
    width: 100%; 
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 24px;
  }
  
  .small-box-inner {
     padding-top: 16px;
     padding-left: 24px;
     padding-right: 24px;
     > ul,ol { margin: 4px 16px 20px 0; };
     > table { margin: 4px 16px 20px 16px; };
     margin-bottom: 16px; 
     box-shadow: 0 1px 5px 0 rgba(0,0,0,.5);
     border: 1px solid ${Theme.colors.dimmest};
     border-radius: 5px;
     max-width: 85%;
  }
  
  .inset-box-outer {
     border-radius: 8px;
     width: 100%;
     box-shadow: inset 0 1px 8px 0 rgba(0,0,0,.425);
     border: 1px solid ${Theme.colors.dimmest};
     margin-bottom: 24px;
     margin-top: 24px;
  }
  
  .inset-box-inner {
     padding: 10px;
     *:last-child {
       margin-bottom: 0;
     }
  }
  
  ul > li {
    padding-left: 4px;
  }
  
  ol > li {
    padding-left: 8px;
  }
  
  ul,ol {
    padding-inline-start: 32px;
  }
  
  button {
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    background: none;
    ${typeface(16, 700)}
    color: ${Theme.colors.almostWhite};
    
    &.focus-visible {
      outline: unset;
    }
    
    &:hover {
      color: white;
    }
  }
  
  p {
    margin-bottom: 1em;
  }
`;

const markdownInternal = getMd({
    html: true,
    linkify: false,
    highlight: function (str, lang) {
        if (lang && hljsObj.value && hljsObj.value.getLanguage(lang)) {
            try {
                return hljsObj.value.highlight(lang, str).value;
            } catch (e) { console.log(e); }
        }
        return '';
    },
}).use(MarkdownItAnchor, {});
const markdownUserEditable = getMd({preventRelativeLinks: true, forceNewTab: true}).disable('image');

//content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 100 100'><path fill='%23333' stroke='%23666' stroke-width='10' d='m43,35H5v60h60V57M45,5v10l10,10-30,30 20,20 30-30 10,10h10V5z'/></svg>");

export default ({noBottomMargin, className, text, internal, linkOpener, preserveSpaces}) => {

    let [hljs, setHljs] = useState(hljsObj.value);
    let ref = (r) => {
        if (r) {
            r.querySelectorAll("[x-link-data]").forEach(e => {
                e.onclick = linkOpener(e.getAttribute("x-link-data"));
            });
        }
    };

    useEffect(() => {
        (async () => {
            if (internal && !hljs) {
                setHljs(await getHljs());
            }
        })();
    }, [internal]);

    let replaceSpaces = (t) => t.replace(/  +/mg, (match) => match.replace(/ /g, "&nbsp;"));

    let finalText = preserveSpaces ? replaceSpaces(text) : text;

    return <MarkdownStyle
        noBottomMargin={noBottomMargin}
        className={className}
        ref={ref}
        dangerouslySetInnerHTML={{__html: (internal ? markdownInternal : markdownUserEditable).render(finalText || "", {linkOpener: !!linkOpener, hljs: hljs})}}
    />
};

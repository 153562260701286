import React, { Component } from 'react';
import {getSignedUrlSync} from "../helpers/SignedUrlLoader";
import styled from '@emotion/styled';
import { animated } from 'react-spring'
import {sleep} from "../helpers/AsyncHelper";
import { observable, autorun, runInAction } from 'mobx'
import Theme from "../styles/Theme";
import {ScrollCss} from "../styles/Page";
import {EndDiv} from "../routes/gallery/GalleryComponent";
import { observer, inject } from "mobx-react";
import { css } from '@emotion/react'

const ScrollDiv = styled.div`
  height: inherit;
  padding: 4px;
  overflow-y: scroll;
  ${p => p.hidden ? `display: none;` : ``}
  
  ${ScrollCss};
`;

const GridDiv = styled.div`
  ${p => p.css};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${p => p.sidelength*1.15+6}px, 1fr));
  grid-auto-rows: ${p => p.sidelength*1.15+6 + p.containerExtraHeight}px;
  user-select: none;
  overflow-scrolling: touch;
  margin-top: ${p => p.topSpace ? p.topSpace : "unset"};
`;

const imageHolderCSS = css`
  contain: strict;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  background: none;
  padding: 0;
  border: none;
`;

const ImageHolder = styled.div`
  ${imageHolderCSS};
`;

const ImageHolderButton = animated(styled.button`
  ${imageHolderCSS};
  
  cursor: ${p => p.usePointer ? "pointer" : "auto"};
  ${p => p.showHover ? 
  `&:hover {
    background: ${Theme.colors.dimmer};
  }` : '' };
`);

const ImageWrapper = styled.div`
  display: inline;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  background: rgba(255,255,255,.05);
  box-shadow: inset 0 0 2px 0 rgba(255,255,255,.1);
  position: relative;
`;

const Image = styled.img`
  box-shadow: 0 0 ${p => 7.0*p.sidelength/128.0+1}px 0 rgba(0,0,0,.5);
  opacity: ${props => props.photoVisible ? 1 : 0};
  transition: 200ms opacity;
  image-rendering: ${"-webkit-optimize-contrast"};
`;

const Center = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OverlayHolder = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

class GridImage extends Component {

    constructor(props) {
        super(props);

        this.state = { photoVisible: false };
        this.mounted = false;
    }

    shouldComponentUpdate = (nextProps, nextState, nextContext) => {
        return this.state.photoVisible !== nextState.photoVisible
            || this.props.thumbSize !== nextProps.thumbSize
            || this.props.showHover !== nextProps.showHover
            || this.props.isButton !== nextProps.isButton;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.dispose){
            this.dispose();
        }
        this.dispose = autorun(() => {
            this.applySelectStyle(this.ref, this.props.selected.get());
        });
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.dispose){
            this.dispose();
        }
    }

    loaded = async () => {
        await sleep(Math.random()*150);
        if (this.mounted) {
            this.setState({photoVisible: true});
        }
    };

    setRef = (ref) => {
        if (ref !== this.ref) {
            this.ref = ref;
            this.applySelectStyle(this.ref, this.props.selected.get());
        }
    }

    applySelectStyle = (ref, selected) => {
        if (ref) {
            if (selected) {
                ref.style.cssText = `background: ${Theme.colors.almostDim};`;
            } else {
                ref.style.cssText = ``;
            }
        }
    }

    render() {
        let ImageHolderComponent = this.props.isButton ? ImageHolderButton : ImageHolder;
        let Overlay = this.props.overlay;
        let makeBitmap = async () => (this.imgRef && 'createImageBitmap' in window && this.imgRef.complete && this.imgRef.naturalWidth !== 0) ? await createImageBitmap(this.imgRef) : null;
        return (
            <ImageHolderComponent
                 ref={this.setRef}
                 onClick={async (e) => {
                     e.persist();
                     let bitmap = await makeBitmap();
                     this.props.clicked(this.props.data, bitmap)(e);
                 }}
                 onDoubleClick={this.props.doubleClicked(this.props.data)}
                 usePointer={this.props.usePointer}
                 showHover={this.props.showHover}
            >
                {this.props.img ?
                    <ImageWrapper
                        width={`${this.props.img.w}`}
                        height={`${this.props.img.h}`}
                    >
                        <Image
                            loading="lazy"
                            ref={(ref) => this.imgRef = ref}
                            crossOrigin={"Anonymous"}
                            sidelength={this.props.thumbSize}
                            width={`${this.props.img.w}`}
                            height={`${this.props.img.h}`}
                            src={getSignedUrlSync(this.props.img.fn)}
                            srcSet={this.props.img2x ? `${getSignedUrlSync(this.props.img2x.fn)} 2x` : null}
                            draggable={false}
                            onLoad={this.loaded}
                            photoVisible={this.state.photoVisible}
                        />
                        {Overlay ?
                            <OverlayHolder>
                                <Overlay photo={this.props.data} mobile={this.props.mobile} makeBitmap={makeBitmap} {...this.props.overlayProps}/>
                            </OverlayHolder>
                            : null
                        }
                    </ImageWrapper>
                    :
                    null
                }
            </ImageHolderComponent>

        );
    };
}

const GridImageObserver = observer(GridImage);

const PhotoContainer = ({
    container,
    selectedMap,
    selected,
    photo,
    containerProps,
    thumbSize,
    usePointer,
    showHover,
    isButton,
    overlay,
    overlayProps,
    mobile,
    cellClicked,
    cellDoubleClicked
}) => {
    const Container = container;
    if(!selectedMap.has(photo.ident)){
        selectedMap.set(photo.ident, observable.box(selected.has(photo.ident)))
    }
    return <Container {...containerProps}>
        <GridImageObserver
            img={photo.img[thumbSize]}
            img2x={photo.img[thumbSize*2]}
            data={photo}
            clicked={cellClicked}
            doubleClicked={cellDoubleClicked}
            ident={photo.ident}
            thumbSize={thumbSize}
            selected={selectedMap.get(photo.ident)}
            usePointer={usePointer}
            showHover={showHover}
            isButton={isButton}
            overlay={overlay}
            overlayProps={overlayProps}
            mobile={mobile}
        />
    </Container>;
};

class Grid extends Component {

    constructor(props){
        super(props);
        this.resetData();
        this.state = {results: []}
    }

    resetData = () => {
        this.data = {
            index: new Map(),
            selected: new Set(),
            selectAnchor: null,
            results: [],
            selectedMap: new Map()
        };
    };

    componentWillUnmount() {
        if(this.mutationObserver){
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }
        this.bindScrollRef(null);
    }

    cellDoubleClicked = (data) => {
        return (e) => {
            if (this.props.doubleClickAction){
                this.props.doubleClickAction(data);
            }
        };
    };

    cellClicked = (data, bitmap) => {
        let ident = data.ident;
        return (e) => {
            let cancelSelection = false;
            if (this.props.clickAction){
                if (this.props.clickAction(data, bitmap)){
                    cancelSelection = true;
                }
            }
            if (!this.props.noSelection && !cancelSelection) {
                let selectAnchor = null;
                if (e.ctrlKey || e.metaKey || this.props.isTouch) {
                    if (this.data.selected.has(ident)) {
                        this.select(ident, false);
                    } else {
                        this.select(ident, true);
                    }
                    selectAnchor = ident;
                } else if (e.shiftKey && this.data.selectAnchor !== null) {
                    let a = this.data.index.get(ident);
                    let b = this.data.index.get(this.data.selectAnchor);
                    selectAnchor = this.data.selectAnchor;
                    this.clearSelected();
                    if (a !== undefined && b !== undefined) {
                        let start = Math.min(a, b);
                        let end = Math.max(a, b);
                        for (let i = start; i <= end; ++i) {
                            let middleIdent = this.data.results[i].ident;
                            this.select(middleIdent, true);
                        }
                    } else {
                        selectAnchor = ident;
                        this.select(ident, true);
                    }
                } else {
                    this.clearSelected();
                    selectAnchor = ident;
                    this.select(ident, true);
                }
                this.data.selectAnchor = selectAnchor;
                if (this.props.selectedChanged) {
                    this.props.selectedChanged(this.data.selected);
                }
            }
        }
    };
    componentDidUpdate(prevProps) {
        if(this.props.results !== prevProps.results){
            let index = this.data.results.length;

            if(this.props.resetCount !== prevProps.resetCount){
                index = 0;
                this.resetData();
            }

            for (index; index < this.props.results.length; ++index) {
                let r = this.props.results[index];

                this.data.results.push({...r, selected: this.data.selectedMap.get(r.ident) });
                this.data.index.set(r.ident, index);
            }
        }
        if(this.props.clearSelected && this.props.clearSelected !== prevProps.clearSelected){
            this.props.clearSelected(this.clearSelected);
        }
        if(this.props.resetScroll && this.props.resetScroll !== prevProps.resetScroll){
            this.props.resetScroll(this.resetScroll);
        }
    };

    clearSelected = () => {
        this.data.selected.forEach(ident =>
            this.select(ident, false)
        );
        this.data.selected = new Set();
        this.data.selectAnchor = null;
    };

    select = (ident, val) => {
        runInAction(() => {
            this.data.selectedMap.get(ident).set(val);
        });
        if(val) {
            this.data.selected.add(ident);
        } else {
            this.data.selected.delete(ident);
        }
    };

    resetScroll = () => {
        if (this.scrollRef) {
            this.scrollRef.scrollTo(0, 0);
        }
    };

    scroll = async (e) => {
        if(!this.props.paused) {
            let threshold = 200 + 520;
            if (e.target.scrollTop + e.target.clientHeight > e.target.scrollHeight - threshold) {
                if (this.props.loadMore) {
                    await this.props.loadMore();
                }
            }
        }
    };

    shouldComponentUpdate = (nextProps, nextState, nextContext) => {
        return this.props.holderStyle !== nextProps.holderStyle ||
            this.props.thumbSize !== nextProps.thumbSize ||
            this.props.results !== nextProps.results ||
            this.props.results.length !== nextProps.results.length ||
            this.props.resetCount !== nextProps.resetCount ||
            this.props.isTouch !== nextProps.isTouch ||
            this.props.isEmpty !== nextProps.isEmpty;
    };

    bindScrollRef = (ref) => {
        if (this.scrollRef){
            this.scrollRef.removeEventListener('scroll', this.scroll);
            if (this.mutationObserver){
                this.mutationObserver.disconnect();
                this.mutationObserver = null;
            }
        }
        this.scrollRef = ref;
        if (this.scrollRef) {
            this.scrollRef.addEventListener('scroll', this.scroll);
            let config = { attributes: false, childList: true, subtree: false };
            this.mutationObserver = new MutationObserver(async () => {
                await this.scroll({target: this.scrollRef});
            });
            this.mutationObserver.observe(this.gridRef, config);
        }
        if (this.props.setScrollRef) {
            this.props.setScrollRef(ref);
        }
    };

    render() {
        let container = this.props.container || React.Fragment;
        return (
            <>
                { this.props.isEmpty ?
                    <Center><EndDiv mobile={this.props.appState.media.mobile} visible={true}>No applicable photos.</EndDiv></Center>
                : null }
                <ScrollDiv ref={this.bindScrollRef} hidden={this.props.isEmpty} onScroll={this.props.onScroll}>
                    <GridDiv topSpace={this.props.topSpace} css={this.props.holderStyle} sidelength={this.props.thumbSize} ref={(ref) => this.gridRef = ref} containerExtraHeight={this.props.containerExtraHeight || 0}>
                        {
                            this.props.results.map((r) => {
                                let containerProps = this.props.container ? {"data-photo": r} : {};
                                return <PhotoContainer
                                    key={"p-" + this.props.resetCount + "-" + r.ident}
                                    container={container}
                                    selectedMap={this.data.selectedMap}
                                    selected={this.data.selected}
                                    photo={r}
                                    containerProps={containerProps}
                                    thumbSize={this.props.thumbSize}
                                    usePointer={this.props.usePointer}
                                    showHover={!this.props.isTouch}
                                    isButton={!this.props.noSelection || !!this.props.clickAction}
                                    overlay={this.props.overlay}
                                    overlayProps={this.props.overlayProps}
                                    mobile={this.props.appState.media.mobile}
                                    cellClicked={this.cellClicked}
                                    cellDoubleClicked={this.cellDoubleClicked}
                                />;
                            })
                        }
                    </GridDiv>
                </ScrollDiv>
            </>
        )
    }
}

export default inject("appState")(observer(Grid));
import './MainSite.css'
import 'overlayscrollbars/css/OverlayScrollbars.css';
import React, {Component, useEffect, useRef, Suspense} from 'react'
import { Route, Switch, useLocation, useHistory, Router, Redirect } from 'react-router-dom'
import Canvas from '../components/canvas/Canvas'
import AppNavbar from "../components/AppNavbar"
import { withRouter } from 'react-router';
import { EventEmitter } from 'eventemitter3';
import { Provider, observer, inject } from "mobx-react";
import { observable, autorun, runInAction, reaction } from "mobx";
import Api from "../components/Api";
import styled from '@emotion/styled';
import {Global, css} from '@emotion/react';
import WindowHelper from "../helpers/WindowHelper";
import * as moment from 'moment';
import {defaultCss} from "../styles/Page";
import {zoomLevel} from "zoom-level";
import "focus-visible";
import PhotoPickerModal from "../components/PhotoPickerModal";
import Theme from "../styles/Theme";
import { createBrowserHistory } from 'history';
import Preview from 'react-dnd-preview';
import posthog from 'posthog-js'
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Config from "../Config";
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TouchTransition, MouseTransition } from 'dnd-multi-backend';
import {tagCss } from "../styles/Tag";
import {Helmet} from "react-helmet";

const Frontpage = React.lazy(() => import('../routes/frontpage/Frontpage'));
const Photo = React.lazy(() => import('../routes/photo/Photo'));
const Browser = React.lazy(() => import('../routes/browser/Browser'));
const PhotoUpload = React.lazy(() => import('../routes/upload/PhotoUpload'));
const Settings = React.lazy(() => import('../routes/settings/Settings'));
const Story = React.lazy(() => import("../routes/story/Story"));
const UserStoryList = React.lazy(() => import("../routes/storylist/user/UserStoryList"));
const Explore = React.lazy(() => import("../routes/senses/Senses"));
const ProfileGallery = React.lazy(() => import("../routes/gallery/profile/ProfileGallery"));
const SenseGallery = React.lazy(() => import("../routes/gallery/sense/SenseGallery"));
const
    UserPhotoGallery = React.lazy(() => import("../routes/gallery/userphoto/UserPhotoGallery"));
const Subscription = React.lazy(() => import("../routes/subscription/Subscription"));
const Favorites = React.lazy(() => import("../routes/favorites/Favorites"));
const EmailVerify = React.lazy(() => import("../routes/email/EmailVerify"));
const RecoveryVerify = React.lazy(() => import("../routes/recovery/RecoveryVerify"));
const Recovery = React.lazy(() => import("../routes/recovery/Recovery"));
const RecoveryRecover = React.lazy(() => import("../routes/recovery/RecoveryRecover"));
import {
    TopGalleryAllTime,
    TopGalleryDay,
    TopGalleryMonth,
    TopGalleryWeek
} from "../routes/gallery/top/TopGallery";
const RecentStoryList = React.lazy(() => import("../routes/storylist/recent/RecentStoryList"));
const ActivityMap = React.lazy(() => import("../routes/map/ActivityMap"));
import {HotGalleryDay, HotGalleryHour, HotGalleryMonth, HotGalleryWeek} from "../routes/gallery/hot/HotGallery";
import {SpinnerFadeIn} from "../components/Spinner";
import Following from "../routes/userlist/Following";
import Followers from "../routes/userlist/Followers";
import {FeedAll, FeedOnlyShowcased} from "../routes/feed/Feed";
import Help from "../routes/help/Help";
import SiteBlog from "../routes/storylist/blog/SiteBlog";
import Background from "../components/Background";
import {GripIconLightShadowed} from "../svg/Icons";
import StyledModal from "../components/StyledModal";
import {
    ModalButtonsDiv,
    ModalCenterTextDiv,
    ModalContentDiv
} from "../styles/Modal";
import {Button120} from "../styles/Button";
import {RecentGalleryAll, RecentGalleryShowcased} from "../routes/gallery/recent/RecentGallery";
import OverlayControls from "../components/OverlayControls";

let stripePromise = loadStripe(Config.APP_STRIPE_PUBLISHABLE_KEY);

const AppDiv = styled.div`
  pointer-events: auto;
  height: 100%;
  width: 100%;
  position: relative; 
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  touch-action: manipulation;
`;

const RouterDiv = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
`;

const NonPluginDiv = styled.div`
  position: absolute;
  top: ${WindowHelper.NAV_HEIGHT}px;
  width: 100%;
`;

const CanvasDiv = styled.div`
  position: ${props => props.mini ? "absolute" : "fixed"};
  z-index: 20;
  pointer-events: none;
  top: ${props => props.mini ? `-${WindowHelper.NAV_HEIGHT}px` : "0"};
  user-select: none;
  height: var(--app-height);
`;

const CanvasCutoutDiv = styled.div`
  position: fixed;
  z-index: 20;
  pointer-events: auto;
  top: ${WindowHelper.NAV_HEIGHT}px;
  user-select: none;
`;

const CanvasCutout = styled.div`
  position: absolute;
  background: none;
  user-select: none
`;

const PageDiv = styled.div`
  height: auto;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 3000;
`;

const PhotoOverlayDiv = styled.div`
  will-change: opacity;
`;

const AppNavbarBG = styled.div`
  z-index: 0;
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${WindowHelper.NAV_HEIGHT}px;
  user-select: none;
  background: ${Theme.colors.background};
`;

const ListingUnderlayDiv = styled.div`
`;

const SetPhotoTo = inject("appState")(observer((props) => {
    useEffect(() => {
        runInAction(() => {
            props.appState.photo.ident = props.ident;
            props.appState.underlayLocation = null;
            props.doTransition();
        });
    }, [props.ident]);
    return <></>;
}));

const LocationWatcher = withRouter(inject("appState")(observer((props) => {
    let justLoadedRef = useRef(true);
    let justLoaded = justLoadedRef.current;

    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        runInAction(() => {
            props.appState.history = history;
        });
    }, []);

    if (justLoaded){
        history.replace(location.pathname + location.search + location.hash, {});
    }

    useEffect(() => {
        let state = location.state || {};
        posthog.capture('$pageview');
        runInAction(() => {
            props.appState.location = location;
            props.appState.photoPickDirective = null;
        });
        if (state.usePhotoOverlay) {
            runInAction(() => {
                props.appState.photo.standalone = false;
                props.appState.photo.series = state.photoSeries;
                props.appState.photo.sortValue = state.photoSortValue;
                props.appState.underlayLocation = state.underlayLocation;
            });
        } else {
            runInAction(() => {
                props.appState.photo.standalone = true;
                props.appState.photo.series = null;
                props.appState.photo.sortValue = null;
                props.appState.underlayLocation = location;
            });
        }
        if (state.photoIdent) {
            runInAction(() => {
                props.appState.photo.ident = state.photoIdent;
            });
            if(!props.appState.photo.transitionActive) {
                props.doPhotoTransition();
            }
        } else if (location.pathname.search("^[/]photo[/][_]?[a-zA-Z0-9_-]+([/].*)?") !== -1) {
        } else {
            runInAction(() => {
                props.appState.photo.ident = null;
            });
        }
    }, [location.pathname]);
    justLoadedRef.current = false;
    return <></>;
})));


const ScrollGrip = styled.div`
  background: rgba(255,255,255,.125);
  position: fixed;
  user-select: none;
  transition: 300ms opacity;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.5);
  contain: strict;
  pointer-events: none;
`;
const Scroller = inject("appState")(observer(({appState}) => {
    let state = useRef({});
    let applyMovement = (e) => {
        let mouseDiff = e.clientY - state.current.startMouse;
        let newGripTop = mouseDiff + state.current.startGripTop;

        let minPct = 30/(window.innerHeight - WindowHelper.NAV_HEIGHT);
        let viewPct = Math.max(state.current.visibleHeight / state.current.scrollHeight, minPct);
        let newScrollTop = (state.current.scrollHeight - state.current.visibleHeight) * (
                newGripTop - WindowHelper.NAV_HEIGHT) / (window.innerHeight - WindowHelper.NAV_HEIGHT - viewPct * (window.innerHeight - WindowHelper.NAV_HEIGHT)
            );

        if (state.current.targetRef) {
            if(state.current.targetRef === window){
                window.scroll(0, newScrollTop);
            } else {
                state.current.targetRef.scrollTop = newScrollTop;
            }
        }
    }
    let updateScrollSize = () => {
        if (state.current.targetRef){
            if (state.current.targetRef === window){
                state.current.scrollHeight = document.documentElement.scrollHeight;
            } else {
                state.current.scrollHeight = state.current.targetRef.scrollHeight;
            }
        } else {
            state.current.scrollHeight = 0;
        }
    };
    let updateWindowSize = () => {
        if (state.current.targetRef){
            if (state.current.targetRef === window){
                state.current.visibleHeight = window.innerHeight;
            } else {
                state.current.visibleHeight = state.current.targetRef.clientHeight;
            }
        } else {
            state.current.visibleHeight = window.innerHeight;
        }
    };
    let updateScroll = () => {
        if (state.current.targetRef) {
            if (state.current.targetRef === window) {
                state.current.scrollTop = window.scrollY;
            } else {
                state.current.scrollTop = state.current.targetRef.scrollTop;
            }
        } else {
            state.current.scrollTop = 0;
        }
    };
    let mousedown = (e) => {
        e.preventDefault();
        animate();
        state.current.dragging = true;
        e.target.setPointerCapture(e.pointerId);
        state.current.startMouse = e.clientY;
        state.current.startScrollTop = state.current.scrollTop;
        state.current.startGripTop = state.current.gripTop;
    };
    let mousemove = (e) => {
        if (state.current.dragging) {
            applyMovement(e);
        }
    };
    let mouseup = (e) => {
        if (state.current.dragging) {
            applyMovement(e);
        }
        state.current.dragging = false;
        e.target.releasePointerCapture(e.pointerId)
    };
    let scroll = (e) => {
        updateScroll();
        //animate();
        show();
    };
    let show = () => {
        if (state.current.visibleHeight < state.current.scrollHeight) {
            state.current.opacity = 1;
            if (state.current.showId) {
                clearTimeout(state.current.showId);
                state.current.showId = null;
            }
            state.current.showId = setTimeout(hide, 2000);
        }
    };
    let hide = () => {
        state.current.opacity = 0;
    };
    let setRef = (ref) => {
        if (state.current.gripRef){
            state.current.gripRef.removeEventListener("pointerup", mouseup);
            state.current.gripRef.removeEventListener("pointermove", mousemove);
            state.current.gripRef.removeEventListener("pointerdown", mousedown);
        }
        state.current.gripRef = ref;
        if (state.current.gripRef){
            state.current.gripRef.addEventListener("pointerup", mouseup);
            state.current.gripRef.addEventListener("pointermove", mousemove);
            state.current.gripRef.addEventListener("pointerdown", mousedown);
        }
        hide();
        animate();
    };
    let setTargetRef = (t) => {
        if (state.current.targetRef){
            state.current.observer.disconnect();
            state.current.observer = null;
            state.current.targetRef.removeEventListener("scroll", scroll);
        }
        state.current.targetRef = t;
        if (state.current.targetRef){
            state.current.targetRef.addEventListener("scroll", scroll, {passive: true});
            let ref = state.current.targetRef;
            if (ref === window){
                ref = document.body;
            }
            state.current.observer = new MutationObserver(updateScrollSize);
            state.current.observer.observe(ref, {subtree: true, childList: true, characterData: true});
        }
        updateScroll();
        updateScrollSize();
        updateWindowSize();
    };
    setTargetRef(appState.scrollTarget);
    useEffect(() => {
        runInAction(() => {
            appState.showScrollbars = show;
        })
        animate(true);
        return () => {
            setRef(null);
            setTargetRef(null);
            if (state.current.animateRef){
                window.cancelAnimationFrame(state.current.animateRef);
            }
            runInAction(() => {
                appState.showScrollbars = null;
            })
        }
    }, []);
    let animFrame = useRef(0);
    let animate = (forever) => {
        if (animFrame.current % 5 === 0) {
            updateScrollSize();
            updateWindowSize();
        }
        if (state.current.gripRef && (animFrame.current % 2 === 0)) {
            let scrollPct = state.current.scrollTop / (state.current.scrollHeight - state.current.visibleHeight);
            scrollPct = isNaN(scrollPct) ? 0 : scrollPct;
            let minPct = 30/(window.innerHeight - WindowHelper.NAV_HEIGHT);
            let viewPct = Math.max(state.current.visibleHeight / state.current.scrollHeight, minPct);
            let top = WindowHelper.NAV_HEIGHT + scrollPct * (window.innerHeight - WindowHelper.NAV_HEIGHT - viewPct * (window.innerHeight - WindowHelper.NAV_HEIGHT));
            state.current.gripTop = top;
            let height = viewPct * (window.innerHeight - WindowHelper.NAV_HEIGHT);
            state.current.gripRef.style.cssText = `
                pointer-events: all;
                right: 0px;
                width: 12px;
                top: ${top}px;
                height: ${height}px;
                opacity: ${state.current.opacity};
                ${appState.scrollContextSwitch ? `transition: none;` : ``}
            `;
            runInAction(() => appState.scrollContextSwitch = false);
        }
        animFrame.current++;
        if (forever) {
            state.current.animateRef = window.requestAnimationFrame(() => animate(true));
        }
    };
    return <ScrollGrip ref={setRef}/>
}));

const SpinnerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px);
  width: 100%;
  background: ${Theme.colors.background};
`;

const TagPreview = styled.div`
  z-index: 2000;
`;

const TagShift = styled.div`
  position: absolute;
  top: -12px;
  right: -30px;
  
  background: ${Theme.colors.backgroundLight};
  min-width: 240px;
  height: 38px;
  border-radius: 5px;
  box-shadow: 0 0 6px 1px rgba(0,0,0,.75), 0 0 25px 0 rgba(0,0,0,.75), inset 0 0 0px 1px rgba(255,255,255,.15);
  padding: 8px;
  overflow: hidden;
  transform: scale(1);
  cursor: grabbing;
  display: flex;
`;

const TagDiv = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  ${tagCss};
`;

const DragHandle = styled.div`
  width: 16px;
  height: 16px;
  color: ${Theme.colors.dimmer};
  cursor: grab;
`;

const generatePreview = ({itemType, item, style}) => {
    return itemType === "tagcontext" ? <TagPreview style={style}>
        <TagShift>
            <TagDiv>{item.tag}</TagDiv>
            <DragHandle>
                <GripIconLightShadowed scale={16} holderVerticalAlign={"-2px"}/>
            </DragHandle>
        </TagShift>
    </TagPreview> : <div/>;
};

let HTML5ToTouchModified = {
    backends: [
        {
            backend: HTML5Backend,
            transition: MouseTransition
        },
        {
            backend: TouchBackend,
            options: {
              enableMouseEvents: false,
              enableHoverOutsideTarget: true
            },
            preview: false,
            transition: TouchTransition
        }
    ]
};

class MainSite extends Component {

    constructor(props){
        super(props);

        moment.locale("en");

        this.animate = this.animate.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        this.emitter = new EventEmitter();

        this.appState = observable({
            user: null,
            loggedOut: !Api.isCredentialed(),
            photo: {
                ident: null,
                sortValue: null,
                view: {
                    x: 0,
                    y: 0,
                    z: 1,
                    shrinkPct: 1
                },
                viewScrollY: 1,
                viewShrinkAmtMin: .35,
                viewSidebarPct : .3333,
                info: null,
                infoReady: false,
                dragRegion: null,
                dragValid: false,
                editing: false,
                showRegions: false,
                showRegionsBoldAmt: 0.0,
                hiddenRegions: false,
                selectedRegion: null,
                selectedRegionPct: 0,
                selectedRegionZoomed: false,
                hoverRegion: null,
                clickable: false,
                hoverOnPhoto: false,
                activeDisplay: null,
                adjustments: {
                    exposure: 1.0,
                    offset: 0.0,
                    gamma: 1.0,
                    saturation: 0.0
                },
                standalone: true,
                series: null,
                viewpointBounds: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0
                },
                frameIsFilled: false,
                editCropping: false,
                transitionActive: false,
                initTransition: false,
                lightbox: false,
                frontPageOpen: false,
                browserOpen: false,
                helpOpen: false,
                triggerShowcase: null,
                fadeIn: false,
                justUploaded: null
            },
            photoEdits: {
                regions: {
                    create: [],
                    edit: [],
                    delete: new Map()
                },
                description: null,
                title: null,
                senses: null,
                tags: null,
                coords:null,
            },
            tagEdits: {
                description: null,
                title: null,
                tags: null
            },
            viewport: {
                width: 0,
                height: 0,
                renderWidth: 0,
                renderHeight: 0,
                canvasX: 0,
                canvasY: 0,
                navHeight: WindowHelper.NAV_HEIGHT,
                borderAmount: WindowHelper.BORDER_AMOUNT,
                minVerticalPadding: 25,
                additionalVGutter: 0,
                zoomLevel: zoomLevel(),
                fullViewportClickable: false,
                windowScrollX: 0,
                windowScrollY: 0,
                bodyClientWidth: document.body.clientWidth,
                mouseX: 0,
                mouseY: 0
            },
            cursor: "auto",
            hideCursor: false,
            thumbnailSpeculative: null,
            thumbnailSpeculativePotential: null,
            thumbnailSpeculativeCallback: null,
            inPhoto: false,
            controls: {
                cropType: "any",
                cropCustomW: 1,
                cropCustomH: 1,
                cropRotate: false
            },
            justMounted: true,
            media: {
                skinny: WindowHelper.isSkinny(),
                nearSkinny: WindowHelper.isNearSkinny(),
                mini: WindowHelper.isShort() || WindowHelper.isMobile(),
                somewhatMini: WindowHelper.isSomewhatShort() || WindowHelper.isMobile(),
                short: WindowHelper.isShort(),
                somewhatShort: WindowHelper.isSomewhatShort(),
                innerWidth: window.innerWidth,
                touch: false,
                mobile: WindowHelper.isMobile()
            },
            overlayRef: null,
            underlayScrollPos: 0,
            underlayLocation: null,
            lastSeriesNav: "next",
            modalCount: 0,
            photoPickDirective: null,
            emitter: this.emitter,
            speculate: () => {},
            history: null,
            location: null,
            entry: "mainSite",
            runCanvasAnimationFrame: () => {},
            time: new Date().getTime(),
            scrollTarget: window,
            scrollContextSwitch: false,
            underlayScrollTarget: null,
            globalBackground: null,
            initialUserLoad: false,
            dragElementDiv: null,
            genericError: null,
            showOverlayControls: false,
            mapPreferences: JSON.parse(localStorage.getItem("mapPreferences") ?? JSON.stringify({ "productMount" : "sony_e" })),
        }, {
            history: observable.ref,
            location: observable.ref,
            emitter: observable.ref,
            thumbnailSpeculative: observable.ref,
            thumbnailSpeculativePotential: observable.ref,
            speculate: observable.ref,
            runCanvasAnimationFrame: observable.ref,
            photoPickDirective: observable.ref,
            dragElementDiv: observable.ref
        });

        Api.addRefreshFunction(this.refreshLogin);

        reaction(() => this.appState.photo.initTransition, () => {
            if (this.appState.photo.initTransition) {
                runInAction(() => {
                    this.appState.photo.transitionActive = true;
                    this.appState.photo.initTransition = false;
                });
                this.doPhotoTransition();
            }
        });

        reaction(() => this.appState.photo.ident, async () => {
            if(this.appState.photo.ident) {
                runInAction(()=>{
                    this.appState.photo.info = null;
                    this.appState.photo.editing = false;
                });
                let recent = (this.appState.photo.justUploaded === this.appState.photo.ident) ? "?new=true" : "";
                let newInfo = await Api.get("/photo/" + this.appState.photo.ident + recent);
                if (newInfo) {
                    runInAction(() => {
                        if (this.appState.inPhoto && this.appState.photo.ident === newInfo.ident) {
                            this.appState.photo.infoReady = false;
                            this.appState.photo.info = newInfo;
                            this.appState.photo.activeDisplay = this.appState.photo.info.displays[0];
                            this.appState.photo.justUploaded = null;
                        }
                    });
                } else {
                    runInAction(() => {
                        if (this.appState.inPhoto) {
                            this.appState.photo.infoReady = false;
                            this.appState.photo.info = null;
                            this.appState.photo.activeDisplay = null;
                            this.appState.photo.justUploaded = null;
                        }
                    });
                }
            } else {
                runInAction(()=> {
                    this.appState.photo.info = null;
                    this.appState.photo.transitionActive = false;
                });
            }
        });

        autorun(() => {
            if (this.appState.photo.ident === null){
                requestAnimationFrame(() => {
                    window.scroll({top: this.appState.underlayScrollPos, left:0, behavior:"auto"});
                });
            }
        });

        reaction(() => [this.appState.modalCount, this.appState.photo.editCropping, this.appState.browserOpen, this.appState.frontpageOpen, this.appState.helpOpen, this.appState.inPhoto], () => {
            if (this.appState.modalCount > 0 || this.appState.photo.editCropping || ((this.appState.frontpageOpen || this.appState.browserOpen || this.appState.helpOpen) && !this.appState.inPhoto)){
                document.body.style.overflowY = "hidden";
                document.body.style.height = "100%";
            } else {
                document.body.style.overflowY = "scroll";
                document.body.style.height = "auto";
            }
        });

        reaction(() => [
            this.appState.inPhoto,
            this.appState.frontpageOpen,
            this.appState.browserOpen,
            this.appState.helpOpen,
            this.appState.underlayScrollTargetBrowser,
            this.appState.underlayScrollTargetHelp,
            this.appState.underlayScrollTargetFrontpage
        ], () => {
            runInAction(() => {
                if (this.appState.inPhoto){
                    this.appState.scrollTarget = window;
                } else {
                    if (this.appState.frontpageOpen || this.appState.browserOpen || this.appState.helpOpen){
                        this.appState.scrollTarget = this.appState.underlayScrollTargetBrowser || this.appState.underlayScrollTargetHelp || this.appState.underlayScrollTargetFrontpage;
                    } else {
                        this.appState.scrollTarget = window;
                    }
                }
                this.appState.scrollContextSwitch = true;
            });
        });

        reaction(() => this.appState.user, () => {
            if (this.appState.user) {
                posthog.identify(this.appState.user.ident);
                if (this.appState.user.alias) {
                    posthog.people.set({alias: this.appState.user.alias});
                }
                if (this.appState.user.name) {
                    posthog.people.set({name: this.appState.user.name});
                }
            } else {
                posthog.reset();
            }
        });

        reaction(() => this.appState.media.touch, () => {
            if (this.appState.media.touch) {
                posthog.register({
                    "Touch" : "yes"
                });
            }
        });
    }

    animate(now) {
        if (!this.lastTime) {
            this.lastTime = now;
        }
        let delta = (now - this.lastTime)/1000.0;
        this.lastTime = now;

        if (this.canvas.refs.photoCanvas) {
            runInAction(() => {
                let rect = this.canvas.refs.photoCanvas.getBoundingClientRect();
                this.appState.viewport.canvasX = rect.left;
                this.appState.viewport.canvasY = rect.top;
            });
        }

        let updates = [];
        this.emitter.emit("animate", delta, updates);
        updates.forEach((f)=>f());

        let full = this.appState.photo.selectedRegionZoomed || this.appState.viewport.fullViewportClickable;
        let top = full ? 0 : this.appState.photo.viewpointBounds.top;
        let left = full ? 0 : this.appState.photo.viewpointBounds.left;
        let bottom = full ? window.innerHeight - this.appState.viewport.navHeight : this.appState.photo.viewpointBounds.bottom;
        let right = full ? window.innerWidth : this.appState.photo.viewpointBounds.right;
        let cursor = this.appState.hideCursor ? "none" : this.appState.cursor;
        if (this.refs.cutout) {
            this.refs.cutout.style.cssText =
                `top: ${top}px;
                 left: ${left}px;
                 width: ${(right - left)}px;
                 height: ${(bottom - top)}px;
                 cursor: ${cursor};
                 `;
        }

        runInAction(() => {
            this.appState.time = new Date().getTime();
        });

        this.frame = requestAnimationFrame(this.animate);
    }

    componentDidMount(){
        this.location = "";
        this.unlisten = this.props.history.listen((location, action) => {
            this.location = location.pathname;
        });
        runInAction(() => {
            this.appState.justMounted = true;
        });
        window.addEventListener("resize", this.handleResize);

        this.customStyle = document.createElement("style");
        document.head.appendChild(this.customStyle);

        WindowHelper.recalc();
        this.animate(0);

        for (let eventName of ["wheel", "touchstart", "touchend", "touchcancel", "touchmove", "mouseup", "mousedown", "mousemove", "mouseover", "mouseout", "click", "doubleclick"]) {
            this.refs.appDiv.addEventListener(eventName, (event) => {
                if(eventName === "touchstart" && !this.appState.media.touch){
                    runInAction(() => {
                        this.appState.media.touch = true;
                    });
                }
                this.emitter.emit(eventName, event);
            }, {passive:false});
        }

        for (let eventName of ["wheel", "touchstart", "touchend", "touchcancel", "touchmove", "mouseup", "mousedown", "mousemove", "mouseover", "mouseout", "click", "doubleclick"]) {
            this.refs.cutout.addEventListener(eventName, (event) => {
                this.emitter.emit("photo-" + eventName, event);
            }, {passive:false});
        }

        autorun(() => {
            let cursor = this.appState.hideCursor ? "none" : this.appState.cursor;
            if (this.refs.cutout.style.cursor !== cursor) {
                this.refs.cutout.style.cursor = cursor;
            }
        });

        runInAction(() => {
            this.appState.justMounted = false;
        });

        this.handleResize();

        let loggedIn = Api.refresh();
        if (!loggedIn) {
            runInAction(() => this.appState.initialUserLoad = true);
        }

        reaction(() => [this.appState.photo.ident], () => {
            if(this.appState.photo.ident === null) {
                this.doPhotoTransition();
            }
        });

        this.doPhotoTransition();

        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("mousemove", (e) => {
            if (e.clientX > window.innerWidth - 240){
                if (this.appState.showScrollbars){
                    this.appState.showScrollbars();
                }
            }
        });

        setInterval(() => this.appState.emitter.emit("5s"), 5000);
    }

    doPhotoTransition = () => {
        let rule = (r) => this.customStyle.sheet.insertRule(r);
        while (this.customStyle.sheet.cssRules.length > 0) {
            this.customStyle.sheet.deleteRule(0);
        }
        if (this.appState.photo.ident){
            runInAction(() => {
                this.appState.inPhoto = true;
            });
            if (this.appState.photo.fadeIn) {
                if (this.appState.photo.transitionActive) {
                    /*rule(`
                      .underlay-div {
                        top: ${-this.appState.underlayScrollPos + WindowHelper.NAV_HEIGHT}px;
                        display: block;
                        width: 100%;
                        position: fixed;
                      }
                    `);*/
                    rule(`
                  .photo-overlay-div {
                    display: block;
                    opacity: 0;
                    position: fixed;
                    top: ${WindowHelper.NAV_HEIGHT}px;
                    z-index: 30;
                  }
                `);
                    rule(`
                  .canvas-div {
                    transform: scale(.9);
                  }
                `);
                    document.body.clientHeight; // trigger flow
                    if (!this.doingTransition) {
                        window.requestAnimationFrame(() => {
                            while (this.customStyle.sheet.cssRules.length > 0) {
                                this.customStyle.sheet.deleteRule(0);
                            }
                            /*rule(`
                              .underlay-div {
                                top: ${-this.appState.underlayScrollPos + WindowHelper.NAV_HEIGHT}px;
                                display: block;
                                width: 100%;
                                position: fixed;
                              }
                            `);*/
                            rule(`
                          .photo-overlay-div {
                            display: block;
                            transition: 200ms opacity;
                            opacity: 1;
                            position: fixed;
                            top: ${WindowHelper.NAV_HEIGHT}px;
                            z-index: 30;
                          }
                        `);
                            rule(`
                          .canvas-div {
                            transition: 200ms transform;
                            transform: scale(1);
                          }
                        `);
                            setTimeout(() => {
                                window.scrollTo({top: 0, left: 0, behavior: "auto"});
                                runInAction(() => this.appState.photo.transitionActive = false);
                                this.doPhotoTransition();
                                this.doingTransition = false;
                                this.handleScroll(); // re-updates canvasX/canvasY with proper values
                            }, 250);
                        });
                    }
                    this.doingTransition = true;
                } else {
                    rule(`
                      .underlay-div {
                        display: none;
                      }
                    `);
                    rule(`
                      .photo-overlay-div {
                        display: block;
                      }
                    `);
                }
            } else {
                rule(`
                  .underlay-div {
                    display: none;
                  }
                `);
                rule(`
                  .photo-overlay-div {
                    display: block;
                  }
                `);
                window.scrollTo({top: 0, left: 0, behavior: "auto"});
                runInAction(() => this.appState.photo.transitionActive = false);
                this.handleScroll();
            }
        } else {
            runInAction(() => {
                this.appState.inPhoto = false;
            });
            rule(`
              .photo-overlay-div {
                display: none;
              }
            `);
            rule(`
              .underlay-div {
                display: block;
              }
            `);
        }
    };

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(e){
        runInAction(() => {
            if (this.canvas && this.canvas.refs.photoCanvas) {
                let rect = this.canvas.refs.photoCanvas.getBoundingClientRect();
                this.appState.viewport.canvasX = rect.left;
                this.appState.viewport.canvasY = rect.top;
                this.appState.viewport.windowScrollX = window.scrollX;
                this.appState.viewport.windowScrollY = window.scrollY;
            }
        });
    }

    handleResize(e){
        WindowHelper.onResize(this.appState.media.touch);
        this.emitter.emit("resize", e);
        runInAction(() => {
            this.appState.media.skinny = WindowHelper.isSkinny();
            this.appState.media.nearSkinny = WindowHelper.isNearSkinny();
            this.appState.media.mini = WindowHelper.isMobile() || WindowHelper.isShort();
            this.appState.media.somewhatMini = WindowHelper.isMobile() || WindowHelper.isSomewhatShort();
            this.appState.media.mobile = WindowHelper.isMobile();
            this.appState.media.short = WindowHelper.isShort();
            this.appState.media.somewhatShort = WindowHelper.isSomewhatShort();
            this.appState.media.innerWidth = window.innerWidth;
            this.appState.viewport.bodyClientWidth = document.body.clientWidth;
            this.appState.viewport.borderAmount = WindowHelper.isMobile() ? 1 : WindowHelper.BORDER_AMOUNT;
            this.appState.viewport.zoomLevel = zoomLevel();
        });

        posthog.register({
            "WindowInnerWidth" : window.innerWidth,
            "WindowInnerHeight" : window.innerHeight
        });
    }

    refreshLogin = async () => {
        let json = null;
        if (Api.isCredentialed()) {
            json = await Api.get("/user/me");
        }

        runInAction(() => {
            if (Api.isCredentialed()) {
                this.appState.user = json;
                this.appState.loggedOut = false;
            } else {
                this.appState.user = null;
                this.appState.loggedOut = true;
            }
            this.appState.initialUserLoad = true
        });
    };

    render() {
        let background = ``;
        if (!this.appState.photo.transitionActive && this.appState.inPhoto && this.appState.photo.lightbox){
            background = `body { background: ${Theme.colors.background} !important; }`;
        } else if (this.appState.globalBackground && !(!this.appState.photo.transitionActive && this.appState.inPhoto)) {
            background = `body { background: ${this.appState.globalBackground} !important; }`;
        }
        let genericError = (typeof this.appState.genericError === "string")
            ? {error: this.appState.genericError, title: "An Error Occurred"}
            : this.appState.genericError;
        return (
            <Elements stripe={stripePromise}>
                <Provider appState={this.appState}>
                    <DndProvider options={HTML5ToTouchModified}>
                        <AppDiv ref={"appDiv"}>
                            <Global styles={css`${defaultCss}; ${background}`}/>
                            <Helmet>
                                <title>Lumathon</title>
                            </Helmet>
                            <PageDiv>
                                <AppNavbarBG/>
                                <AppNavbar/>
                                <NonPluginDiv>
                                    <ListingUnderlayDiv className={"underlay-div"}>
                                        <Suspense fallback={<SpinnerPage><SpinnerFadeIn/></SpinnerPage>}>
                                            { this.appState.underlayLocation ?
                                                <Switch location={this.appState.underlayLocation}>

                                                    <Route exact path='/'
                                                           component={Frontpage} />

                                                    <Route path='/photo/_:ident/:dummyTitle?'
                                                           render={(p) => <SetPhotoTo doTransition={this.doPhotoTransition} ident={p.match.params.ident}/>} />
                                                    <Route path='/story/_:ident/:dummyTitle?'
                                                           render={(p) => <Story storyIdent={p.match.params.ident} />} />

                                                    <Route path='/browse'
                                                           render={(p) => <Browser/>} />

                                                    <Route exact path='/subscription' render={p => <Subscription/>}/>

                                                    <Route path='/explore/senses/:sense/photos'
                                                           render={(p) => <SenseGallery sense={p.match.params.sense} /> }/>
                                                    <Route path='/explore/senses/:sense?'
                                                           render={(p) => <Explore sense={p.match.params.sense}/>} />

                                                    <Route path='/explore/top/alltime/photos'
                                                           render={(p) => <TopGalleryAllTime/> }/>
                                                    <Route path='/explore/top/month/photos'
                                                           render={(p) => <TopGalleryMonth/> }/>
                                                    <Route path='/explore/top/week/photos'
                                                           render={(p) => <TopGalleryWeek/> }/>
                                                    <Route path='/explore/top/day/photos'
                                                           render={(p) => <TopGalleryDay/> }/>

                                                    <Route path='/explore/hot/month/photos'
                                                           render={(p) => <HotGalleryMonth/> }/>
                                                    <Route path='/explore/hot/week/photos'
                                                           render={(p) => <HotGalleryWeek/> }/>
                                                    <Route path='/explore/hot/day/photos'
                                                           render={(p) => <HotGalleryDay/> }/>
                                                    <Route path='/explore/hot/hour/photos'
                                                           render={(p) => <HotGalleryHour/> }/>

                                                    <Route path='/explore/recent/photos/all'
                                                           render={(p) => <RecentGalleryAll/> }/>
                                                    <Route path='/explore/recent/photos/showcased'
                                                           render={(p) => <RecentGalleryShowcased/> }/>
                                                    <Route path='/explore/recent/stories'
                                                           render={(p) => <RecentStoryList/> }/>

                                                    <Route path='/blog'
                                                           render={(p) => <SiteBlog/> }/>

                                                    <Route path='/feed/all'
                                                           render={(p) => <FeedAll/>} />
                                                    <Route path='/feed'
                                                           render={(p) => <FeedOnlyShowcased/>} />
                                                    <Route path='/following'
                                                           render={(p) => <Following/>} />
                                                    <Route path='/followers'
                                                           render={(p) => <Followers/>} />
                                                    <Route path='/favorites/photos'
                                                           render={(p) => <Favorites type={"photos"}/>} />
                                                    <Route path='/favorites/stories'
                                                           render={(p) => <Favorites type={"stories"}/>} />
                                                    <Route path='/favorites'>
                                                        <Redirect to={"/favorites/photos"}/>
                                                    </Route>
                                                    <Route path='/settings/:subsection'
                                                           render={(p) => <Settings/>} />
                                                    <Route path='/settings'>
                                                        <Redirect to={"/settings/account"}/>
                                                    </Route>

                                                    <Route path={'/email/verify'}
                                                           render={(p) => <EmailVerify/>}/>
                                                    <Route path={'/recovery/verify'}
                                                           render={(p) => <RecoveryVerify/>}/>
                                                    <Route path={'/recovery/recover'}
                                                           render={(p) => <RecoveryRecover/>}/>
                                                    <Route path={'/recovery'}
                                                           render={(p) => <Recovery/>}/>

                                                    <Route path='/upload'
                                                           render={(p) => <PhotoUpload /> }/>

                                                    <Route path='/map/:hashtype/:lat/:lng'
                                                           render={(p) => <ActivityMap emitter={this.emitter}/>} />
                                                    <Route path='/map/:hashtype/:hash'
                                                           render={(p) => <ActivityMap emitter={this.emitter}/>} />
                                                    <Route path='/map'
                                                           render={(p) => <ActivityMap emitter={this.emitter}/>} />
                                                    <Route path='/maps'>
                                                        <Redirect to={'/map'}/>
                                                    </Route>

                                                    <Route path='/help'
                                                           render={(p) => <Help/>} />

                                                    {/*<Route path='/user/_:userIdent/showcase/favorites'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userIdent={p.match.params.userIdent} showcased={true} sort={"favorites"} /> }/>*/}
                                                    <Route path='/user/_:userIdent/map/:hashtype/:lat/:lng'
                                                           render={(p) => <ActivityMap userIdent={p.match.params.userIdent} emitter={this.emitter} /> }/>
                                                    <Route path='/user/_:userIdent/map/:hashtype/:hash'
                                                           render={(p) => <ActivityMap userIdent={p.match.params.userIdent} emitter={this.emitter} /> }/>
                                                    <Route path='/user/_:userIdent/map'
                                                           render={(p) => <ActivityMap userIdent={p.match.params.userIdent} emitter={this.emitter} /> }/>
                                                    <Route path='/user/_:userIdent/showcase'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userIdent={p.match.params.userIdent} showcased={true} sort={"showcase_date"} /> }/>
                                                    <Route path='/user/_:userIdent/stories'
                                                           render={(p) => <UserStoryList userIdent={p.match.params.userIdent} />} />
                                                    <Route path='/user/_:userIdent/photos/tag/:tag/publish_date'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userIdent={p.match.params.userIdent} sort={"publish_date"}/> } />
                                                    <Route path='/user/_:userIdent/photos/tag/:tag/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userIdent={p.match.params.userIdent} reversed={true}/> } />
                                                    <Route path='/user/_:userIdent/photos/tag/:tag'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userIdent={p.match.params.userIdent}/> } />
                                                    <Route path='/user/_:userIdent/photos/unpublished/tag/:tag/upload_date'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userIdent={p.match.params.userIdent} sort={"upload_date"}/> }/>
                                                    <Route path='/user/_:userIdent/photos/unpublished/tag/:tag/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userIdent={p.match.params.userIdent} reversed={true}/> }/>
                                                    <Route path='/user/_:userIdent/photos/unpublished/tag/:tag'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userIdent={p.match.params.userIdent}/> }/>
                                                    <Route path='/user/_:userIdent/photos/unpublished/upload_date'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userIdent={p.match.params.userIdent} sort={"upload_date"}/> }/>
                                                    <Route path='/user/_:userIdent/photos/unpublished/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userIdent={p.match.params.userIdent} reversed={true}/> }/>
                                                    <Route path='/user/_:userIdent/photos/unpublished'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userIdent={p.match.params.userIdent}/> }/>
                                                    <Route path='/user/_:userIdent/photos/publish_date'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userIdent={p.match.params.userIdent} sort={"publish_date"} /> }/>
                                                    <Route path='/user/_:userIdent/photos/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userIdent={p.match.params.userIdent} reversed={true} /> }/>
                                                    <Route path='/user/_:userIdent/photos'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userIdent={p.match.params.userIdent} /> }/>
                                                    <Route path='/user/_:userIdent'
                                                           render={(p) => <ProfileGallery userIdent={p.match.params.userIdent} /> }/>

                                                    {/*<Route path='/:userAlias/showcase/favorites'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userAlias={p.match.params.userAlias} showcased={true} sort={"favorites"} /> }/>*/}
                                                    <Route path='/:userAlias/map/:hashtype/:lat/:lng'
                                                           render={(p) => <ActivityMap userAlias={p.match.params.userAlias} emitter={this.emitter} /> }/>
                                                    <Route path='/:userAlias/map/:hashtype/:hash'
                                                           render={(p) => <ActivityMap userAlias={p.match.params.userAlias} emitter={this.emitter} /> }/>
                                                    <Route path='/:userAlias/map'
                                                           render={(p) => <ActivityMap userAlias={p.match.params.userAlias} emitter={this.emitter} /> }/>
                                                    <Route path='/:userAlias/showcase'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userAlias={p.match.params.userAlias} showcased={true} sort={"showcase_date"}A /> }/>
                                                    <Route path='/:userAlias/stories'
                                                           render={(p) => <UserStoryList userAlias={p.match.params.userAlias} />} />
                                                    <Route path='/:userAlias/photos/tag/:tag/publish_date'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userAlias={p.match.params.userAlias} sort={"publish_date"}/> } />
                                                    <Route path='/:userAlias/photos/tag/:tag/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userAlias={p.match.params.userAlias} reversed={true}/> } />
                                                    <Route path='/:userAlias/photos/tag/:tag'
                                                           render={(p) => <UserPhotoGallery unpublished={false} tag={p.match.params.tag} userAlias={p.match.params.userAlias}/> } />
                                                    <Route path='/:userAlias/photos/unpublished/tag/:tag/upload_date'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userAlias={p.match.params.userAlias} sort={"upload_date"}/> }/>
                                                    <Route path='/:userAlias/photos/unpublished/tag/:tag/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userAlias={p.match.params.userAlias} reversed={true}/> }/>
                                                    <Route path='/:userAlias/photos/unpublished/tag/:tag'
                                                           render={(p) => <UserPhotoGallery unpublished={true} tag={p.match.params.tag} userAlias={p.match.params.userAlias}/> }/>
                                                    <Route path='/:userAlias/photos/unpublished/upload_date'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userAlias={p.match.params.userAlias} sort={"upload_date"}/> }/>
                                                    <Route path='/:userAlias/photos/unpublished/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userAlias={p.match.params.userAlias} reversed={true}/> }/>
                                                    <Route path='/:userAlias/photos/unpublished'
                                                           render={(p) => <UserPhotoGallery unpublished={true} userAlias={p.match.params.userAlias}/> }/>
                                                    <Route path='/:userAlias/photos/publish_date'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userAlias={p.match.params.userAlias} sort={"publish_date"}/> }/>
                                                    <Route path='/:userAlias/photos/chronological'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userAlias={p.match.params.userAlias} reversed={true}/> }/>
                                                    <Route path='/:userAlias/photos'
                                                           render={(p) => <UserPhotoGallery unpublished={false} userAlias={p.match.params.userAlias} /> }/>
                                                    <Route path='/:userAlias'
                                                           render={(p) => <ProfileGallery userAlias={p.match.params.userAlias} /> }/>

                                                </Switch>
                                                : <Background bg={Theme.colors.background}/>
                                            }
                                        </Suspense>
                                    </ListingUnderlayDiv>
                                    <PhotoOverlayDiv className={"photo-overlay-div"}>
                                        <CanvasDiv className={"canvas-div"} mini={this.appState.media.mini}>
                                            <Canvas
                                                onRef={ref => {this.canvas = ref; window.requestAnimationFrame(() => this.handleScroll());}}
                                                mouseElement={this.refs.canvasDiv}
                                                emitter={this.emitter}
                                            />
                                        </CanvasDiv>
                                        <CanvasCutoutDiv>
                                            <CanvasCutout ref={"cutout"} data-photo-touch={"true"}/>
                                        </CanvasCutoutDiv>
                                        <LocationWatcher doPhotoTransition={this.doPhotoTransition}/>
                                        <Suspense fallback={<></>}>
                                            <Photo emitter={this.emitter} />
                                        </Suspense>
                                    </PhotoOverlayDiv>
                                    {this.appState.user ?
                                        <PhotoPickerModal
                                            hidden={!this.appState.photoPickDirective}
                                            userIdent={this.appState.user.ident}
                                            select={(photo) => {
                                                let func = this.appState.photoPickDirective.func;
                                                runInAction(() => this.appState.photoPickDirective = null);
                                                func(photo.ident);
                                            }}
                                            forcedFilters={this.appState.photoPickDirective ? this.appState.photoPickDirective.forcedFilters : null}
                                            onRequestClose={() => runInAction(() => this.appState.photoPickDirective = null)}
                                            session={this.appState.photoPickDirective ? this.appState.photoPickDirective.session : "default"}
                                            title={this.appState.photoPickDirective ? this.appState.photoPickDirective.title : null}
                                            cancelAction={() => runInAction(() => this.appState.photoPickDirective = null)}
                                        />
                                        : null
                                    }
                                </NonPluginDiv>
                                <OverlayControls/>
                                <Overlay ref={(ref) => runInAction(() => this.appState.overlayRef = ref)}/>
                                {!this.appState.media.touch ? <Scroller/> : null}
                            </PageDiv>
                        </AppDiv>
                        <Preview generator={generatePreview}/>
                        <div style={{display: "none"}} ref={(ref) => runInAction(() => this.appState.dragElementDiv = ref)}/>
                        <StyledModal
                            isOpen={!!this.appState.genericError}
                            onRequestClose={() => runInAction(() => this.appState.genericError = null)}
                            contentLabel={genericError ? genericError.title : ""}
                        >
                            <ModalContentDiv>
                                <ModalCenterTextDiv>
                                    {genericError ? genericError.error : ""}
                                </ModalCenterTextDiv>
                            </ModalContentDiv>
                            <ModalButtonsDiv>
                                <Button120 onClick={() => runInAction(() => this.appState.genericError = null)}>Close</Button120>
                            </ModalButtonsDiv>
                        </StyledModal>
                    </DndProvider>
                </Provider>
            </Elements>
        );
    }
}

const MainSiteWrapped = withRouter(observer(MainSite));

export default class AppWrapper extends Component {

    constructor(props){
        super(props);

        this.history = this.makeHistory();
    }

    makeHistory(){
        let h = createBrowserHistory();
        h.prelisteners = [];
        h.prelisten = function(f) {
            this.prelisteners.push(f);
            return () => { this.prelisteners = this.prelisteners.filter(e => e !== f) };
        };

        let _replace = h.replace;
        h.replace = function() {
            let args = ["replace"].concat(Array.from(arguments));
            h.prelisteners.forEach((e) => e.apply(null, args));
            _replace.apply(this, arguments);
        };

        let _push = h.push;
        h.push = function() {
            let args = ["push"].concat(Array.from(arguments));
            h.prelisteners.forEach((e) => e.apply(null, args));
            _push.apply(this, arguments);
        } ;

        let _go = h.go;
        h.go = function() {
            let args = ["go"].concat(Array.from(arguments));
            h.prelisteners.forEach((e) => e.apply(null, args));
            _go.apply(this, arguments);
        };

        let _handlePop = h.handlePop;
        h.handlePop = function() {
            let args = ["pop"].concat(Array.from(arguments));
            h.prelisteners.forEach((e) => e.apply(null, args));
            _handlePop.apply(this, arguments);
        };

        return h;
    }

    render() {
        return (
            <Router history={this.history} getUserConfirmation={(message, callback)=> callback(message()) }><MainSiteWrapped /></Router>
        );
    }
}

import React from "react"
const GalleryComponent = React.lazy(() => import("../GalleryComponent"));
const StandardTabbableInfiniteScrollList = React.lazy(() => import("../../../components/StandardTabbableInfiniteScrollList"));

const getRetrievalParams = (segment) => {
    let url = `/gallery/hot/${segment}`;
    let query = {};
    return {url: url, sortAscend: false, query: query};
};

const DATA = [
    {
        id: "hour",
        label: "Hour",
        path: "/explore/hot/hour/photos"
    },
    {
        id: "day",
        label: "Day",
        path: "/explore/hot/day/photos"
    },
    {
        id: "week",
        label: "Week",
        path: "/explore/hot/week/photos"
    },
    {
        id: "month",
        label: "Month",
        path: "/explore/hot/month/photos"
    }
];

const HotGallery = ({segment}) => <StandardTabbableInfiniteScrollList
    label={"Hot Photos"}
    segment={segment}
    data={DATA}
    segmentToParams={getRetrievalParams}
    subtext={"By Viewer Engagement"}
    component={GalleryComponent}
/>;
export const HotGalleryMonth = () => <HotGallery segment={"month"}/>;
export const HotGalleryWeek = () => <HotGallery segment={"week"}/>;
export const HotGalleryDay = () => <HotGallery segment={"day"}/>;
export const HotGalleryHour = () => <HotGallery segment={"hour"}/>;

import React from 'react';
import styled from '@emotion/styled'
import Theme from "../styles/Theme";

const Styled = styled.div`
  display: inline-block;
  padding-left: ${p => p.mobile ? (p.slim ? 5 : 7) : (p.slim ? 8 : 10)}px;
  padding-right: ${p => p.mobile ? (p.slim ? 5 : 7) : (p.slim ? 8 : 10)}px;
  color: ${Theme.colors.dim};
`;
export default ({mobile, slim, className}) => <Styled slim={slim} className={className} mobile={mobile}>/</Styled>
import React from 'react'

import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import styled from '@emotion/styled'
import { ButtonLikeMiniButton, ButtonLikeMiniDimButton } from '../styles/Button'
import moment from 'moment'
import parser from "../helpers/chrono/ChronoParser";
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";

const DateOverlay = styled.div`
  position: absolute;
  background: ${Theme.colors.backgroundDark};
  z-index: 10;
  box-shadow: 0 0 2px 1px rgba(0,0,0,.35), 0 0 20px 5px ${Theme.colors.backgroundDark};
  right: 0;
  top: 40px;
  display: ${p => p.show ? "block" : "none"};
  border-radius: 5px;
  
  .Range {
    ${typeface(14, 400)};
  }
  
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${ Theme.colors.kindaDim } !important;
    color: ${ Theme.colors.background } !important;
    border-radius: 0 !important;
  }
  
  .Range .DayPicker-Day--selected.DayPicker-Day--start,.DayPicker-Day--end {
    background-color: ${ Theme.colors.almostWhite } !important;
    color: ${ Theme.colors.background } !important;
    border-radius: 0 !important;
  }
  
  .Range .DayPicker-Day {
    border-radius: 50%;
  }
  
  .Range .DayPicker-Day--start.DayPicker-Day--end {
    background-color: ${ Theme.colors.almostWhite } !important;
    color: ${ Theme.colors.background } !important;
    border-radius: 50% !important;
  }
  .Range .DayPicker-Day--start:not(.DayPicker-Day--end) {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .Range .DayPicker-Day--end:not(.DayPicker-Day--start) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .Range .DayPicker-Day--today {
    color: ${ Theme.colors.almostWhite } !important;
    ${typeface(14, 700)};
  }
  .Range .DayPicker-Day--today.DayPicker-Day--selected {
    color: ${ Theme.colors.backgroundDarkest } !important;
    ${typeface(14, 700)};
  }
`;

const StyledInput = styled.input`
  
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.35), inset 0 0 2px 0 rgba(255,255,255,.15);
  border: none;
  cursor: pointer;
  height: 30px;
  width: 158px;
  border-radius: 3px;
  padding: 5px;
  outline: none !important;
  ${typeface(12, 500, -.0675)};
  
  color: ${p => p.active ? Theme.colors.background : Theme.colors.kindaDim};
  background-color: ${p => p.active ? Theme.colors.kindaDim : Theme.colors.backgroundLighter};
`;

const ButtonHolder = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const HolderDiv = styled.div`
  text-align: right;
  position: relative;
`;

const CloseButtonHolder = styled.div`
  position: absolute;
  right: 3px;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CloseButton = styled.div`
  border-radius: 3px;
  ${typeface(16, 400)};
  user-select: none;
  cursor: pointer;
  text-align: center;
  
  line-height: 21px;
  height: 22px;
  width: 22px;
  
  color: ${Theme.colors.dim};
  
  &:hover {
    color: ${Theme.colors.dimmer};
    background: rgba(255,255,255,.4);
  }
`;

const ButtonLikeMiniDimDivStyled = styled(ButtonLikeMiniDimButton)`
  padding-left: 8px;
  padding-right: 8px;
`;

const ButtonLikeMiniDivStyled = styled(ButtonLikeMiniButton)`
  padding-left: 8px;
  padding-right: 8px;
`;

export default class DateTimeRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);

        this.setFrom = this.setFrom.bind(this);
        this.setTo = this.setTo.bind(this);
        this.setFromTo = this.setFromTo.bind(this);
        this.resetFromTo = this.resetFromTo.bind(this);
        this.notify = this.notify.bind(this);
        this.hide = this.hide.bind(this);
        this.parseInput = this.parseInput.bind(this);

        this.state = this.getInitialState();
        this.from = null;
        this.to = null;
        this.inputValue = null;
        this.activeValue = null;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    getInitialState() {
        return {
            from: null,
            to: null,
            mouseOver: null,
            showing: false,
            value: "",
            active: false
        };
    }

    setFrom(date){
        if (date) {
            this.from = date.startOf('day');
        } else {
            this.from = null;
        }
        this.setState({from: date});
    }

    setTo(date){
        if (date) {
            this.to = date.startOf('day');
        } else {
            this.to = null;
        }
        this.setState({to: date});
    }

    setFromTo(from, to){
        this.setFrom(from);
        this.setTo(to);
    }

    resetFromTo(){
        this.from = null;
        this.to = null;
        this.setState({from: null, to: null});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.value !== this.props.value){
            if (this.props.value) {
                let start = this.props.value[0];
                let end = this.props.value[1];
                this.setFromTo(start, end);
            } else {
                this.setFromTo(null, null);
            }
            this.notify(false);
        }
    }

    notify(callback=true){
        if(this.from && !this.to){
            this.setTo(this.from);
        }
        if(this.to && !this.from){
            this.setFrom(this.to);
        }

        if (this.props.onChange && (this.from && this.to) || (!this.from && !this.to)){
            this.hide();
            let from = this.from ? this.from.clone() : null;
            let to = this.to ? this.to.clone() : null;
            if(callback) {
                this.props.onChange([from, to]);
            }
            if(this.from && this.to){
                let fromText = this.from.format("YYYY/M/D");
                let toText = this.to.format("YYYY/M/D");
                if (this.from.isSame(this.to)){
                    this.setState({value: fromText, active: true});
                } else {
                    this.setState({value: fromText + " - " + toText, active: true});
                }
            } else {
                this.setState({value: "", active: false});
            }
        }
    }

    handleDayClick(day) {
        day = moment(day);
        if(this.from === null && this.to === null){
            this.setFromTo(day, null);
        } else if (this.from !== null && this.to === null) {
            this.setFromTo(this.from, day);
            if (day > this.from){
                this.setFromTo(this.from, day);
            } else {
                this.setFromTo(day, this.from);
            }
            this.notify();
        } else if (this.to !== null && this.from === null) {
            if (day < this.to){
                this.setFromTo(day, this.to);
            } else {
                this.setFromTo(day, this.to);
            }
            this.notify();
        } else {
            this.setFromTo(day, null);
        }
    }

    handleDayMouseEnter(day) {
        day = moment(day);
        this.setState({
            mouseOver: day,
        });
    }

    handleResetClick() {
        this.inputValue = "";
        this.activeValue = "";
        this.setState(this.getInitialState());
        this.resetFromTo();
        this.notify();
    }

    handleClick(event) {
        if (this.wrapper && !this.wrapper.contains(event.target)) {
            this.hide();
        }
        this.setState({
            mouseOver: null
        });
    }

    hide(){
        this.setState({showing: false});
    }

    handleChangeValue(event) {
        this.inputValue = event.target.value;
        this.setState({value: event.target.value});
    }

    parseInput(){
        if(this.activeValue !== this.inputValue){

            let parsed = this.parseDate(this.inputValue);
            if (parsed){
                this.setFromTo(parsed[0], parsed[1]);
                this.notify();
                this.hide();
            }

            this.activeValue = this.inputValue;
        }
    }

    parseDate(v){
        let results = parser.parse(v, new Date(), {forwardDate: false});
        if (results.length > 0){
            let result = results[0];
            return [
                moment(result.start.date()),
                result.end ? moment(result.end.date()) : moment(result.start.date())
            ];
        } else {
            return null;
        }
    }

    render() {
        const { from, to, mouseOver } = this.state;

        let fromDate = from ? from.toDate() : null;
        let toDate = to ? to.toDate() : null;
        let mouseOverDate = mouseOver ? mouseOver.toDate() : null;

        if (fromDate === null){
            fromDate = mouseOverDate;
        } else if (toDate === null){
            toDate = mouseOverDate;
        }
        if (toDate === null){
            toDate = fromDate;
        }
        if (fromDate > toDate && toDate !== null){
            let t = toDate;
            toDate = fromDate;
            fromDate = t;
        }

        const selectedDays = [ { from: fromDate, to: toDate } ];
        const modifiers = { start: fromDate, end: toDate };

        return (
            <div>
                <HolderDiv ref={(el) => (this.wrapper = el)}>
                    <StyledInput
                        onClick={() => this.setState({showing: true})}
                        value={this.state.value}
                        onChange={this.handleChangeValue}
                        onBlur={this.parseInput}
                        placeholder={"–"}
                        active={this.state.active}
                        onKeyDown={(e) => { if(e.key === "Enter"){ this.parseInput() } }}
                    />
                    { (this.state.value !== "") ?
                        <CloseButtonHolder><CloseButton onClick={this.handleResetClick}>×</CloseButton></CloseButtonHolder>
                        : null
                    }
                    <DateOverlay show={this.state.showing}>
                        <DayPicker
                            className={"Range"}
                            numberOfMonths={1}
                            selectedDays={selectedDays}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                            onDayMouseEnter={this.handleDayMouseEnter}
                        />
                        <ButtonHolder>
                            <ButtonLikeMiniDimDivStyled onClick={() => {
                                this.resetFromTo();
                                this.notify();
                            }}>
                                ✕ Clear
                            </ButtonLikeMiniDimDivStyled>
                            <ButtonLikeMiniDivStyled onClick={() => {
                                this.notify();
                            }}>
                                ✓ Done
                            </ButtonLikeMiniDivStyled>
                        </ButtonHolder>
                    </DateOverlay>
                </HolderDiv>
            </div>
        );
    }
}
import React from 'react'
import StoryItem from "./components/StoryItem";
import InfiniteScrollList from "../../components/InfiniteScrollList";
import styled from '@emotion/styled'
import {inject, observer} from 'mobx-react'

const ElementFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6px;
  padding-left: 6px;
  margin: 10px 0;
`;

const ElementFlexForward = styled(ElementFlex)`
  flex-direction: column;
`;

const ElementFlexReverse = styled(ElementFlex)`
  flex-direction: column-reverse;
`;

const ElementHolder = styled.div`
  margin: 10px auto;
  width: 100%;
  max-width: 720px;
  contain: layout;
`;

export const StoryItemStyledAsList = styled(StoryItem)`
  background: none;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  ${p => (p.showSnippet && p.story.firstTextBlock && p.story.firstTextBlock !== "") ? `min-height: ${p.doubleSize ? 240 : 140}px` : ``};
  padding-left: ${p => p.doubleSize ? 40 : 14}px;
  padding-right: ${p => p.doubleSize ? 40 : 14}px;
  
  transition: none;
  
  &:hover, &.focus-visible {
    background: none;
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0 1px 6px 0 rgba(0,0,0,.7);
  }
`;

export default inject("appState")(observer(({params, waitFor, header, appState, showAuthor, showSnippet, showType, emptyMessage, doneMessage}) => {

    let renderElement = (data, navCallback) => {
        return <ElementHolder key={data.ident}>
            <StoryItemStyledAsList
                onNav={navCallback}
                doubleSize={!appState.media.skinny}
                maxWidth={720}
                story={data}
                showAuthor={showAuthor !== undefined ? showAuthor : true}
                showSnippet={showSnippet !== undefined ? showSnippet : true}
                showType={showType !== undefined ? showType : false}
            />
        </ElementHolder>;
    };

    let renderContainerReverse = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexReverse ref={setRef}>
                {children}
            </ElementFlexReverse>
            :
            <></>;
    };

    let renderContainer = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexForward ref={setRef}>
                {children}
            </ElementFlexForward>
            :
            <></>;
    };

    return <InfiniteScrollList
        params={params}
        waitFor={waitFor}
        header={header}
        renderElement={renderElement}
        renderContainerReverse={renderContainerReverse}
        renderContainer={renderContainer}
        emptyMessage={emptyMessage}
        doneMessage={doneMessage}
    />
}));
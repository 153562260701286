import React, {useState} from 'react';
import styled from '@emotion/styled';
import {typeface} from "../../styles/Typeface";
import Theme from "../../styles/Theme";
import {SectionHeader} from "../storylist/user/UserStoryList";
import {CameraIconLightShadowed} from "../../svg/Icons";
import {
    FormGroupDiv,
    FormLabel,
    FormLabelDiv,
    ModalButtonsDiv,
    ModalCenterTextDiv,
    ModalContentDiv
} from "../../styles/Modal";
import {Button120, Button120Gray} from "../../styles/Button";
import {RadioGroup, Radio} from 'react-radio-group'
import StyledModal from "../../components/StyledModal";
import {inject, observer} from 'mobx-react';
import {runInAction} from 'mobx'

const Container = styled.div`
  width: 100%;
  height: 100%;
  ${p => p.mini ? `padding: 5px 0 0 0;` : `padding: 10px;`}
  display: flex;
  flex-direction: column;
  align-items: center;
  ${typeface(14, 400)};
  color: ${Theme.colors.almostWhite};
`;

const SectionHeaderTop = styled(SectionHeader)`
  padding-top: 0px;
  ${p => p.mini ? `
    padding-bottom: 2px;
    color: ${Theme.colors.dim};
  ` : `` }
  ${p => typeface(p.mini ? 12 : 13, 500, .1)};
`;

const Summary = styled.div`
  ${p => p.mini ? 
    `margin: 0 15px 0 15px;` :
    `margin: 0 15px 5px 15px;`
  }
  ${p => p.mini ? `
  color: ${Theme.colors.kindaDim};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `: ``}
  ${p => typeface(p.mini ? 12 : 14, 400)};
  text-align: center;
`;

const Content = styled.div`
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductsHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Products = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ProductName = styled.div`
  ${typeface(12, 600)};
  margin-bottom: 4px;
  text-align: center;
  ${p => p.mini ? `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `: ``}
`;

const ProductPoints = styled.div`
  text-align: center;
`;

const ProductPoint = styled.div`
  ${typeface(11, 400)};
  color: ${Theme.colors.notQuiteDim};
  margin: ${p => p.mini ? 1 : 3}px;
`;

const Product = styled.a`
  margin: ${p => p.mini ? 5 : 8}px;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 50%;
  
  display: flex;
  border-radius: 5px;
  padding: ${p => p.mini ? 5 : 10}px;
  background: ${Theme.colors.backgroundLight};
  box-shadow: 0px 0px 0px 1px rgba(255,255,255,.06) inset, 0 0 2px 1px rgba(0,0,0,.25);
  min-height: 44px;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background: ${Theme.colors.backgroundLighter};
    ${ProductName} {
      color: ${Theme.colors.almostWhite};
    }
    ${ProductPoint} {
      color: ${Theme.colors.almostDim};
    }
  }
`;

const SystemSection = styled.div`
  color: ${Theme.colors.dim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  width: 100%;
  text-align: right;
  ${typeface(11, 600)};
`;

const SystemIcon = styled.span`
  color: ${Theme.colors.dimmer};
  margin-right: 6px;
  vertical-align: -4px;
`;

const SystemText = styled.span`
  vertical-align: baseline;
`;

const MountType = styled.span`
  color: ${Theme.colors.notQuiteDim};
`;

const MountLabel = styled.span`
  color: ${Theme.colors.dimmer};
  padding-right: 8px;
  vertical-align: baseline;
`;

const SystemButton = styled.a`
  &:hover {
    ${SystemText}, ${MountLabel} {
      color: ${Theme.colors.kindaDim};
    }
    ${SystemIcon} {
      color: ${Theme.colors.nearingDim};
    }
  }
`;

const NotFoundDiv = styled.div`
  width: ${p => p.mini ? 220 : 240}px;
  height: ${p => p.mini ? 50 : 80}px;
  border-radius: 30px;
  border: dashed 2px rgba(255,255,255,.065);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.dimmer};
  ${p => typeface(p.mini ? 12 : 14, 400)};
`;


let displayName = (name) => {
    return name.replaceAll(/\(SEL\w+\)/g, "");
};

let mountDisplayName = (mount) => {
    return {
        "sony_e": "Sony E",
        "canon_rf": "Canon RF",
        "canon_ef": "Canon EF",
        "nikon_z": "Nikon Z",
        "nikon_f": "Nikon F",
    }[mount];
};

export default inject("appState")(observer(({data, appState}) => {
    let info = data.properties.productsInfo;
    let [selectedMount, setSelectedMount] = useState(appState.selectedMount);
    let [showMountSelect, setShowMountSelect] = useState(false);
    let closeMountSelect = () => setShowMountSelect(false);
    let openMountSelect = () => {
        setSelectedMount(appState.mapPreferences.productMount);
        setShowMountSelect(true);
    }
    let applyModalSelect = () => {
        let existing = JSON.parse(localStorage.getItem("mapPreferences")) ?? { "productMount" : "sony_e" };
        existing.productMount = selectedMount;
        localStorage.setItem("mapPreferences", JSON.stringify(existing));
        runInAction(() => {
            appState.mapPreferences.productMount = selectedMount;
        });
        closeMountSelect();
    };
    let mountSelectChanged = (e) => {
        setSelectedMount(e);
    };
    return <>
        <Container mini={appState.media.somewhatMini}>
            <SectionHeaderTop mini={appState.media.somewhatMini}>Equipment Suggestion</SectionHeaderTop>
            {info ? <Content>
                <Summary mini={appState.media.somewhatMini}>{info.summary}</Summary>
                <ProductsHolder>
                    <Products>
                        {info.products.map(p =>
                            <Product href={p.details.url} target={"_blank"} key={p.details.name} mini={appState.media.somewhatMini}>
                                <ProductName mini={appState.media.somewhatMini}>{displayName(p.details.name)}</ProductName>
                                <ProductPoints>
                                    {p.rationale.points.map(point =>
                                        <div key={point}>
                                            <ProductPoint mini={appState.media.somewhatMini}>{point}</ProductPoint>
                                        </div>
                                    )}
                                </ProductPoints>
                            </Product>
                        )}
                        {info.products.length === 0 ?
                            <NotFoundDiv mini={appState.media.somewhatMini}>More products coming soon!</NotFoundDiv>
                            : null
                        }
                    </Products>
                </ProductsHolder>
            </Content> : null}
            <SystemSection>
                <SystemButton onClick={openMountSelect}>
                    <MountLabel>Mount type:</MountLabel>
                    <SystemIcon><CameraIconLightShadowed scale={16}/></SystemIcon>
                    <SystemText>{mountDisplayName(appState.mapPreferences.productMount)}</SystemText>
                </SystemButton>
            </SystemSection>
        </Container>
        <StyledModal
            isOpen={showMountSelect}
            onRequestClose={closeMountSelect}
            contentLabel="Select Mount Type"
            key={"selectMountModal"}
        >
            <ModalContentDiv>
                <ModalCenterTextDiv>
                    <FormGroupDiv>
                        <RadioGroup Component={"span"} selectedValue={selectedMount}
                                    onChange={mountSelectChanged}>
                            <FormLabel>
                                <Radio value={"sony_e"}/>
                                <FormLabelDiv>Sony E <MountType>(Mirrorless)</MountType></FormLabelDiv>
                            </FormLabel>
                            <FormLabel>
                                <Radio value={"canon_rf"}/>
                                <FormLabelDiv>Canon RF <MountType>(Mirrorless)</MountType></FormLabelDiv>
                            </FormLabel>
                            <FormLabel>
                                <Radio value={"canon_ef"}/>
                                <FormLabelDiv>Canon EF <MountType>(DSLR)</MountType></FormLabelDiv>
                            </FormLabel>
                            <FormLabel>
                                <Radio value={"nikon_z"}/>
                                <FormLabelDiv>Nikon Z <MountType>(Mirrorless)</MountType></FormLabelDiv>
                            </FormLabel>
                            <FormLabel>
                                <Radio value={"nikon_f"}/>
                                <FormLabelDiv>Nikon F <MountType>(DSLR)</MountType></FormLabelDiv>
                            </FormLabel>
                        </RadioGroup>
                    </FormGroupDiv>
                </ModalCenterTextDiv>
            </ModalContentDiv>
            <ModalButtonsDiv>
                <Button120 onClick={closeMountSelect}>Cancel</Button120>
                <Button120Gray onClick={applyModalSelect}>Apply</Button120Gray>
            </ModalButtonsDiv>
        </StyledModal>
    </>
}));
import React, {useState, useEffect} from 'react'
import styled from '@emotion/styled'
import WindowHelper from "../helpers/WindowHelper";

const Spinner = (props) => {
    let scale = props.scale ?? 38;
    return (
        <svg width={scale} height={scale} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
        </svg>
    );
};

export const SpinnerCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScreenVerticalCenter = styled.div`
  width: 100%;
  height: ${p => p.customHeight ? p.customHeight : `calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px)`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FadeInDiv = styled.div`
  transition: opacity 250ms;
  opacity: ${p => p.opacity};
`;

export const SpinnerFadeIn = () => {
    let [visible, setVisible] = useState(false);
    useEffect(() => {
        let id = setTimeout(() => setVisible(true), 500);
        return () => clearTimeout(id);
    }, [])
    return <FadeInDiv opacity={visible ? 1 : 0}><Spinner/></FadeInDiv>;
};

export default Spinner;
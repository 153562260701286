
export default {
    colors: {
        backgroundDarkestTransparent: "rgba(20,20,20,.9)",
        backgroundDarkest: "rgb(20,20,20)",
        backgroundDarker: "rgb(25,25,25)",
        backgroundDark: "rgb(30,30,30)",
        background: "rgb(35,35,35)",
        backgroundTransparent: "rgba(35,35,35,.9)",
        backgroundMoreTransparent: "rgba(35,35,35,.7)",
        backgroundLight: "rgb(40,40,40)",
        backgroundLighter: "rgb(45,45,45)",
        dimmest:  "#343434",
        dimmer:  "#484848",
        dim:  "#666666",
        dimTransparent:  "rgba(80,80,80,.925)",
        notQuiteDim:  "#777777",
        nearingDim:  "#8A8A8A",
        almostDim: "#969696",
        kindaDim: "#B7B7B7",
        almostWhite: "#D7D7D7",
        red: "#FF8080",
        green: "#80FF80",
        ai: "#FF6438",
        aiReadable: "#ffb899",
        aiDark: "#b34627",
        minus: "#3870ff",
        minusReadable: "#7e8fb9",
    },
    bgs: {
        gradient: `linear-gradient(90deg, rgba(35,35,35,1) 0%, rgba(45,45,45,1) 40%, rgba(45,45,45,1) 60%, rgba(35,35,35,1) 100%)`,
        metal30: `linear-gradient(30deg, rgba(35,35,35,1) 0%, rgba(50,50,50,1) 50%, rgba(35,35,35,1) 100%)`,
        metal30Bright: `linear-gradient(30deg, rgba(55,55,55,1) 0%, rgba(75,75,75,1) 50%, rgba(55,55,55,1) 100%)`
    }
};
import {React, useRef, useEffect} from "react";
import {inject, observer} from "mobx-react";
import { runInAction, autorun, toJS, observable } from "mobx";
import { getSignedUrlSync } from "../helpers/SignedUrlLoader";
import styled from '@emotion/styled';
import Spinner from './Spinner';
import {urlTitleSegment} from "../helpers/MiscHelpers";
import { css } from '@emotion/react';

const HolderDiv = styled.div`
    ${ p => !p.flexible ? `
      contain: size layout;
      width: ${p.width}px;
      height: ${p.height}px;
    ` : ``};
    display:inline-flex;
    margin:${(props) => (props.noMargin ? 0 : (props.skinny ? 15 : 30))}px;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

const ThumbnailImage = styled.img`
  height: ${ p => p.scaledHeight }px;
  width: ${ p => p.scaledWidth }px;
  ${ p => p.wasUnprocessed ? `transition: 250ms opacity` : ``};
  ${ p => p.wasUnprocessed ? `opacity: ${p.photoLoaded ? `1` : `0`}` : ``};
  ${`image-rendering: -webkit-optimize-contrast`};
`;

const thumbAnchorStyle = p => css`
  background: #1E1E1E;
  padding: 0;
  margin: 0;
  border: none;
  display: block;
  box-shadow: 0 0 ${p.skinny ? `8px` : `12px`} rgba(0,0,0,.5);
  transition: 75ms box-shadow, 75ms transform;
`;

const ThumbAnchor = styled.a`
  ${p => thumbAnchorStyle(p)};
  ${ p => p.canClick ? ``: `pointer-events: none`};
  cursor: ${p => p.lightbox ? `zoom-in` : `pointer`};
  &:active {
    box-shadow: 0 0 ${p => p.skinny ? `${8*2.0/3.0}px` : `8px`} rgba(0,0,0,.65);
    transform: scale(.97);
  }
`;

const ThumbAnchorDiv = styled.div`
  ${p => thumbAnchorStyle(p)};
`;

const VisibleSwitch = styled.div`
    opacity: ${props => props.vis ? "1" : "0"};
    ${p => p.useTransition ? `transition: 200ms opacity` : ``};
`;

const UnprocessedContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const Thumbnail = (props) => {
    let stateRef = useRef(observable({
        filename: "",
        iconVisible: false,
        photoLoaded: false,
        height:1,
        width:1,
        processed: true,
        wasUnprocessed: false,
        useTransition: false
    }));
    let state = stateRef.current;

    let imgRef = useRef();

    let getPreferredFactor = () => {
        return ((props.appState.media.skinny || props.forceSkinny) ? 128 : 256) * (props.doubleSize ? 2 : 1) * (props.halfSize ? .5 : 1);
    };

    let update = () => {
        let factor = getPreferredFactor();
        let multiplier = ((window.devicePixelRatio > 1) ? 2 : 1);
        let targetFactor = factor * multiplier;
        if (props.thumb.processed) {
            let image = props.thumb.images[0];
            props.thumb.images.forEach(i => {
                if (i.factor === targetFactor){
                    image = i;
                }
            });
            runInAction(() => {
                state.filename = getSignedUrlSync(image.filename);
                state.width = image.w;
                state.height = image.h;
                state.scaledWidth = image.w / multiplier;
                state.scaledHeight = image.h / multiplier;
                state.factor = factor;
                state.processed = true;
                state.iconVisible = false;
                state.photoLoaded = false;
                state.useTransition = false;
            });
        } else {
            let ow = props.thumb.w;
            let oh = props.thumb.h;
            let normW = 1;
            let normH = 1;
            if (ow && oh) {
                normW = ow / Math.max(ow, oh);
                normH = oh / Math.max(ow, oh);
            }

            let area = normW * normH;
            let adj = 1 - Math.max(area - .85, 0.0) * .9;

            let w = normW * adj * 256;
            let h = normH * adj * 256;

            runInAction(() => {
                state.filename = "";
                state.width = w;
                state.height = h;
                state.factor = factor;
                state.scaledWidth = w;
                state.scaledHeight = h;
                state.processed = false;
                state.wasUnprocessed = true;
                state.iconVisible = true;
                state.photoLoaded = false;
                state.useTransition = true;
            });
        }
    }

    useEffect(() => autorun(() => {
        update();
    }), [props.thumb?.ident]);

    let loaded = (e) => {
        if (props.onLoad) {
            props.onLoad();
        }
        runInAction(() => {
            state.iconVisible = true;
            state.photoLoaded = true;
            state.useTransition = true;
        });
    }

    let speculateThumbnail = async () => {
        if(props.thumb.processed){
            let fnArr = props.thumb.images;
            let fnFilter = fnArr.filter(i => i.factor === getPreferredFactor() * ((window.devicePixelRatio>1) ? 2 : 1));
            let bitmap = null;
            if (imgRef.current && 'createImageBitmap' in window && imgRef.current.complete && imgRef.current.naturalWidth !== 0) {
                try {
                    bitmap = await createImageBitmap(imgRef.current);
                } catch (e) {
                    // no-op, just use filename/url
                }
            }
            runInAction(() => {
                props.appState.speculate({
                    ident: props.thumb.ident,
                    w: props.thumb.w,
                    h: props.thumb.h,
                    fn: fnFilter.length > 0 ? fnFilter[0].filename : fnArr[0].filename,
                    bitmap: bitmap
                });
            });
        }
    }

    let click = (e) => {
        if (state.processed) {
            let url = "/photo/_" + props.thumb.ident + urlTitleSegment(props.thumb.title);
            if (e && e.ctrlKey || e.metaKey) {
                // allow default
            } else {
                if (props.onNav) {
                    props.onNav();
                }
                runInAction(() => {
                    props.appState.lastSeriesNav = "next";
                    props.appState.underlayScrollPos = props.appState.viewport.windowScrollY;
                    props.appState.photo.initTransition = true;
                    props.appState.photo.fadeIn = true;
                    props.appState.photo.lightbox = !!props.lightbox;
                    props.appState.photo.ident = props.thumb.ident;
                    props.appState.photo.sortValue = props.thumb.sortValue;
                    props.appState.history.push(url, {
                        usePhotoOverlay: true,
                        photoIdent: props.thumb.ident,
                        photoSortValue: props.thumb.sortValue,
                        photoSeries: props.underlaySeries,
                        underlayLocation: toJS(props.appState.underlayLocation)
                    });
                });
                if (e) {
                    e.preventDefault();
                }
            }
        } else {
            if (e) {
                e.preventDefault();
            }
        }
    }

    useEffect(() => {
        if (props.doClickSetter) {
            props.doClickSetter(click);
        }

        if (props.doSpeculateSetter) {
            props.doSpeculateSetter(speculateThumbnail);
        }
    });

    let ThumbAnchorClass = props.noninteractive ? ThumbAnchorDiv : ThumbAnchor;
    let skinny = props.appState.media.skinny || props.forceSkinny;
    return (
        <HolderDiv
            noMargin={props.noMargin}
            skinny={skinny}
            data-sort-value={props.thumb.sortValue}
            data-ident={props.thumb.ident}
            onMouseEnter={speculateThumbnail}
            onTouchStart={speculateThumbnail}
            width={state.factor}
            height={state.scaledHeight}
            flexible={props.flexible}
        >
            <VisibleSwitch key="key" vis={state.iconVisible && !props.invisible} useTransition={state.useTransition}>
                <ThumbAnchorClass
                    lightbox={props.lightbox}
                    className={props.className}
                    href={`/photo/_${props.thumb.ident}${urlTitleSegment(props.thumb.title)}`}
                    onClick={props.noninteractive ? null : click}
                    onFocus={speculateThumbnail}
                    skinny={skinny}
                    canClick={state.processed && !props.noninteractive}
                    noninteractive={props.noninteractive}
                >
                    {state.processed ?
                        <ThumbnailImage
                            loading="lazy"
                            skinny={skinny}
                            scaledHeight={state.scaledHeight}
                            scaledWidth={state.scaledWidth}
                            crossOrigin={"Anonymous"}
                            width={state.width}
                            height={state.height}
                            src={state.filename}
                            onLoad={loaded}
                            ref={imgRef}
                            wasUnprocessed={state.wasUnprocessed}
                            photoLoaded={state.photoLoaded}
                        />
                        :
                        <UnprocessedContainer
                            width={state.scaledWidth}
                            height={state.scaledHeight}
                        >
                            <Spinner/>
                        </UnprocessedContainer>
                    }
                </ThumbAnchorClass>
            </VisibleSwitch>
        </HolderDiv>
    );
}

export default inject('appState')(observer(Thumbnail));
import React, {useState, useEffect} from 'react';
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";
import DumbLinkContainer from "./DumbLinkContainer";
import styled from '@emotion/styled';

export const Subtext = styled.div`
  text-align: center;
  color: ${Theme.colors.dim};
  margin-bottom: 3px;
  text-shadow: 0 2px 1px rgba(0, 0, 0, .25);
`;

const Spacer = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  color: ${Theme.colors.dimmer};
  ${typeface(16, 700)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
`;

const AStyled = styled.a`
  ${typeface(16, 700)};
  color: ${Theme.colors.dimmer};
  &:hover, &.focus-visible {
    color: ${Theme.colors.almostDim};
  }
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
  display: inline-block;
`;

const CurrentPage = styled.span`
  ${typeface(16, 700)};
  color: ${Theme.colors.nearingDim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  display: inline-block;
`;

const Links = styled.div`
  margin-top: 4px;
`

export default ({segment, data, segmentToParams, subtext, component, header, waitFor, ...others}) => {
    let [loaded, setLoaded] = useState(false);
    useEffect(() => {
        (async () => {
            if (waitFor) {
                await waitFor;
            }
            setLoaded(true);
        })();
    }, []);
    let links = data.map(d => {
        if (d.id === segment){
            return <CurrentPage key={d.id}>
                {d.label}
            </CurrentPage>;
        } else {
            return <DumbLinkContainer to={d.path} key={d.id}>
                <AStyled>{d.label}</AStyled>
            </DumbLinkContainer>;
        }
    }).flatMap((value, index, array) =>
        array.length - 1 !== index ? [value, <Spacer key={`spacer-${index}`}>·</Spacer>] : [value]
    );
    const ContainerComponent = component;
    return <>
        <ContainerComponent
            header={
                <>
                    {header}
                    {loaded ? <Links><Subtext>{links}</Subtext></Links> : null}
                </>
            }
            params={segmentToParams(segment)}
            waitFor={waitFor}
            {...others}
        />
    </>;
};
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent);
const knownBad = browser.satisfies({
    chrome: ">=1"
}) || true; // browser support is generally bad -- turn it off for now
const safari = browser.satisfies({safari: ">=1"});

const BLUR_SUPPORT = "(backdrop-filter: blur(15px)) or (-webkit-backdrop-filter: blur(15px))";
const TRUE_CONDITION = "(background-color: black)";
const FILTER_CONDITION = knownBad ? "(not " + TRUE_CONDITION + ")" : BLUR_SUPPORT;

export const backdropFilterSupportCondition = () => `${FILTER_CONDITION}`;
export const isSafari = () => safari;

export const hasSmoothGradients = () => browser.satisfies({chrome: ">=1"}) || browser.satisfies({safari: ">=1"});

const yearParser = {};
yearParser.pattern = function () { return /(\d\d\d\d)/; };
yearParser.extract = function (context, match) {
    let result = context.createParsingResult(match.index, match[0]);
    result.start = context.createParsingComponents();
    result.start.assign('year', parseInt(match[1]));
    return result;
};

export default yearParser;
import React, {useRef, useEffect} from 'react';
import {observable, runInAction} from 'mobx'
import styled from '@emotion/styled';
import Theme from "../../styles/Theme";
import {typeface} from "../../styles/Typeface";
import MapThumbnail from "./MapThumbnail";
import FadeIn from "../../components/FadeIn";
import {observer, inject} from 'mobx-react';
import TabDisplay from "./TabDisplay";
import {MapsApi} from "../../components/Api";
import {RightIconLightShadowed, UpvoteLightShadowed} from "../../svg/Icons";
import {SpinnerCenter, SpinnerFadeIn} from "../../components/Spinner";
import {AiTabTitle} from "./WikiDisplay";

const SummaryDiv = styled.div`
  display: flex;
  flex-direction: column;
  ${p => p.mini ? `height: 100%` : `min-height: 100%;`};
  justify-content: center;
  padding-bottom: 8px;
`;

const Summary = styled.div`
  margin: ${p => p.mini ? 0 : 2}px 20px 0 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Description = styled.div`
  ${p => typeface(p.mini ? 13 : 14, 400)};
  color: ${Theme.colors.almostWhite};
`;

const PlaceSection = styled.div`
  ${p => typeface(p.mini ? 13 : 14, 400)};
  ${p => p.mini ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;` :
  ``};
  color: ${Theme.colors.nearingDim};
`;

const PlaceSeperator = styled.span`
  color: ${Theme.colors.nearingDim};
`;

const PlaceName = styled.span`
  color: ${Theme.colors.nearingDim};
`;

const Grid = styled.div`
  ${p => p.horizontal ?
    `width: 100%;
     display: flex;
     gap: 10px;
     height: 120px;
    ` :
    `display: grid;
     grid-template-columns: 120px 120px 120px;
     justify-content: center;
     margin-bottom: 20px;
    `
  }
`;

const GridSmall = styled(Grid)`
  width: 100%;
  ${p => p.horizontal ? `
    gap: 0px;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 0px;
    height: 50px;
  ` : `
    margin-bottom: 10px;
    grid-template-columns: 110px 110px 110px;
    align-content: stretch;
    align-items: center;
    flex-grow: 4;
  `}
`;

const LinkItem = styled.div`
  ${p => typeface(p.mini ? 12 : 14, 400)};
  color: ${Theme.colors.almostWhite};
  ${p => p.mini ? 
    `display: flex;
     align-items: center;` : 
    `margin: 10px 5px;`
  }
`;

const LinkItemSmall = styled(LinkItem)`
  ${typeface(12, 400)};
  color: ${Theme.colors.almostDim};
  margin: ${p => p.mini ? 0 : 5}px;
`;

const Label = styled.div`
  text-align: center;
  width: 100%;
  ${p => p.mini ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;` :
  ``};
`;

const LabelSmall = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const BlankImg = styled.div`
  width: ${p => p.mini ? 30 : 60}px;
  height: ${p => p.mini ? 30 : 60}px;
  background: ${Theme.colors.backgroundLighter};
  box-shadow: 0px 0px ${p => p.mini ? 3 : 6}px 0px rgba(0,0,0,.75), inset 0px 0px 0px 1px rgba(255,255,255,.075);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${p => p.mini ? `margin: 0 6px;` : ``};
`;

const Link = styled.a`
  text-align: center;
`;

const ThumbHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.mini ? `height: 70px;` : `height: 110px;`}
  ${p => p.mini ? `width: 90px;` : ``}
`;

const ThumbHolderSmall = styled(ThumbHolder)`
  height: ${p => p.mini ? 60 : 82}px;
  ${p => p.mini ? `
    width: 44px;` 
  : `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const MapIcon = styled.div`
  width: ${p => p.mini ? 20 : 32}px;
  height: ${p => p.mini ? 20 : 32}px;
  background-color: ${Theme.colors.nearingDim};
  mask-image: url(${p => p.mask});
  mask-size: cover;
`;

const BlurbSection = styled.div`
  border-radius: 10px;
  ${p => p.mini ? `padding: 6px 9px;` : `padding: 8px 12px;`}
  background: ${Theme.colors.backgroundLight};
  box-shadow: 0px 0px 0px 1px rgba(255,255,255,.06) inset, 0 0 2px 1px rgba(0,0,0,.25);
  width: 100%;
`;

const SubStatSection = styled.div`
  ${p => typeface(p.mini ? 11 : 12, 400)};
  margin-top: ${p => p.mini ? 6 : 15}px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const SubStat = styled.div`
  color: ${p => p.mini ? Theme.colors.dim : Theme.colors.dim};
`;

const SubStatValue = styled.div`
  color: ${p => p.mini ? Theme.colors.nearingDim : Theme.colors.nearingDim};
  display: inline-block;
  margin-left: 6px;
`;

export const MapIconHolder = ({icon, mini}) => {
    return <BlankImg mini={mini}>
        <MapIcon mask={`/static/maki/${icon}.svg`} mini={mini}/>
    </BlankImg>
};

let uppercase = (s) => s[0].toUpperCase() + s.slice(1);

let SummaryTab = inject("appState")(observer(({props, queryData, onClickPoi, onClickSpecies, pois, species, appState}) => {
    let built = props.landuse ? (props.landuse.built ?? 0)*.01 : 0;
    let pctHuman = Math.min(Math.exp(props.pop / 30000), built ?? 0, 1 - props.insights.natural.rating);
    let numWildlife = Math.max(0, Math.min(3, Math.floor(3 * (1-pctHuman))));
    let numPois = Math.min(6 - numWildlife, pois.length);
    let mini = appState.media.somewhatMini;
    return <SummaryDiv mini={mini}>
        <Summary mini={mini}>
            <BlurbSection mini={mini}>
                <Description mini={mini}>{props.summary.description}</Description>
                {props.summary.places.length > 0 ?
                    <PlaceSection mini={mini}>
                        {props.summary.places.map((place, index) =>
                            <React.Fragment key={"place-"+index}>
                                {index > 0 ? <PlaceSeperator>, </PlaceSeperator> : null}
                                <PlaceName>{place.name}</PlaceName>
                            </React.Fragment>
                        )}
                    </PlaceSection>
                    : null
                }
            </BlurbSection>
            <SubStatSection mini={mini}>
                <SubStat mini={mini}>Pop. density:<SubStatValue mini={mini}>{Math.round(props.pop)}∕km²</SubStatValue></SubStat>
                <SubStat mini={mini}>Elevation:<SubStatValue mini={mini}>{props.height}m</SubStatValue></SubStat>
            </SubStatSection>
        </Summary>
        <GridSmall horizontal={mini}>
            {pois.slice(0, numPois).map((e) => {
                return <LinkItemSmall key={"poi-" + e.id} mini={mini}>
                    <Link onClick={onClickPoi ? () => onClickPoi(e) : null}>
                        <ThumbHolderSmall mini={mini}>
                            {e.image ?
                                <MapThumbnail src={e.image} maxWidth={mini ? 44 : 75} maxHeight={mini ? 40 : 60} shadowSize={mini ? 3 : 5}/>
                                :
                                <FadeIn timeMs={150}>
                                    <MapIconHolder icon={e.icon} mini={mini}/>
                                </FadeIn>
                            }
                        </ThumbHolderSmall>
                        {!mini ?
                            <LabelSmall>{uppercase(e.data.name)}</LabelSmall>
                            : null
                        }
                    </Link>
                </LinkItemSmall>;
            })}
            {species.slice(0, 6 - numPois).map((e) =>
                <LinkItemSmall key={"species-" + e.taxonId} mini={mini}>
                    <Link onClick={onClickSpecies ? () => onClickSpecies(e) : null}>
                        <ThumbHolderSmall mini={mini}>
                            {e.image ?
                                <MapThumbnail src={e.image} maxWidth={mini ? 44 : 75} maxHeight={mini ? 40 : 60} shadowSize={mini ? 3 : 5}/>
                                :
                                <FadeIn timeMs={150}><MapIconHolder icon={'veterinary'} mini={mini}/></FadeIn>
                            }
                        </ThumbHolderSmall>
                        {!mini ?
                            <LabelSmall>{uppercase((e.vernacular && e.vernacular.length > 0) ? e.vernacular[0] : e.name)}</LabelSmall>
                            : null
                        }
                    </Link>
                </LinkItemSmall>
            )}
        </GridSmall>
    </SummaryDiv>;
}));

let PlacesTab = inject("appState")(observer(({props, queryData, onClickPoi, onClickSpecies, pois, species, appState}) => {
    return <Grid horizontal={appState.media.somewhatMini}>
        {pois.map((e) => {
            return <LinkItem key={e.id} mini={appState.media.somewhatMini}>
                <Link onClick={onClickPoi ? () => onClickPoi(e) : null}>
                    <ThumbHolder mini={appState.media.somewhatMini}>
                        {e.image ?
                            <MapThumbnail src={e.image} maxWidth={appState.media.somewhatMini ? 70 : 90} maxHeight={appState.media.somewhatMini ? 60 : 80} shadowSize={appState.media.somewhatMini ? 4 : 6}/>
                            :
                            <FadeIn timeMs={150}>
                                <MapIconHolder icon={e.icon}/>
                            </FadeIn>
                        }
                    </ThumbHolder>
                    <Label mini={appState.media.somewhatMini}>{uppercase(e.data.name)}</Label>
                </Link>
            </LinkItem>;
        })}
    </Grid>;
}));

let WildlifeTab = inject("appState")(observer(({props, queryData, onClickPoi, onClickSpecies, pois, species, appState}) => {
    return <Grid horizontal={appState.media.somewhatMini}>
        {species.map((e) => <LinkItem key={e.taxonId} mini={appState.media.somewhatMini}>
            <Link onClick={onClickSpecies ? () => onClickSpecies(e) : null}>
                <ThumbHolder mini={appState.media.somewhatMini}>
                    {e.image ?
                        <MapThumbnail src={e.image} maxWidth={appState.media.somewhatMini ? 70 : 90} maxHeight={appState.media.somewhatMini ? 60 : 80} shadowSize={6}/>
                        :
                        <FadeIn timeMs={150}><MapIconHolder icon={'veterinary'}/></FadeIn>
                    }
                </ThumbHolder>
                <Label mini={appState.media.somewhatMini}>{uppercase((e.vernacular && e.vernacular.length > 0) ? e.vernacular[0] : e.name)}</Label>
            </Link>
        </LinkItem>)}
    </Grid>;
}));

const AiSummary = styled.div`
  text-align: center;
  ${p => typeface(p.mobile ? 14 : 15, 500, .0085)};
  color: ${Theme.colors.ai};
  margin-bottom: 16px;
  text-shadow: 0 2px 1px rgba(0,0,0,0.5);
`;

const AiDim = styled.div`
  ${p => typeface(p.mobile ? 14 : 15, 500, .0085)};
  color: ${Theme.colors.aiDark};
`;

const AIList = styled.ul`
  ${p => typeface(p.mobile ? 14 : 15, 400, .0085)};
  list-style: none;
  list-style-position: outside;
  padding-right: 10px;
  color: ${Theme.colors.aiReadable};
`;

const BulletDiv = styled.div`
  position: absolute;
  left: -20px;
  top: 1px;
  user-select: none;
`;

const PlusDiv = styled(BulletDiv)`
  color: ${Theme.colors.ai};
`;

const AiListItem = styled.li`
  margin-bottom: 4px;
  position: relative;
`;

const AiTab = inject("appState")(observer(({appState, getAiData, aiPlaceName}) => {
    const data = useRef(observable({
        aiData: null
    }));

    useEffect(() => {
        (async () => {
            let aiData = await getAiData();
            runInAction(() => {
                data.current.aiData = aiData;
            })
        })();
    }, []);

    return data.current.aiData ?
        <>
            <AiSummary>
                <AiDim>{aiPlaceName}</AiDim>
                Photo Opportunities
            </AiSummary>
            <AIList mobile={appState.media.mobile}>{data.current.aiData.list.map((item, index) => {
                return <AiListItem key={`item-${index}`}><PlusDiv><RightIconLightShadowed scale={14}/></PlusDiv>{item}</AiListItem>;
            })}</AIList>
        </>
        :
        <SpinnerCenter>
            <SpinnerFadeIn/>
        </SpinnerCenter>
}));

let makeTabs = (props, queryData, onClickPoi, onClickSpecies, pois, species, getAiData, aiPlaceName) => {
    let tabs = [
        {
            label: "Summary",
            content: <SummaryTab props={props} queryData={queryData} onClickPoi={onClickPoi} onClickSpecies={onClickSpecies} pois={pois} species={species}/>

        }
    ];
    if (getAiData) {
        tabs.push({
            label: "AI Insights",
            scrollY: true,
            scrollYMini: true,
            content: <AiTab getAiData={getAiData} aiPlaceName={aiPlaceName}/>,
            titleComponent: AiTabTitle
        });
    }
    tabs.push(
        {
            label: "Places",
            scrollY: true,
            scrollXMini: true,
            content: <PlacesTab props={props} queryData={queryData} onClickPoi={onClickPoi} onClickSpecies={onClickSpecies} pois={pois} species={species}/>
        },
        {
            label: "Wildlife",
            scrollY: true,
            scrollXMini: true,
            content: <WildlifeTab props={props} queryData={queryData} onClickPoi={onClickPoi} onClickSpecies={onClickSpecies} pois={pois} species={species}/>
        }
    );
    return tabs;
};

let getHashFromQueryDataWithWikipedia = (queryData) => {
    let places = queryData.properties.summary.places;
    let placeIndex = places.findIndex((place) => place.wikipedia);
    if (placeIndex > -1) {
        let name = places.slice(placeIndex).map((place) => place.name).join(", ");
        return {hash: places[placeIndex].hash, aiPlaceName: name};
    } else {
        return {hash: null, aiPlaceName: null};
    }
}

let makeAiDataGetter = (queryData) => {
    let {hash, aiPlaceName} = getHashFromQueryDataWithWikipedia(queryData);
    if (hash) {
        return {
            getAiData: async () => {
                return await MapsApi.get(`/poi/insights/general/hash/${hash}`);
            },
            aiPlaceName: aiPlaceName
        }
    } else {
        return {getAiData: null, aiPlaceName: null};
    }
};

export default ({queryData, onClickPoi, onClickSpecies, queryNum}) => {
    let props = queryData.properties;
    let pois = props.insights.top.map(e => e.poi).filter(e => e.data.name);
    let species = props.species;
    let {getAiData, aiPlaceName} = makeAiDataGetter(queryData)
    return <TabDisplay
        queryNum={queryNum}
        makeTabs={() => makeTabs(props, queryData, onClickPoi, onClickSpecies, pois, species, getAiData, aiPlaceName)}
    />;
};

import React from 'react';
import DumbLinkContainer from "./DumbLinkContainer";
import styled from '@emotion/styled';
import Theme from "../styles/Theme";
import {typeface} from "../styles/Typeface";

const UserDiv = styled.div`
  display: inline-block;
`;

const UserA = styled.a`
    color: ${Theme.colors.almostDim};
    cursor: pointer;
    ${typeface(16, 700)};
    
    &:hover {
        color: ${Theme.colors.almostWhite};
    }
`;

const NonInteractiveDiv = styled.div`
    color: ${Theme.colors.almostDim};
    ${typeface(16, 700)};
`;

export default (props) => {
    let link;
    if (props.user){
        if (props.user.alias){
            link = "/" + props.user.alias;
        } else {
            link = "/user/_" + props.user.ident;
        }
    }
    return <UserDiv>
        {props.user ?
            (props.nonInteractive ?
                    <NonInteractiveDiv className={props.className}>{props.user.name}</NonInteractiveDiv> :
                    <DumbLinkContainer to={link}>
                        <UserA className={props.className} onClick={props.onNav}>{props.user.name}</UserA>
                    </DumbLinkContainer>
            )
            :
            <span/>
        }
    </UserDiv>
};
import React, {useRef, useEffect, useState} from 'react';
import styled from '@emotion/styled';

const PopupThumb = styled.img`
  max-height: ${p => p.maxHeight}px;
  max-width: ${p => p.maxWidth}px;
  margin: ${p => p.margin}px;
  box-shadow: 0px 0px ${p => p.shadowSize}px 0px rgba(0,0,0,.75);
  opacity: ${p => p.opacity};
  transition: opacity 150ms;
`;

export default ({src, maxWidth, maxHeight, margin, shadowSize}) => {
    let marginFinal = margin  ?? 7;
    let maxWidthFinal = maxWidth ?? 200;
    let maxHeightFinal = maxHeight ?? 200;
    let shadowSizeFinal = shadowSize ?? 8;
    let [loaded, setLoaded] = useState(false);
    let onLoad = () => {
        setLoaded(true);
    };
    return <PopupThumb
        src={`${src}?width=${maxWidthFinal*2}`}
        maxWidth={maxWidthFinal}
        maxHeight={maxHeightFinal}
        onLoad={onLoad}
        opacity={loaded ? 1.0 : 0.0}
        margin={marginFinal}
        shadowSize={shadowSizeFinal}
     />;
};
import React from 'react';
import WikiDisplay from "./WikiDisplay";
import {
    GBIFIconLightShadowed,
    WikidataIconLightShadowed, WikipediaIconLightShadowed
} from "../../svg/Icons";
import {MapsApi} from "../../components/Api";


let makeLinks = (speciesData) => {
    let links = [];
    links.push({
        icon: GBIFIconLightShadowed,
        url: `https://www.gbif.org/species/${speciesData.properties["taxonId"]}`,
        title: "GBIF"
    });
    if (speciesData.properties["wikipedia"]) {
        links.push({
            icon: WikipediaIconLightShadowed,
            url: `https://en.wikipedia.org/wiki/${speciesData.properties["wikipedia"]}`,
            title: "Wikipedia"
        });
    }
    if (speciesData.properties["wikidata"]) {
        links.push({
            icon: WikidataIconLightShadowed,
            url: `https://www.wikidata.org/wiki/${speciesData.properties["wikidata"]}`,
            title: "Wikidata"
        });
    }
    return links;
};

let makeAiDataGetter = (speciesData) => {
    let wikipedia = speciesData.properties["wikipedia"];
    if (wikipedia) {
        return async () => {
            return await MapsApi.get(`/species/insights/plusminus/${speciesData.properties.taxonId}`);
        }
    } else {
        return null;
    }
};

export default ({speciesData}) => <WikiDisplay
    key={speciesData.properties.taxonId}
    displayData={speciesData}
    getId={(speciesData) => speciesData.properties.taxonId}
    getName={(speciesData) => speciesData.properties.name}
    getImage={(speciesData) => speciesData.properties.image}
    getWikipedia={(speciesData) => speciesData.properties.wikipedia}
    getCustomDescription={(speciesData) => "No information available."}
    links={makeLinks(speciesData)}
    getIcon={(speciesData) => 'veterinary'}
    getAiData={makeAiDataGetter(speciesData)}
/>;
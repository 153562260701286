import React, {useEffect, useReducer} from 'react';
import styled from '@emotion/styled';
import {withRouter} from 'react-router-dom'
import {inject, observer} from "mobx-react";
import Api from "../../components/Api";
import {typeface} from "../../styles/Typeface";
import Theme from "../../styles/Theme";
import Breadcrumbs from "../../components/Breadcrumbs";
import DumbLinkContainer from "../../components/DumbLinkContainer";
import {css} from '@emotion/react'
import MiniGallery from "../../components/MiniGallery";
import {Button120} from "../../styles/Button";
import {RightIconNoShadow} from "../../svg/Icons";
import {Emphasis} from "../../styles/Span";
import Matchup from "./components/Matchup";
import {SectionHeader} from "../storylist/user/UserStoryList";
import {CenterHelp, HelpLinkThin} from "../../components/HelpLink";
import Background from "../../components/Background";
import WithFooter from "../../components/WithFooter";
import {Helmet} from "react-helmet";

const LinkStylable = ({label, className, to, onNav}) => {
    return <DumbLinkContainer onNav={onNav} to={to}><a className={className}>{label}</a></DumbLinkContainer>;
};

const LinkStyle = css`
    cursor: pointer;
    &:hover {
        color: ${Theme.colors.almostWhite};
    }
`;

const OpenButton = styled(Button120)`
  background: none;
  min-width: unset;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 7px;
  vertical-align: text-bottom;
  line-height: 10px;
  position: relative;
  top: 1px;
`;

const ExploreLinkSC = styled(LinkStylable)`
  ${LinkStyle};
  &:hover {
    color: white;
  }
  &:hover ${OpenButton} {
    color: white !important;
    box-shadow: 0 0 0px 1px rgba(255,255,255,1) inset, 0 2px 4px 1px rgba(0,0,0,.25) !important;
  }
  ${ p => p.breadcrumb ? (p.mobile ? typeface(20, 700) : typeface(24, 700)) : ''};
`;

export const ExploreLinkStyled = inject("appState")(observer(({appState, ...rest}) => {
    return <ExploreLinkSC mobile={appState.media.mobile} {...rest}/>
}));

export const EllipsisStyleLabel = styled.span`
    ${LinkStyle};
`;

const LinkDiv = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

export const ExploreLink = ({sense, onNav, showButton, breadcrumb, className}) => {
    return <ExploreLinkStyled className={className} breadcrumb={breadcrumb} onNav={onNav} to={`/explore/senses/${sense}`} label={
        <LinkDiv>
            <span>{sense}</span>
            {showButton ? <OpenButton><RightIconNoShadow inline={true} holderVerticalAlign={'text-bottom'} scale={12}/></OpenButton> : null}
        </LinkDiv>
    }/>;
};

const ExploreLinkBright = styled(ExploreLink)`
  color: ${Theme.colors.almostWhite};
`;

function flatToHierarchy(flat) {

    let roots = [];
    let all = {};

    flat.forEach((data) => {
        all[data.name] = { data: data, children: [] }
    });

    Object.keys(all).forEach((name) => {
        let parentName = all[name].data.parent;
        if (parentName in all){
            all[parentName].children.push(all[name]);
        } else {
            roots.push(all[name]);
        }
    })

    return roots
}

const PageDiv = styled.div`
    width: 100%;
`;

const ContentDiv = styled.div`
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
`;

const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${ p => p.skinny ? "" : `max-height: 500px`};
    align-content: center;
    width: 100%;
`;

const CategoryGroupDiv = styled.div`
    max-width: 180px;
    margin: 5px 30px 20px 30px;
    ${typeface(22)};
    color: ${Theme.colors.almostWhite};
`;

const NameDiv = styled.div`
`;

const ChildrenDiv = styled.div`
    ${typeface(16)};
    color: ${Theme.colors.almostDim};
`;

const SubNameDiv = styled.div`

`;

const MiniGalleryHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const EmptyDisplay = styled.div`
  ${typeface(16)};
  color: ${Theme.colors.almostDim};
  margin: 25px;
  text-align: center;
`;

const MatchupHolder= styled.div`
  padding: 10px ${p => p.skinny ? 0 : 5}px 25px ${p => p.skinny ? 0 : 5}px;
  ${ p => p.skinny ? '' : `
      display: flex;
      justify-content: center;
      align-items: center;
  `}
`;

const MatchupSection = styled.div`
  display: ${p => p.skinny ? "block" : "inline-block"};
  padding: ${p => p.skinny ? `15px 0px` : `25px 0px`};
  border-radius: ${p => p.skinny ? 0 : 75}px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.3), 0 0 0 1px rgba(255,255,255,.2);
  background: ${Theme.colors.backgroundLight};
  overflow: hidden;
`;

const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
`;

const SenseExplanationHolder = styled.div`
  padding: 0 20px;
  max-width: 540px;
  margin: 8px auto 16px auto;
  text-align: left;
  color: ${Theme.colors.almostDim};
  ${typeface(16, 400, .01)};
`;

const SenseExplanationParagraph = styled.div`
  margin-bottom: 14px;
`;

const CenterHelpPadded = styled(CenterHelp)`
  padding-bottom: 32px;
  margin-bottom: 0;
`;

const Senses = ({sense, appState}) => {

    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {fullPath: false}
    );

    useEffect(() => {
        (async () => {
            setState({fullPath: false, forward: null, backward: null, showCategories: false});
            let childrenPromise = Api.get(`/sense/grandchildren/${sense || ""}`);
            let ancestorsPromise = Api.get(`/sense/ancestors/${sense || ""}`);
            let children = await childrenPromise;
            let ancestors = await ancestorsPromise;
            if (children !== null && ancestors !== null) {
                setState({fullPath: false, forward: flatToHierarchy(children)[0], backward: ancestors});
            }
        })();
    }, [sense]);

    let categories = state.forward;
    let ancestors = state.backward;

    let crumbs = [];
    if (ancestors && ancestors.length >= 1){
        crumbs.push(<React.Fragment key={ancestors[0].name}>{ancestors[0].name}</React.Fragment>);
        if (state.fullPath || ancestors.length === 1) {
            crumbs = crumbs.concat(ancestors.slice(1).map(a => <ExploreLink breadcrumb={true} key={a.name} sense={a.name}/>));
        } else {
            crumbs.push(
                <EllipsisStyleLabel key={"click-for-more"} onClick={() => setState({fullPath: true})}>…</EllipsisStyleLabel>
            );
        }
    }
    if (!ancestors || ancestors.length === 0){
        crumbs.push(<React.Fragment key={""}>Explore <Emphasis>showcased photos</Emphasis> related to …</React.Fragment>);
    } else {
        crumbs.push(<ExploreLinkStyled breadcrumb={true} key={""} to={"/explore/senses"} label={"Senses"}/>);
    }
    crumbs.reverse();

    let openGallery = () => {
        appState.history.push(`/explore/senses/${sense}/photos`);
    };

    let isSecondLevel = ancestors && ancestors.length === 1;
    let emptyDisplay = ancestors && ancestors.length > 1 ? <CenterFlex><SenseExplanationHolder>
        No one has showcased a photo related to <Emphasis>{sense}</Emphasis>. Be the first!
    </SenseExplanationHolder></CenterFlex> : null;

    let mainPage = !sense || sense === "";

    const getRetrievalParams = (sense) => {
        let url = `/gallery/sense/${sense}`;
        let sortAscend = false;
        let sortField = "rating";
        let query = {};
        query["after-sort-value"] = "max";
        query["after-ident"] = "max";
        query["inclusive"] = false;
        query["null-is-big"] = sortAscend;
        query["ascend"] = sortAscend;
        return {url, query, sortAscend, sortField};
    };

    return <Background bg={Theme.colors.background}>
        <WithFooter>
            <PageDiv>
                {categories ?
                    <>
                        { mainPage ?
                            <>
                                <Helmet>
                                    <title>Senses</title>
                                </Helmet>
                                <Breadcrumbs>Senses</Breadcrumbs>
                                <CenterFlex>
                                    <SenseExplanationHolder>
                                        <SenseExplanationParagraph>
                                            Photography is at its best when it rouses a <Emphasis>sense</Emphasis> or <Emphasis>feeling</Emphasis>.
                                        </SenseExplanationParagraph>
                                        <SenseExplanationParagraph>
                                            Contribute your judgment, or explore the top photos in each nested category (as ranked by users).
                                        </SenseExplanationParagraph>
                                    </SenseExplanationHolder>
                                </CenterFlex>
                                <MatchupHolder skinny={appState.media.skinny}>
                                    <MatchupSection skinny={appState.media.skinny}>
                                        <Matchup/>
                                    </MatchupSection>
                                </MatchupHolder>
                                <SectionHeader>Explore</SectionHeader>
                            </>
                            :
                            <>
                                <Helmet>
                                    <title>{sense.charAt(0).toUpperCase() + sense.slice(1)}</title>
                                </Helmet>
                                <Breadcrumbs>{crumbs}</Breadcrumbs>
                            </>
                        }
                        { isSecondLevel ?
                            <CenterFlex>
                                <SenseExplanationHolder>
                                    Select a subcategory to view its top photos.
                                </SenseExplanationHolder>
                            </CenterFlex>
                            : null
                        }
                        {!mainPage ?
                            <MiniGalleryHolder>
                                <MiniGallery params={getRetrievalParams(sense)} emptyDisplay={emptyDisplay} buttonAction={openGallery} loadedCallback={() => setState({ showCategories: true})}/>
                            </MiniGalleryHolder>
                            :
                            null
                        }
                        { (state.showCategories || !sense || sense === "") ?
                            <>
                                <ContentDiv>
                                    <FlexDiv skinny={appState.media.skinny}>
                                        {categories.children.map((cat) =>
                                            <CategoryGroupDiv key={cat.data.name}>
                                                <NameDiv>
                                                    <ExploreLinkBright showButton={true} sense={cat.data.name}/>
                                                </NameDiv>
                                                <ChildrenDiv>
                                                    {cat.children.map((sub) =>
                                                        <SubNameDiv key={sub.data.name}><ExploreLink sense={sub.data.name}/></SubNameDiv>
                                                    )}
                                                </ChildrenDiv>
                                            </CategoryGroupDiv>
                                        )}
                                    </FlexDiv>
                                </ContentDiv>
                                <CenterHelpPadded><HelpLinkThin path={"/help/discovery/rankings#senses-rankings"}/></CenterHelpPadded>
                            </>
                            : null
                        }
                    </>
                    : null}
            </PageDiv>
        </WithFooter>
    </Background>;
}

export default withRouter(inject("appState")(observer(Senses)));
import React from "react"
import UserList from "./UserList";
import {Helmet} from "react-helmet";

const getRetrievalParams = () => {
    let url = "/user/me/following";
    return {url: url, sortAscend: false, query: {}};
};

export default () => <>
    <Helmet>
        <title>Following</title>
    </Helmet>
    <UserList
        getRetrievalParams={getRetrievalParams}
        title={"I'm Following"}
        emptyMessage={"Not following anyone."}
        loginRequired={true}
    />
</>
import React, {useRef, useState} from 'react';
import {PhotoSelectionPanel} from "../../components/PhotoPickerModal";
import styled from '@emotion/styled';
import Theme from "../../styles/Theme";
import {PinIconLightShadowed} from "../../svg/Icons";
import ContextTrigger from "../browser/contextmenu/ContextTrigger";
import {ContextItemStyled, ContextMenuStyled, MenuDividerDiv} from "../browser/contextmenu/ContextMenu";
import { runInAction, toJS, observable } from "mobx";
import { inject } from "mobx-react";
import {useListener} from "../../helpers/UseListener";

const ExpandDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const PhotoOverlay = styled.div`
  pointer-events: none;
  height: 100%;
  width: 100%;
  outline: ${p => p.selected ? '2px dashed rgba(255,255,255,.825)' : 'none'};
  border-radius: 4px;
  z-index: 100;
  position: relative;
`;

const OptionsButton = styled.button`
  all: unset;
  height: ${p => p.mobile ? 24 : 30}px;
  width: ${p => p.mobile ? 24 : 30}px;
  color: ${p => p.hasCoords ? Theme.colors.almostWhite : Theme.colors.dimmest};
  background: ${Theme.colors.backgroundLighter};
  border-top-left-radius: 10px;
  
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  &:hover {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.35), 0 1px 6px 0 rgba(0,0,0,.7);
  }
  bottom: 0;
  right: 0;
  position: absolute;
  
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
`;

const SelectorContext = React.createContext({});

const OverlayContainer = ({mobile, photo, openPhoto, makeBitmap}) => {
    let buttonRef = useRef();

    let [overriddenCoords, setOverriddenCoords] = useState(null);

    useListener(`photo:${photo.ident}`, (info) => {
        setOverriddenCoords({coords: info.coords});
    });

    let hasCoords = overriddenCoords ? !!overriddenCoords.coords : !!photo.coords;

    return <SelectorContext.Consumer>
        {({showMenuAt, zoomToPhoto, openLocationSetterFor, clearPhotoLocation, cancelLocationSetter, settingLocationForPhoto}) => {
            let selected = settingLocationForPhoto === photo.ident;
            let contextMenu = ({close}) => React.createElement(() => {
                let clickZoom = () => {
                    zoomToPhoto(photo.ident, true, false);
                    close();
                };
                let clickClear = () => {
                    clearPhotoLocation(photo.ident);
                    close();
                }
                let clickSet = () => {
                    openLocationSetterFor(photo.ident);
                    close();
                };
                let clickOpen = async () => {
                    cancelLocationSetter();
                    openPhoto(photo, await makeBitmap());
                    close();
                }
                return <ContextMenuStyled>
                    <ContextItemStyled onClick={clickSet}>{() => <>Set Location</>}</ContextItemStyled>
                    {hasCoords ? <ContextItemStyled onClick={clickClear}>{() => <>Clear Location</>}</ContextItemStyled> : null}
                    <MenuDividerDiv/>
                    {hasCoords ? <ContextItemStyled onClick={clickZoom}>{() => <>Zoom To</>}</ContextItemStyled> : null}
                    <ContextItemStyled onClick={clickOpen}>{() => <>Open Photo</>}</ContextItemStyled>
                </ContextMenuStyled>;
            });
            let onClick = showMenuAt ? (e) => {
                requestAnimationFrame(() => {
                    if (buttonRef.current) {
                        let rect = buttonRef.current.getBoundingClientRect();
                        showMenuAt(rect.right, rect.bottom, false, contextMenu);
                    }
                });
            } : null
            return <ExpandDiv>
                <OptionsButton mobile={mobile} hasCoords={hasCoords} onClick={onClick} ref={buttonRef} selected={selected}>
                    <PinIconLightShadowed scale={mobile ? 16 : 20} shadowAmt={hasCoords ? 1 : .5}/>
                </OptionsButton>
                <PhotoOverlay selected={selected}/>
            </ExpandDiv>;
        }}
    </SelectorContext.Consumer>;
}

export const MapPhotoSelector = inject("appState")((
    {hidden, userIdent, userAlias, mobile, pageRef, zoomToPhoto, appState, openLocationSetterFor, clearPhotoLocation, cancelLocationSetter, settingLocationForPhoto}
) => {
    let data = useRef(observable({}));
    let myIdent = appState.user ? appState.user.ident : null;
    let myAlias = appState.user ? appState.user.alias : null;
    let isMe = userIdent === myIdent || userAlias === myAlias;
    return <SelectorContext.Provider value={{
        showMenuAt: data.current.showMenuAt,
        zoomToPhoto,
        openLocationSetterFor,
        clearPhotoLocation,
        settingLocationForPhoto,
        appState,
        cancelLocationSetter
    }}>
        <ContextTrigger
            showCallback={(i) => runInAction(() => data.current.isShowing = i)}
            holderProps={{}}
            holder={"div"}
            isTouch={false}
            showMenuAt={(s) => runInAction(() => data.current.showMenuAt = s)}
            close={(c) => runInAction(() => data.current.close = c)}
            portalRef={pageRef}
        >
            <PhotoSelectionPanel
                paused={hidden}
                userIdent={userIdent}
                userAlias={userAlias}
                select={null}
                session={'map-photo-selector-' + userIdent}
                title={isMe ? "My Photos" : "Photo Gallery"}
                mobile={mobile}
                filtersAlwaysHidable={true}
                overlay={OverlayContainer}
                onScroll={data.current.close}
            />
        </ContextTrigger>
    </SelectorContext.Provider>;
});

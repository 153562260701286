import React, {memo, useEffect, useRef, useState} from 'react';
import Api from "../../components/Api";
import { observable, runInAction, trace } from 'mobx'
import { observer, inject } from "mobx-react";
import WindowHelper from "../../helpers/WindowHelper";
import Theme from "../../styles/Theme";
import Thumbnail from "../../components/Thumbnail";
import StoryItem from "../storylist/components/StoryItem";
import styled from '@emotion/styled'
import {typeface} from "../../styles/Typeface";
import UserLink from "../../components/UserLink";
import {
    LogoIconStatic,
    LogoLiveIcon,
    MoveDownIconLightShadowed,
    MoveUpIconLightShadowed,
} from "../../svg/Icons";
import {ScrollCss} from "../../styles/Page";
import {hasSmoothGradients, isSafari} from "../../styles/BackdropFilter";
import {ButtonLikeButton} from "../../styles/Button";
import queryString from 'query-string'
import {memoIgnoreChildren} from "../../helpers/ReactHelpers";


const fastMemo = memo;

const UserLinkStyled = fastMemo(styled(UserLink)`
  color: ${Theme.colors.dim};
  &:hover {
    color: ${Theme.colors.almostDim};
  }
`);

const StoryItemDiv = fastMemo(styled.div`
  max-width: ${p => p.skinny ? 540 : 680}px;
  margin: 0 auto;
  padding: 10px 20px 30px 20px;
  &:first-of-type {
    margin-top: 30px;
  }
  will-change: transform;
  pointer-events: auto;
  opacity: ${props => props.invisible ? 0 : 1};
  transition: 200ms opacity;
  contain: content;
`);

const StoryItemStyled = fastMemo(styled(StoryItem)`
  background: rgba(45,45,45,${p => Math.sqrt(p.bgOpacity)});
  box-shadow: inset 0 0 0 1px rgba(255,255,255,${p => p.bgOpacity*.05}), 0 0 5px 0 rgba(0,0,0,${p => p.bgOpacity*.35});
  &:hover, &.focus-visible {
    background: rgba(45,45,45,${p => Math.sqrt(p.bgOpacity)});
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.25), 0 0 8px 0 rgba(0,0,0,.5);
  }
  opacity: ${p => p.opacity};
  transition: 150ms opacity;
`);

const ThumbnailBlock = memo(styled.div`
  display: flex;
  flex-direction: ${p => p.doubleSize ? "column" : (p.photoOnLeft ? 'row' : 'row-reverse')};
  max-width: 540px;
  margin: 0 auto;
  padding: 0 0 20px 0;
  opacity: ${p => p.invisible ? 0 : 1};
  contain: content;
  pointer-events: none;
  & a {
    pointer-events: auto;
  }
`);

const ThumbnailDiv = memo(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`);

export const ThumbnailContextDiv = memo(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${typeface(16, 400)};
  color: ${Theme.colors.almostDim};
  max-width: 420px;
  min-width: 160px;
  padding: ${p => p.doubleSize ? "0 15px 15px 15px" : "15px"};
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
  opacity: ${p => p.dim ? 0 : 1};
  transition: 150ms opacity;
`);

const ThumbnailContextContentDiv = fastMemo(styled.div`
  pointer-events: auto;
`);

export const TitleDiv = memo(styled.div`
  color: ${Theme.colors.almostDim};
  font-style: italic;
`);

export const UntitledDiv = memo(styled.div`
  color: ${Theme.colors.dim};
`);

const Content = memo(styled.div`
  position: relative;
  z-index: 5;
  pointer-events: none;
`);

const Header = fastMemo(styled.div`
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  contain: content;
  z-index: 5;
  max-width: 320px;
  display: inline-block;
`);

const SiteIdHolder = fastMemo(styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`);

const SiteIdLiveOnly = fastMemo(styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-area: 1 / 1 / 2 / 2;
  padding: 0 10px;
`);

const SiteIdShadow = fastMemo(styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 4px 6px rgba(0,0,0,.75));
  top: 0;
  color: black;
  will-change: transform;
  grid-area: 1 / 1 / 2 / 2;
  z-index: -1;
  padding: 0 10px;
`);

const SiteNameHidden = fastMemo(styled.div`
  color: ${Theme.colors.almostWhite};
  ${p => p.mobile ? typeface(38, 600, .01) : typeface(48, 600, .015)};
  margin-left: 14px;
  user-select: none;
  visibility: hidden;
`);

const SiteName = fastMemo(styled.div`
  color: ${Theme.colors.almostWhite};
  ${p => p.mobile ? typeface(38, 600, .01) : typeface(48, 600, .015)};
  text-shadow: 0px 5px 0px rgba(0,0,0,.25);
  margin-left: 14px;
  user-select: none;
`);

const Slogan = fastMemo(styled.div`
  ${p => p.mobile ? typeface(16, 400) : typeface(20, 400)};
  color: ${Theme.colors.nearingDim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.25);
`);

const ThumbnailStyled = fastMemo(styled(Thumbnail)`
  opacity: ${p => p.opacity};
  filter: ${p => p.blackAndWhite ? `grayscale(100%)` : `none`};
  transition: 150ms opacity, 150ms filter, 75ms transform;
  &:hover {
    opacity: 1;
    filter: none;
  }
  ${ isSafari() ? `will-change: opacity, filter;` : ``};
`);

const CenterSecondary = memo(styled.div`
  display: flex;
  justify-content: center;
`);

const Secondary = memo(styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 40%;
  grid-template-columns: 30% 30%;
  grid-auto-rows: calc(256px + 64px);
  justify-items: center;
  align-items: center;
  z-index: 3;
  
  top: 0;
  transform: translate3d(0, 128px, -.65px) scale(1.65);
  transform-origin: top;
  position: absolute;
`);

const PageDiv = memo(styled.div`
  width: 100%;
  text-align: center;
  
  perspective: 1px;
  perspective-origin: top;
  
  height: var(--app-height);
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  
  position: fixed;
  top: 0;
  padding-top: ${WindowHelper.NAV_HEIGHT}px;
  
  ${p => (p.bgLoaded && !hasSmoothGradients()) ? 
          `background-image: url('${'/static/images/bgGradient.png'}')` : 
          `background-image: linear-gradient(to right, rgb(34,34,34) 0%, rgb(45,45,45) 40%, rgb(45,45,45) 60%, rgb(34,34,34) 100%);`};
  background-size: 100% auto;
  background-repeat: repeat-y;
  
  ${ScrollCss};
`);

const DownArrow = fastMemo(styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${p => p.visible ? 1 : 0};
  transition: 200ms opacity;
  position: fixed;
  top: calc(var(--app-height) - 30px);
  left: calc(50% - 25px);
  height: 30px;
  width: 50px;
  color: ${Theme.colors.dim};
  will-change: opacity;
`);

const ResetButton = fastMemo(styled(ButtonLikeButton)`
  pointer-events: auto;
  width: 200px;
  height: 34px;
  border-radius: 4px;
  margin-top: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.backgroundLighter};
  color: ${Theme.colors.notQuiteDim};
  
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.035), 0 0 5px 0 rgba(0,0,0,.25);
  &:hover, &.focus-visible {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.25), 0 0 5px 0 rgba(0,0,0,.5) !important;
  }
`);

const SiteLogoHolder = fastMemo(styled.div`
  text-align: center;
  z-index: 10;
  pointer-events: none;
`);

const FadeInDiv = memo(styled.div`
  opacity: ${p => p.shown ? 1 : 0};
  transition: opacity 100ms;
`);

export const FontLoadFadeIn = ({children, className}) => {
    let [shown, setShown] = useState(false);
    useEffect(() => {
        document.fonts.ready.then(() => setShown(true));
        setTimeout(() => setShown(true), 2000);
    });
    return <FadeInDiv shown={shown} className={className}>
        {children}
    </FadeInDiv>;
};

export const SiteLogo = ({slogan, mobile}) => {
    return <FontLoadFadeIn>
        <SiteLogoHolder>
            <SiteIdHolder>
                <SiteIdShadow>
                    <LogoIconStatic scale={mobile ? 38 : 48}/>
                    <SiteName mobile={mobile}>Lumathon</SiteName>
                </SiteIdShadow>
                <SiteIdLiveOnly>
                    <LogoLiveIcon lightShadow scale={mobile ? 38 : 48}/>
                    <SiteNameHidden mobile={mobile}>Lumathon</SiteNameHidden>
                </SiteIdLiveOnly>
            </SiteIdHolder>
            <Slogan mobile={mobile}>{slogan}</Slogan>
        </SiteLogoHolder>
    </FontLoadFadeIn>;
}

export default inject('appState')(observer(({appState}) => {

    let pageRef = useRef(null);
    let contentRef = useRef(null);
    let secondaryRef = useRef(null);
    let firstLabelRef = useRef(null);

    let hist = appState.history;
    let loc = appState.location;

    let state = useRef(observable({
        content: [],
        secondary: [],
        contentDone: false,
        secondaryDone: false,
        contentCounter: 0,
        secondaryCounter: 0,
        contentLoading: false,
        secondaryLoading: false,
        hasScrolled: false,
        showResetButton: false,
        firstLabelNotInView: true
    }));

    let loadMoreContent = async () => {
        if(!state.current.contentLoading) {
            runInAction(() => {
                state.current.contentLoading = true;
                state.current.contentCounter += 1;
            });
            let counter = state.current.contentCounter;
            try {
                let query = {
                    num: 10
                };
                if (state.current.content.length > 0){
                    query["after-time"] = state.current.content[state.current.content.length-1].time;
                    query["after-position"] = state.current.content[state.current.content.length-1].position;
                } else if (loc.state && loc.state.position && loc.state.time && state.current.showResetButton) {
                    query["after-time"] = loc.state.time;
                    query["after-position"] = loc.state.position + 1;
                }
                let content = await Api.get(`/frontpage?${queryString.stringify(query)}`);

                if (counter === state.current.contentCounter) {
                    runInAction(() => {
                        state.current.content = state.current.content.concat(content);
                    });
                }
                if (content.length === 0){
                    runInAction(() => {
                        state.current.contentDone = true;
                    });
                }
            } catch (e) {
                console.log(e);
            }
            runInAction(() => {
                state.current.contentLoading = false;
            })
        }
    };

    let loadMoreSecondary = async () => {
        if(!state.current.secondaryLoading) {
            runInAction(() => {
                state.current.secondaryLoading = true;
                state.current.secondaryCounter += 1;
            });
            let counter = state.current.secondaryCounter;
            try {
                let query = {
                    num: 10
                };
                if (state.current.secondary.length > 0){
                    query["after-time"] = state.current.secondary[state.current.secondary.length-1].time;
                    query["after-position"] = state.current.secondary[state.current.secondary.length-1].position;
                }
                let content = await Api.get(`/frontpage/secondary?${queryString.stringify(query)}`);
                if (counter === state.current.secondaryCounter) {
                    runInAction(() => {
                        state.current.secondary = state.current.secondary.concat(content);
                    });
                }
                if (content.length === 0){
                    runInAction(() => {
                        state.current.secondaryDone = true;
                    });
                }
            } catch (e) {
                console.log(e);
            }
            runInAction(() => {
                state.current.secondaryLoading = false;
            })
        }
    };

    useEffect(() => {
        window.addEventListener('resize', checkFirstLabelVis);
        return () => window.removeEventListener('resize', checkFirstLabelVis);
    }, []);

    useEffect(() => {
        if (loc.state && loc.state.position && loc.state.time){
            runInAction(() => {
                state.current.showResetButton = true;
            })
        } else {
            runInAction(() => {
                state.current.showResetButton = false;
            })
        }
        runInAction(() => appState.frontpageOpen = true);
        loadMoreContent();
        loadMoreSecondary();
        return () => {
            setPageRef(null);
            runInAction(() => appState.frontpageOpen = false);
        }
    }, []);

    let mapTo = (value, inFrom, inTo, outFrom, outTo) => {
        let adjValue = Math.max(Math.min(value, inTo), inFrom);
        let pct = (adjValue - inFrom) / (inTo - inFrom);
        return outFrom + pct * (outTo - outFrom);
    };

    let clickOn = (item, list) => () => {
        hist.replace(loc.pathname + loc.search + loc.hash, {position: item.position, time: item.time, list: list});
    }

    let displayContentElement = (contentState, showFull) => (item, index) => {
        let element = <></>;
        if (item.type === "photo") {
            let doubleSize = true;
            element = <ThumbnailBlock doubleSize={doubleSize}
                                      photoOnLeft={contentState.photoOnLeft}
                                      key={"photo-" + item.data.ident}
                                      invisible={index > 1 && !showFull}
            >
                <ThumbnailDiv>
                    <ThumbnailStyled
                        thumb={item.data}
                        doubleSize={doubleSize}
                        invisible={index > 1 && !showFull}
                        opacity={(!showFull && (index > 0) && (index < 3) ? .25 : 1)}
                        blackAndWhite={!showFull && (index === 1)}
                        onNav={clickOn(item, "front")}
                    />
                </ThumbnailDiv>
                <ThumbnailContextDiv ref={ref => { if(index === 0) { firstLabelRef.current = ref; checkFirstLabelVis(); }}}
                                     doubleSize={doubleSize}
                                     dim={!showFull && (state.current.firstLabelNotInView || index > 0)}>
                    <ThumbnailContextContentDiv>
                        {item.data.title && item.data.title !== "" ?
                            <TitleDiv>{item.data.title}</TitleDiv>
                            : <UntitledDiv>Photo by</UntitledDiv>}
                        <UserLinkStyled user={item.data.owner}/>
                    </ThumbnailContextContentDiv>
                </ThumbnailContextDiv>
            </ThumbnailBlock>;
            contentState.photoOnLeft = !contentState.photoOnLeft;
        } else if (item.type === "story") {
            element = <StoryItemDiv key={"story-" + item.data.ident} skinny={appState.media.skinny} invisible={index > 1 && !showFull}>
                <StoryItemStyled story={item.data}
                                 showAuthor={true}
                                 doubleSize={!appState.media.skinny}
                                 bgOpacity={mapTo((appState.media.skinny ? 2000 : appState.media.innerWidth), 1080, 1300, .9, 0)}
                                 showSnippet={true}
                                 onNav={clickOn(item, "front")}
                                 opacity={(!showFull && (index > 0) && (index < 3) ? .15 : 1)}
                />
            </StoryItemDiv>;
        }
        return element;
    };
    let displayContent = (content, showFull) => {
        let contentState = {photoOnLeft: true};
        return content.map(displayContentElement(contentState, showFull));
    };

    let displaySecondaryElement = (state, showFull) => (item, index) => {
        return <ThumbnailStyled
            thumb={item.data}
            forceSkinny={false}
            opacity={mapTo(appState.media.innerWidth, 880, 1300, .075, .45) * (!showFull && (index > 3) ? .25 : 1)}
            key={"photo-" + item.data.ident}
            invisible={index > 5 && !showFull}
            blackAndWhite={true}
        />
    };
    let displaySecondary = (secondary, showFull) => {
        let contentState = {photoOnLeft: true};
        return secondary.map(displaySecondaryElement(contentState, showFull))
    };

    let checkFirstLabelVis = () => {
        if (firstLabelRef.current) {
            let elem = firstLabelRef.current;
            let bottom = elem.getBoundingClientRect().bottom;
            let hidden = bottom > window.innerHeight;
            runInAction(() => state.current.firstLabelNotInView = hidden);
        }
    };

    let scroll = (e) => {
        if (appState.photo.ident){
            return;
        }

        checkFirstLabelVis();

        runInAction(() => {
            state.current.hasScrolled = pageRef.current.scrollTop > 0;
        })
        let threshold = 800;
        if (contentRef.current) {
            let bottomOfScreen = pageRef.current.scrollTop + pageRef.current.offsetHeight;
            if (!state.current.contentDone && bottomOfScreen > contentRef.current.scrollHeight - threshold) {
                loadMoreContent();
            }
        }
        if (secondaryRef.current) {
            let scaling = 2;
            let bottomOfScreen = pageRef.current.scrollTop + pageRef.current.offsetHeight*scaling;
            if (!state.current.secondaryDone && bottomOfScreen > secondaryRef.current.scrollHeight*scaling - threshold) {
                loadMoreSecondary();
            }
        }
    }

    let setPageRef = (ref) => {
        if (pageRef.current){
            pageRef.current.removeEventListener("scroll", scroll);
        }
        pageRef.current = ref;
        if (pageRef.current) {
            pageRef.current.addEventListener("scroll", scroll, {passive: true});
        }
        runInAction(() => {
            appState.underlayScrollTargetFrontpage = ref;
        });
    };

    let goToTop = () => {
        runInAction(() => {
            state.current.showResetButton = false;
            state.current.content = [];
            state.current.contentDone = false;
        });
        hist.replace(loc.pathname + loc.search + loc.hash, {});
        loadMoreContent();
    };

    let showFull = state.current.hasScrolled || appState.media.skinny ||
        (state.current.content && state.current.content.length > 0 && state.current.content[0].type !== "photo");

    if (!hasSmoothGradients()) {
        useEffect(() => {
            let img = new Image();
            img.onload = () => runInAction(() => state.current.bgLoaded = true);
            img.src = "/static/images/bgGradient.png";
        }, []);
    }

    return (
        <>
            <PageDiv ref={setPageRef} bgLoaded={state.current.bgLoaded}>
                <Header>
                    <SiteLogo slogan={"A modern home for your photos"} mobile={appState.media.mobile}/>
                </Header>
                <Content ref={contentRef}>
                    {state.current.showResetButton ?
                        <ResetButton key="reset" onClick={goToTop}>
                            <MoveUpIconLightShadowed filterDef={'drop-shadow(0 0 2px rgba(0,0,0,.85))'} scale={20} widthPct={2}/>
                        </ResetButton>
                    : null}
                    {displayContent(state.current.content, showFull)}
                </Content>
                {!appState.media.skinny ?
                    <Secondary ref={secondaryRef}>
                        {displaySecondary(state.current.secondary, showFull)}
                    </Secondary>
                    : null
                }
            </PageDiv>
            <DownArrow visible={!showFull}>
                <MoveDownIconLightShadowed filterDef={'drop-shadow(0 0 2px rgba(0,0,0,.85))'} scale={20} widthPct={2}/>
            </DownArrow>
        </>
    );
}));
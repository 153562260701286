import React, {Component, useEffect, useRef, useState} from 'react'
import OverlayScrollbars from "overlayscrollbars";
import styled from '@emotion/styled';
import {MoveDownIconLightShadowed, MoveRightIconLightShadowed} from "../svg/Icons";
import Theme from "../styles/Theme";
import withScrolling, {createVerticalStrength} from 'react-dnd-scrolling';

const DndScrollDiv = withScrolling('div');
const vStrength = createVerticalStrength(30);

class CustomOverlayScrollbarsComponent extends Component {
    constructor(props) {
        super(props);
        this._osInstance = null;
        this._osTargetRef = React.createRef();
        this.state = {noTransition: true, downArrowVisible: false, rightArrowVisible: false};
    }

    osTarget() {
        return this._osTargetRef.current || null;
    }

    osInstance() {
        return this._osInstance;
    }

    updateArrowVisibility() {
        let osInstance = this.osInstance();
        if (osInstance) {
            let state = osInstance.getState();
            let scroll = osInstance.scroll();
            let downVisible = state.hasOverflow.y && scroll.position.y < scroll.max.y;
            let rightVisible = state.hasOverflow.x && scroll.position.x < scroll.max.x;
            this.refs.downArrow.style.opacity = downVisible ? 1 : 0;
            this.refs.rightArrow.style.opacity = rightVisible ? 1 : 0;
        }
    };

    makeOptions() {
        return {
            scrollbars: this.props.options.scrollbars,
            overflowBehavior: this.props.options.overflowBehavior,
            className: this.props.className,
            callbacks: {
                onScroll: (e) => {
                    this.scroll = this.osInstance().scroll();
                    this.props.options.callbacks.onScroll(e);
                    this.updateArrowVisibility();
                },
                onOverflowChanged: (e) => {
                    this.props.options.callbacks.onOverflowChanged(e);
                    this.updateArrowVisibility();
                },
                onHostSizeChanged: (e) => {
                    this.hostSize = e;
                    this.props.options.callbacks.onHostSizeChanged(e);
                    this.updateArrowVisibility();
                },
            }
        };
    }

    componentDidMount() {
        this._osInstance = OverlayScrollbars(this.osTarget(), this.makeOptions(), this.props.extensions);
        if (this.props.setOsInstance){
            this.props.setOsInstance(this._osInstance);
        }

        this.updateArrowVisibility();
    }

    componentWillUnmount() {
        if (OverlayScrollbars.valid(this._osInstance)) {
            this._osInstance.destroy();
            this._osInstance = null;
            if (this.props.setOsInstance){
                this.props.setOsInstance(null);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (OverlayScrollbars.valid(this._osInstance)) {
            this._osInstance.options(this.makeOptions());
        }
    }

    setRef = (ref) => {
        this._osTargetRef.current = ref;
    }

    render() {
        let {
            options,
            extensions,
            children,
            className,
            scrollDivRef,
            showDownArrow,
            showRightArrow,
            setOsInstance,
            useDndComponent,
            bgFadeColorArray,
            ...divProps
        } = this.props;

        let ScrollComponent = useDndComponent ? DndScrollDiv : 'div';
        let scrollProps = useDndComponent ? {
            verticalStrength: vStrength
        } : {};

        return (
            <div
                className={`${className} os-host`}
                {...divProps}
                ref={this.setRef}
            >
                <div className="os-resize-observer-host"/>
                <div className="os-padding">
                    <ScrollComponent className="os-viewport" ref={scrollDivRef} {...scrollProps}>
                        <div className="os-content">
                            {this.props.children}
                        </div>
                    </ScrollComponent>
                </div>
                <div className="os-scrollbar os-scrollbar-horizontal ">
                    <div className="os-scrollbar-track">
                        <div className="os-scrollbar-handle"/>
                    </div>
                </div>
                <div className="os-scrollbar os-scrollbar-vertical">
                    <div className="os-scrollbar-track">
                        <div className="os-scrollbar-handle"/>
                    </div>
                </div>
                <div className="os-scrollbar-corner"/>

                <RightArrow ref={"rightArrow"} visible={showRightArrow} bgFadeColorArray={bgFadeColorArray}>
                    <MoveRightIconLightShadowed
                        filterDef={
                            'drop-shadow(0 0 3px rgba(0,0,0,1)) ' +
                            (bgFadeColorArray ?
                                    `drop-shadow(0 0 6px rgba(${bgFadeColorArray[0]}, ${bgFadeColorArray[1]}, ${bgFadeColorArray[2]}, 1.0)) ` +
                                    `drop-shadow(0 0 12px rgba(${bgFadeColorArray[0]}, ${bgFadeColorArray[1]}, ${bgFadeColorArray[2]}, 1.0)) `
                                : ''
                            )
                        }
                        scale={40}
                        widthPct={.5}
                    />
                </RightArrow>
                <DownArrow ref={"downArrow"} visible={showDownArrow} bgFadeColorArray={bgFadeColorArray}>
                    <MoveDownIconLightShadowed
                        filterDef={
                            'drop-shadow(0 0 3px rgba(0,0,0,1)) ' +
                            (bgFadeColorArray ?
                                    `drop-shadow(0 0 6px rgba(${bgFadeColorArray[0]}, ${bgFadeColorArray[1]}, ${bgFadeColorArray[2]}, 1.0)) ` +
                                    `drop-shadow(0 0 12px rgba(${bgFadeColorArray[0]}, ${bgFadeColorArray[1]}, ${bgFadeColorArray[2]}, 1.0)) `
                                : ''
                            )
                        }
                        scale={20}
                        widthPct={2}
                    />
                </DownArrow>
            </div>
        );
    }
}

const CustomOverlayScrollbarsComponentStyled = styled(CustomOverlayScrollbarsComponent)`
  .os-scrollbar-handle {
    background: rgba(255,255,255,.15) !important;
  }
  ${p => p.$force ? `overflow: auto !important;` : ``}
`;

export const RightArrow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: ${p => p.visible ? 'visible' : 'hidden'};
  transition: 200ms opacity;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100px;
  will-change: opacity;
  color: ${Theme.colors.dim};
  pointer-events: none;
  user-select: none;
  ${p => !!p.bgFadeColorArray ? `
    background: linear-gradient(to right, 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${0}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.25,1.1)}),
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.5,1.1)}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.75,1.1)}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${1})
    );` : ``}
  align-items: flex-end;
  padding-right: 5px;
`;

export const DownArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${p => p.visible ? 'visible' : 'hidden'};
  transition: 200ms opacity;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  will-change: opacity;
  color: ${Theme.colors.dim};
  pointer-events: none;
  user-select: none;
  ${p => !!p.bgFadeColorArray ? `
    background: linear-gradient(to bottom, 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${0}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.25,1.1)}),
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.5,1.1)}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${Math.pow(0.75,1.1)}), 
        rgba(${p.bgFadeColorArray[0]}, ${p.bgFadeColorArray[1]}, ${p.bgFadeColorArray[2]}, ${1})
    );` : ``}
  align-items: flex-end;
  padding-bottom: 5px;
`;

export default ({children, showArrow, onScroll, scrollDivRef, className, useDndComponent, scrollY, scrollX, scrolledCallback, bgFadeColorArray, force}) => {
    let realScrollY = (scrollY == null) || !!scrollY;
    let realScrollX = !!scrollX;

    let actuallyShowDownArrow = showArrow && realScrollY;
    let actuallyShowRightArrow = showArrow && realScrollX;
    let osInstance = useRef(null);

    useEffect(() => {
        if (scrolledCallback) {
            scrolledCallback(false);
        }
    }, []);

    let generalCallback = () => {
        if (osInstance.current) {
            let scroll = osInstance.current.scroll();
            //setDownArrowVisible(scroll.position.y < scroll.max.y);
            //setRightArrowVisible(scroll.position.x < scroll.max.x);
            if (scrolledCallback && realScrollY) {
                scrolledCallback(scroll.position.y > 0);
            }
            if (scrolledCallback && realScrollX) {
                scrolledCallback(scroll.position.x > 0);
            }
        }
    };
    let scroll = (e) => {
        if (onScroll){
            onScroll(e);
        }
        generalCallback();
    };
    let setOsInstance = (instance) => {
        osInstance.current = instance;
        generalCallback();
    }
    return <CustomOverlayScrollbarsComponentStyled
        $force={force}
        bgFadeColorArray={bgFadeColorArray}
        showDownArrow={actuallyShowDownArrow}
        showRightArrow={actuallyShowRightArrow}
        scrollDivRef={scrollDivRef}
        className={className}
        useDndComponent={useDndComponent}
        options={{
            scrollbars: {
                autoHide: 'scroll'
            },
            overflowBehavior : {
                y: realScrollY ? "scroll" : "hidden",
                x: realScrollX ? "scroll" : "hidden"
            },
            callbacks: {
                onScroll: scroll,
                onOverflowChanged: generalCallback,
                onHostSizeChanged: generalCallback
            },
            className : "os-theme-light"
        }}
        setOsInstance={setOsInstance}
    >
        <>
            {children}
        </>
    </CustomOverlayScrollbarsComponentStyled>;
};
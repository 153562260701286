import * as chrono from 'chrono-node';
import moment from 'moment'

const weekParser = {};
weekParser.pattern = function () { return /this week|last week/; };
weekParser.extract = function (context, match) {
    let result = context.createParsingResult(match.index, match[0]);
    result.start = context.createParsingComponents();
    result.end = context.createParsingComponents();

    let now = moment();

    let start;
    let end;

    if (match[0] === "this week"){
        start = now.clone().startOf("week");
        end = now.clone().endOf("week");
    } else if (match[0] === "last week"){
        start = now.clone().startOf("week").subtract(1, "weeks");
        end = now.clone().endOf("week").subtract(1, "weeks");
    } else {
        return false;
    }

    result.start.imply('year', start.year());
    result.start.imply('month', start.month() + 1);
    result.start.imply('day', start.date());
    result.start.imply('hour', start.hour());
    result.start.imply('minute', start.minute());
    result.start.imply('second', start.second());

    result.end.imply('year', end.year());
    result.end.imply('month', end.month() + 1);
    result.end.imply('day', end.date());
    result.end.imply('hour', end.hour());
    result.end.imply('minute', end.minute());
    result.end.imply('second', end.second());

    return result;
};

export default weekParser;
import React from 'react';
import ReactDOM from 'react-dom';
import MainSite from "./apps/MainSite";
import Modal from 'react-modal';
import posthogInit from "./posthogInit";
import AppErrorBoundary from "./components/AppErrorBoundary";
import Config from "./Config";
import "core-js/stable";
import "regenerator-runtime/runtime";
import maplibre from '!maplibre-gl';

maplibre.workerCount = navigator.hardwareConcurrency ? Math.max(navigator.hardwareConcurrency-1,1) : 6;
maplibre.prewarm();

if (Config.APP_IS_DEV && window.location.hash === "#debug") {
    (async () => {
        await import('screenlog/dist/screenlog.min');
        screenLog.init();
    })();
}

window.posthog = posthogInit();
const rootEl = document.getElementById('root');

ReactDOM.render(
    <AppErrorBoundary app={"main"}>
        <MainSite/>
    </AppErrorBoundary>,
    rootEl
);

Modal.setAppElement(rootEl);

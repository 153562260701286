import styled from '@emotion/styled';
import Theme from "./Theme";

export const DimmerSpan = styled.span`
    color: ${Theme.colors.dimmer};
`;

export const DimSpan = styled.span`
    color: ${Theme.colors.dim};
`;

export const NotQuiteDimSpan = styled.span`
    color: ${Theme.colors.notQuiteDim};
`;

export const NearingDimSpan = styled.span`
    color: ${Theme.colors.nearingDim};
`;

export const AlmostDimSpan = styled.span`
    color: ${Theme.colors.almostDim};
`;

export const KindaDimSpan = styled.span`
    color: ${Theme.colors.kindaDim};
`;

export const AlmostWhiteSpan = styled.span`
    color: ${Theme.colors.almostWhite};
`;

export const WhiteSpan = styled.span`
    color: white;
`;

export const AiSpan = styled.span`
    color: ${Theme.colors.ai};
`;

export const Emphasis = styled.span`
  color: ${Theme.colors.almostWhite};
  text-shadow: 0px 2px 1px rgba(0,0,0,.35);
  font-weight: 500;
`;

export const FixedWidthEmphasis = styled(Emphasis)`
  font-family: monospace;
`;
import { EventEmitter } from 'eventemitter3';
import { useEffect } from 'react';

const emitter = new EventEmitter();

export const useListener = (name, callback) => {
    useEffect(() => {
        emitter.on(name, callback);
        return () => emitter.off(name, callback);
    }, [name, callback]);
}

export const broadcastToListeners = (name, ...args) => {
    emitter.emit(name, ...args);
}

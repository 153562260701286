import React, {useEffect, useRef} from 'react'
import Api from "../components/Api";
import {observable, runInAction} from 'mobx'
import {inject, observer} from 'mobx-react'
import styled from '@emotion/styled'
import Theme from "../styles/Theme";
import {sleep} from "../helpers/AsyncHelper";
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import {ButtonLikeButton} from "../styles/Button";
import {MoveUpIconLightShadowed} from "../svg/Icons";
import Background from "./Background";
import WithFooter from "./WithFooter";

const PageDiv = styled.div`
  padding-bottom: 20px;
`;

const ResetButton = styled(ButtonLikeButton)`
  position: fixed;
  
  margin-top: 10px;
  left: calc(50vw - 100px);
  width: 200px;
  height: 34px;
  border-radius: 4px;
  pointer-events: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.backgroundLighter};
  opacity: .9;
  color: ${Theme.colors.notQuiteDim};
  z-index: 20;
  
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.035), 0 0 5px 0 rgba(0,0,0,.25);
  &:hover, &.focus-visible {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.25), 0 0 5px 0 rgba(0,0,0,.5) !important;
  }
`;

const VerticalSpacer = styled.div`
  margin-top: 30px;
  height: ${p => (p.mobile ? 200 : 240) * (p.moreSpace ? 1.1 : 1) }px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${p => p.empty ? "flex-start" : "center"};
  padding-bottom: ${p => p.mobile ? 25 : 60 }px;
`;

const EndDiv = styled.div`
  width: 50%;
  min-width: 240px;
  max-width: 480px;
  height: ${p => p.mobile ? 160 : 160 }px;
  border-radius: ${p => p.mobile ? 30 : 40 }px;
  border: dashed 2px rgba(255,255,255,.065);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.colors.dimmer};
  opacity: ${p => p.visible ? 1.0 : 0};
  ${p => p.visible ? `transition: opacity 500ms linear` : ``};
`;

export default inject('appState')(observer((props) => {

    let state = useRef(observable({
        canLoadMoreReverse: true,
        canLoadMoreForward: true,
        notDoneLoadingForward: true,
        notDoneLoadingReverse: true,
        firstElement: null,
        lastElement: null,
        underlaySeries: null,
        awaitingLoadUrl: null,
        loadedIdents: new Set(),
        elements: [],
        elementsReverse: [],
        time: null,
        showTopButton: false,
        doneWaiting: false,
        resetCount: 0
    }));

    let listenerData = useRef({
        reverseContainerScrollHeight: 0,
        reverseContainerListener: null
    });

    let data = state.current;

    let hist = useHistory();
    let loc = useLocation();

    let applySortObjToQuery = props.applySortObjToQuery ? props.applySortObjToQuery : (query, sortObj) => {
        query["after-ident"] = sortObj.ident;
        query["after-sort-value"] = sortObj.sortValue;
    };
    let extractSortObj = props.extractSortObj ? props.extractSortObj : (data) => ({
        ident: data.ident,
        sortValue: data.sortValue
    });
    let extractIdentifier = props.extractIdentifier ? props.extractIdentifier : (data) => data.ident;
    let minSortObj = props.minSortObj ? props.minSortObj : {ident: "min", sortValue: "min"};
    let maxSortObj = props.maxSortObj ? props.maxSortObj : {ident: "max", sortValue: "max"};

    let resetData = () => {
        runInAction(() => {
            data.elements = [];
            data.elementsReverse = [];
            data.canLoadMoreForward = true;
            data.canLoadMoreReverse = true;
            data.notDoneLoadingForward = true;
            data.notDoneLoadingReverse = true;
            data.lastElement = null;
            data.firstElement = null;
            data.time = null;
            data.awaitingLoadUrl = null;
            data.loadedIdents.clear();
            data.showTopButton = false;
            data.doneWaiting = false;
            data.resetCount = data.resetCount + 1;
        });
    }

    let waitAndUnlock = (reverse) => {
        if (reverse){
            runInAction(() => data.canLoadMoreReverse = data.notDoneLoadingReverse);
        } else {
            runInAction(() => data.canLoadMoreForward = data.notDoneLoadingForward);
        }
    }

    let load = async (reverse) => {
        let canLoadMore = (reverse ?
            data.notDoneLoadingReverse && data.canLoadMoreReverse
            : data.canLoadMoreForward && data.notDoneLoadingForward);
        if (canLoadMore) {
            let {url, query, sortAscend, sortField} = props.params;

            let midway = false;
            let finalQuery = {...query};
            if (sortField) {
                finalQuery["sort-field"] = sortField;
            }
            if (hist.location.state && hist.location.state.ident && hist.location.state.sortValue) {
                applySortObjToQuery(finalQuery, hist.location.state);
                finalQuery["inclusive"] = !reverse;
                midway = true;
            } else {
                if (sortAscend){
                    applySortObjToQuery(finalQuery, minSortObj);
                } else {
                    applySortObjToQuery(finalQuery, maxSortObj);
                }
                finalQuery["inclusive"] = false;
            }
            finalQuery["num"] = 16;
            finalQuery["null-is-big"] = sortAscend;

            if (reverse) {
                runInAction(() => data.canLoadMoreReverse = false);
                finalQuery["ascend"] = !sortAscend;
                if (data.firstElement) {
                    applySortObjToQuery(finalQuery, data.firstElement);
                    finalQuery["inclusive"] = false;
                } else if (data.lastElement) {
                    applySortObjToQuery(finalQuery, data.lastElement);
                    finalQuery["inclusive"] = true;
                } else if (!midway) {
                    runInAction(() => data.notDoneLoadingForward = false);
                }
            } else {
                runInAction(() => data.canLoadMoreForward = false);
                finalQuery["ascend"] = sortAscend;
                if (data.lastElement) {
                    applySortObjToQuery(finalQuery, data.lastElement);
                    finalQuery["inclusive"] = false;
                } else if (data.firstElement) {
                    applySortObjToQuery(finalQuery, data.firstElement);
                    finalQuery["inclusive"] = true;
                } else if (!midway) {
                    runInAction(() => data.notDoneLoadingReverse = false);
                }
            }

            runInAction(() => {
                let series = {};
                series.url = url;
                series.type = "gallery";

                series.nextQuery = {};
                series.nextQuery["sort-field"] = sortField;
                series.nextQuery["num"] = 1;
                series.nextQuery["inclusive"] = false;
                series.nextQuery["null-is-big"] = sortAscend;
                series.nextQuery["ascend"] = sortAscend;

                series.previousQuery = {};
                series.previousQuery["sort-field"] = sortField;
                series.previousQuery["num"] = 1;
                series.previousQuery["inclusive"] = false;
                series.previousQuery["null-is-big"] = sortAscend;
                series.previousQuery["ascend"] = !sortAscend;

                runInAction(() => data.underlaySeries = series);
            });

            runInAction(() => data.awaitingLoadUrl = url);
            let resetCount = data.resetCount;
            let elements = await Api.get(url + "?" + queryString.stringify(finalQuery));
            if (resetCount !== data.resetCount) {
                return;
            }
            if (props.waitFor) {
                await props.waitFor;
            }
            runInAction(() => data.doneWaiting = true);
            if (elements && elements.length !== 0) {
                if (data.awaitingLoadUrl !== url){
                    return;
                }
                let nestedElements = [];
                elements.forEach(element => {
                    let obj = {data: element};
                    if (!data.loadedIdents.has(extractIdentifier(element))) {
                        runInAction(() => data.loadedIdents.add(extractIdentifier(element)));
                        nestedElements.push(obj);
                    }
                });

                if (reverse){
                    runInAction(() => data.elementsReverse = data.elementsReverse.concat(nestedElements));
                } else {
                    runInAction(() => data.elements = data.elements.concat(nestedElements));
                }
                if (nestedElements.length > 0) {
                    if (reverse) {
                        if (!data.lastElement){
                            runInAction(() => data.lastElement = extractSortObj(nestedElements[0].data));
                        }
                        runInAction(() => data.firstElement = extractSortObj(nestedElements[nestedElements.length - 1].data));
                    } else {
                        if (!data.firstElement){
                            runInAction(() => data.firstElement = extractSortObj(nestedElements[0].data));
                        }
                        runInAction(() => data.lastElement = extractSortObj(nestedElements[nestedElements.length - 1].data));
                    }
                }
                waitAndUnlock(reverse);
            } else {
                if (reverse){
                    runInAction(() => data.notDoneLoadingReverse = false);
                } else {
                    runInAction(() => data.notDoneLoadingForward = false);
                }
            }
        }
    }

    let loadForward = async () => {
        return await load(false);
    }
    let loadReverse = async () => {
        return await load(true);
    }

    let loadReverseWithSleep = async () => {
        await sleep(500);
        return await loadReverse();
    }

    let scroll = () => {
        if (props.appState.photo.ident){
            return;
        }
        let threshold = 600;
        if((window.scrollY + window.innerHeight >= document.body.scrollHeight - threshold - 800)){
            loadForward();
        }
        if((window.scrollY <= threshold)){
            loadReverse();
        }
    }

    let reset = async () => {
        resetData();
        window.scrollTo({left: 0, top: 0, behavior: "auto"});

        do {
            await loadForward();
        } while (data.canLoadMoreForward && document.documentElement.scrollHeight <= document.documentElement.clientHeight);

        await loadReverse(true);
        await sleep(500);
    }

    useEffect(() => {
        reset();
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll);
    }, [props.params]);

    let navCallback = (sortObj) => () => {
        hist.replace(loc.pathname + loc.search + loc.hash, sortObj);
    };

    let setReverseRef = (ref) => {
        let ld = listenerData.current;
        let config = { attributes: true, childList: true, subtree: true };
        runInAction(() => {
            let same = ld.reverseContainer === ref;
            if (ld.reverseContainerListener && !same){
                ld.reverseContainerListener.disconnect();
                ld.reverseContainerListener = null;
            }
            ld.reverseContainer = ref;
            if (ref && !same) {
                let initAmt = ld.reverseContainer.scrollHeight - ld.reverseContainerScrollHeight;
                ld.reverseContainerScrollHeight = ld.reverseContainer.scrollHeight;
                if (initAmt > 0) {
                    window.scrollBy({left: 0, top: initAmt, behavior: "auto"});
                }
                ld.reverseContainerListener = new MutationObserver(() => {
                    if (ld.reverseContainer) {
                        let amt = ld.reverseContainer.scrollHeight - ld.reverseContainerScrollHeight;
                        if (amt > 0) {
                            window.scrollBy({left: 0, top: amt, behavior: "auto"});
                        }
                        ld.reverseContainerScrollHeight = ld.reverseContainer.scrollHeight;
                    }
                });
                ld.reverseContainerListener.observe(ld.reverseContainer, config);
            }
        });
    };

    let goToTop = async () => {
        hist.replace(loc.pathname + loc.search + loc.hash, {});
        reset();
    };

    let childrenReverse = data.elementsReverse.map((t) => props.renderElement(t.data, navCallback(extractSortObj(t.data))));
    let children = data.elements.map((t) => props.renderElement(t.data, navCallback(extractSortObj(t.data))));

    let doneLoading = !data.notDoneLoadingForward && !data.notDoneLoadingReverse;
    let empty = data.elements.length + data.elementsReverse.length === 0;
    let mobile = props.appState.media.mobile;
    let showMessageOnEmpty = (props.showMessageOnEmpty !== undefined) ? props.showMessageOnEmpty : true;
    let showMessageOnNotEmpty = (props.showMessageOnNotEmpty !== undefined) ? props.showMessageOnNotEmpty : true;

    return <Background bg={Theme.colors.background}>
        <WithFooter>
            <PageDiv>
                {data.notDoneLoadingReverse && data.doneWaiting ?
                    <ResetButton onClick={goToTop}>
                        <MoveUpIconLightShadowed filterDef={'drop-shadow(0 0 2px rgba(0,0,0,.85))'} scale={20} widthPct={2}/>
                    </ResetButton>
                    : null
                }
                {props.header}
                {props.renderContainerReverse(childrenReverse, setReverseRef)}
                {props.renderContainer(children, ()=>{})}
                <VerticalSpacer moreSpace={true} mobile={mobile} empty={empty}>
                    <EndDiv mobile={mobile} visible={doneLoading && ((empty && showMessageOnEmpty) || (!empty && showMessageOnNotEmpty))}>
                        {empty ? (props.emptyMessage ? props.emptyMessage : "We couldn't find anything.") : (props.doneMessage ? props.doneMessage : "That's all we could find!")}
                    </EndDiv>
                </VerticalSpacer>
            </PageDiv>
        </WithFooter>
    </Background>;
}))
import React, {useEffect, useState, useRef} from 'react';
import RestrictedMarkdown from "../../components/RestrictedMarkdown";
import {
  useLocation,
  useRouteMatch
} from "react-router-dom";
import DumbLinkContainer from "../../components/DumbLinkContainer";
import styled from '@emotion/styled';
import { css } from "@emotion/react";
import Theme from "../../styles/Theme";
import WindowHelper from "../../helpers/WindowHelper";
import {typeface} from "../../styles/Typeface";
import Breadcrumbs from "../../components/Breadcrumbs";
import {ExploreLinkStyled} from "../senses/Senses";
import {inject, observer} from "mobx-react";
import {runInAction, observable} from "mobx";
import {SpinnerFadeIn} from "../../components/Spinner";
import Scroll from "../../components/Scroll";
import {ScrollCss} from "../../styles/Page";
import scrollIntoView from "scroll-into-view-if-needed"
import Background from "../../components/Background";
import {Helmet} from "react-helmet";
import helpJson from "../../help.json"

const linkStyle = css`
  display: inline-block;
  color: ${Theme.colors.kindaDim};
  ${typeface(14, 700, -.015)};
  width: 100%;
  border-radius: 5px;
  padding: 4px 8px;
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
`;

const TreeLink = styled.a`
  ${linkStyle};
  user-select: none;
`;

const CurrentLink = styled.div`
  ${linkStyle};
  color: ${Theme.colors.almostWhite};
  background: ${Theme.colors.dim};
  user-select: none;
`;

const LeftPanel = styled.div`
  padding-bottom: 40px;
`;

const ScrollLeft = styled(Scroll)`
  height: 100%;
  width: 275px;
  flex-basis: 275px;
  flex-shrink: 0;
  padding: 40px;
  //background: ${Theme.colors.background};
  border-right: 1px solid rgba(60,60,60,1);
  box-shadow: 0 0 3px 1px rgba(0,0,0,.5);
`;

const RightPanel = styled.div`
  top: 0px;
  padding: 0px 10px 80px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  height: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
  
   ${ScrollCss};
`;

const HelpHolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
`;

const IndexTitleLink = styled.a`
  ${linkStyle};
  margin-bottom: 10px;
  ${typeface(16, 700, -.02)};
`;

const CurrentIndexTitleLink = styled.a`
  ${linkStyle};
  margin-bottom: 10px;
  color: ${Theme.colors.almostWhite};
  background: ${Theme.colors.dim};
  ${typeface(16, 700, -.02)};
`;

const TableSpacer = styled.div`
  display: inline-block;
  width: ${p => p.size*24}px;
`;

const HelpRestrictedMarkdown = styled(RestrictedMarkdown)`
  color: ${Theme.colors.kindaDim};
  //text-shadow: 0 2px 1px rgba(0,0,0,.5);
  ${typeface(16, 400, .01)};
  width: min(640px, 100%);
  margin: 16px 0;
  padding: 0px 8px;
  
  a {
    color: ${Theme.colors.almostWhite};
    text-shadow: 0 2px 1px rgba(0,0,0,.8);
    ${typeface(16, 700, .0025)};
    
    &:hover {
      color: white;
    }
    
    &::after {
      content: "";
      display: none;
    }
    
    &:hover::after {
      content: "";
      display: none;
    }
  }
  
  p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 18px;
  }
  
  h1:not(:first-child),
  h2:not(:first-child), 
  h3:not(:first-child), 
  h4:not(:first-child),
  h5:not(:first-child), 
  h6:not(:first-child) {
    margin-top: 36px;
  }
  
  strong {
    ${typeface(16, 600, .002)};
  }
  
  table strong {
    font-size: unset;
    letter-spacing: unset;
  }
 
  .highlighted {
    animation: highlightAnimation 3s linear;
  }
  
  @keyframes highlightAnimation {
    0% { background: none }
    10% { background: none }
    25% { background: ${Theme.colors.backgroundLighter} }
    50% { background: ${Theme.colors.backgroundLighter} }
    100% { background: none }
  }
  
  pre code {
    word-wrap: normal;
  }
`;

const SectionLink = styled.a`
  display: inline-block;
  color: ${Theme.colors.kindaDim};
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  ${typeface(16, 700, -.02)};
  margin-left: 20px;
  margin-bottom: 4px;
`;

const Subsections = styled.div`
  width: min(624px, 100%);
  margin: 0 0 20px 0;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.65);
  border: 1px solid rgba(255,255,255,.15);
`;

const SubsectionsLabel = styled.div`
  color: ${Theme.colors.notQuiteDim};
  margin-bottom: 4px;
`;

const PageDiv = styled.div`
  height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px);
`;

const SpinnerHolder = styled.div`
  margin-top: 60px;
`;

const ExploreLinkStyledDim = styled(ExploreLinkStyled)`
  color: ${Theme.colors.dim};
`;

let makeHash = (node, basePath='') => {
    let path = `${basePath}/${node.content}`;
    let output = {};
    output["data"] = node;
    output["children"] = {};
    output["path"] = path;
    if (node.children) {
        node.children.forEach(child => {
            output["children"][child.content] = makeHash(child, path);
        });
    }
    return output;
};

export const HELP_INDEX_HASH = makeHash(helpJson);

export default inject("appState")(observer(({appState}) => {
    let {url, path} = useRouteMatch();
    let index = helpJson;
    let indexHash = HELP_INDEX_HASH;
    let [content, setContent] = useState(null);
    let location = useLocation();
    let normalizedPath = location.pathname.replace(/\/$/, "");
    let indexRefs = useRef({});

    let ensureTreeLinkVisible = () => {
        let el = indexRefs.current[normalizedPath];
        if (el){
            scrollIntoView(el, {
                scrollMode: 'if-needed',
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        ensureTreeLinkVisible();
    }, [normalizedPath]);

    const scrollToHash = (hash, smooth) => {
        window.requestAnimationFrame(() => {
            if (hash) {
                let el = document.getElementById(hash.replace("#", "").toLowerCase());
                if (el) {
                    el.classList.add("highlighted");
                    scrollIntoView(el, {
                        block: 'start',
                        behavior: smooth ? 'smooth' : 'auto'
                    });
                }
            }
        });
    }

    useEffect(() => {
        (async () => {
            setContent(null);
            let contentFetch = await fetch(`/static${normalizedPath}.md`);
            if (contentFetch.ok) {
                let contentBody = await contentFetch.text();
                setContent(contentBody);
                scrollToHash(location.hash, false);
            } else if (contentFetch.status === 404) {
                setContent("Page not found.");
            }
        })();
    }, [location.pathname]);

    useEffect(() => {
        runInAction(() => appState.helpOpen = true);
        return () => runInAction(() => appState.helpOpen = false);
    }, []);

    let indexToComponents = (node, basePath) => {
        let topComponent = null;
        if (basePath.length === 0){
            let active = url === normalizedPath;
            let LinkClass = active ? CurrentIndexTitleLink : IndexTitleLink;
            topComponent = <DumbLinkContainer to={url}>
                <LinkClass ref={ref => indexRefs.current[url] = ref}>{node.title}</LinkClass>
            </DumbLinkContainer>
        }
        let childrenComponents = null;
        if (node.children) {
            childrenComponents = node.children.map(childNode => {
                let link = `${url}/${basePath.concat(childNode.content).join("/")}`;
                let active = link === normalizedPath;
                let LinkClass = active ? CurrentLink : TreeLink;
                return <React.Fragment key={link}>
                    <div>
                        <DumbLinkContainer to={link}>
                            <LinkClass ref={ref => indexRefs.current[link] = ref}>
                                <TableSpacer size={basePath.length}/>{childNode.title}
                            </LinkClass>
                        </DumbLinkContainer>
                    </div>
                    {childNode.children && childNode.children.length > 0 ?
                        indexToComponents(childNode, basePath.concat(childNode.content))
                        : null
                    }
                </React.Fragment>;
            });
        }
        return <>
            {topComponent}
            {childrenComponents}
        </>
    };

    let pathLinks = [];
    let target = url;
    let pathParts = normalizedPath.split("/").slice(2);

    let hashNode = pathParts.reduce((hash, val) => {
        if (hash && hash.children && hash.children[val]){
            return hash.children[val];
        } else {
            return null;
        }
    }, indexHash);

    let parent = pathParts.slice(0,pathParts.length-1).reduce((hash, val) => {
        if (hash && hash.children && hash.children[val]){
            return hash.children[val];
        } else {
            return null;
        }
    }, indexHash);
    let dataIndex = -1;
    if (parent && parent.data) {
        parent.data.children.forEach((child, idx) => {
            if (child.content === pathParts[pathParts.length - 1]) {
                dataIndex = idx;
            }
        });
    }
    let nextSection = null;
    if (dataIndex >= 0){
        nextSection = parent.data.children[dataIndex+1];
    }

    let title = "Help";
    pathParts.forEach((part, index) => {
        target += `/${part}`;
        let partLabel = part;
        if (index === pathParts.length-1 && hashNode){
            partLabel = hashNode.data.title;
        }
        if (target !== normalizedPath) {
            pathLinks.push(<ExploreLinkStyledDim key={target} breadcrumb={true} to={target} label={partLabel}/>);
        } else {
            pathLinks.push(<React.Fragment key={target}>{partLabel}</React.Fragment>);
            title = partLabel;
        }
    });
    let crumbs = [ pathLinks.length > 0 ?
            <ExploreLinkStyledDim mobile={appState.media.mobile} key={url} breadcrumb={true} to={url} label={"Help"}/> :
            <React.Fragment key={url}>Help</React.Fragment>
    ].concat(pathLinks);

    let linkOpener = (path) => () => {
        let parts = path.split("#");
        if (parts[0] === location.pathname){
            appState.history.replace(path);
        } else {
            appState.history.push(path);
        }
        if (parts.length === 2 && parts[0] === location.pathname){
            scrollToHash(parts[1], true);
        } else {
            window.scrollTo({top: 0, left: 0, behavior: "auto"});
        }
    };

    let setScrollRef = (ref) => {
        runInAction(() => {
            appState.underlayScrollTargetHelp = ref;
        });
    };

    let showContent = content !== null && hashNode && hashNode.data;

    return <Background bg={Theme.colors.backgroundDark}>
        <PageDiv>
            <HelpHolder>
                {!appState.media.mobile ?
                    <ScrollLeft>
                        <LeftPanel>
                            {
                                indexToComponents(index, [])
                            }
                        </LeftPanel>
                    </ScrollLeft>
                    : null
                }
                <RightPanel ref={setScrollRef}>
                    {showContent ?
                        <>
                            <Helmet>
                                <title>{title}</title>
                            </Helmet>
                            <Breadcrumbs sepSlim={true} sepColor={Theme.colors.dimmer} children={crumbs}/>
                            <HelpRestrictedMarkdown
                                preserveSpaces={hashNode.data["legal"]}
                                internal={true}
                                text={content}
                                linkOpener={linkOpener}
                            />
                        </>
                        :
                        <SpinnerHolder><SpinnerFadeIn/></SpinnerHolder>
                    }
                    {(showContent && hashNode.data.children) ?
                        <Subsections>
                            <SubsectionsLabel>Subsections:</SubsectionsLabel>
                            {
                                hashNode.data.children.map(child => {
                                    let link = `${hashNode.path}/${child.content}`;
                                    return <div key={link}>
                                        <DumbLinkContainer to={link}>
                                            <SectionLink>{child.title}</SectionLink>
                                        </DumbLinkContainer>
                                    </div>;
                                })
                            }
                        </Subsections>
                        : null}
                    {(showContent && nextSection) ? <Subsections>
                        <SubsectionsLabel>Next section:</SubsectionsLabel>
                        <DumbLinkContainer to={nextSection.content}>
                            <SectionLink>{nextSection.title}</SectionLink>
                        </DumbLinkContainer>
                    </Subsections> : null}
                </RightPanel>
            </HelpHolder>
        </PageDiv>
    </Background>;
}));

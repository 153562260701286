import React, {useState, useEffect, useRef, useReducer, memo} from 'react';
import {toJS, reaction} from 'mobx';
import {inject, observer} from "mobx-react";
import StoryItem from "../../storylist/components/StoryItem";
import Api from "../../../components/Api";
import {useIsUnmounted} from "../../../helpers/ReactHelpers";
import Theme from "../../../styles/Theme";
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {StoryContent, SectionHeader} from "../../storylist/user/UserStoryList";
import {typeface} from "../../../styles/Typeface";
import AvatarEditor from 'react-avatar-editor'
import {
    Button120,
    ButtonLike,
    ButtonLikeButton,
    ButtonLikeButtonGray,
    ButtonLikeMiniDimButton
} from "../../../styles/Button";
import {Handle, RailElement } from "../../../components/sliderhelpers/SliderHelpers";
import { Slider, Rail, Handles } from 'react-compound-slider'
import {
    CheckmarkIconLightShadowed,
    EditIconLightShadowed,
    EditIconShadowed,
    FollowIconLightShadowed,
    IsFollowingIconLightShadowed,
    PlusIconLightShadowed,
    RightIconNoShadow,
    SwapIconLightShadowed,
    XIconLightShadowed
} from "../../../svg/Icons";
import {getSignedUrlSync} from "../../../helpers/SignedUrlLoader";
import {NoProfileImage} from "../../../svg/Graphics";
import TextareaAutosizeNoManual from "../../../components/TextareaAutosizeNoManual";
import RestrictedMarkdown from "../../../components/RestrictedMarkdown";
import TextInput from "../../../components/TextInput";
import {runInAction, observable} from "mobx"
import Breadcrumbs from "../../../components/Breadcrumbs";
import DumbLinkContainer from "../../../components/DumbLinkContainer";
import Thumbnail from "../../../components/Thumbnail";
import queryString from 'query-string';
import {RadioGroup, Radio} from 'react-radio-group'
import {FormGroupDiv, FormLabel, FormLabelDiv} from "../../../styles/Modal";
import Spinner, {SpinnerFadeIn} from "../../../components/Spinner";
import {Helmet} from "react-helmet";
import BaseMap from "../../map/BaseMap";

class AvatarEditorModified extends AvatarEditor {

    getImageScaledToScreen() {
        let ratio = 2;
        const { width, height } = this.getDimensions();

        const canvas = document.createElement('canvas');

        if (this.isVertical()) {
          canvas.width = height * ratio;
          canvas.height = width * ratio;
        } else {
          canvas.width = width * ratio;
          canvas.height = height * ratio;
        }

        this.paintImage(canvas.getContext('2d'), this.state.image, 0, ratio);

        return canvas;
    }

}

const MoreButton = styled(Button120)`
  background: none;
  min-width: unset;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 0 7px;
  line-height: 10px;
  color: ${Theme.colors.dim};
  position: absolute;
  top: -1px;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.05), 0 1px 4px 0 rgba(0,0,0,.25);
`;

const SectionHeaderLink = styled.a`
  &:hover {
    color: ${Theme.colors.kindaDim} !important; 
  }
  
  &:hover ${MoreButton} {
    color: ${Theme.colors.kindaDim} !important;
    box-shadow: 0 0 0px 1px rgba(255,255,255,.2) inset, 0 2px 4px 1px rgba(0,0,0,.25) !important;
  }
  
  position: relative;
`;

const Holder = styled.div`
  width: 100%;
`;

const Section = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding-top: 32px;
  contain: layout;
`;

const Subtle = css`
  border: none;
  outline: none;
  ${typeface(14, 400)};
  background: none;
  margin: 4px;
  
  text-shadow: 0px 2px 1px rgba(0,0,0,.35);
  color: ${Theme.colors.notQuiteDim};
  display: inline-block;
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  &:hover:not(:disabled) {
    color: ${Theme.colors.kindaDim};
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0 1px 6px 0 rgba(0,0,0,.7);
  }
  &:disabled {
    opacity: .25;
  }
`;

const SubtleButtonLabel = styled.label`
  ${Subtle};
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${Theme.colors.background};
  ${p => p.disabled ? `opacity: .25` : ``};
`;

const SubtleButton = styled.button`
  ${Subtle};
  &:not(:disabled) {
    cursor: pointer;
  }
`;

const SubtleButtonRound = styled(SubtleButton)`
  width: 32px;
  height: 32px;
  padding: 4px;
  margin: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${Theme.colors.background};
`;

const CustomShowcaseItemDiv = styled.div`
  position: relative;
`;

const ShowcaseCustomRemoveButton = styled(SubtleButtonRound)`
  position: absolute;
  top: ${p => p.mobile ? -3 : 3}px;
  right: ${p => p.mobile ? -3 : 3}px;
  background: ${Theme.colors.backgroundLight};
`;

const ShowcaseCustomSwapButton = styled(SubtleButtonRound)`
  background: ${Theme.colors.backgroundLight};
  width: ${p => p.mobile ? 24 : 32}px;
  height: ${p => p.mobile ? 24 : 32}px;
`;

const SubtleButtonRoundBottomMargin = styled(SubtleButtonRound)`
    margin-bottom: 5px;
`;

const SubtleButtonHolder = styled.div`
  display: flex;
  justify-content: center;
`

const SubtleButtonLabelHolder = styled.div`
  float: right;
  position: relative;
  bottom: 32px;
  right: -8px;
`

const UserImageHolder = styled.div`
  text-align: center;
`;

const CropImg = styled.img`
  width: ${p => p.scale}px;
  height: ${p => p.scale}px;
  clip-path: circle(50%);
  filter: ${p => p.blackAndWhite ? `grayscale(100%)` : `none`};
  transition: 150ms filter;
`;

const CropDiv = styled.div`
  width: ${p => p.scale}px;
  height: ${p => p.scale}px;
  clip-path: circle(50%);
`;

export const UserImage = ({filename, scale, blackAndWhite, className, onLoad}) => {
    let sc = scale ? scale : 128;
    let doOnLoad = () => {
        if (onLoad) {
            onLoad();
        }
    }
    let hasImage = filename !== null && filename !== "";
    useEffect(() => {
        if (!hasImage) {
            doOnLoad();
        }
    }, []);
    return hasImage ?
        <CropImg
            className={className}
            scale={sc}
            crossOrigin={"Anonymous"}
            src={getSignedUrlSync(filename)}
            blackAndWhite={blackAndWhite}
            onLoad={doOnLoad}
        />
        :
        <CropDiv className={className} scale={sc}>
            <NoProfileImage scale={sc}/>
        </CropDiv>;
}

const HiddenInput = styled.input`
  display: none !important;
`;

const ButtonLikeLabel = styled.label`
  ${ButtonLike};
  width: 160px;
  line-height: 34px;
`;

const ProfileImageEditorHolder = styled.div`
  display: inline-block;
  width: 128px;
  height: 128px;
  border-radius: 64px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  margin: 8px;
  & canvas {
    clip-path: circle(50%);
  }
`;

export const ProfileImageHolder = styled.div`
  display: inline-block;
  width: 128px;
  height: 128px;
  border-radius: 64px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  margin: 8px;
  transition: opacity 150ms;
  opacity: ${p => p.invisible ? 0 : 1};
`;

export const UserImageComponent = ({filename}) => {
    let [loaded, setLoaded] = useState(false);
    return <ProfileImageHolder invisible={!loaded}>
        <UserImage filename={filename} onLoad={() => setLoaded(true)}/>
    </ProfileImageHolder>
};

const SliderDiv = styled.div`
  display: inline-block;
`

const ButtonLikeButtonSmall = styled(ButtonLikeButton)`
  width: 54px;
`;

const ButtonLikeButtonGraySmall = styled(ButtonLikeButtonGray)`
  width: 54px;
`;

const SmallSliderHolder = styled.div`
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  padding-bottom: 3px;
  height: 20px;
  user-select: none;
  width: 130px;
`;

const SmallSlider = ({onChange}) => {

    let sliderStyle = {
        position: "relative",
        width: "115px"
    };

    let domain = [0, 1000];

    let changed = (value) => {
        onChange(value[0]/1000.0);
    };

    return <>
        <SmallSliderHolder>
            <Slider rootStyle={sliderStyle} domain={domain} values={[0]}
                    onUpdate={changed} onChange={changed}>
                <Rail>
                    {({ getRailProps }) => (
                        <RailElement {...getRailProps()} />
                    )}
                </Rail>
                <Handles>
                    {({ handles, activeHandleID, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map(handle => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                    transform={(v) => v}
                                    isActive={handle.id === activeHandleID}
                                    noTooltip={true}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
            </Slider>
        </SmallSliderHolder>
    </>;
};

const BioTextInput = styled(TextInput)`
  width: 100%;
`;

const BioTextarea = styled(TextareaAutosizeNoManual)`
  resize: none;
  text-shadow: 0 2px 1px rgba(0,0,0,.8);
  color: ${Theme.colors.almostWhite};
  ${typeface(16, 400)}
`;

const BioRestrictedMarkdown = memo(styled(RestrictedMarkdown)`
  color: ${Theme.colors.almostWhite};
  text-shadow: 0 2px 1px rgba(0,0,0,.8);
  margin-bottom: 4px;
  ${typeface(16, 400)}
`);

const BioHolder = styled.div`
  display: flex;
  justify-content: center;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`

const UserName = styled.span`
  ${typeface(24, 700)};
`;

const FollowDiv = styled.div`
  text-align: center;
  height: 32px;
  margin-bottom: 20px;
`;

const FollowingButton = styled(SubtleButton)`
  background: ${Theme.colors.backgroundLighter};
  color: ${Theme.colors.nearingDim};
  &:hover {
    color: ${Theme.colors.almostWhite};
  }
`;

const FollowButton = styled(SubtleButton)`

`;

const FollowLabel = styled.span`
  margin-right: 6px;
`;

const StoryContentSlim = styled(StoryContent)`
  max-width: 540px;
  margin-bottom: 8px;
`;

const ShowcaseContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
`;

const CustomShowcaseDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomShowcaseItemButton = styled(SubtleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.mobile ? 80 : 160}px;
  height: ${p => p.mobile ? 80 : 160}px;
  margin: ${p => p.mobile ? 5 : 10}px;
`;

const EditDiv = styled.div`
  padding: 20px 40px;
  box-shadow: 0 0 6px 0 rgba(0,0,0,.5), 0 0 0 1px rgba(255,255,255,.1) inset;
  border-radius: 30px;
  background: ${Theme.colors.backgroundLight};
  margin: 0 30px;
  max-width: min(100vw, 700px);
  width: 100%;
  will-change: transform;
`;

const MapHolder = styled.div`
  height: 300px;
  width: 100%;
  margin: 20px auto 20px auto;
  max-width: min(720px, calc(100% - 40px));
  border-radius: 15px;
  box-shadow: 0px 0px 0px 1px rgba(255,255,255,.15), 0px 2px 8px 0px rgba(0,0,0,.5);
  overflow: hidden;
  position: relative;
  color: ${Theme.colors.notQuiteDim};
  z-index: 0;
`;

const SHOWCASE_FILTERS = {
    showcased: true,
    public: true
};

const PUBLIC_FILTERS = {
    showcased: false,
    public: true
};

export default inject("appState")(observer(({userIdent, userAlias, appState, setLoadingPromise}) => {

    const data = useRef(observable({
        user: null,
        userInteraction: null,
        showcaseData: null,
        storyData: null,
        profileImageFile: null,
        scale: 0,
        editingBio: false,
        editingShowcase: false,
        editedShowcaseType: null,
        newBio: null,
        customThumbs: {},
        newCustomThumbs: {},
        userImageLoaded: false
    }));

    let unmounted = useIsUnmounted();
    let editorRef = useRef(null);
    let uploadButtonRef = useRef(null);

    let isMe = false;
    if (appState.user) {
        if (userAlias) {
            isMe = (userAlias === appState.user.alias);
        } else {
            isMe = (userIdent === appState.user.ident);
        }
    }

    let hasBio = !!data.current.user && data.current.user.bio && data.current.user.bio !== "";
    let canEdit = isMe;
    let editingSomething = data.current.profileImageFile !== null || data.current.editingBio || data.current.editingShowcase;

    let getThumbs = async (idents, onlyPublic) => {
        if (idents.length > 0) {
            let query = { photoIdents: idents };
            if (onlyPublic){
                query.public = true;
            }
            return await Api.get(`/gallery/photos?${queryString.stringify(query)}`);
        } else {
            return [];
        }
    }

    let getThumbsMap = async (idents) => {
        let results = await getThumbs(idents, false);
        let map = {};
        results.forEach(t => map[t.ident] = t);
        return map;
    }

    let fileButtonPressed = () => {
        runInAction(() => data.current.profileImageFile = uploadButtonRef.current.files[0]);
    };

    let applyNewProfileImage = async () => {
        if (isMe) {
            let img = editorRef.current.getImageScaledToScreen();
            let promise = await new Promise((resolve, reject) => {
                img.toBlob((blob) => resolve(blob), 'image/jpeg', .8);
            });
            let user = await Api.post("/user/me/profile/image", promise, Api.JSON, true, false);
            if (!unmounted.current) {
                runInAction(() => data.current.user = user);
            }
            await Api.reloadAndNotify();
            resetNewProfileImage();
        }
    };

    let resetNewProfileImage = () => {
        runInAction(() => {
            data.current.profileImageFile = null;
            data.current.scale = 0;
        });
    };

    let setScale = (value) => {
        runInAction(() => {
            data.current.scale = value;
        });
    }

    let editBio = () => {
        runInAction(() => {
            data.current.editingBio = true;
            data.current.newBio = data.current.user.bio ? data.current.user.bio : "";
        });
    }

    let changeNewBio = (value) => {
        if (value.length <= 2000) {
            runInAction(() => data.current.newBio = value);
        }
    }

    let resetNewBio = () => {
        runInAction(() => {
            data.current.editingBio = false;
            data.current.newBio = null;
        });
    }

    let applyNewBio = async () => {
        let user = await Api.post("/user/me/profile/bio", {bio: data.current.newBio});
        if (!unmounted.current) {
            runInAction(() => {
                data.current.user = user;
                data.current.editingBio = false;
                data.current.newBio = null;
            });
        }
    }

    let editShowcaseSettings = () => {
        runInAction(() => {
            data.current.editingShowcase = true;
            data.current.newPreferences = toJS(data.current.user.preferences);
        });
    }

    let resetShowcaseSettings = () => {
        runInAction(() => {
            data.current.editingShowcase = false;
            data.current.newPreferences = null;
        });
    }

    let applyShowcaseSettings = async () => {
        let user = await Api.post("/user/me/profile/preferences", data.current.newPreferences);
        if (user) {
            let showcaseResults = await getShowcaseResultsPromise(user.preferences);
            if (!unmounted.current) {
                runInAction(() => {
                    data.current.user = user;
                    data.current.newPreferences = null;
                    data.current.editingShowcase = false;
                    data.current.showcaseData = showcaseResults;
                });
            }
        } else {
            if (!unmounted.current) {
                runInAction(() => {
                    data.current.user = null;
                    data.current.newPreferences = null;
                    data.current.editingShowcase = false;
                    data.current.showcaseData = null;
                });
            }
        }
    }

    let setShowcaseType = (value) => {
        if (data.current.newPreferences.showcaseType !== value) {
            runInAction(() => {
                data.current.newPreferences.showcaseType = value;
            });
        }
    }

    let pickCustomShowcaseItem = (num) => () => {
        runInAction(() => {
            appState.photoPickDirective = {
                func : (ident) => {
                    let index = data.current.newPreferences.showcaseCustomIdents.indexOf(ident);
                    if (index === -1){
                        let copy = data.current.newPreferences.showcaseCustomIdents.slice(0);
                        copy[num] = ident;
                        runInAction(() => {
                            data.current.newPreferences.showcaseCustomIdents = copy;
                        });
                    }
                },
                forcedFilters: SHOWCASE_FILTERS,
                session: "profile_select_showcase",
                title: "Select Photo to Feature"
            }
        })
    };

    let removeCustomShowcaseItem = (num) => () => {
        let copy = data.current.newPreferences.showcaseCustomIdents.slice(0);
        copy.splice(num, 1);
        runInAction(() => {
            data.current.newPreferences.showcaseCustomIdents = copy;
        });
    };

    let swapCustomShowcaseItem = (num) => () => {
        let copy = data.current.newPreferences.showcaseCustomIdents.slice(0);
        let item = copy[num];
        copy.splice(num, 1);
        copy.splice(num+1, 0, item);
        runInAction(() => {
            data.current.newPreferences.showcaseCustomIdents = copy;
        });
    };

    let doLink = url => e => {
        if (e && (e.metaKey || e.ctrlKey)){
            // allow default
        } else {
            if (e) {
                e.preventDefault();
            }
            appState.history.push(url);
        }
    }

    let photoMapLink = userAlias ? `/${userAlias}/map` : `/user/_${userIdent}/map`;
    let openPhotoMap = doLink(photoMapLink);

    let photosLink = userAlias ? `/${userAlias}/photos` : `/user/_${userIdent}/photos`;
    let openPhotos = doLink(photosLink);

    let storiesLink = userAlias ? `/${userAlias}/stories` : `/user/_${userIdent}/stories`;
    let openStories = doLink(storiesLink);

    let showcaseLink = userAlias ? `/${userAlias}/showcase` : `/user/_${userIdent}/showcase`;
    let openShowcase = doLink(showcaseLink);

    let follow = async () => {
        if (appState.user && !isMe){
            let userInteraction;
            if (userAlias) {
                userInteraction = await Api.post(`/user/alias/${userAlias}/follow`);
            } else {
                userInteraction = await Api.post(`/user/ident/${userIdent}/follow`);
            }
            runInAction(() => {
                data.current.userInteraction = userInteraction;
            });
        }
    };

    let unfollow = async () => {
        if (appState.user && !isMe){
            let userInteraction;
            if (userAlias) {
                userInteraction = await Api.post(`/user/alias/${userAlias}/unfollow`);
            } else {
                userInteraction = await Api.post(`/user/ident/${userIdent}/unfollow`);
            }
            runInAction(() => {
                data.current.userInteraction = userInteraction;
            });
        }
    };

    let fetchUserInteraction = async () => {
        runInAction(() => {
            data.current.userInteraction = null;
        });
        if (appState.user && !isMe){
            let userInteraction;
            if (userAlias) {
                userInteraction = await Api.get(`/user/alias/${userAlias}/interaction`);
            } else {
                userInteraction = await Api.get(`/user/ident/${userIdent}/interaction`);
            }
            runInAction(() => {
                data.current.userInteraction = userInteraction;
            });
        }
    };

    let getShowcaseResultsPromise = (preferences) => {
        if (preferences.showcaseType === "random"){
            let showcaseParams = {
                "public": true,
                "showcased": true,
                "num": 3
            };
            if (userAlias) {
                return Api.get(`/gallery/user/alias/${userAlias}/random?${queryString.stringify(showcaseParams)}`);
            } else {
                return Api.get(`/gallery/user/ident/${userIdent}/random?${queryString.stringify(showcaseParams)}`);
            }
        } else if (preferences.showcaseType === "recent") {
            let showcaseParams = {
                "public": true,
                "showcased": true,
                "sort-field": "showcased_at",
                "after-ident": "max",
                "after-sort-value": "max",
                "ascend": false,
                "inclusive": false,
                "null-is-big": true,
                "num": 3
            };
            if (userAlias) {
                return Api.get(`/gallery/user/alias/${userAlias}?${queryString.stringify(showcaseParams)}`);
            } else {
                return Api.get(`/gallery/user/ident/${userIdent}?${queryString.stringify(showcaseParams)}`);
            }
        } else if (preferences.showcaseType === "custom") {
            return getThumbs(preferences.showcaseCustomIdents, true);
        }
    };

    useEffect(() => {
        (async () => {
            let loading = new Promise(async (resolve, reject) => {
                runInAction(() => {
                    data.current.storyData = null;
                    data.current.showcaseData = null;
                    data.current.user = null;
                });
                let userResultsPromise;
                if (userAlias) {
                    userResultsPromise = Api.get(`/user/alias/${userAlias}`);
                } else {
                    userResultsPromise = Api.get(`/user/ident/${userIdent}`);
                }
                let storyResultsPromise;
                if (userAlias) {
                    storyResultsPromise = Api.get(`/story/for_user/alias/${userAlias}?public=true&count=3&types=default`);
                } else {
                    storyResultsPromise = Api.get(`/story/for_user/ident/${userIdent}?public=true&count=3&types=default`);
                }
                let userResults = await userResultsPromise;
                let storyResults = await storyResultsPromise;
                if (userResults) {
                    let showcaseResults = await getShowcaseResultsPromise(userResults.preferences);
                    if (!unmounted.current) {
                        runInAction(() => {
                            data.current.storyData = storyResults;
                            data.current.user = userResults;
                            data.current.showcaseData = showcaseResults;
                        });
                    }
                }
                resolve();
            });
            if (setLoadingPromise){
                setLoadingPromise(loading);
            }
            await loading;
        })();
    }, [userAlias, userIdent]);

    useEffect(() => {
        fetchUserInteraction();
    }, [userAlias, userIdent, appState.user]);

    useEffect(() => reaction(
        () => data.current.newPreferences ?
            [data.current.newPreferences.showcaseType, data.current.newPreferences.showcaseCustomIdents]
            : null,
        async () => {
            if (data.current.newPreferences && data.current.newPreferences.showcaseType === "custom") {
                let map = await getThumbsMap(data.current.newPreferences.showcaseCustomIdents);
                runInAction(() => {
                    data.current.newCustomThumbs = map;
                });
            } else {
                runInAction(() => {
                    data.current.newCustomThumbs = {};
                });
            }
        }
    ), []);

    let openShowcasePicker = () => {
        runInAction(() => {
            appState.photoPickDirective = {
                func : (ident) => {
                    runInAction(() => {
                        appState.lastSeriesNav = "next";
                        appState.underlayScrollPos = appState.viewport.windowScrollY;
                        appState.photo.initTransition = true;
                        appState.photo.fadeIn = false;
                        appState.photo.lightbox = false;
                        appState.photo.ident = ident;
                        appState.photo.sortValue = "";
                        appState.photo.triggerShowcase = ident;
                        appState.history.push("/photo/_" + ident, {
                            usePhotoOverlay: true,
                            photoIdent: ident,
                            photoSortValue: "",
                            photoSeries: null,
                            underlayLocation: toJS(appState.underlayLocation)
                        });
                    });
                },
                forcedFilters: PUBLIC_FILTERS,
                session: "profile_new_showcase",
                title: "Select Photo to Showcase"
            }
        })
    };

    let setUserImageLoaded = () => runInAction(() => data.current.userImageLoaded = true);

    return (
        <Holder>
            {!!data.current.user ?
                <>
                    <Helmet>
                        <title>{(data.current.user.name || data.current.user.alias) || "User Profile"}</title>
                    </Helmet>
                    <Breadcrumbs><UserName>{data.current.user.name}</UserName></Breadcrumbs>
                    <UserImageHolder>
                        {data.current.profileImageFile !== null && isMe ?
                            <>
                                <ProfileImageEditorHolder>
                                    <AvatarEditorModified
                                        ref={editorRef}
                                        image={data.current.profileImageFile}
                                        width={128}
                                        height={128}
                                        border={0}
                                        scale={1+data.current.scale*data.current.scale*10}
                                    />
                                </ProfileImageEditorHolder>
                                <br/>
                                <SliderDiv>
                                    <SmallSlider onChange={setScale}/>
                                </SliderDiv>
                                <br/>
                                <ButtonLikeButtonSmall onClick={resetNewProfileImage}>
                                    <XIconLightShadowed scale={20}/>
                                </ButtonLikeButtonSmall>
                                <ButtonLikeButtonGraySmall onClick={applyNewProfileImage}>
                                    <CheckmarkIconLightShadowed scale={20}/>
                                </ButtonLikeButtonGraySmall>
                            </>
                        :
                            <>
                                <ProfileImageHolder invisible={!data.current.userImageLoaded}>
                                    <UserImage onLoad={setUserImageLoaded} filename={data.current.user.imageFilename} />
                                    {isMe ?
                                        <SubtleButtonLabelHolder>
                                            <SubtleButtonLabel disabled={editingSomething} htmlFor="fileSelect"><EditIconLightShadowed scale={16}/></SubtleButtonLabel>
                                            <HiddenInput
                                                disabled={data.current.editingBio || data.current.editingShowcase}
                                                onChange={fileButtonPressed}
                                                id="fileSelect"
                                                type="file"
                                                ref={uploadButtonRef}
                                                accept="image/jpeg"
                                            />
                                        </SubtleButtonLabelHolder>
                                        : null
                                    }
                                </ProfileImageHolder>
                            </>
                        }
                    </UserImageHolder>
                    { appState.user && !isMe ?
                        <FollowDiv>
                            { data.current.userInteraction !== null ?
                                (data.current.userInteraction.follow ?
                                    <FollowingButton onClick={unfollow}>
                                        <FollowLabel>Following</FollowLabel>
                                        <IsFollowingIconLightShadowed shadowAmt={.35} scale={16}
                                                                      holderVerticalAlign={"text-bottom"}/>
                                    </FollowingButton>
                                    :
                                    <FollowButton onClick={follow}>
                                        <FollowLabel>Follow</FollowLabel>
                                        <FollowIconLightShadowed shadowAmt={.35} scale={16}
                                                                 holderVerticalAlign={"text-bottom"}/>
                                    </FollowButton>
                                )
                                : null
                            }
                        </FollowDiv>
                        : null
                    }
                    { data.current.editingBio ?
                        <CenterDiv>
                            <EditDiv>
                                <BioHolder>
                                    <BioTextInput
                                        maxLength={2000}
                                        inputComponent={BioTextarea}
                                        value={data.current.newBio}
                                        placeholder="Enter bio text"
                                        onChange={(e) => {
                                            changeNewBio(e.target.value);
                                        }}
                                    />
                                </BioHolder>
                                <CenterDiv>
                                    <ButtonLikeButtonSmall onClick={resetNewBio}>
                                        <XIconLightShadowed scale={20}/>
                                    </ButtonLikeButtonSmall>
                                    <ButtonLikeButtonGraySmall onClick={applyNewBio}>
                                        <CheckmarkIconLightShadowed scale={20}/>
                                    </ButtonLikeButtonGraySmall>
                                </CenterDiv>
                            </EditDiv>
                        </CenterDiv>
                        :
                        <>
                            { hasBio || canEdit ?
                                <BioHolder>
                                    { hasBio ? <BioRestrictedMarkdown noBottomMargin={true} text={data.current.user.bio}/> : null }
                                </BioHolder>
                                : null
                            }
                            { canEdit ?
                                <SubtleButtonHolder>
                                    <SubtleButton disabled={editingSomething} onClick={editBio}>{hasBio ? "Edit" : "Add"} bio</SubtleButton>
                                </SubtleButtonHolder>
                                : null
                            }
                        </>
                    }
                    {data.current.showcaseData !== null && (data.current.showcaseData.length > 0 || isMe) ?
                        <Section>
                            <SectionHeader>
                                <SectionHeaderLink onClick={openShowcase} href={showcaseLink}>
                                    Showcase
                                    <MoreButton><RightIconNoShadow inline={true} holderVerticalAlign={'text-bottom'} scale={12}/></MoreButton>
                                </SectionHeaderLink>
                            </SectionHeader>
                            { !data.current.editingShowcase ?
                                <>
                                    {isMe ?
                                        <SubtleButtonHolder>
                                            <SubtleButtonRoundBottomMargin onClick={openShowcasePicker}>
                                                <PlusIconLightShadowed scale={16}/>
                                            </SubtleButtonRoundBottomMargin>
                                        </SubtleButtonHolder>
                                        : null
                                    }
                                <ShowcaseContent>
                                    {data.current.showcaseData.map(photo =>
                                        <Thumbnail key={photo.ident} thumb={photo}/>
                                    )}
                                </ShowcaseContent>
                                </>
                                : null
                            }
                        </Section>
                        : null
                    }
                    {(canEdit && data.current.showcaseData) ?
                        ( data.current.editingShowcase ?
                                <CenterDiv>
                                    <EditDiv>
                                        <FormGroupDiv>
                                            <RadioGroup Component={"span"} selectedValue={data.current.newPreferences.showcaseType}
                                                        onChange={setShowcaseType}>
                                                <FormLabel>
                                                    <Radio value={"random"}/>
                                                    <FormLabelDiv>Shuffled each page view</FormLabelDiv>
                                                </FormLabel>
                                                <FormLabel>
                                                    <Radio value={"recent"}/>
                                                    <FormLabelDiv>Most recently showcased</FormLabelDiv>
                                                </FormLabel>
                                                <FormLabel>
                                                    <Radio value={"custom"}/>
                                                    <FormLabelDiv>Custom selection</FormLabelDiv>
                                                </FormLabel>
                                            </RadioGroup>
                                        </FormGroupDiv>
                                        {
                                            (data.current.newPreferences.showcaseType === "custom") ?
                                                <CustomShowcaseDiv>
                                                    {[0,1,2].map(n =>
                                                        <React.Fragment key={"custom-part-"+n}>
                                                            <CustomShowcaseItemDiv>
                                                                <CustomShowcaseItemButton
                                                                    mobile={appState.media.mobile}
                                                                    key={"custom-"+n}
                                                                    disabled={ n > data.current.newPreferences.showcaseCustomIdents.length }
                                                                    onClick={pickCustomShowcaseItem(n)}
                                                                >
                                                                    {
                                                                        (n < data.current.newPreferences.showcaseCustomIdents.length) ?
                                                                            (() => {
                                                                                let thumb = data.current.newCustomThumbs[data.current.newPreferences.showcaseCustomIdents[n]];
                                                                                return thumb ? <Thumbnail thumb={thumb} halfSize noninteractive /> : <SpinnerFadeIn/>;
                                                                            })()
                                                                            :
                                                                            <div>{n === data.current.newPreferences.showcaseCustomIdents.length ?
                                                                                <PlusIconLightShadowed scale={16}/>
                                                                                : null
                                                                            }</div>
                                                                    }
                                                                </CustomShowcaseItemButton>
                                                                {(n < data.current.newPreferences.showcaseCustomIdents.length) ?
                                                                    <ShowcaseCustomRemoveButton
                                                                        mobile={appState.media.mobile}
                                                                        onClick={removeCustomShowcaseItem(n)}
                                                                    >
                                                                        <XIconLightShadowed scale={16}/>
                                                                    </ShowcaseCustomRemoveButton>
                                                                    :
                                                                    null
                                                                }
                                                            </CustomShowcaseItemDiv>
                                                            {
                                                                (n < 2) ?
                                                                    <ShowcaseCustomSwapButton
                                                                        disabled={n >= data.current.newPreferences.showcaseCustomIdents.length-1}
                                                                        onClick={swapCustomShowcaseItem(n)}
                                                                        mobile={appState.media.mobile}
                                                                    >
                                                                        {n < data.current.newPreferences.showcaseCustomIdents.length-1 ?
                                                                            <SwapIconLightShadowed scale={appState.media.mobile ? 12 : 16}/>
                                                                            :
                                                                            null
                                                                        }
                                                                    </ShowcaseCustomSwapButton>
                                                                    : null
                                                            }
                                                        </React.Fragment>
                                                    )}
                                                </CustomShowcaseDiv>
                                                : null
                                        }
                                        <CenterDiv>
                                            <ButtonLikeButtonSmall onClick={resetShowcaseSettings}>
                                                <XIconLightShadowed scale={20}/>
                                            </ButtonLikeButtonSmall>
                                            <ButtonLikeButtonGraySmall onClick={applyShowcaseSettings}>
                                                <CheckmarkIconLightShadowed scale={20}/>
                                            </ButtonLikeButtonGraySmall>
                                        </CenterDiv>
                                    </EditDiv>
                                </CenterDiv>
                                :
                                <SubtleButtonHolder>
                                    <SubtleButton disabled={editingSomething} onClick={editShowcaseSettings}>Edit showcase selection</SubtleButton>
                                </SubtleButtonHolder>
                        )
                        : null
                    }
                    {data.current.storyData !== null && (data.current.storyData.stories.length > 0 || isMe) ?
                        <Section>
                            <SectionHeader>
                                <SectionHeaderLink onClick={openStories} href={storiesLink}>
                                    Stories
                                    <MoreButton><RightIconNoShadow inline={true} holderVerticalAlign={'text-bottom'} scale={12}/></MoreButton>
                                </SectionHeaderLink>
                            </SectionHeader>
                            {isMe ?
                                <SubtleButtonHolder>
                                    <DumbLinkContainer to={"/story/_new"}>
                                        <SubtleButtonRoundBottomMargin>
                                            <PlusIconLightShadowed scale={16}/>
                                        </SubtleButtonRoundBottomMargin>
                                    </DumbLinkContainer>
                                </SubtleButtonHolder>
                                : null
                            }
                            <StoryContentSlim>
                                { data.current.storyData.stories.map(story =>
                                    <StoryItem key={story.ident} story={story}/>
                                ) }
                            </StoryContentSlim>
                        </Section>
                        : null
                    }
                    <Section>
                        <SectionHeader><SectionHeaderLink onClick={openPhotoMap} href={photoMapLink}>
                            Photo Map
                            <MoreButton><RightIconNoShadow inline={true} holderVerticalAlign={'text-bottom'} scale={12}/></MoreButton>
                        </SectionHeaderLink></SectionHeader>
                        <MapHolder mobile={appState.media.mobile}>
                            <BaseMap
                                dimKey
                                allowPhotoClick
                                userIdent={userIdent}
                                userAlias={userAlias}
                                resizeId={`profile-page${appState.inPhoto ? '-underlay' : ''}`}
                            />
                        </MapHolder>
                    </Section>
                    <Section>
                        <SectionHeader><SectionHeaderLink onClick={openPhotos} href={photosLink}>
                            Photos
                            <MoreButton><RightIconNoShadow inline={true} holderVerticalAlign={'text-bottom'} scale={12}/></MoreButton>
                        </SectionHeaderLink></SectionHeader>
                        {isMe ?
                            <SubtleButtonHolder>
                                <DumbLinkContainer to={"/upload"}>
                                    <SubtleButtonRound>
                                        <PlusIconLightShadowed scale={16}/>
                                    </SubtleButtonRound>
                                </DumbLinkContainer>
                            </SubtleButtonHolder>
                            : null
                        }
                    </Section>
                </>
                : null
        }
        </Holder>
    );
}));

import React from "react"
import styled from '@emotion/styled'
import {inject, observer} from 'mobx-react'
import Thumbnail from "../../components/Thumbnail";
import {StoryItemStyledAsList} from "../storylist/StoryList";
import {ThumbnailContextDiv, TitleDiv, UntitledDiv} from "../frontpage/Frontpage";
import UserLink from "../../components/UserLink";
import Theme from "../../styles/Theme";
import DumbLinkContainer from "../../components/DumbLinkContainer";
import {typeface} from "../../styles/Typeface";
import InfiniteScrollList from "../../components/InfiniteScrollList";
import StandardTabbableInfiniteScrollList from "../../components/StandardTabbableInfiniteScrollList";
import LoggedOutError from "../../components/LoggedOutError";

const ElementFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ElementFlexForward = styled(ElementFlex)`
  flex-direction: column;
`;

const ElementFlexReverse = styled(ElementFlex)`
  flex-direction: column-reverse;
`;

const ElementHolder = styled.div`
  margin: 20px 0;
  width: 100%;
  max-width: 720px;
`;

const CenterDiv = styled.div`
  text-align: center;
`;

const Subtext = styled.div`
  text-align: center;
  color: ${Theme.colors.dim};
  margin-bottom: 12px;
`;

const UserLinkStyled = styled(UserLink)`
  color: ${Theme.colors.dim};
  &:hover {
    color: ${Theme.colors.almostDim};
  }
`;

const SubtextLink = styled.a`
  ${typeface(16,700,0)};
  color: ${Theme.colors.dim};
  &:hover {
    color: ${Theme.colors.almostDim};
  }
`;

const DATA = [
    {
        id: "showcased",
        label: "Showcased Only",
        path: "/feed"
    },
    {
        id: "all",
        label: "All Photos",
        path: "/feed/all"
    }
];

const getRetrievalParams = (userIdent) => (segment) => {
    let url = `/feed/ident/${userIdent}`;
    return {url: url, sortAscend: false, query: {"only-showcased": segment === "showcased"}};
};

const Feed = inject("appState")(observer(({appState, onlyShowcased}) => {

    let applySortObjToQuery = (query, sortObj) => {
        query["after-ident"] = sortObj.ident;
        query["after-ident-type"] = sortObj.type;
        query["after-sort-value"] = sortObj.sortValue;
    };
    let extractSortObj = (data) => ({
        type: data.type,
        ident: data.data.ident,
        sortValue: data.data.sortValue
    });
    let extractIdentifier = (data) => `${data.type}:${data.data.ident}`;
    let minSortObj = {type: "story", ident: "min", sortValue: "min"};
    let maxSortObj = {type: "photo", ident: "max", sortValue: "max"};

    let renderElement = (data, navCallback) => {
        let content = null;
        if (data.type === "photo") {
            content = <CenterDiv>
                <Thumbnail onNav={navCallback} thumb={data.data} doubleSize={true}/>
                <ThumbnailContextDiv doubleSize={true}>
                    <div>
                        {data.data.title && data.data.title !== "" ?
                            <TitleDiv>{data.data.title}</TitleDiv>
                            : <UntitledDiv>Photo by</UntitledDiv>}
                        <UserLinkStyled user={data.data.owner}/>
                    </div>
                </ThumbnailContextDiv>
            </CenterDiv>
        } else if (data.type === "story"){
            content = <StoryItemStyledAsList onNav={navCallback} doubleSize={!appState.media.skinny} maxWidth={720} story={data.data} showAuthor={true} showSnippet={true}/>
        }
        return <ElementHolder key={`${data.type}:${data.data.ident}`}>
            {content}
        </ElementHolder>;
    };

    let renderContainerReverse = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexReverse ref={setRef}>
                {children}
            </ElementFlexReverse>
            :
            <></>;
    };

    let renderContainer = (children, setRef) => {
        return children.length > 0 ?
            <ElementFlexForward ref={setRef}>
                {children}
            </ElementFlexForward>
            :
            <></>;
    };

    return (appState.user ? <StandardTabbableInfiniteScrollList
        label={"Your Feed"}
        subtext={<>Content from <DumbLinkContainer to={"/following"}><SubtextLink>Following</SubtextLink></DumbLinkContainer></>}
        waitFor={null}
        renderElement={renderElement}
        renderContainerReverse={renderContainerReverse}
        renderContainer={renderContainer}
        applySortObjToQuery={applySortObjToQuery}
        extractSortObj={extractSortObj}
        extractIdentifier={extractIdentifier}
        minSortObj={minSortObj}
        maxSortObj={maxSortObj}
        segmentToParams={getRetrievalParams(appState.user.ident)}
        segment={onlyShowcased ? "showcased" : "all"}
        data={DATA}
        component={InfiniteScrollList}
    /> : <LoggedOutError message={"Please log in to view your feed."}/>)
}));

export const FeedAll = () => <Feed onlyShowcased={false}/>
export const FeedOnlyShowcased = () => <Feed onlyShowcased={true}/>
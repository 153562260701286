import * as PIXI from 'pixi.js'
import ImageShaderLQ from './ImageShaderLQ'
import ImageShaderHQ from './ImageShaderHQ'
import ImageShaderLQWithAdjustments from "./ImageShaderLQWithAdjustments";

export default class ImageHQRenderer extends PIXI.ObjectRenderer {
    constructor(renderer) {
        super(renderer);
        this.shaderLQ = new ImageShaderLQ();
        this.shaderLQWithAdjustments = new ImageShaderLQWithAdjustments();
        try {
            this.shaderHQ = new ImageShaderHQ(2.0, .25, 6, false, true);
        } catch (e){
            this.shaderHQ = null;
            console.log(e);
        }
    }

    start() {
    };

    render(sprite) {
        this.quad = new PIXI.QuadUv();

        if (!sprite.texture.valid) {
            return;
        }

        let exposure = (sprite.exposure === undefined) ? 1.0 : sprite.exposure;
        let offset = (sprite.offset === undefined) ? 0.0 : sprite.offset;
        let gamma = (sprite.gamma === undefined) ? 1.0 : sprite.gamma;
        let saturation = (sprite.saturation === undefined) ? 0.0 : sprite.saturation;
        let needAdjustments = (exposure !== 1.0 || offset !== 0.0 || gamma !== 1.0 || saturation !== 0.0);

        let lqShader = needAdjustments ?  this.shaderLQWithAdjustments : this.shaderLQ;

        let shader;
        if (sprite.hq && !sprite.native) {
            shader = this.shaderHQ || lqShader;
        } else if (!sprite.native) {
            shader = lqShader;
        } else {
            shader = lqShader;
        }

        this._renderNormal(sprite, shader, exposure, offset, gamma, saturation, sprite.alpha);
    };

    _renderNormal(sprite, shader, exposure, offset, gamma, saturation, alpha) {
        this.renderer.state.setBlendMode(PIXI.BLEND_MODES.NORMAL);

        let quad = this.quad;
        let uvs = sprite.texture._uvs;

        if(shader !== this.shaderLQ) {
            shader.uniforms.exposure = exposure;
            shader.uniforms.offset = offset;
            shader.uniforms.gamma = gamma;
            shader.uniforms.saturation = saturation;
            shader.uniforms.alpha = alpha;
        }

        for (let i=0;i<8;i++) {
            quad.vertices[i] = sprite.vertexData[i];
        }

        quad.uvs[0] = uvs.x0;
        quad.uvs[1] = uvs.y0;
        quad.uvs[2] = uvs.x1;
        quad.uvs[3] = uvs.y1;
        quad.uvs[4] = uvs.x2;
        quad.uvs[5] = uvs.y2;
        quad.uvs[6] = uvs.x3;
        quad.uvs[7] = uvs.y3;

        //quad.invalidate();

        shader.uniforms.uSampler = sprite.texture.baseTexture;

        let invWorldTransform = sprite.transform.worldTransform.copyTo(new PIXI.Matrix()).invert().decompose(new PIXI.Transform());
        invWorldTransform.scale.x /= window.innerWidth;
        invWorldTransform.scale.y /= window.innerHeight;
        let invWorldMatrix = new PIXI.Matrix().setTransform(invWorldTransform.position.x, invWorldTransform.position.y, invWorldTransform.pivot.x, invWorldTransform.pivot.y, invWorldTransform.scale.x, invWorldTransform.scale.y, invWorldTransform.rotation, invWorldTransform.skew.x, invWorldTransform.skew.y);
        shader.uniforms.invWorldTransform = invWorldMatrix.toArray();

        let worldTransform = sprite.transform.worldTransform.copyTo(new PIXI.Matrix()).decompose(new PIXI.Transform());
        worldTransform.scale.x *= window.innerWidth;
        worldTransform.scale.y *= window.innerHeight;
        let worldMatrix = new PIXI.Matrix().setTransform(worldTransform.position.x, worldTransform.position.y, worldTransform.pivot.x, worldTransform.pivot.y, worldTransform.scale.x, worldTransform.scale.y, worldTransform.rotation, worldTransform.skew.x, worldTransform.skew.y);
        shader.uniforms.worldTransform = worldMatrix.toArray();

        //shader.uniforms.uTexelOffset = shader.off * 20;
        //console.log(shader.uniforms.uTexelOffset);

        this.renderer.shader.bind(shader);
        this.renderer.geometry.bind(quad);
        this.renderer.gl.drawElements(this.renderer.gl.TRIANGLES, 6, this.renderer.gl.UNSIGNED_SHORT, 0);
    };
}

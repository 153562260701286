import React from 'react';
import styled from '@emotion/styled';
import WindowHelper from "../helpers/WindowHelper";
import Footer from "./Footer";

const FooterDiv = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

const ContentAndFooter = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(var(--app-height) - ${WindowHelper.NAV_HEIGHT}px);
  width: 100%;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

export default ({children}) => {
    return <ContentAndFooter>
        <Content>{children}</Content>
        <FooterDiv>
            <Footer/>
        </FooterDiv>
    </ContentAndFooter>;
};
import styled from '@emotion/styled';
import {typeface} from "../../styles/Typeface";
import {useRef, useState} from "react";
import {
    CheckmarkIconLightShadowed,
    LeftIconLightShadowed,
    RightIconLightShadowed,
    XIconLightShadowed
} from "../../svg/Icons";
import {Button120} from "../../styles/Button";
import Theme from "../../styles/Theme";
import {observable, runInAction} from 'mobx'
import {observer, inject} from 'mobx-react';
import {SectionHeader} from "../storylist/user/UserStoryList";

export default inject("appState")(observer(({onHide, appState}) => {

    let mobile = appState.media.mobile;

    const data = useRef(observable({
        step: 0,
        image: "map"
    }));

    const next = () => {
        runInAction(() => {
            data.current.step++;
            data.current.image = stepContent[data.current.step].defImage;
        })
    }
    const prev = () => {
        runInAction(() => {
            data.current.step--;
            data.current.image = stepContent[data.current.step].defImage;
        })
    }

    const ImgButton = ({img, children, mobile}) => {
        return <EmphasisButton
            mobile={mobile}
            onClick={() => runInAction(() => data.current.image = img)}
        >
            {children}
        </EmphasisButton>;
    };

    const stepContent = [{
        component:
            <Section>
                <Headline mobile={mobile}>
                    Map your next photo expedition
                </Headline>
                <TextItems mobile={mobile}>
                    <TextItem>
                        Find interesting <ImgButton mobile={mobile} img={"locations"}>locations</ImgButton>
                    </TextItem>
                    <TextItem>
                        Discover local <ImgButton mobile={mobile} img={"wildlife"}>wildlife</ImgButton>
                    </TextItem>
                    <TextItem>
                        Reveal <ImgButton mobile={mobile} img={"lighting"}>lighting conditions</ImgButton>
                    </TextItem>
                </TextItems>
            </Section>,
        defImage: "map"
    },
        {
            component: <Section>
                <Headline mobile={mobile}>
                    How to interact
                </Headline>
                <TextItems mobile={mobile} allowWide={true}>
                    <TextItem>
                        Tap any empty space to <ImgButton mobile={mobile} img={"query"}>query the area</ImgButton>
                    </TextItem>
                    <TextItem>
                        Tap a <ImgButton mobile={mobile} img={"poi"}>point of interest</ImgButton> for more info
                    </TextItem>
                    <TextItem>
                        Text search using <ImgButton mobile={mobile} img={"search"}>search bar</ImgButton>
                    </TextItem>
                    <TextItem>
                        Swap <ImgButton mobile={mobile} img={"layers"}>map layers</ImgButton> using lower left buttons
                    </TextItem>
                </TextItems>
            </Section>,
            defImage: "interact"
        },
        {
            component: <Section>
                <Headline mobile={mobile}>
                    Features in development
                </Headline>
                <TextItems mobile={mobile}>
                    <TextItem>
                        Trip planning tools
                    </TextItem>
                    <TextItem>
                        Integration with uploaded photos
                    </TextItem>
                    <TextItem>
                        More data visualizations
                    </TextItem>
                </TextItems>
            </Section>,
            defImage: "comingsoon"
        }
    ];

    return <Container>
        <SectionHeaderSlim>Tips</SectionHeaderSlim>
        <ContentDiv>
            {stepContent[data.current.step].component}
        </ContentDiv>
        <ImageContainer>
            <MapImage
                key={data.current.image}
                mobile={mobile}
                srcSet={`/static/images/maphelp/${data.current.image}.png 2x`}
                small={data.current.step === 1}
            />
        </ImageContainer>
        <StepperDiv>
            <ArrowButton onClick={prev} visible={data.current.step>0}><LeftIconLightShadowed scale={20}/></ArrowButton>
            <StepDisplayDiv>{data.current.step + 1} ∕ {stepContent.length}</StepDisplayDiv>
            {data.current.step < stepContent.length-1 ?
                <ArrowButton onClick={next} visible={true}><RightIconLightShadowed scale={20}/></ArrowButton>
                :
                <ArrowButton onClick={onHide} visible={true}><CheckmarkIconLightShadowed scale={20}/></ArrowButton>
            }
        </StepperDiv>
        <CloseButton onClick={onHide}><XIconLightShadowed scale={20}/></CloseButton>
    </Container>;
}));

const MapImage = ({srcSet, mobile, small}) => {
    let [loaded, setLoaded] = useState(false);
    return <ImageDiv visible={loaded} mobile={mobile}>
        <img height={small ? 120 : 140} width={small ? 240 : 280} onLoad={() => setLoaded(true)} srcSet={srcSet} />
    </ImageDiv>;
};

const EmphasisButton = styled.button`
  outline: none;
  border: none;
  background: none;
  margin: unset;
  padding: unset;
  color: ${Theme.colors.almostWhite};
  text-shadow: 0px 2px 1px rgba(0,0,0,.35);
  ${p => typeface(p.mobile ? 14 : 16, 600, .0065)};
  &:hover {
    color: white;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentDiv = styled.div`
  width: 100%;
`;

const StepperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Headline = styled.div`
  ${p => typeface(p.mobile ? 18 : 22, 500, .015)};
  margin: 8px 4px;
  color: ${Theme.colors.kindaDim};
  text-align: center;
`;

const TextItem = styled.li`
  margin: 4px 4px;
`;

const TextItems = styled.ul`
  ${p => typeface(p.mobile ? 14 : 16, 400)};
  color: ${Theme.colors.almostDim};
  padding-left: 0;
  margin-bottom: 0;
`;

const Section = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArrowButton = styled(Button120)`
  background: none;
  min-width: unset;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  color: ${Theme.colors.kindaDim};
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.1), 0 1px 4px 0 rgba(0,0,0,.65);
  visibility: ${p => p.visible ? "visible" : "hidden"};
`;

const StepDisplayDiv = styled.div`
  margin: 6px;
  width: 44px;
  color: ${Theme.colors.notQuiteDim};
  pointer-events: none;
  user-select: none;
`;

const ImageDiv = styled.div`
  margin: 0 auto;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0,.375);
  transition: opacity 250ms;
  opacity: ${p => p.visible ? 1 : 0};
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 10px;
  background: none;
  border: none;
  color: ${Theme.colors.kindaDim};
  z-index: 10;
  right: 6px;
`;

const SectionHeaderSlim = styled(SectionHeader)`
  margin: 0;
  padding: 0;
  color: ${Theme.colors.notQuiteDim};
`;
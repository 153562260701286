import React, {useReducer, useRef, useEffect, useState} from "react"
import StyledModal from "./StyledModal";
import Grid from "./Grid";
import { css } from '@emotion/react'
import {runInAction, toJS} from 'mobx'
import equal from 'fast-deep-equal/es6'
import {inject, observer} from "mobx-react";
import PhotoListingFilterBar, {
    DEFAULT_SORT_DIRECTION,
    DEFAULT_SORT_FIELD,
    getFilteredPhotoListingUrl
} from "./PhotoListingFilterBar";
import styled from '@emotion/styled';
import Api from "./Api";
import {sleep} from "../helpers/AsyncHelper";
import Scroll from "./Scroll";
import Theme from "../styles/Theme";
import {FilterIconLightShadowed, PrevIconLightShadowed} from "../svg/Icons";
import {Button120} from "../styles/Button";
import {SectionHeader} from "../routes/storylist/user/UserStoryList";
import cloneDeep from 'clone-deep'

const THUMB_SIZE = 128;

const gridPaddingStyle = css`
  padding: 20px;
  padding-bottom: 100px;
`;

const ManagerLayout = styled.div`
  background: none;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const LeftPanel = styled.div`
  height: 100%;
`;

const RightPanel = styled.div` 
  position: absolute;
  height: var(--app-height);
  left: ${p => p.filtersHidable ? "0px" : "calc(275px + 5px)"};
  width: ${p => p.filtersHidable ? "100%" : "calc(100% - 275px - 5px)"};
  top: 0px;
`;

const StyledModalHideable = styled(StyledModal)`
  ${p => p.hidden ? "display: none;" : ""};
  padding: 0;
`;

const LeftPanelHolder = styled.div`
  position: fixed;
  transform: ${p => (p.minimized && p.hidable) ? "translate(-286px, 0)" : `translate(${p.hidable ? -8 : 0}px, 0)`};
  transition: transform 250ms;
  z-index: 200;
  box-shadow: ${p => p.hidable ? "0px 0px 5px 1px rgba(0,0,0,.75), 0px 0px 0px 1px rgba(100,100,100,1);" : "none"};
  will-change: transform;
  width: 280px;
  height: 100%;
`;

const ScrollLeft = styled(Scroll)`
  width: 280px;
  background: ${Theme.colors.backgroundLight};
  height: 100%;
`;

const FilterToggleButton = styled.button`
  position: absolute;
  top: 0px;
  left: 280px;
  width: 56px;
  height: 50px;
  background: ${Theme.colors.backgroundLight};
  border: none;
  outline: none;
  color: ${p => p.highlight ? Theme.colors.almostWhite : Theme.colors.dim};
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,.75), 0px 0px 0px 1px rgba(100,100,100,1);
  border-bottom-right-radius: 20px;
`;

const CancelButtonHolder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
`;

const CancelButton = styled(Button120)`
  background: rgba(25,25,25,.95);
`;

const Title = styled.div`
  color: ${Theme.colors.kindaDim};
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;
  height: 80px;
  background: linear-gradient(180deg, rgba(40,40,40,1) 0%, rgba(40,40,40,1) 50%, rgba(40,40,40,0) 100%);
  pointer-events: none;
  padding-bottom: 20px;
`;

const SectionHeaderThin = styled(SectionHeader)`
  margin: 0 50px;
  text-align: center;
`;

const usePhotoInfiniteScroll = ({paused, filterInfo, userIdent, userAlias, session, seriesCallback}) => {
    const [state, setState] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {thumbs: [], resetCount: 0, doneLoading: false}
    );

    let edge = (DEFAULT_SORT_DIRECTION === "asc") ? "min" : "max";

    const data = useRef({
        afterIdent: edge,
        afterSortValue: edge,
        loadingMore: false,
        doneLoading: false,
        doneFetching: false,
        requestNum: 0,
        pendingResults: [],
        displayedResults: [],
        loadNum: 0,
        requiresLoad: true,
    });

    let reloadResults = () => {
        setState({resetCount: state.resetCount+1});
    };

    let getMoreData = async () => {
        if (data.current.pendingResults.length === 0 && !data.current.doneFetching) {
            data.current.requestNum++;
            let thisRequestNum = data.current.requestNum;
            let defaultInfo = {
                sortDirection: DEFAULT_SORT_DIRECTION,
                sortField: DEFAULT_SORT_FIELD
            };
            let info = {...defaultInfo, ...filterInfo};
            let url = getFilteredPhotoListingUrl(256, userIdent, userAlias, data.current.afterSortValue, data.current.afterIdent, info, (url, params) => {
                let series = {};
                series.url = url;
                series.type = "browser";
                series.nextQuery = Object.assign({}, params);
                series.nextQuery["num"] = 1;
                series.previousQuery = Object.assign({}, params);
                series.previousQuery["num"] = 1;
                series.previousQuery["null-is-big"] = !params["null-is-big"];
                series.previousQuery["ascend"] = !params["ascend"];
                if (seriesCallback) {
                    seriesCallback(series);
                }
            });
            let newResults = await Api.get(url);
            if (!data.current.open || !newResults) {
                return
            }
            let willBeDone = newResults.length === 0;
            if (thisRequestNum !== data.current.requestNum) {
                return;
            }
            if (newResults.length > 0) {
                data.current.afterSortValue = newResults[newResults.length - 1].sortValue;
                data.current.afterIdent = newResults[newResults.length - 1].ident;
                data.current.pendingResults = data.current.pendingResults.concat(newResults);
            }

            if (willBeDone) {
                data.current.doneFetching = true;
            }
        }
        return data.current.pendingResults.splice(0, 64);
    };

    let loadMore = async () => {
        if(!data.current.doneLoading && (userIdent || userAlias)) {
            if (!data.current.loadingMore) {
                data.current.loadMoreNow = true;

                while (data.current.loadMoreNow && !data.current.doneLoading) {
                    data.current.loadingMore = true;
                    data.current.loadMoreNow = false;

                    data.current.loadNum++;
                    let thisLoadNum = data.current.loadNum;
                    let newResults = await getMoreData();
                    if (!data.current.open) {
                        return
                    }
                    if (!newResults || data.current.loadNum !== thisLoadNum) {
                        return;
                    }

                    data.current.doneLoading = newResults.length === 0;
                    setState({doneLoading: data.current.doneLoading});

                    while (newResults.length > 0) {
                        if (data.current.loadNum !== thisLoadNum) {
                            return;
                        }

                        data.current.displayedResults = data.current.displayedResults.concat(newResults.splice(0, 20));
                        setState({thumbs: data.current.displayedResults});

                        await sleep(1);
                        if (!data.current.open) {
                            return;
                        }
                    }

                    data.current.loadingMore = false;
                }
            } else {
                data.current.loadMoreNow = true;
            }
        }
    };

    useEffect(() => {
        data.current.open = true;

        if (!equal(data.current.lastFilterInfo, filterInfo)) {
            let edge = (filterInfo.sortDirection === "asc") ? "min" : "max";
            data.current = {...data.current, loadingMore: false, doneLoading: false, doneFetching: false, afterIdent: edge, afterSortValue: edge, pendingResults: [], displayedResults: []};
            setState({thumbs: data.current.displayedResults, doneLoading: data.current.doneLoading});
            data.current.requiresLoad = true;
            data.current.doneLoading = false;
            data.current.lastFilterInfo = cloneDeep(filterInfo);
        }

        if (!paused) {
            if (data.current.lastShownSession !== session) {
                let edge = (DEFAULT_SORT_DIRECTION === "asc") ? "min" : "max";
                data.current.afterSortValue = edge;
                data.current.afterIdent = edge;
                data.current.displayedResults = [];
                data.current.requiresLoad = true;
                data.current.doneLoading = false;
                setState({thumbs: data.current.displayedResults});
            }

            if (data.current.requiresLoad) {
                loadMore();
                data.current.requiresLoad = false;
            }

            data.current.lastShownSession = session;
        }

        return () => data.current.open = false;
    }, [session, paused, filterInfo]);

    return {
        loadMore,
        reloadResults,
        thumbs: data.current.lastShownSession === session || paused ? state.thumbs : [],
        doneLoading: state.doneLoading,
        resetCount: state.resetCount
    }
};

const usePhotoInfiniteScrollWithFilters = ({paused, session, userIdent, userAlias, forcedFilters, seriesCallback}) => {
    const [state, setState] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {filterInfo: {filters: {...forcedFilters}}, filtersActive: false}
    );

    const vars = usePhotoInfiniteScroll({
        userIdent: userIdent,
        userAlias: userAlias,
        session: session,
        paused: paused,
        filterInfo: state.filterInfo,
        seriesCallback: seriesCallback,
    });

    let data = useRef({});

    let filterBarChanged = async (info, filtersActive) => {
        setState({filterInfo: info, filtersActive: filtersActive});
    };

    useEffect(() => {
        if (!paused && data.current.oldSession !== session) {
            setState({filterInfo: {filters: {...forcedFilters}}, filtersActive: false});
            data.current.oldSession = session;
        }
    }, [session]);

    return {
        ...vars,
        filterBarChanged,
        filtersActive: state.filtersActive,
    };
}

export const PhotoSelectionPanel = inject("appState")((
    {
        paused,
        session,
        userIdent,
        userAlias,
        select,
        forcedFilters,
        mobile,
        title,
        filtersAlwaysHidable,
        container,
        containerExtraHeight,
        overlay,
        onScroll,
        appState
    }
) => {
    const [showFilters, setShowFilters] = useState(false);
    const seriesRef = useRef();

    const {loadMore, thumbs, doneLoading, resetCount, filtersActive, filterBarChanged} = usePhotoInfiniteScrollWithFilters({
        userIdent: userIdent,
        userAlias: userAlias,
        session: session,
        paused: paused,
        forcedFilters: forcedFilters,
        seriesCallback: (series) => seriesRef.current = series,
    });

    let filtersHidable = mobile || filtersAlwaysHidable;

    let openPhoto = (photo, bitmap) => {
        runInAction(() => {
            let img = photo.img[THUMB_SIZE*((window.devicePixelRatio>1) ? 2 : 1)];
            appState.speculate({
                ident: photo.ident,
                w: photo.w,
                h: photo.h,
                fn: img.fn,
                bitmap: bitmap
            });
        });
        let ident = photo.ident;
        let url = "/photo/_" + ident;
        runInAction(() => {
            appState.lastSeriesNav = "next";
            appState.underlayScrollPos = appState.viewport.windowScrollY;
            appState.photo.initTransition = true;
            appState.photo.fadeIn = true;
            appState.photo.lightbox = false;
            appState.photo.ident = ident;
            appState.photo.sortValue = photo.sortValue;
            appState.history.push(url, {
                usePhotoOverlay: true,
                photoIdent: ident,
                photoSortValue: photo.sortValue,
                photoSeries: seriesRef.current,
                underlayLocation: toJS(appState.underlayLocation)
            });
        });
    };

    return <ManagerLayout>
        <LeftPanelHolder minimized={!showFilters} hidable={filtersHidable} >
            {filtersHidable ?
                <FilterToggleButton
                    highlight={filtersActive && !showFilters}
                    minimized={!showFilters}
                    onClick={() => setShowFilters(!showFilters)}
                >
                    {showFilters ? <PrevIconLightShadowed scale={16}/> : <FilterIconLightShadowed scale={20}/>}
                </FilterToggleButton>
                : null
            }
            <ScrollLeft>
                <LeftPanel>
                    <PhotoListingFilterBar
                        changed={filterBarChanged}
                        defaultSortField={DEFAULT_SORT_FIELD}
                        defaultSortDirection={DEFAULT_SORT_DIRECTION}
                        userIdent={userIdent}
                        userAlias={userAlias}
                        forcedFilters={forcedFilters}
                        paused={paused}
                        session={session}
                    />
                </LeftPanel>
            </ScrollLeft>
        </LeftPanelHolder>
        <RightPanel filtersHidable={filtersHidable} >
            {title ? <Title><SectionHeaderThin>{title}</SectionHeaderThin></Title> : null}
            <Grid
                noSelection = {true}
                resetCount = {resetCount}
                loadMore = {loadMore}
                holderStyle = {gridPaddingStyle}
                results = {thumbs}
                thumbSize = {THUMB_SIZE / (mobile ? 2 : 1)}
                clickAction = {select}
                doubleClickAction = {select}
                paused = {paused}
                usePointer = {true}
                topSpace={title ? "40px" : "0"}
                isEmpty={thumbs.length === 0 && doneLoading}
                container={container}
                containerExtraHeight={containerExtraHeight}
                overlay={overlay}
                overlayProps={{openPhoto: openPhoto}}
                onScroll={onScroll}
            />
        </RightPanel>
    </ManagerLayout>;
});

export default inject('appState')(observer((props) => {

    let cancel = () => {
        if (props.cancelAction) {
            props.cancelAction();
        }
    };

    return <StyledModalHideable
        width={`calc(100% - ${props.appState.media.mobile ? 6 : 80}px)`}
        height={`calc(100% - ${props.appState.media.mobile ? 6 : 80}px)`}
        isOpen={true}
        hidden={props.hidden}
        onRequestClose={props.onRequestClose}
    >
        <PhotoSelectionPanel
            paused={props.hidden}
            userIdent={props.userIdent}
            userAlias={props.userAlias}
            select={props.select}
            forcedFilters={props.forcedFilters}
            session={props.session}
            title={props.title}
            mobile={props.appState.media.mobile}
        />
        <CancelButtonHolder>
            <CancelButton onClick={cancel}>Cancel</CancelButton>
        </CancelButtonHolder>
    </StyledModalHideable>
}));
import React from 'react'
import StoryList from "../StoryList";
import styled from '@emotion/styled';
import {SiteLogo} from "../../frontpage/Frontpage";
import {typeface} from "../../../styles/Typeface";

const getRetrievalParams = () => {
    let url = "/siteblog";
    let query = { "include-snippet" : true };
    return {url: url, sortAscend: false, sortField: "public_at", query: query};
};

const Header = styled.div`
  padding-top: 50px;
  padding-bottom: 20px;
`;

const TitleSpan = styled.div`
  ${typeface(20, 600, .01)};
`;

export default ({}) => {
    return <>
        <StoryList
            header={
                <>
                    <Header>
                        <SiteLogo slogan={<TitleSpan>Site Blog and News</TitleSpan>}/>
                    </Header>
                </>
            }
            showAuthor={false}
            params={getRetrievalParams()}
        />
    </>;
};

import React, {useEffect, useState, useRef} from 'react'
import Api from "../../../components/Api";
import {inject, observer} from 'mobx-react'
import DumbLinkContainer from "../../../components/DumbLinkContainer";
import styled from '@emotion/styled'
import Theme from "../../../styles/Theme";
import UserSectionHeader from "../../../components/UserSectionHeader";
import {ButtonLikeButton, TextLikeButtonSmall} from "../../../styles/Button";
import StyledModal from "../../../components/StyledModal";
import {
    Explanation, ExplanationHelpHolder, ExplanationSectionWithHelp,
    ExplanationSmall,
    ModalButtonsDiv,
    ModalFlex,
    ModalLabelDiv
} from "../../../styles/Modal";
import {Emphasis, FixedWidthEmphasis} from "../../../styles/Span";
import CodeBlock from "../../../components/CodeBlock";
import TextInput from "../../../components/TextInput";
import escapeHtml from 'escape-html'
import {TransparentInput} from "../../../styles/Input";
import {typeface} from "../../../styles/Typeface";
import Scroll from "../../../components/Scroll";
import {HelpLinkThin} from "../../../components/HelpLink";
import StoryList, {StoryItemStyledAsList} from "../StoryList";
import {useComponentWillMount} from "../../../helpers/ReactHelpers";
import {Helmet} from "react-helmet";

export const SectionHeader = styled.div`
  align-items: center;
  width: 100%;
  padding: 15px;
  color: ${Theme.colors.almostDim};
  ${typeface(14, 500, .1)};
  text-shadow: 0px 2px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
`;

const NewStoryLink = styled.div`
  text-shadow: 0px 2px 1px rgba(0,0,0,.35);
  color: ${Theme.colors.nearingDim};
  display: inline-block;
  border-radius: 20px;
  padding: 6px 14px;
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  &:hover {
    color: ${Theme.colors.kindaDim};
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0 1px 6px 0 rgba(0,0,0,.7);
  }
`;

const NewStoryHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
`;

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const StoryListHolder = styled.div`
  margin-top: 22px;
  width: 100%;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
`;

export const StoryContent = styled.div`
  ${typeface(18, 400)};
  margin-left: auto;
  margin-right: auto;
  padding-left: 6px;
  padding-right: 6px;
`;

const Holder = styled.div`
  width: 100%;
`;

const CenterDiv = styled.div`
  text-align: center;
`;

const UrlTextInput = styled(TextInput)`
  margin-bottom: 14px;
  margin-top: 2px;
  width: min(320px, calc(100% - 30px));
  display: inline-block;
  height: 34px;
`;

const UrlTextField = styled(TransparentInput)`
  &::placeholder {
    text-align: left;
  }
`;

const LabelWithPointer = styled.label`
  cursor: pointer;
  margin-bottom: 0px;
`;

const LabelBlock = styled.div`
  display: block;
  color: ${Theme.colors.almostDim};
`;

const ModalSection = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
  width: 100%;
  opacity: ${ p => p.disabled ? ".2" : "1"};
  transition: 200ms opacity;
`;

const ModalScroll = styled(Scroll)`
  width: 100%;
  max-height: calc(var(--app-height) - 120px);
`;

const NumberedIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: ${Theme.colors.kindaDim};
  color: ${Theme.colors.backgroundDarkest};
  display: inline-block;
  text-align: center;
  line-height: 24px;
  margin-right: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,.5);
`;

const ExplanationSmallLeftAlign = styled(ExplanationSmall)`
  text-align: left;
  width: calc(100% - 30px);
  margin-right: auto;
  margin-left: auto;
`;

const ElementHolder = styled.div`
  margin: 20px 0;
  width: 100%;
  max-width: 720px;
  contain: layout;
`;

const UserStoryListHeader = inject('appState')(observer((props) => {

    let [data, setData] = useState(null);
    let [embedModalOpen, setEmbedModalOpen] = useState(false);
    let [urlPattern, setUrlPattern] = useState("");

    let openEmbedModal = () => {
        setEmbedModalOpen(true);
    };
    let closeEmbedModal = () => {
        setEmbedModalOpen(false);
    };

    let embedActive = urlPattern.includes("[id]");

    let help = "Requires valid story display page URL pattern.";

    let getListCode = () => {
        return embedActive ? `<div data-storylist data-story-url-pattern="${escapeHtml(urlPattern)}"></div>` : help;
    };

    let getPageCode = () => {
        return embedActive ? `<div data-story data-story-url-pattern="${escapeHtml(urlPattern)}"></div>` : help;
    };

    let isOwner;
    if (props.userAlias){
        isOwner = (!!props.appState.user && (props.appState.user.alias === props.userAlias));
    } else {
        isOwner = (!!props.appState.user && (props.appState.user.ident === props.userIdent));
    }

    let refresh = async () => {
        let url;
        if (props.userAlias){
            url = `/story/for_user/alias/${props.userAlias}?include-snippet=true&public=false`;
        } else {
            url = `/story/for_user/ident/${props.userIdent}?include-snippet=true&public=false`;
        }
        if (!isOwner) {
            url = url + "&types=default";
        }
        let results = await Api.get(url);
        setData(results);
    };

    let unpublished = data ? data.stories : [];

    useEffect(() => {
        (async () => {
            let loading = new Promise(async (resolve, reject) => {
                await refresh();
                resolve();
            });
            if (props.setLoadingPromise){
                props.setLoadingPromise(loading);
            }
            await loading;
        })();
    }, [props.userIdent, props.userAlias, props.appState.user]);

    return(
            <Holder>
                <StoryContent>
                    {data ?
                        <>
                            <Helmet>
                                <title>{data.user.name} Stories</title>
                            </Helmet>
                            <UserSectionHeader user={data.user} section={"Stories"} />
                            { isOwner ?
                                <>
                                    <Section>
                                        <SectionHeader>Drafts</SectionHeader>
                                        <NewStoryHolder>
                                            <DumbLinkContainer to={`/story/_new`}>
                                                <a><NewStoryLink>+ New Draft</NewStoryLink></a>
                                            </DumbLinkContainer>
                                        </NewStoryHolder>
                                        { unpublished.length > 0 ?
                                            <StoryListHolder>
                                                {unpublished.map((story, index) =>
                                                    <ElementHolder key={"story-"+index}>
                                                        <StoryItemStyledAsList
                                                            story={story}
                                                            doubleSize={!props.appState.media.skinny}
                                                            showType={true}
                                                        />
                                                    </ElementHolder>
                                                )}
                                            </StoryListHolder>
                                            : null
                                        }
                                    </Section>
                                </>
                                : null
                            }
                            <>
                                {isOwner ?
                                    <>
                                    <SectionHeader>Published</SectionHeader>
                                    <CenterDiv>
                                        <TextLikeButtonSmall onClick={openEmbedModal}>HTML Embed Code</TextLikeButtonSmall>
                                    </CenterDiv>
                                        </>
                                    : null}
                            </>
                            { isOwner ?
                                <>
                                    <StyledModal
                                        width={"min(480px, 100vw)"}
                                        isOpen={embedModalOpen}
                                        onRequestClose={closeEmbedModal}
                                        contentLabel="HTML Embed Code"
                                    >
                                        <ModalScroll showArrow={true}>
                                            <ModalFlex>
                                                <ModalLabelDiv>
                                                    <ModalSection>
                                                        <CenterDiv>
                                                            <ExplanationHelpHolder>
                                                                <HelpLinkThin path={"/help/plugins/types/stories"}/>
                                                            </ExplanationHelpHolder>
                                                            <LabelWithPointer>
                                                                <LabelBlock>
                                                                    <NumberedIcon>1</NumberedIcon>
                                                                    Enter <Emphasis>URL pattern</Emphasis> of
                                                                    your <Emphasis>story display page</Emphasis>:
                                                                </LabelBlock>
                                                                <UrlTextInput
                                                                    inputComponent={UrlTextField}
                                                                    type="text"
                                                                    onChange={(e) => setUrlPattern(e.target.value)}
                                                                    value={urlPattern}
                                                                    placeholder={"/content/story.html#[id]"}
                                                                />
                                                            </LabelWithPointer>
                                                        </CenterDiv>
                                                        <ExplanationSmallLeftAlign>
                                                            <Emphasis>Note</Emphasis>:
                                                            Pattern must include "<FixedWidthEmphasis>[id]</FixedWidthEmphasis>".
                                                            On your site, this text is replaced with story identifiers. Ensure any URL that
                                                            matches the pattern returns a generic <Emphasis>story display page</Emphasis>.
                                                        </ExplanationSmallLeftAlign>
                                                    </ModalSection>
                                                    <ModalSection disabled={!embedActive}>
                                                        <Explanation><NumberedIcon>2</NumberedIcon>
                                                            Embed within your <Emphasis>story display page</Emphasis>:
                                                        </Explanation>
                                                        <CodeBlock disabled={!embedActive} height={"26px"} width={"100%"} text={getPageCode()}/>
                                                    </ModalSection>
                                                    <ModalSection disabled={!embedActive}>
                                                        <Explanation>
                                                            <NumberedIcon>3</NumberedIcon>
                                                            Embed within your <Emphasis>story list page</Emphasis>:
                                                        </Explanation>
                                                        <CodeBlock disabled={!embedActive} height={"26px"} width={"100%"} text={getListCode()}/>
                                                    </ModalSection>
                                                    <ExplanationSectionWithHelp>
                                                        <ExplanationSmall>
                                                            <ul>
                                                                <li>Only <Emphasis>public</Emphasis> stories are included</li>
                                                                <li>Pages must include <Emphasis>plugin loader code</Emphasis></li>
                                                                <li>Apply custom <Emphasis>CSS styling</Emphasis> as needed</li>
                                                            </ul>
                                                        </ExplanationSmall>
                                                    </ExplanationSectionWithHelp>
                                                </ModalLabelDiv>
                                            </ModalFlex>
                                        </ModalScroll>
                                        <ModalButtonsDiv>
                                            <ButtonLikeButton onClick={closeEmbedModal}>Close</ButtonLikeButton>
                                        </ModalButtonsDiv>
                                    </StyledModal>
                                </>
                                : null
                            }
                        </>
                        : null
                    }
                </StoryContent>
            </Holder>
    );
}));

const getRetrievalParams = (userIdent, userAlias, isOwner) => {
    let url = userAlias ? `/storyset/user/alias/${userAlias}` : `/storyset/user/ident/${userIdent}`;
    let query = { "include-snippet" : true };
    if (!isOwner) {
        query.types = "default";
    }
    return {url: url, sortAscend: false, sortField: "public_at", query: query};
};

export default inject('appState')(observer(({userIdent, userAlias, appState}) => {

    let isOwner;
    if (userAlias){
        isOwner = (!!appState.user && (appState.user.alias === userAlias));
    } else {
        isOwner = (!!appState.user && (appState.user.ident === userIdent));
    }

    let loadPromise = useRef(null);
    let resolver = useRef(null);
    useComponentWillMount(() => {
        loadPromise.current = new Promise((resolve) => resolver.current = resolve);
    });

    let setLoadingPromise = async (promise) => {
        await promise;
        resolver.current();
    }
    return <>
        <StoryList
            header={
                <UserStoryListHeader
                    userIdent={userIdent}
                    userAlias={userAlias}
                    setLoadingPromise={setLoadingPromise}
                />
            }
            waitFor={loadPromise.current}
            params={getRetrievalParams(userIdent, userAlias, isOwner)}
            showAuthor={false}
            showSnippet={true}
            showType={true}
            emptyMessage={"No published stories."}
        />
    </>;
}));

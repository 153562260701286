import styled from '@emotion/styled';
import { css } from '@emotion/react'
import {backdropFilterSupportCondition} from "../styles/BackdropFilter"
import Theme from "./Theme";
import React, {memo} from 'react'
import {typeface} from "./Typeface";

export const TextLike = css`
  background: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: none;
  color: ${Theme.colors.kindaDim};
  text-decoration: underline;
  
  &:hover {
    color: ${Theme.colors.almostWhite};
  }
`;

export const TextLikeButton = styled.button`
  ${TextLike};
  justify-content: center;
  display: inline-flex;
  margin: 4px 4px;
  height: 34px;
`;

export const TextLikeButtonSmall = styled(TextLikeButton)`
  ${typeface(14, 400)};
  color: ${Theme.colors.almostDim};
  min-width: 120px;
  height: 24px;
`;

export const ButtonLike = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  color: ${Theme.colors.almostWhite};
  margin: 4px 4px;
  user-select: none;
  outline: none;
  border: none;
  text-shadow: 0px 2px 1px rgba(0,0,0,.5);
  height: 34px;
  cursor: pointer;
  width: 120px;
  line-height: 32px;
  ${typeface(16, 400)};
  background: rgba(25,25,25,.8);
  
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  &:hover:not(:disabled) {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0 1px 6px 0 rgba(0,0,0,.7);
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: .15;
  }

  @supports ${backdropFilterSupportCondition()} {
    -webkit-backdrop-filter: blur(10px); 
    backdrop-filter: blur(10px); 
   
  }
  @supports not ${backdropFilterSupportCondition()} {
  }
`;

export const ButtonLikeButton = styled.button`
  ${ButtonLike};
`;

export const ButtonLikeButtonRed = styled(ButtonLikeButton)`
  
  @supports ${backdropFilterSupportCondition()} {
    background: rgba(160,0,0,.6);
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    background: rgba(160,0,0,.6);
  }
`;

export const ButtonLikeButtonYellow = styled(ButtonLikeButton)`
  
  @supports ${backdropFilterSupportCondition()} {
    background: rgba(180,180,0,.6);
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    background: rgba(180,180,0,.6);
  }
`;

export const ButtonLikeButtonGreen = styled(ButtonLikeButton)`
  
  @supports ${backdropFilterSupportCondition()} {
    background: rgba(0,160,0,.6);
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    background: rgba(0,160,0,.6);
  }
`;

export const ButtonLikeButtonGray = styled(ButtonLikeButton)`
  
  @supports ${backdropFilterSupportCondition()} {
    background: rgba(80,80,80,.6);
  }
  
  @supports not ${backdropFilterSupportCondition()} {
    background: rgba(80,80,80,.6);
  }
`;

export const ButtonLikeMini = css`
  display: inline-flex;
  border-radius: 8px;
  color: #000;
  margin: 0px 0px 0px 4px;
  ${typeface(10, 500, .01)};
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0,0,0,.25), 0px 0px 0px 1px rgba(255,255,255,.15) inset;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  user-select: none;
  border: none;
  padding: 0;
  height: 20px;
  background: rgba(255,255,255,.6);
  
  @supports ${backdropFilterSupportCondition()} {
    -webkit-backdrop-filter: blur(10px); 
    backdrop-filter: blur(10px); 
  }
  @supports not ${backdropFilterSupportCondition()} {
  }
  
  &:hover {
    box-shadow: 0px 2px 2px rgba(0,0,0,.25), 0px 0px 0px 1px rgba(255,255,255,.35) inset;
  }
`;

export const ButtonLikeMiniButton = styled.button`
  ${ButtonLikeMini};
  opacity: ${p => p.disabled ? .3 : 1};
  &:disabled {
    box-shadow: none;
    cursor: auto;
  }
`;

export const ButtonLikeMiniDimButton = styled(ButtonLikeMiniButton)`
  color: ${Theme.colors.almostWhite};
  box-shadow: inset 0 0 0 1px rgba(255,255,255,.075), 0 1px 4px 0 rgba(0,0,0,.5);
  &:hover {
    box-shadow: inset 0 0 0 1px rgba(255,255,255,.2), 0 1px 6px 0 rgba(0,0,0,.7);
  }
  
  background: rgba(45,45,45,.6);
  @supports ${backdropFilterSupportCondition()} {
    -webkit-backdrop-filter: blur(10px); 
    backdrop-filter: blur(10px); 
    
  }
  @supports not ${backdropFilterSupportCondition()} {
  }
`;


export const ButtonMinWidth = css`
  min-width: 80px;
  width: 120px;
`;

export const Button120 = memo(styled(ButtonLikeButton)`
  ${ButtonMinWidth}
`);

export const Button120Red = memo(styled(ButtonLikeButtonRed)`
  ${ButtonMinWidth}
`);

export const Button120Yellow = memo(styled(ButtonLikeButtonYellow)`
  ${ButtonMinWidth}
`);

export const Button120Gray = memo(styled(ButtonLikeButtonGray)`
  ${ButtonMinWidth}
`);

export const Button140 = memo(styled(ButtonLikeButton)`
  min-width: 100px;
  width: 140px;
`);

export const TransparentButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  width: 100%;
  height: 100%;
`;

export const ButtonIcon = styled.div`
  display: inline-block;
  margin-right: 8px;
  vertical-align: text-top;
  font-size: 0;
  top: -1px;
`;


const settingsButtonLikeButtonSmallRound = (base) => styled(base)`
  border-radius: 15px;
  width: auto;
  padding-left: 14px;
  padding-right: 14px;
  height: 26px;
  display: inline-block;
  ${typeface(12, 400)};
  line-height: 1.5em;
  background: ${Theme.colors.background};
  vertical-align: 1px;
`;

export const SettingsButtonLikeButtonSmallRound = settingsButtonLikeButtonSmallRound(ButtonLikeButton);
export const SettingsButtonLikeButtonSmallRoundGray = settingsButtonLikeButtonSmallRound(ButtonLikeButtonGray);
import React, {useEffect, useState} from 'react';
import TextInput from "../../components/TextInput";
import Theme from "../../styles/Theme";
import styled from '@emotion/styled';
import {MapsApi} from "../../components/Api";
import queryString from 'query-string';
import {typeface} from "../../styles/Typeface";
import MapThumbnail from "./MapThumbnail";
import FadeIn from "../../components/FadeIn";
import Scroll from "../../components/Scroll";
import {TransparentInput} from "../../styles/Input";
import {XIconLightShadowed} from "../../svg/Icons";

const ScrollContainer = styled.div`
`;

const SearchScroll = styled(Scroll)`
  height: 100%;
`;

const OuterHolder = styled.div`
  width: min(${p => p.width}, calc(100vw - 20px));
  transition: width 250ms;
  will-change: width;
  max-height: inherit;
`;

const Container = styled.div`
  background: ${Theme.colors.background};
  padding: 6px 12px 12px 12px;
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, .9);
  border: 1px solid rgba(55, 55, 55, 1);
  border-radius: 10px;
  overflow: hidden;
  min-height: 52px;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ResultHolder = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ResultText = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const ResultMainText = styled.div`
  ${typeface(14, 500)};
  color: ${Theme.colors.almostWhite};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResultSubText = styled.div`
  ${typeface(12, 400)};
  color: ${Theme.colors.notQuiteDim};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ResultThumbPadding = styled.div`
  height: 46px;
  width: 60px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ResultBlankImg = styled.div`
  padding: 5px;
  width: 30px;
  height: 30px;
  background: ${Theme.colors.backgroundLighter};
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,.75), inset 0px 0px 0px 1px rgba(255,255,255,.075);
  display: inline-block;
`;

const Results = styled.div`
  margin-top: 10px;
`;

const SearchTextInput = styled(TextInput)`
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 13px;
  top: 13px;
  color: ${Theme.colors.nearingDim};
`;

export default ({onSelect, onResultVisibilityChange, width, mobile, allowSpecies}) => {
    let [value, setValue] = useState("");
    let [results, setResults] = useState([]);
    let search = async () => {
        let query = { text: value, places: true, species: !!allowSpecies };
        let r = await MapsApi.get("/search?" + queryString.stringify(query));
        if (r) {
            setResults(r);
        }
    };
    let click = (r) => () => {
        onSelect(r);
        clear();
    };

    let clear = () => {
        setValue("");
        setResults([]);
    };

    let uppercase = (s) => s[0].toUpperCase() + s.slice(1);
    let hasResults = results.length > 0;

    useEffect(() => {
        if (onResultVisibilityChange) {
            onResultVisibilityChange(hasResults);
        }
    }, [hasResults])

    return <OuterHolder width={width}>
        <Container>
            <form onSubmit={(e) => { e.preventDefault(); search(); }}>
                <SearchTextInput
                    mobile={mobile}
                    placeholder={`Search places${allowSpecies ? ' and wildlife' : ''}`}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </form>
        { hasResults ?
            <>
                <CloseButton onClick={clear}>
                    <XIconLightShadowed scale={18}/>
                </CloseButton>
                <SearchScroll force={true} showArrow={true} bgFadeColorArray={[35,35,35]}>
                    <Results>{
                        results.slice(0, 10).map(r =>
                            <ResultHolder onClick={click(r)} key={r.type + ":" + r.id}>
                                <ResultThumbPadding>
                                    {r.image ? <MapThumbnail src={r.image} maxHeight={40} maxWidth={40} margin={0} shadowSize={4}/> : <FadeIn timeMs={150}><ResultBlankImg/></FadeIn>}
                                </ResultThumbPadding>
                                <ResultText>
                                    <ResultMainText>{ r.text }</ResultMainText>
                                    <ResultSubText>{ uppercase(r.subtext) }</ResultSubText>
                                </ResultText>
                            </ResultHolder>
                        )
                    }</Results>
                </SearchScroll>
            </>
            : null}
         </Container>
    </OuterHolder>;
};
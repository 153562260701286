import React, { useEffect, useState } from 'react';
import Api from "./Api";
import Thumbnail from "./Thumbnail";
import queryString from 'query-string'
import styled from '@emotion/styled'
import {Button120} from "../styles/Button";
import Theme from "../styles/Theme";
import {inject, observer} from 'mobx-react'
import {RightIconLightShadowed} from "../svg/Icons";
import {useIsUnmounted} from "../helpers/ReactHelpers";

const Holder = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: ${ p => p.useMinHeight ? (p => p.skinny ? '115.5px' : '231px') : `0px` };
  align-items: center;
`;

const OpenButton = styled(Button120)`
  background: none;
  min-width: unset;
  width: ${ p => p.skinny ? `30px` : `60px` };
  height: ${ p => p.skinny ? `30px` : `60px` };
  border-radius: ${ p => p.skinny ? `15px` : `30px` };
  color: ${Theme.colors.dim};
  margin: ${ p => p.skinny ? `10px` : `20px` };
  
  &:hover {
    color: ${Theme.colors.almostWhite};
  }
`

const LastThumbDiv = styled.div`
  display: flex;
  align-items: center;
`

export default inject('appState')(observer(({params, buttonAction, appState, loadedCallback, emptyDisplay, num}) => {
    let {url, query} = params;
    query["num"] = num || 3;

    let [data, setData] = useState(null);
    let unmounted = useIsUnmounted();
    useEffect(() => {
        (async () => {
            setData(null);
            let thumbs = await Api.get(url + "?" + queryString.stringify(query));
            if (!unmounted.current) {
                setData(thumbs);
            }
        })();
    }, []);

    useEffect(() => {
        if (data){
            if (loadedCallback){
                loadedCallback();
            }
        }
    }, [data]);

    return <Holder useMinHeight={!data || data.length > 0} skinny={appState.media.skinny}>
        {data && data.length === 0 ? emptyDisplay : null}
        {data ?
            ((buttonAction && data.length > 0) ?
                <>
                    { data.slice(0, data.length-1).map((thumb) => <Thumbnail key={thumb.ident} thumb={thumb}/>) }
                    <LastThumbDiv>
                        <Thumbnail key={data[data.length-1].ident} thumb={data[data.length-1]}/>
                        <OpenButton onClick={buttonAction} skinny={appState.media.skinny}><RightIconLightShadowed scale={appState.media.skinny ? 15 : 30}/></OpenButton>
                    </LastThumbDiv>
                </>
                : data.map((thumb) => <Thumbnail key={thumb.ident} thumb={thumb}/>)
            )
            : null
        }
    </Holder>
}));